/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import {
  b2cHealthCheck,
  projHealthCheck,
  registrationHealthCheck,
  clearB2CApiCache,
  clearProjApiCache,
  clearRegApiCache,
} from "../../api/testApi";
import { getTokenFromCache } from "../../auth/authHelper";
import { IToken, noToken } from "../../types/Auth";
import * as jwtDecode from "jwt-decode";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

const HomePage: React.FC = () => {
  const session = useSelector((state: RootState) => {
    return state.auth;
  }); //REDUX

  const [sessionToken, setSessionToken] = useState<IToken>(noToken());

  const [b2cTest, setB2CTest] = useState<string | null>(null);
  const [b2cTestConfig, setB2CTestConfig] = useState<string | null>(null);
  const [b2cTestAuth, setB2CTestAuth] = useState<string | null>(null);

  const [projTest, setProjTest] = useState<string | null>(null);
  const [projTestConfig, setProjTestConfig] = useState<string | null>(null);
  const [projTestAuth, setProjTestAuth] = useState<string | null>(null);

  const [regTest, setRegTest] = useState<string | null>(null);
  const [regTestConfig, setRegTestConfig] = useState<string | null>(null);
  const [regTestAuth, setRegTestAuth] = useState<string | null>(null);

  const [cacheB2CMessage, setCacheB2CMessage] = useState<string>();
  const [cacheProjMessage, setCacheProjMessage] = useState<string>();
  const [cacheRegMessage, setCacheRegMessage] = useState<string>();

  useEffect(() => {
    const token = getTokenFromCache();
    setSessionToken(token);
  }, []);

  const maskResult = (value: string | undefined): string | undefined => {
    if (value) {
      const s = value.match(
        "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
      );
      if (s !== null) {
        return (
          value.substring(0, 6) + " ... " + value.substring(value.length - 4)
        );
      }
    }
    return value;
  };

  const testB2C = async (endpoint: string, displayResult: any) => {
    await b2cHealthCheck(
      endpoint,
      (result) => {
        displayResult(result);
      },
      (error) => {
        displayResult(error);
      }
    );
  };

  const testProjApi = async (endpoint: string, displayResult: any) => {
    await projHealthCheck(
      endpoint,
      (result) => {
        displayResult(result);
      },
      (error) => {
        displayResult(error);
      }
    );
  };

  const testRegistration = async (endpoint: string, displayResult: any) => {
    await registrationHealthCheck(
      endpoint,
      (result) => {
        displayResult(result);
      },
      (error) => {
        displayResult(error);
      }
    );
  };

  const clearCache = async () => {
    setCacheB2CMessage(undefined);
    setCacheProjMessage(undefined);
    setCacheRegMessage(undefined);
    await clearB2CApiCache(
      () => setCacheB2CMessage("B2CApi cache cleared successfully"),
      (err) => setCacheB2CMessage(err)
    );
    await clearProjApiCache(
      () => setCacheProjMessage("ProjApi cache cleared successfully"),
      (err) => setCacheProjMessage(err)
    );
    await clearRegApiCache(
      () => setCacheRegMessage("RegApi cache cleared successfully"),
      (err) => setCacheRegMessage(err)
    );
  };

  const runTest = async () => {
    setB2CTest(null);
    setB2CTestConfig(null);
    setB2CTestAuth(null);
    setProjTest(null);
    setProjTestConfig(null);
    setProjTestAuth(null);
    setRegTest(null);
    setRegTestConfig(null);
    setRegTestAuth(null);

    await testB2C("healthcheck", (result) => setB2CTest(result));
    await testB2C("healthcheck/config", (result) => setB2CTestConfig(result));
    await testB2C("healthcheck/auth", (result) => setB2CTestAuth(result));

    await testProjApi("healthcheck", (result) => setProjTest(result));
    await testProjApi("healthcheck/config", (result) =>
      setProjTestConfig(result)
    );
    await testProjApi("healthcheck/auth", (result) => setProjTestAuth(result));

    await testRegistration("healthcheck", (result) => setRegTest(result));
    await testRegistration("healthcheck/config", (result) =>
      setRegTestConfig(result)
    );
    await testRegistration("healthcheck/auth", (result) =>
      setRegTestAuth(result)
    );
  };

  const getIdTokenExpiration = () => {
    const token = session.token;
    if (token) {
      const jsonToken = jwtDecode(token);
      if (jsonToken) {
        const d = new Date(0);
        d.setUTCSeconds(jsonToken.exp);
        const d1 = new Date(0);
        d1.setUTCSeconds(jsonToken.iat);
        return `${d} (issued: ${d1}) `;
      }
      return "wrong token";
    }
    return "no token";
  };

  const getAccessTokenExpiration = () => {
    const token = session.accessToken;
    if (token) {
      const jsonToken = jwtDecode(token);
      if (jsonToken) {
        const d = new Date(0);
        d.setUTCSeconds(jsonToken.exp);
        const d1 = new Date(0);
        d1.setUTCSeconds(jsonToken.iat);
        return `${d} (issued: ${d1}) `;
      }
      return "wrong token";
    }
    return "no token";
  };

  const GridRow = ({ header, value, headerColor = "textPrimary" }) => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="subtitle2"
            color={headerColor}
            style={{ padding: "2px 6px 2px 0px", minWidth: "260px" }}
          >
            {header}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            style={{ padding: "2px 2px 2px 6px", whiteSpace: "pre-line" }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div id="test-page" style={{ padding: "64px" }}>
      <GridRow header="VERSION" value={process.env.VERSION} />
      <GridRow header="NODE_ENV" value={process.env.NODE_ENV} />
      <GridRow header="PUBLIC_URL" value={process.env.PUBLIC_URL} />

      <GridRow
        header="B2C_APPLICATION"
        value={maskResult(process.env.B2C_APPLICATION)}
      />
      <GridRow
        header="APPINSIGHTS_INSTRUMENTATIONKEY"
        value={maskResult(process.env.APPINSIGHTS_INSTRUMENTATIONKEY)}
      />

      <GridRow header="User:" value={session?.user} />
      {session?.objectId?.startsWith("2424527f") ? (
        <div>
          <GridRow
            header="NODE_PATH"
            value={process.env.NODE_PATH}
            headerColor="secondary"
          />
          <GridRow
            header="B2C_API_URL"
            value={process.env.B2C_API_URL}
            headerColor="secondary"
          />
          <GridRow
            header="PROJECTS_API_URL"
            value={process.env.PROJECTS_API}
            headerColor="secondary"
          />
          <GridRow
            header="REGISTRATION_API_URL"
            value={process.env.REGISTRATION_API_URL}
            headerColor="secondary"
          />
          <GridRow
            header="B2C ID Token (cached as msal.idtoken)"
            value={session?.token}
            headerColor="secondary"
          />
          <GridRow
            header="ID Token Expiration:"
            value={getIdTokenExpiration()}
            headerColor="secondary"
          />
          <GridRow
            header="Access Token Expiration:"
            value={getAccessTokenExpiration()}
            headerColor="secondary"
          />
          <GridRow
            header="Cache:"
            value={
              sessionToken?.token
                ? "ID token successfully found in cache"
                : "No token found in cache"
            }
            headerColor="secondary"
          />
          <hr></hr>
          <div>
            <Grid container direction="row" justify="flex-start">
              <Button variant="outlined" onClick={() => runTest()}>
                Run Healthcheck
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 12 }}
                onClick={() => clearCache()}
              >
                Clear Cache
              </Button>
            </Grid>

            <hr></hr>
            {cacheB2CMessage ? (
              <GridRow
                header="B2C Api:"
                value={cacheB2CMessage}
                headerColor="primary"
              />
            ) : null}

            {cacheProjMessage ? (
              <GridRow
                header="Proj Api:"
                value={cacheProjMessage}
                headerColor="primary"
              />
            ) : null}

            {cacheRegMessage ? (
              <GridRow
                header="Reg Api:"
                value={cacheRegMessage}
                headerColor="primary"
              />
            ) : null}

            {b2cTest ? (
              <GridRow
                header="B2C Api:"
                value={b2cTest}
                headerColor="secondary"
              />
            ) : null}

            {b2cTestConfig ? (
              <GridRow
                header="B2C Api Settings:"
                value={b2cTestConfig}
                headerColor="secondary"
              />
            ) : null}

            {b2cTestAuth ? (
              <GridRow
                header="B2C Api Auth:"
                value={b2cTestAuth}
                headerColor="secondary"
              />
            ) : null}

            {projTest ? (
              <GridRow
                header="api-projects online:"
                value={projTest}
                headerColor="secondary"
              />
            ) : null}

            {projTestConfig ? (
              <GridRow
                header="api-projects settings:"
                value={projTestConfig}
                headerColor="secondary"
              />
            ) : null}

            {projTestAuth ? (
              <GridRow
                header="api-projects auth:"
                value={projTestAuth}
                headerColor="secondary"
              />
            ) : null}

            {regTest ? (
              <GridRow
                header="Registration Api:"
                value={regTest}
                headerColor="secondary"
              />
            ) : null}

            {regTestConfig ? (
              <GridRow
                header="Registration Api Settings:"
                value={regTestConfig}
                headerColor="secondary"
              />
            ) : null}

            {regTestAuth ? (
              <GridRow
                header="Registration Api Auth:"
                value={regTestAuth}
                headerColor="secondary"
              />
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HomePage;
