import * as types from "./constants";
import {
  getUserMemberships,
  IUserMembership,
} from "../../api/Account/usersApi";
import { ILoggedUser, noLoggedUser } from "../../types/Auth";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const refreshUser = async (dispatch: any) => {
  dispatch({
    type: types.GET_USER_MEMBERSHIP,
    data: { ...noLoggedUser(), fetching: true },
  });

  await getUserMemberships(
    (result: IUserMembership) => {
      const state: ILoggedUser = {
        userId: result.userId || null,
        isAdmin: result.isAdministrator,
        isCustomer: result.isCustomer,
        isDistributor: result.isDistributor,
        isSupport: result.isSupport,
        fetching: false,
        error: null,
      };
      dispatch({ type: types.GET_USER_MEMBERSHIP_SUCCESS, data: state });
    },
    (errMessage) => {
      dispatch({
        type: types.GET_USER_MEMBERSHIP_FAIL,
        data: { ...noLoggedUser(), error: errMessage },
      });
    }
  );
};
