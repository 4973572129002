import React from "react";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

export interface ICurrencyTextBoxProps {
  nameAmount: string;
  nameCcy: string;
  label: string;
  amount?: number;
  ccy?: string;
  onChangeAmount: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeCcy: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  touched: boolean | undefined;
  error?: string;
}

const CurrencyTextBox: React.FC<ICurrencyTextBoxProps> = ({
  nameAmount,
  nameCcy,
  label,
  amount,
  ccy,
  onChangeAmount,
  onChangeCcy,
  touched,
  error,
}) => {
  return (
    <Grid container>
      <Grid item>
        <div>
          <TextField
            label={label}
            name={nameAmount}
            type="number"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            value={amount}
            onChange={onChangeAmount}
          />
          {error && touched && <FormHelperText>{error}</FormHelperText>}
        </div>
      </Grid>
      <Grid item>
        <div>
          <InputLabel id="ccy-label">&nbsp;</InputLabel>
          <NativeSelect name={nameCcy} value={ccy} onChange={onChangeCcy}>
            <option></option>
            <option value="USD">USD</option>
            <option value="MXN">MXN</option>
            <option value="AUD">AUD</option>
            <option value="GBP">GBP</option>
          </NativeSelect>
        </div>
      </Grid>
    </Grid>
  );
};

export default CurrencyTextBox;
