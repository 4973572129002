import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";

export interface IProjectBranchDialogProps {
  open: boolean;
  handleSubmit: (request: string) => void;
  handleCancel: () => void;
}

const ProjectBranchDialog: React.FC<IProjectBranchDialogProps> = ({
  open,
  handleSubmit,
  handleCancel,
}) => {
  const formik = useFormik({
    initialValues: {
      branchName: "",
    },
    validationSchema: Yup.object({
      branchName: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      await handleSubmit(values.branchName);
    },
  });

  useEffect(() => {
    if (open) {
      formik.setFieldValue("branchName", "");
    }
  }, [open]);

  const useStyles = makeStyles({
    root: {
      // flexGrow: 1,
      padding: "18px",
    },
    input: {
      verticalAlign: "top",
      marginTop: "12px",
    },
  });
  const styles = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="development details"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Add Project Branch</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={styles.root}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="flex-start"
          >
            <Typography variant="body2">
              Branches are not required unless the customer has intentions to
              buy additional licenses in the future. The user will be asked for
              the branch name when activating the product
            </Typography>
            <ul>
              <li>
                <Typography variant="body2">
                  Each branch will have the same amount of MaxLicenses unless
                  the
                  <b>LicenseOverride</b> table assigns a different value
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Branches can be added at a later stage, however existing
                  licenses need a manual update from NULL to the new branch to
                  keep the license count in order
                </Typography>
              </li>
              <li>
                <Typography variant="body2">
                  Branches can be removed only when <b>no-active</b> licenses
                  exist, otherwise the <b>Licenses</b> table should be updated
                  manually with the replacement branch name
                </Typography>
              </li>
            </ul>

            <TextField
              label="Branch Name"
              type="text"
              name="branchName"
              className={styles.input}
              InputLabelProps={{ shrink: true }}
              value={formik.values.branchName}
              onChange={handleChange}
            />
            <Grid item />
            <Grid container direction="row" justify="flex-end">
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>Submit</Grid>
                  {formik.isSubmitting && (
                    <Grid item>
                      <CircularProgress size={18} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectBranchDialog;
