import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  releaseNotes: string;
  downloads: string;
  fileShare: string;
  fileDownload: string;
  shareByEmail: string;
  copyToClipboard: string;
  unauthorized: string;
  notFound: string;
  languageEs: string;
  languageEn: string;
  myOrders: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    releaseNotes: "Release Notes",
    downloads: "Downloads",
    fileShare: "Share",
    fileDownload: "Download",
    shareByEmail: "Email",
    copyToClipboard: "Clipboard",
    unauthorized: "Unauthorized",
    notFound: "File not found",
    languageEn: "Language English",
    languageEs: "Language Spanish",
    myOrders: "Orders",
  },

  es: {
    releaseNotes: "Notas de Revision",
    downloads: "Descargas",
    fileShare: "Compartir",
    fileDownload: "Descargar",
    shareByEmail: "Correo",
    copyToClipboard: "Portapapeles",
    unauthorized: "Permiso denegado",
    notFound: "No se encontro el archivo",
    languageEn: "Idioma Ingles",
    languageEs: "Idioma Español",
    myOrders: "Ordenes",
  },
});
