import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  title: string;
  header: string;
  developersHeader: string;
  distributorsHeader: string;
  smallBusinessesHeader: string;
  bigCompaniesHeader: string;
  developersText: string;
  distributorsText: string;
  smallBusinessesText: string;
  bigCompaniesText: string;
  purchaseOptions: string;
  purchaseOptionsText1: string;
  iAmA: string;
  softwareDeveloper: string;
  iSell: string;
  weighingProducts: string;
  iOwnA: string;
  smallBusiness: string;
  iWorkFor: string;
  bigCompany: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    title: "Our Services",
    header: "We offer a wide range of weighing products and services",
    iAmA: "I am a",
    softwareDeveloper: "Software Developer",
    iSell: "I sell",
    weighingProducts: "Weighing Products",
    iOwnA: "I own a",
    smallBusiness: "Small Business",
    iWorkFor: "I work for a",
    bigCompany: "Big Company",
    developersHeader: "For Developers",
    distributorsHeader:
      "For Intermediaries working in automation, engineering and scale companies",
    smallBusinessesHeader:
      "For Small Companies with a business critical operation controlled by a scale",
    bigCompaniesHeader: "For Big Companies",
    developersText:
      "<p>We offer an API service to enable you to display weight from a remote scale or to print tickets</p><p>We can help you even if your application may not provide all the configuration, weighing standards and protocols. Our API service comes with UI designers and testing tools to help you with this.</p><p>Our API tools allows device re-configuration without having to recompile or re-deploy your code. And your customer can easily change printers and scale indicators at any time</p><p><a href='/faq/purchasingFaq/developer'>Click here</a> to learn more about purchasing process</a>",
    distributorsText:
      "<p>After you provide the automation and hardware connectivity, we deal with the software integration targeting one or more specific processes.  The workstations will allow the user to interact with hardware, store their everyday transactions and generate daily reports</p><p>To ensure confidentiality and self-administration, the end customer does not necessarily need to get involved with us. You can handle directly their licensing options though our website.</p><p><a href='/faq/purchasingFaq/distributor'>Click here</a> to learn more about purchasing process</a>",
    smallBusinessesText:
      "<p>We can integrate software being it in a shop, warehouse or a farm.  One or more workstations, each one allowing the user to interact with the hardware, store their everyday transactions and generate daily reports.</p><p>Since each business is unique, we develop applications to fit your company specific needs beyond the more common applications of loading and unloading of trucks and containers, quality control through dynamometers for tension and compression tests, and vehicle control through a truck scale.</p><p><a href='/faq/purchasingFaq/customer'>Click here</a> to learn more about purchasing process</a></p>",
    bigCompaniesText:
      "<p>We integrate software for any kind of process connected to a scale.  We understand your company has many areas, some of them connected or running independently, so think of the software as micro-services where each workstation runs in isolation letting the user interact with the hardware, store their everyday transactions and generate daily reports.</p><p>The information is stored in a central location to ensure interoperability with other applications, we can also provide cloud integration in Azure if you already have the infrastructure in place.</p><p><a href='/faq/purchasingFaq/customer'>Click here</a> to learn more about purchasing process</a></p>",
    purchaseOptionsText1:
      "<p>With more than 20 years of experience, we understand that each process is different, each customer has different budget and each company has different infrastructure, therefore a 'one size fits all' approach is not your best option.</p><p>We customize products & services to meet your specific needs. Please click on the relevant link below to know more about our products and services and to enquire about options to purchase them</p>",
    purchaseOptions: "Purchase Options",
  },
  es: {
    title: "Nuestros Servicios",
    header: "Ofrecemos una amplia variedad de productos para el pesaje",
    iAmA: "Soy un",
    softwareDeveloper: "Programador Software",
    iSell: "Vendo",
    weighingProducts: "Equipo de Pesaje",
    iOwnA: "Soy dueño de",
    smallBusiness: "Pequeño Negocio",
    iWorkFor: "Trabajo para",
    bigCompany: "Empresa Grande",
    developersHeader: "Para Programadores",
    distributorsHeader:
      "Para Intermediarios trabajando en automatización, ingeniería y básculas",
    smallBusinessesHeader:
      "Para Pequeños Negocios con operaciones controladas por medio de una báscula",
    bigCompaniesHeader: "Para las Grandes Empresas",
    developersText:
      "<p>Ofrecemos una interface API para solicitar el peso mostrado en una bascula remota e impresi&oacute;n de etiquetas.</p><p>El programa que ustedes desarrollen no necesita saber de configuraciones, est&aacute;ndares y protocolos de pesaje, nosotros nos encargamos de esa parte.&nbsp; Se incluye una interface de usuario y herramientas para dise&ntilde;o y diagnostico.</p><p>Las herramientas incluidas permiten la reconfiguraci&oacute;n de los dispositivos sin requerir la re-compilaci&oacute;n, ni re-distribuci&oacute;n del software.&nbsp; El cliente puede cambiar de indicadores e impresoras en cualquier momento.</p><p>Haga <a href='/faq/purchasingFaq/developer'>clic aqui</a> para conocer el proceso de compra y contacto con nosotros</a></p>",

    distributorsText:
      "<p>Ustedes proporcionan la automatización, hardware y conexiones.  Nosotros integramos el software para controlar uno o mas procesos.  Cada estación de trabajo permite al usuario interactuar con el hardware, guardar sus transacciones del dia y generar reportes por fechas</p><p>Confidencialidad y administación autonoma: No es necesario que el cliente final interactue con nosotros, ustedes pueden manejar directamente sus licencias en nuestro portal de internet.  Opcionalmente el cliente puede obtener soporte directamente con nosotros y tener acceso al portal de internet para sus descargas</p><p>Haga <a href='/faq/purchasingFaq/distributor'>clic aqui</a> para conocer el proceso de compra y contacto con nosotros</a></p>",

    smallBusinessesText:
      "<p>Integramos software en todo tipo de negocios desde una granja, una tienda o un almacén.  El software se distribuye en una o más estaciones de trabajo y cada una le permite al usuario interactuar con el hardware, guardar sus transacciones del día y generar reportes por fechas.</p><p>Como cada negocio es único y diferente, desarrollamos productos diseñados a la medida. Las aplicaciones más comunes son carga y descarga de camiones y contenedores, control de calidad por medio de dinamómetros que miden fuerza y tensión, control de vehículos por medio de una báscula de camión, etc.</p><p>Haga <a href='/faq/purchasingFaq/customer'>clic aqui</a> para conocer el proceso de compra y contacto con nosotros</a></p>",
    bigCompaniesText:
      "<p>Desarrollamos software para cualquier tipo de operaci&oacute;n conectada a una bascula y sabemos que su empresa tiene diversas &aacute;reas, algunas conectadas y otras operando independientemente.&nbsp;&nbsp; As&iacute; que piense en el software como microservicios corriendo de forma independiente, cada estaci&oacute;n de trabajo le permite al usuario interactuar con el hardware, registrar las operaciones del d&iacute;a y generar reportes por fechas.</p><p>La informaci&oacute;n se guarda en una ubicaci&oacute;n central para permitir la interoperabilidad con otras aplicaciones.&nbsp; Tambi&eacute;n podemos integrar en la nube con Azure si ya cuenta con la infraestructura.</p><p>Haga <a href='/faq/purchasingFaq/customer'>clic aqui</a> para conocer el proceso de compra y contacto con nosotros</p>",
    purchaseOptionsText1:
      "<p>Con mas de 20 años de experiencia, sabemos que cada proceso de pesaje es único, cada cliente tiene diferente presupuesto y cada empresa cuenta con diferente infraestructura, por lo que es dificil encontrar una solución que se ajuste a todos. </p><p>Nosotros hacemos su producto a la medida y presupuesto.  Por favor seleccione la opción mas relevante para conocer nuestros servicios y las opciones para comprar nuestros productos</p>  ",
    purchaseOptions: "Proceso de compra",
  },
});
