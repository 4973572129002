/* eslint-disable */
import { setupAxios, processApiRequest } from "../apiHelper";

//SETUP
const baseUrl = process.env.B2C_API_URL;
const baseApi = setupAxios(baseUrl!);

export interface IDbUser {
  userId: string;
  name: string;
  companyName: string;
  isAdmin: boolean;
  isCustomer: boolean;
  isDistributor: boolean;
  isSupport: boolean;
  parentId: string;
}

export interface ISyncResults {
  inserted: number;
  updated: number;
  deleted: number;
  deactivated: number;
  total: number;
}

export const clearCache = async (
  onSuccess: (result: void) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "users/clear-cache";
  await processApiRequest(baseApi.get(url), {
    onSuccess: (response) => onSuccess(),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const synchronizeUsers = async (
  onSuccess: (result: ISyncResults) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "users";
  await processApiRequest(baseApi.put(url), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) => errorMessage("Not found"),
    badRequest: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};
