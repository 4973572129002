import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IResources from "./.resources";
import {
  DataTypeProvider,
  SortingState,
  SearchState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  SearchPanel,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import { Grid, Link, Typography } from "@material-ui/core";

export interface IMyDocumentRow {
  productId: string;
  productName: string;
  documentName: string;
  onOpen: (productId: string | undefined) => void;
}

export interface IMyDocumentsGridProps {
  documents: IMyDocumentRow[];
  resources: IResources;
}

const MyDocumentsGrid: React.FC<IMyDocumentsGridProps> = ({
  documents,
  resources,
}) => {
  const [rows, setRows] = useState<IMyDocumentRow[]>([]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      table: {
        maxWidth: 1024,
      },
      nestedListItem: {
        paddingLeft: theme.spacing(4),
      },
      expandButton: {
        width: "32px",
        height: "32px",
        margin: "2px 9px 2px 0",
      },
    })
  );
  const styles = useStyles();

  const useRowStyles = makeStyles({
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
  });

  const [columns] = useState([
    { name: "productName", title: resources.product },
    { name: "documentName", title: resources.document },
  ]);

  const [productColumn] = useState(["productName"]);
  const ProductColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => (
    <Link
      href="#"
      color={row.isCanceled === true ? "secondary" : "primary"}
      onClick={() => row.onOpen(row.productId)}
      variant="inherit"
    >
      <div>{row.productName}</div>
    </Link>
  );

  return (
    <React.Fragment>
      <DevExpressGrid rows={documents} columns={columns}>
        <SearchState />
        <SortingState
          defaultSorting={[{ columnName: "productName", direction: "desc" }]}
        />
        <DataTypeProvider
          for={productColumn}
          formatterComponent={ProductColumnRenderer}
        />
        <FilteringState />
        <IntegratedSorting />
        <IntegratedFiltering />
        <Table />
        <TableHeaderRow showSortingControls />
        <Toolbar />
        <SearchPanel />
      </DevExpressGrid>
    </React.Fragment>
  );
};

export default MyDocumentsGrid;
