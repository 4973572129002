import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IUserV0 } from "../../../../api/Account/usersApi";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  IInstallationDetailsRequest,
  IProjectResponse,
} from "../../../../api/Admin/dbProjectsApi";
import * as Yup from "yup";
import UserDropDown from "../../_shared/UsersDropDown";

export interface IInstallationDialogProps {
  row: IProjectResponse | undefined;
  open: boolean;
  users: IUserV0[];
  enableUser: boolean;
  handleSubmit: (request: IInstallationDetailsRequest) => void;
  handleCancel: () => void;
}

const InstallationDialog: React.FC<IInstallationDialogProps> = ({
  row,
  users,
  open,
  enableUser,
  handleSubmit,
  handleCancel,
}) => {
  const formik = useFormik({
    //enableReinitialize: true,
    initialValues: {
      isInstalled: row?.installed || false,
      installationDate: row?.installationDate || new Date(),
      installationUser: row?.installationUser || undefined,
      installationNotes: row?.installationNotes || undefined,
    },
    validationSchema: Yup.object({
      isInstalled: Yup.boolean(),
      installationDate: Yup.date().default(() => new Date()),
      installationUser: Yup.string().when("isInstalled", {
        is: true,
        then: Yup.string().required("Installation user is required"),
      }),
    }),
    onSubmit: async (values) => {
      await handleSubmit({
        programId: row?.programId || "",
        isInstalled: values.isInstalled,
        installationUser: values.installationUser || row?.installationUser,
        installationDate: values.installationDate,
        installationNotes: values.installationNotes,
      });
    },
  });

  useEffect(() => {
    if (open) {
      formik.setFieldValue("isInstalled", row?.installed);
      if (row?.installationDate) {
        formik.setFieldValue("installationDate", row?.installationDate);
      } else {
        formik.setFieldValue(
          "installationDate",
          row?.devEndDate || row?.devStartDate || row?.orderDate || new Date()
        );
      }

      formik.setFieldValue("installationUser", row?.installationUser);
      formik.setFieldValue("installationNotes", row?.installationNotes);
    }
  }, [open]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      formControls: {
        padding: "3px 0px 12px 0px",
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      input: {
        verticalAlign: "top",
        marginTop: "0px",
        paddingTop: "0px",
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
  );
  const styles = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="installed by"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">{row?.name}</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={formik.handleSubmit} className={styles.root}>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              className={styles.formControls}
            >
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      name="isInstalled"
                      color="primary"
                      checked={formik.values.isInstalled}
                      onChange={handleCheckBoxChange}
                    />
                  }
                  label="Installed"
                />
              </Grid>

              {formik.values.isInstalled ? (
                <>
                  <Grid item xs={12} sm={6}>
                    <>
                      <FormControl
                        fullWidth
                        error={
                          formik.errors.installationUser &&
                          formik.touched.installationUser
                        }
                      >
                        <UserDropDown
                          name="installationUser"
                          label="Installed By"
                          users={users}
                          value={formik.values.installationUser}
                          enabled={enableUser}
                          allowEmpty={true}
                          allowShowId={true}
                          onChange={handleChange}
                          touched={formik.touched.installationUser}
                          error={formik.errors.installationUser}
                        />
                      </FormControl>
                    </>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <DatePicker
                        name="installationDate"
                        label="Date"
                        value={formik.values.installationDate}
                        onChange={(dt) =>
                          formik.setFieldValue("installationDate", dt)
                        }
                      />
                    </FormControl>
                  </Grid>
                </>
              ) : (
                <div>Installation pending</div>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Installation Notes"
                    name="installationNotes"
                    disabled={!formik.values.isInstalled}
                    type="text"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={formik.values.installationNotes}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container justify="flex-end">
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>Submit</Grid>
                  {formik.isSubmitting && (
                    <Grid item>
                      <CircularProgress size={18} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </form>
        </MuiPickersUtilsProvider>
      </DialogContent>
    </Dialog>
  );
};

export default InstallationDialog;
