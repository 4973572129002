import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from "@material-ui/core";

import { IBlobInfo } from "../../../../api/Admin/filesApi";
import { IBlobWithLinks } from "../../../../api/Admin/dbFilesApi";
import { IUserV0 } from "../../../../api/Account/usersApi";
import UserDropDown from "../../../admin/_shared/UsersDropDown";
import DeleteIcon from "@material-ui/icons/Delete";

export interface IBlobInfoRequest {
  fileUrl: string;
  onSuccess: (result: IBlobInfo) => void;
}

export interface IBlobLinkedUsersDialogProps {
  file: IBlobWithLinks | undefined;
  users: IUserV0[];
  open: boolean;
  handleLinkUsers: (userIds: string[]) => void;
  handleUnlinkUsers: (userIds: string[]) => void;
  handleClose: () => void;
}

const BlobLinkedUsersDialog: React.FC<IBlobLinkedUsersDialogProps> = ({
  file,
  users,
  open,
  handleLinkUsers,
  handleUnlinkUsers,
  handleClose,
}) => {
  const [dropDownUser, setDropDownUser] = useState<string | undefined>();
  const [dropDownFilter, setDropDownFilter] = useState<string | undefined>();
  const [availableUsers, setAvailableUsers] = useState<IUserV1[]>([]);
  const [usersList, setUsersList] = useState<string[]>([]);

  const addUser = () => {
    if (dropDownUser && !usersList.find((x) => x === dropDownUser)) {
      setUsersList([...usersList, dropDownUser]);
      setDropDownUser(undefined);
    }
  };

  const deleteUser = (userId: string) => {
    if (usersList.find((x) => x === userId)) {
      setUsersList([...usersList.filter((u) => u !== userId)]);
    }
  };

  const linkAll = () => {
    handleLinkUsers(usersList);
  };

  const unlinkAll = () => {
    if (file?.users) {
      handleUnlinkUsers(file?.users);
    }
  };

  const refreshUsers = () => {
    switch (dropDownFilter) {
      case "Support":
        setAvailableUsers([...users.filter((x) => x.isSupport)]);
        break;
      case "Distributor":
        setAvailableUsers([...users.filter((x) => x.isDistributor)]);
        break;
      case "Customer":
        setAvailableUsers([...users.filter((x) => x.isCustomer)]);
        break;
      case "Admin":
        setAvailableUsers([...users.filter((x) => x.isAdmin)]);
        break;
      default:
        setAvailableUsers([...users]);
        break;
    }
  };

  useEffect(() => {
    if (file?.users) {
      setUsersList([...file.users]);
    } else {
      setUsersList([]);
    }
    if (users) {
      refreshUsers();
    }
  }, [open]);

  useEffect(() => {
    refreshUsers();
  }, [users, dropDownFilter]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="new project"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <>
          <Typography variant="h6">Linked Users</Typography>
          <Typography variant="subtitle2">{file?.displayName} </Typography>
          <Typography variant="body2">
            Files will appear on the user account available downloads.
            <br />
            When <strong>product but no user</strong> is assigned, then all
            users that have an order to that product will have the file as an
            available dowload.
            <br />
            When <strong>product and user</strong> is assigned, then it will be
            hidden from the rest of users sharing the same order and only
            visible to the assigned user(s)
          </Typography>
        </>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Grid container item direction="row" justify="center">
            <Grid item xs={7}>
              <UserDropDown
                name="userId"
                label="Users"
                users={availableUsers}
                value={dropDownUser}
                enabled={true}
                allowEmpty={true}
                allowShowId={true}
                onChange={(e) => setDropDownUser(e.target.value)}
                touched={false}
                error={undefined}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel id="platform-label">Filter</InputLabel>
                <Select
                  label="Filter"
                  name="dropDownFilter"
                  value={dropDownFilter}
                  onChange={(e) => setDropDownFilter(e.target.value)}
                >
                  <MenuItem value={undefined}>All</MenuItem>
                  <MenuItem value="Support">Support</MenuItem>
                  <MenuItem value="Customer">Customer</MenuItem>
                  <MenuItem value="Distributor">Distributor</MenuItem>
                  <MenuItem value="Admin">Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Button color="primary" onClick={() => addUser()}>
                Add
              </Button>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <List>
            {usersList &&
              usersList.map((x) => {
                const user = users.find((u) => u.userId === x);
                return (
                  <ListItem key={x}>
                    <ListItemText primary={user?.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        size="small"
                        onClick={() => deleteUser(x)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={4}>
            <Button onClick={unlinkAll} variant="contained" color="secondary">
              Unlink All
            </Button>
          </Grid>

          <Grid
            item
            container
            xs={8}
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <Button onClick={linkAll} variant="contained" color="primary">
              Save Changes
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default BlobLinkedUsersDialog;
