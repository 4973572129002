import React, { useState } from "react";
import { Link } from "@material-ui/core";
import {
  DataTypeProvider,
  SortingState,
  SearchState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  TableColumnWidthInfo,
  TreeDataState,
  CustomTreeData,
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableTreeColumn,
  SearchPanel,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";

import Moment from "react-moment";
import { IPurchaseOrderWithDetails } from "../../../api/Account/myOrdersApi";
import IResources, { resources } from "./.resources";
import { IUserV0 } from "../../../api/Account/usersApi";
import { ILoggedUser } from "../../../types/Auth";
import * as urls from "../../../types/Constants";

//https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/

export interface IPurchaseOrderRow extends IPurchaseOrderWithDetails {
  id: string;
  ownerName?: string;
  ownerCompany?: string;
  endUserName?: string;
  endUserCompany?: string;
  singleProductOrder: string;
  index: number;
  hasChildren: boolean;
  parentId?: string;
}

export interface IMyPurchasesGridProps {
  orders: IPurchaseOrderRow[];
  user: IUserV0;
  treeView: boolean;
  loggedUser: ILoggedUser;
  handleGoToUrl: (msg: string) => void;
  //handleNotification: (msg: string | null | undefined) => void;
}

const MyPurchasesGrid: React.FC<IMyPurchasesGridProps> = ({
  orders,
  user,
  treeView,
  loggedUser,
  handleGoToUrl,
}) => {
  const res: IResources = resources;

  const [defaultHiddenColumnNames] = useState(["endUserName", "ownerName"]);

  const [columnsCustomer] = useState([
    { name: "orderId", title: res.orderId },
    { name: "date", title: res.date },
    { name: "singleProductOrder", title: res.product },
  ]);
  const [columnWidthsCustomer, setColumnWidthsCustomer] = useState<
    TableColumnWidthInfo[]
  >([
    { columnName: "orderId", width: 150 },
    { columnName: "date", width: 150 },
    { columnName: "singleProductOrder", width: 300 },
  ]);

  const [columnsDist] = useState([
    { name: "orderId", title: res.orderId },
    { name: "date", title: res.date },
    { name: "endUserName", title: res.customerName },
    { name: "endUserCompany", title: res.companyName },
    { name: "ownerName", title: res.representedBy },
    { name: "singleProductOrder", title: res.product },
    { name: "licenses", title: "" },
  ]);
  const [columnWidthsDist, setColumnWidthsDist] = useState<
    TableColumnWidthInfo[]
  >([
    { columnName: "orderId", width: 150 },
    { columnName: "date", width: 150 },
    { columnName: "endUserName", width: 250 },
    { columnName: "endUserCompany", width: 300 },
    { columnName: "ownerName", width: 250 },
    { columnName: "singleProductOrder", width: 340 },
    { columnName: "licenses", width: 100 },
  ]);

  const getChildRows = (row, rows) => {
    const childRows = rows.filter(
      (r) => r.parentId === (row ? row.id : undefined)
    );
    return childRows.length ? childRows : null;
  };

  const [dateColumn] = useState(["date"]);
  const DateColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    return (
      <div>
        {!row.parentId && <Moment format="DD/MM/YYYY">{row.date}</Moment>}
      </div>
    );
  };

  const [singleProductOrder] = useState(["singleProductOrder"]);
  const SingleProductColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    const program = row.details[row.index];
    const url = urls.getMyDownloads(row?.endUserId || "", program.programId);
    return (
      <div>
        {!row.hasChildren && (
          <Link
            key={url}
            href="#"
            color={program.isCancelled === true ? "secondary" : "primary"}
            onClick={(e) => {
              e.preventDefault();
              handleGoToUrl(url);
            }}
          >
            {loggedUser.isCustomer ? program.productName : program.programName}
          </Link>
        )}
      </div>
    );
  };

  const [licensesColumn] = useState(["licenses"]);
  const LicensesColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    const program = row.details[row.index];
    const url = urls.getProjectLicensesUrl(
      program.programId, // x.programId,
      program.productId,
      row.endUserId,
      program.productName
    );
    return (
      <div>
        {!row.hasChildren && (
          <Link
            key={url}
            href={url}
            onClick={(e) => {
              e.preventDefault();
              handleGoToUrl(url);
            }}
            color={program.isCancelled === true ? "secondary" : "primary"}
          >
            {res.licenses}
          </Link>
        )}
      </div>
    );
  };

  const renderColumns = () => {
    if (user?.isCustomer) {
      return columnsCustomer;
    }
    return columnsDist;
  };

  return (
    <DevExpressGrid rows={orders} columns={renderColumns()}>
      <SearchState />
      <SortingState />
      {treeView && <TreeDataState />}
      <DataTypeProvider
        for={dateColumn}
        formatterComponent={DateColumnRenderer}
      />
      <DataTypeProvider
        for={singleProductOrder}
        formatterComponent={SingleProductColumnRenderer}
      />
      <DataTypeProvider
        for={licensesColumn}
        formatterComponent={LicensesColumnRenderer}
      />
      <FilteringState />
      <IntegratedFiltering />
      <IntegratedSorting />
      {treeView && <CustomTreeData getChildRows={getChildRows} />}

      <Table />
      {user?.isCustomer !== true ? (
        <TableColumnResizing
          columnWidths={columnWidthsDist}
          onColumnWidthsChange={setColumnWidthsDist}
        />
      ) : (
        <TableColumnResizing
          columnWidths={columnWidthsCustomer}
          onColumnWidthsChange={setColumnWidthsCustomer}
        />
      )}

      <TableHeaderRow showSortingControls />
      <Toolbar />
      {user?.isCustomer !== true && (
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
      )}
      {user?.isCustomer !== true && <TableFilterRow />}
      {user?.isCustomer !== true && <ColumnChooser />}
      {treeView && <TableTreeColumn for="orderId" />}
      <SearchPanel />
    </DevExpressGrid>
  );
};

export default MyPurchasesGrid;
