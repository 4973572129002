import React, { useState, useEffect } from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import { IUserV0 } from "../../../api/Account/usersApi";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import PersonIcon from "@material-ui/icons/Person";
import SwapVertIcon from "@material-ui/icons/SwapVert";

export interface IUserDropDownProps {
  name: string;
  label: string;
  users: IUserV0[];
  value: string | undefined;
  enabled: boolean;
  allowEmpty: boolean;
  allowShowId: boolean;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  touched: boolean | undefined;
  error?: string;
}

interface IUserKeyValue {
  displayValue: string;
  user: IUserV0 | undefined;
  value?: string;
}

const UserDropDown: React.FC<IUserDropDownProps> = ({
  name,
  label,
  users,
  value,
  enabled,
  allowEmpty,
  allowShowId,
  onChange,
  touched,
  error,
}) => {
  const [values, setValues] = useState<IUserKeyValue[]>([]);
  const [selectedValue, setSelectedValue] = useState<
    IUserKeyValue | undefined
  >();
  //const [showId, setShowId] = useState<boolean>(true);
  const [showAs, setShowAs] = useState<number>(0);
  const [showValueAs, setShowValueAs] = useState<number>(1);

  const switchShowAs = () => {
    setShowAs(showAs > 1 ? 0 : showAs + 1);
  };

  function getDisplayValue(user: IUserV0 | undefined): string {
    if (showAs === 0) return user?.name || "";
    else if (showAs === 1) return `${user?.companyName} (${user?.name})`; // || `(no email) ${user?.name} `;
    return user?.userId || "";
  }
  function sortListCompare(
    value1: string | null | undefined,
    value2: string | null | undefined
  ): number {
    if (value1 && !value2) return 1;
    if (!value1 && value2) return -1;
    if (value1 && value2) {
      if (value1 === value2) return 0;
      if (value1 > value2) return 1;
      return -1;
    }
    return 0;
  }

  function sortList(user1: IUserV0, user2: IUserV0): number {
    if (showAs === 0) return sortListCompare(user1?.name, user2?.name);
    if (showAs === 1)
      return sortListCompare(user1?.companyName, user2?.companyName);
    if (showAs === 2) return sortListCompare(user1?.userId, user2?.userId);

    return 0;
  }

  const switchShowInfoAs = () => {
    setShowValueAs(showValueAs > 2 ? 0 : showValueAs + 1);
  };

  function getInfoDisplayValue(user: IUserV0 | undefined): string | undefined {
    //if (showValueAs === 0) return `${user?.companyName} (${user?.name})`;     // user?.companyName || `(no email) ${user?.name}`;
    if (showValueAs === 0) return user?.userId;
    else if (showValueAs === 1)
      return user?.companyName || `(no company) ${user?.name}`;
    else if (showValueAs === 2) {
      if (user) {
        return user?.isAdmin
          ? "Role: Admin"
          : user?.isCustomer
          ? "Role: Customer"
          : user?.isDistributor
          ? "Role: Distributor"
          : user?.isSupport
          ? "Role: Support"
          : "???";
      } else {
        return "";
      }
    }
    return user?.name;
  }

  useEffect(() => {
    let list: IUserKeyValue[];
    if (allowEmpty === true || value === undefined || value === null) {
      list = [
        { displayValue: "", user: undefined, value: undefined },
        ...users.sort(sortList).map((u) => ({
          displayValue: getDisplayValue(u),
          user: u,
          value: u.userId,
        })),
      ];
    } else {
      list = [
        ...users.sort(sortList).map((u) => ({
          displayValue: getDisplayValue(u),
          user: u,
          value: u.userId,
        })),
      ];
    }
    setValues(list);
    const selected: IUserKeyValue | undefined = list.find(
      (x) => x.value === value
    );
    setSelectedValue(selected);
  }, [users, showAs]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(values.find((x) => x.value === event.target.value));
    onChange(event);
  };

  const UserIdLabel = () => {
    if (allowShowId) {
      return (
        <FormHelperText>
          {getInfoDisplayValue(selectedValue?.user)}
        </FormHelperText>
      );
    }
    return null;
  };

  const useStyles = makeStyles({
    container: {
      display: "inline-block",
      margin: "2px 4px 2px 8px",
    },
    button: {
      display: "inline",
    },
    userIcon: {
      margin: "2px 8px 2px 0 px",
    },
  });
  const styles = useStyles();

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={1}>
        <PersonIcon
          className={styles.userIcon}
          onClick={() => switchShowAs()}
        />
      </Grid>
      <Grid item xs={10}>
        <InputLabel id="user-label">{label}</InputLabel>
        <NativeSelect
          value={value}
          className={styles.container}
          disabled={enabled === false}
          style={{ width: "100%" }}
          onChange={handleChange}
          inputProps={{
            name: `${name}`,
            id: `${name}`,
          }}
        >
          {values.map((item, index) => (
            <option key={index} value={item.value}>
              {item.displayValue}
            </option>
          ))}
        </NativeSelect>
        {error && touched ? (
          <FormHelperText>{error}</FormHelperText>
        ) : (
          <UserIdLabel />
        )}
      </Grid>

      <Grid item xs={1}>
        {value && (
          <IconButton
            aria-label="view details as"
            size="small"
            onClick={() => switchShowInfoAs()}
          >
            <SwapVertIcon fontSize="inherit" />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default UserDropDown;
