import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  title: string;
  productDescription: string;
  trial: string;
  trialDays: string;
  demoAvailable: string;
  requestTrial: string;
  operations: string;
  operationsText: string;
  inbound: string;
  inboundText: string;
  outbound: string;
  outboundText: string;
  public: string;
  publicText: string;
  devices: string;
  devicesText: string;
  scales: string;
  scalesText: string;
  printers: string;
  printersText: string;
  tickets: string;
  ticketsText: string;
  ticketsRs232: string;
  ticketsRs232Text: string;
  ticketsWin: string;
  ticketsWinText: string;
  ticketsEmail: string;
  ticketsEmailText: string;
  reports: string;
  reportsText: string;
  customFields: string;
  customFieldsText: string;
  multipleScales: string;
  multipleScalesText: string;
  storedTare: string;
  storedTareText: string;
  indicatorsSpecs: string;
  indicatorsSpecsText: string;
  protocols: string;
  protocolsText: string;
  users: string;
  usersText: string;
  excel: string;
  excelText: string;
  email: string;
  emailText: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    title: "Weighbridge",
    productDescription: "Truck weighing and ticketing system",
    trial: "Free Trial",
    trialDays: "Free 30 days trial",
    demoAvailable: "Demo mode is available with no time limit",
    requestTrial: "Continue",
    operations: "Business Operations",
    operationsText:
      "Keep track of all inbound and outbound product passing through the scale",
    inbound: "Goods In",
    inboundText:
      "Incoming product from one or many suppliers. The truck weighs in full and returns back empty",
    outbound: "Goods Out",
    outboundText:
      "Outgoing product for one or more customers/warehouses. The truck weighs in empty and weighs out with one or more loads of product(s)",
    public: "External",
    publicText:
      "Offer weighing and ticketing services to external customers for a fixed fee, and keep track of the transactions and usage statistics",
    devices: "Devices",
    devicesText: "You can connect almost any scale indicator and printer",
    scales: "Scales",
    scalesText:
      "Compatible with most brands and models of indicators in the market. Supports RS232 and Ethernet connections",
    printers: "Printers",
    printersText:
      "Compatible with most popular brands and models, dot matrix using RS232 and any printer installed in Windows",
    tickets: "Customize your tickets",
    ticketsText:
      "A built-in ticket editor allows complex tickets to be generated, with special characters, formatting, and formulas calculated at run-time",
    ticketsRs232: "RS232",
    ticketsRs232Text:
      "Serial printers are ideal for barcode printing, harsh environments and heavy daily use. No installation required in Windows",
    ticketsWin: "Windows",
    ticketsWinText:
      "Supports any printer installed in Windows, usually heavy daily use such as Epson LX300, Brother and others",
    ticketsEmail: "Email",
    ticketsEmailText:
      "Environment friendly and cost efective solution. Reduce costs on ink, paper and printer maintenance. The user asks for an email to the driver and the ticket is sent by email in PDF format",
    reports: "Reports",
    reportsText:
      "An integrated report generator allows summary and details of all transactions, per customer, dates, suppliers, etc. Print, export to Excel, PDF, CSV, and send by email",
    customFields: "Custom Fields",
    customFieldsText:
      "Need more or different fields? Supports up to 8 extra fields specific to your business",
    multipleScales: "Multiple Scales",
    multipleScalesText:
      "More than one scale? Supports up to 3 scales, the user can choose the scale on each transaction",
    storedTare: "Stored Tare",
    storedTareText:
      "Long queue of vehicles? Register known tare weights on your vehicles for single weigh operations",
    offline: "Offline Mode",
    offlineText:
      "Scale or PC in mainteanence? The supervisor can register manual operations (gross, tare, net) and identify these later in your reports",
    indicatorsSpecs: "Indicators",
    indicatorsSpecsText:
      "Old school indicator? Supports the most basic modes, such as enquire, broadcast and print button. In addition the most common formats such as SMA and AWTX, or custom formats",
    protocols: "Protocols",
    protocolsText: "Supports RS232 and Ethernet connections",
    users: "User Accounts",
    usersText:
      "Single user or multi-user access to keep track of your scale operators and their shifts",
    excel: "Excel",
    excelText:
      "The application has multiple reports, each one can be sent to Excel for further data analysis",
    email: "Email",
    emailText:
      "Tickets and reports can be exported to PDF and sent by email.  Standard SMTP settings are available and Sengrid which can be used to protect your internal email accounts",
  },
  es: {
    title: "Weighbridge",
    productDescription:
      "Sistema de pesaje de camiones con impresión de etiquetas",
    trial: "Version Prueba",
    trialDays: "Version de prueba por 30 dias",
    demoAvailable: "Modo demo disponible sin limite de tiempo",
    requestTrial: "Continuar",
    operations: "Operaciones de Negocio",
    operationsText:
      "Registre todas las entradas y salidas de producto por medio de una bascula camionera",
    inbound: "Entradas",
    inboundText:
      "Entrada de producto de proveedores.  El vehículo se pesa lleno y una segunda pesada vacio",
    outbound: "Salidas",
    outboundText:
      "Salida de producto para clientes y otras plantas.  El vehículo se pesa vacio y se procede con una o mas pesadas de producto(s)",
    public: "Publica",
    publicText:
      "Podrá ofrecer pesadas y etiquetas a clientes externos por una cuota.  Registre estas transacciones y estadisticas de uso",
    devices: "Dispositivos",
    devicesText: "Puede conectar casi cualquier indicador e impresora",
    scales: "Indicadores",
    scalesText:
      "Compatible con las marcas y modelos de indicador mas comunes en el mercado.  Soporta conexiones RS232 y ethernet",
    printers: "Impresoras",
    printersText:
      "Compatible con las impresoras mas comunes en el mercado, de matriz de puntos por RS232 e impresoras instaladas en Windows",
    tickets: "Personalize sus Etiquetas",
    ticketsText:
      "Un editor de etiquetas integrado permite generar etiquetas complejas con caracteres especiales, formatos y formulas calculadas en tiempo real",
    ticketsRs232: "RS232",
    ticketsRs232Text:
      "Impresoras seriales normalmente matriz de punto son ideales para codigos de barras, ambientes dificiles y alto uso.  No require instalación en Windows",
    ticketsWin: "Windows",
    ticketsWinText:
      "Cualquier impresora instalada en Windows se puede utilizar.  De preferencia para alto uso como Epson LX300, Brother y otras",
    ticketsEmail: "Email",
    ticketsEmailText:
      "Solución amigable con el medio ambiente y de bajo costo.  Reduce costos en tinta, papel y mantenimiento.  El usuario pide al conductor su correo y este se envia en formato PDF",
    reports: "Reportes",
    reportsText:
      "Con un reporteador integrado podrá generar reportes por fechas, producto, cliente, etc.  Exporte sus reportes a Excel, PDF y correo electrónico",
    customFields: "Campos Personalizados",
    customFieldsText:
      "Requiere mas o diferentes campos?  El programa permite agregar hasta 8 campos personalizados a las necesidades de su negocio",
    multipleScales: "Múltiples Básculas",
    multipleScalesText:
      "Mas de una báscula?  El programa permite conectar hasta 3 indicadores de peso, el operador selecciona la báscula al momento de la pesada",
    storedTare: "Taras Almacenadas",
    storedTareText:
      "Largas filas de vehículos?  Registre taras fijas a sus vehículos conocidos, para reducir sus operaciones a una solo pesada",
    offline: "Modo Offline",
    offlineText:
      "Su báscula o computadora están en reparación?  El programa le permite al supervisor capturar manualmente los pesos (bruto, tara y neto).  Estas pesadas seran marcadas luego en sus reportes",
    indicatorsSpecs: "Indicadores",
    indicatorsSpecsText:
      "Tiene un indicador viejo?  El programa es compatible con los protocolos mas básicos como demanda, continuo y botón Print. Tambien comunica con protocolos SMA, AWTX y otors personalizados",
    protocols: "Protocolos",
    protocolsText: "Compatible con comunicación serial RS232 y Ethernet",
    users: "Cuentas de Usuario",
    usersText:
      "Seguridad con cuentas de usuario es una opción que le permite tener mas de un operador y llevar control de sus operaciones y diferentes turnos",
    excel: "Excel",
    excelText:
      "El programa cuenta con multiples reportes, cada uno puede exportarse a Excel ",
    email: "Email",
    emailText:
      "Etiquetas y reportes pueden exportarse en formato PDF y ser enviados por correo.  Acepta configuraciones SMTP corporativas y SendGrid para proteger sus direcciones internas de correo",
  },
});
