import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IUserV1, getB2CUser } from "../../../api/Account/usersApi";
import { RootState } from "../../../redux/reducers/rootReducer";
import { ILoggedUser } from "../../../types/Auth";
import { toast, ToastContainer } from "react-toastify";
import {
  Backdrop,
  CircularProgress,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Button,
  Divider,
  Card,
  CardContent,
  CardActions,
  Container,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import IResources, { resources } from "./.resources";
import * as urls from "../../../types/Constants";
import AccountsImageDark from "../../../images/user_account_dark.png";
import AccountsImageLight from "../../../images/user_account_light.png";
import { ThemeOptions } from "../../../types/Enums";

export interface IAccountPageProps {
  themeName: ThemeOptions;
}

const AccountPage: React.FC = ({ themeName }) => {
  const loggedUser: ILoggedUser = useSelector((state: RootState) => {
    return state.userInfo;
  });
  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });
  const history = useHistory();

  const [user, setUser] = useState<IUserV1>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mailTo, setMailTo] = useState<string>();

  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const refreshAsync = () => {
    if (loggedUser?.userId) {
      setIsLoading(true);
      getB2CUser(
        loggedUser.userId,
        (result) => {
          setUser(result);
          setIsLoading(false);
          const mailTo =
            "mailto:support@softion.com?subject=Change of contact details";
          setMailTo(mailTo);
        },
        (error) => {
          showError(error);
          setIsLoading(false);
        }
      );
    }
  };
  useEffect(() => {
    refreshAsync();
  }, [loggedUser]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        padding: "0px 6px 0px 32px",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      card: {
        backgroundColor: theme.palette.background.default,
        margin: "32px",
        padding: "16px",
        minWidth: 420,
        maxWidth: 420,
        [theme.breakpoints.down("md")]: {
          minWidth: "90%",
          maxWidth: "90%",
        },
      },
      divider: {
        margin: "6px 0 12px 0",
      },
      image: {
        width: 350,
        height: 350,
        opacity: 0.85,
      },
      privacyInfo: {
        textAlign: "center",
        maxWidth: 480,
      },
    })
  );
  const styles = useStyles();

  const ProfileData = ({ caption, value }) => {
    return (
      <Grid item container>
        <Grid item xs={4}>
          <Typography variant="subtitle2">{caption}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="subtitle1">{value || ""}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container>
      <Grid
        container
        className={styles.header}
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Typography variant="h2">{res.myAccountTitle}</Typography>
        <Typography variant="body1">{res.myAccountSubtitle}</Typography>
      </Grid>

      <Backdrop className={styles.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ToastContainer />
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid
          item
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          md={6}
        >
          <Grid>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h5">{res.personalInfo}</Typography>
                <Divider variant="middle" className={styles.divider} />
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <>
                    <ProfileData
                      caption={res.displayName}
                      value={user?.displayName}
                    />
                    <ProfileData
                      caption={res.companyName}
                      value={user?.companyName}
                    />
                    <ProfileData
                      caption="Phone Numbers"
                      value={user?.phoneNumbers}
                    />
                  </>
                </Grid>
              </CardContent>
              <CardActions>
                <Button variant="outlined" color="primary" href={mailTo}>
                  {res.changeDetails}
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h5">{res.loginInfo}</Typography>
                <Divider variant="middle" className={styles.divider} />
                <Grid
                  container
                  direction="column"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <>
                    <ProfileData caption={res.userName} value={user?.email} />
                  </>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => history.push(urls.URL_FORGOT_PASSWORD)}
                >
                  {res.changePassword}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          md={6}
        >
          <Grid item>
            <img
              alt="logo"
              src={
                themeName === "dark" ? AccountsImageDark : AccountsImageLight
              }
              className={styles.image}
            />
          </Grid>
          <Grid item container direction="column" alignItems="center">
            <Typography className={styles.privacyInfo}>
              {res.privacyInfo}
            </Typography>
            <br />
            <Typography className={styles.privacyInfo}>
              {res.contactUsText}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountPage;
