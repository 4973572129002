import React from "react";
import { render } from "react-dom";
import App from "./components/App";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./redux/Store";
import { Provider as ReduxProvider } from "react-redux";
import browserHistory from "./helpers/browserHistory";

//import * as serviceWorker from './serviceWorker';

render(
  <ReduxProvider store={store}>
    <Router history={browserHistory}>
      <App />
    </Router>
  </ReduxProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
