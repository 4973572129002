/* eslint-disable */
//import { IProductsResponse } from "@softion/b2c-service";
import { setupAxios, processApiRequest } from "../apiHelper";
import { rememberProjApi } from "../testApi";

export interface IFileDownload {
  id: number;
  fileId: number;
  userId: string;
  productId: string;
  displayName: string;
  category: string;
  url: string;
  version?: string;
  language?: string;
  platform?: string;
  description?: string;
  releaseNotes?: string;
  size?: number;
  date?: Date;
  isActive: boolean;
}

export interface ProductInfo {
  groupName: string | undefined;
  productId?: string | undefined;
  productName?: string | undefined;
  orderId?: string | undefined;
  orderDate?: Date | undefined;
  latestVersion?: string | undefined;
  isSingleOrder: boolean;
  singleOrder?: ProjectInfo | undefined;
  projects?: ProjectInfo[] | undefined;
  files?: IFileDownload[] | undefined;
}

export interface ProjectInfo {
  projectId?: string | undefined;
  userId?: string | undefined;
  userName?: string | undefined;
  companyName?: string | undefined;
  referenceName?: string | undefined;
  orderId?: string | undefined;
  orderDate?: Date | undefined;
}

export interface IProductsResponse {
  userId?: string;
  userName?: string;
  products: ProductInfo[];
  programId?: string;
  productId?: string;
  productName?: string;
}

export interface IProductAndName {
  productId: string;
  productName?: string;
}

//SETUP
const baseUrl = process.env.PROJECTS_API;
const baseApi = setupAxios(baseUrl!);

const getProductsFromUrl = async (
  url: string,
  onSuccess: (result: IProductsResponse) => void,
  errorMessage: (msg: string) => void
) => {
  await processApiRequest(baseApi.get<IProductsResponse>(url), {
    onSuccess: (response) => {
      rememberProjApi();
      onSuccess(response.data);
    },
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const getMyProducts = async (
  userId: string,
  programId: string | undefined,
  onSuccess: (result: IProductsResponse) => void,
  errorMessage: (msg: string) => void
) => {
  const url = programId
    ? `myproducts/${userId}?programId=${programId}`
    : `myproducts/${userId}`;
  await getProductsFromUrl(url, onSuccess, errorMessage);
};
