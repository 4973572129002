import React, { useState } from "react";
import { IProjectV0Ex } from "../../../api/Admin/dbProjectsApi";
import { IUserV0 } from "../../../api/Account/usersApi";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Link, Grid, Chip, Avatar } from "@material-ui/core";
import Moment from "react-moment";
import {
  DataTypeProvider,
  SortingState,
  SearchState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  TableColumnWidthInfo,
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  SearchPanel,
  TableColumnResizing,
  Toolbar,
  TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import DoneIcon from "@material-ui/icons/Done";
import { useHistory } from "react-router-dom";
import * as urls from "../../../types/Constants";

//https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/

export interface IOrdersGridProps {
  users: IUserV0[];
  projects: IProjectV0Ex[];
}

const ProjectsGrid: React.FC<IOrdersGridProps> = ({ projects }) => {
  const history = useHistory();

  const [columns] = useState([
    { name: "name", title: "Program" },
    { name: "orderId", title: "P.O." },
    { name: "orderDate", title: "Date" },
    { name: "pending", title: "Pending" },
    { name: "userName", title: "End user" },
    { name: "userCompanyName", title: "Company" },
  ]);

  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>([
    { columnName: "name", width: 480 },
    { columnName: "orderId", width: 120 },
    { columnName: "orderDate", width: 120 },
    { columnName: "pending", width: 180 },
    { columnName: "userName", width: 240 },
    { columnName: "userCompanyName", width: 240 },
  ]);

  const useGridStyles = makeStyles((theme: Theme) =>
    createStyles({
      link: {
        padding: "2px 4px 2px 10px",
      },
      avatarSmall: {
        width: theme.spacing(4),
        height: theme.spacing(4),
      },
    })
  );
  const gridStyles = useGridStyles();

  const [defaultHiddenColumnNames] = useState(["userId"]);

  const [dateColumn] = useState(["orderDate"]);
  const DateColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => <Moment format="DD/MM/YYYY">{row.orderDate}</Moment>;

  const [pendingColumn] = useState(["pending"]);
  const PendingColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    return (
      <div>
        {row.pending.length === 0 ? (
          <Grid container direction="row" justify="center">
            {row.isCancelled ? (
              <Chip
                label="Canceled"
                color="secondary"
                onDelete={(e) => e.preventDefault()}
                deleteIcon={<DoneIcon />}
              />
            ) : (
              <Chip
                label="Complete"
                color="primary"
                onDelete={(e) => e.preventDefault()}
                deleteIcon={<DoneIcon />}
              />
            )}
          </Grid>
        ) : (
          <>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center"
            >
              {row.pending.map((x) => (
                <Grid item key={x}>
                  <Avatar className={gridStyles.avatarSmall}>
                    {x.substring(0, 1)}
                  </Avatar>
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </div>
    );
  };

  const [nameColumn] = useState(["name"]);
  const NameColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    const url = urls.getProjectUrl(row.programId); // "/admin/project/" + row.programId;
    return (
      <Link
        href="#"
        color={row.isCancelled === true ? "secondary" : "primary"}
        onClick={() => history.push(url)}
        variant="inherit"
      >
        {row.name}
      </Link>
    );
  };

  return (
    <React.Fragment>
      <DevExpressGrid rows={projects} columns={columns}>
        <SearchState />
        <SortingState
          defaultSorting={[{ columnName: "orderDate", direction: "desc" }]}
        />
        <FilteringState />
        <DataTypeProvider
          for={nameColumn}
          formatterComponent={NameColumnRenderer}
        />

        <DataTypeProvider
          for={dateColumn}
          formatterComponent={DateColumnRenderer}
        />

        <DataTypeProvider
          for={pendingColumn}
          formatterComponent={PendingColumnRenderer}
        />
        <IntegratedSorting />
        <IntegratedFiltering />
        <Table />
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={setColumnWidths}
        />
        <TableHeaderRow showSortingControls />
        <TableFilterRow />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <Toolbar />
        <SearchPanel />
      </DevExpressGrid>
    </React.Fragment>
  );
};

export default ProjectsGrid;
