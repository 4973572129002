/* eslint-disable */
import { setupAxios, processApiRequest, IResponseHandler } from "./apiHelper";

//SETUP
const baseUrl = process.env.B2C_API_URL;
const baseApi = setupAxios(baseUrl!);

export interface IDownloadVariable {
  variableName: string;
  value: string;
}

export interface IDownloadTrialRequest {
  email: string;
  companyName: string;
  contactName: string;
  language: string;
  productId: string;
  industry?: string;
  whoAmI?: string;
  useTemplate: string;
  message?: string;
  captcha?: string;
  variables?: IDownloadVariable[];
}

export function downloadTrial(
  download: IDownloadTrialRequest,
  handler: IResponseHandler
) {
  const url = "/email/download-trial";
  const config: any = {
    errorHandle: false,
    validateStatus: function (status) {
      return status >= 200 && status < 500;
    },
  };
  return processApiRequest(baseApi.post(url, download, config), handler);
}
