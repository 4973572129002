import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Moment from "react-moment";
import prettyBytes from "pretty-bytes";
import { findBlob, IBlob } from "../../../../api/Admin/dbFilesApi";
import { toast, ToastContainer } from "react-toastify";
import {
  Grid,
  Avatar,
  Divider,
  Chip,
  Typography,
  Backdrop,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  CircularProgress,
  Link,
  Breadcrumbs,
  IconButton,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IResources, { resources } from "./.resources";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { useParams, useHistory } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import * as urls from "../../../../types/Constants";
import { IUserV0, getUsersList } from "../../../../api/Account/usersApi";
import { ILoggedUser } from "../../../../types/Auth";
import UserDropDown from "../../../admin/_shared/UsersDropDown";
import SearchIcon from "@material-ui/icons/Search";
import PersonIcon from "@material-ui/icons/Person";

const FileInfoPage: React.FC = () => {
  const { fileId, container } = useParams();

  const [openDropDown, setOpenDropDown] = useState(false);
  const [file, setFile] = useState<IBlob | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [unauthorized, setUnauthorized] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [users, setUsers] = useState<IUserV0[]>();
  const [viewAs, setViewAs] = useState<IUserV0>();
  const [selectedUserId, setSelectedUserId] = useState<string>();

  const history = useHistory();

  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const loggedUser: ILoggedUser = useSelector((state: RootState) => {
    return state.userInfo;
  });

  const showMessage = (msg: string | null | undefined) => {
    if (msg) {
      toast.info(msg);
    }
  };
  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const shareToEmail = () => {
    if (file) {
      const body = "Descarga: " + file.url + "\r\n\r\n" + file.releaseNotes;
      const mailTo =
        "mailto:me@me.com?subject=" +
        encodeURI(file.displayName || "") +
        "&body=" +
        encodeURI(body);
      window.location.href = mailTo;
      setOpenDropDown(false);
    }
  };

  const shareToClipboard = () => {
    if (file) {
      navigator.clipboard.writeText(file.url || "").then(() => {
        showMessage("Copied to clipboard: " + file.url);
      });
      setOpenDropDown(false);
    }
  };

  const refreshUsersAsync = async () => {
    await getUsersList(
      (response) => {
        const orderedUsers: IUserV0[] = response.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setUsers([...orderedUsers]);
      },
      (err) => showError(err)
    );
  };

  const refreshAsync = (userId?: string) => {
    setIsLoading(true);
    findBlob(
      fileId,
      userId,
      (blob) => {
        setIsLoading(false);
        setUnauthorized(false);
        setNotFound(false);
        setFile({ ...blob });
      },
      () => {
        setIsLoading(false);
        setUnauthorized(true);
      },
      () => {
        setIsLoading(false);
        setNotFound(true);
      },
      (error) => {
        setIsLoading(false);
        showError(error);
      }
    );
  };

  useEffect(() => {
    if (loggedUser) {
      if (!users && loggedUser.isAdmin) {
        refreshUsersAsync();
        return;
      }
      refreshAsync();
    }
  }, [users, loggedUser]);

  useEffect(() => {
    if (viewAs) {
      refreshAsync(viewAs.userId);
    }
  }, [viewAs]);

  const themedStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      container: {
        margin: "24px 64px 24px 64px",
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
      },
      chipsContainer: {
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        "& > *": {
          margin: theme.spacing(0.5),
        },
      },
      chip: {
        margin: "10px 0px 10px 0px",
      },
      breadCrums: {
        padding: "0 0 12px 0",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      viewAsControl: {
        maxWidth: 320,
      },
      searchButton: {
        padding: "2px",
        margin: "0px",
        width: "32px",
        height: "32px",
      },
    })
  );
  const styles = themedStyles();

  const Navigation = () => {
    return (
      <Breadcrumbs
        className={styles.breadCrums}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          color="inherit"
          href="#"
          onClick={() => history.push(urls.URL_HOME)}
        >
          Home
        </Link>
        {container !== "@FileExplorer" ? (
          <Link
            color="inherit"
            href="#"
            onClick={() => history.push(urls.URL_MYPURCHASES)}
          >
            {res.myOrders}
          </Link>
        ) : (
          <Link
            color="inherit"
            href="#"
            onClick={() => history.push(urls.URL_FILE_EXPLORER)}
          >
            File Explorer
          </Link>
        )}
        {container !== "@FileExplorer" && (
          <Typography color="textPrimary">
            <Link color="inherit" href="#" onClick={() => history.goBack()}>
              {container || "?"}
            </Link>
          </Typography>
        )}

        <Typography color="textPrimary">
          <Link
            color="inherit"
            href={urls.getFileUrl(file?.fileId || 0)}
            onClick={(e) => e.preventDefault()}
          >
            {file?.displayName}
          </Link>
        </Typography>
      </Breadcrumbs>
    );
  };

  const ViewAsControl = () => {
    return (
      <div className={styles.viewAsControl}>
        <Grid
          xs={12}
          item
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
        >
          <Grid item xs={10}>
            {users && (
              <UserDropDown
                name="userId"
                label="View As"
                users={users}
                value={selectedUserId}
                enabled={true}
                allowEmpty={true}
                allowShowId={true}
                onChange={(e) => setSelectedUserId(e.target.value)}
                touched={false}
                error={undefined}
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              type="submit"
              className={styles.searchButton}
              aria-label="search"
              onClick={() =>
                setViewAs(users?.find((u) => u.userId === selectedUserId))
              }
            >
              <SearchIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {container && <Navigation />}

      <Typography variant="h4" gutterBottom component="div">
        {res.downloads}
      </Typography>
      {viewAs && (
        <Chip
          label={viewAs.name}
          icon={<PersonIcon />}
          color="primary"
          className={styles.chip}
        />
      )}
      {loggedUser?.isAdmin === true &&
        users &&
        container === "@FileExplorer" && <ViewAsControl />}

      {unauthorized || notFound ? (
        <div>
          {unauthorized && (
            <Chip
              color="secondary"
              label={res.unauthorized}
              icon={<SentimentVeryDissatisfiedIcon />}
            />
          )}
          {notFound && (
            <Chip
              color="secondary"
              label={res.notFound}
              icon={<SentimentVeryDissatisfiedIcon />}
            />
          )}
        </div>
      ) : (
        <>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item container spacing={2}>
              <Grid item>
                <Avatar aria-label="user">
                  {(file?.displayName || "...").substring(0, 1)}
                </Avatar>
              </Grid>
              <Grid item>
                <Grid container>{file?.displayName}</Grid>
                {/* <Grid container>
                  <Typography variant="subtitle1">{file?.productId}</Typography>
                </Grid> */}
                <Grid container spacing={6}>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                    >
                      {file?.date ? (
                        <Moment format="DD/MM/YYYY">{file?.date}</Moment>
                      ) : (
                        "Date: n/a"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                    >
                      {file?.size ? prettyBytes(file?.size) : "Size: n/a"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {file?.version && (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        ver {file?.version}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {file?.platform && (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        Windows {file?.platform}{" "}
                        {file?.platform === "x86" ? "(32 Bits)" : "(64 Bits)"}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item>
                    {file?.language && (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        {file?.language === "en"
                          ? res.languageEn
                          : res.languageEs}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={4}
              >
                <Grid item>
                  {file?.isActive || false ? (
                    <>
                      <ButtonGroup
                        variant="contained"
                        color="secondary"
                        ref={anchorRef}
                        aria-label="split button"
                      >
                        <Button>{resources.fileShare}</Button>
                        <Button
                          color="secondary"
                          size="small"
                          aria-controls={
                            openDropDown ? "split-button-menu" : undefined
                          }
                          aria-expanded={openDropDown ? "true" : undefined}
                          aria-label="share link"
                          aria-haspopup="menu"
                          onClick={() =>
                            setOpenDropDown((prevOpen) => !prevOpen)
                          }
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        open={openDropDown}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener
                                onClickAway={() => setOpenDropDown(false)}
                              >
                                <MenuList id="split-button-menu">
                                  <MenuItem onClick={() => shareToEmail()}>
                                    {res.shareByEmail}
                                  </MenuItem>
                                  <MenuItem onClick={() => shareToClipboard()}>
                                    {res.copyToClipboard}
                                  </MenuItem>
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </>
                  ) : null}
                </Grid>
                <Grid item>
                  {file?.isActive || false ? (
                    <Button
                      variant="contained"
                      color="primary"
                      href={file?.url}
                      target="_blank"
                    >
                      {res.fileDownload}
                    </Button>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Backdrop className={styles.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <ToastContainer />

          <Grid container className={styles.root}>
            <Grid item xs={12}>
              <div>
                <Typography variant="subtitle1" gutterBottom component="div">
                  {file?.description}
                </Typography>
                {file?.releaseNotes ? (
                  <div>
                    <Divider />
                    <Typography variant="h5" gutterBottom component="div">
                      {res.releaseNotes}
                    </Typography>
                    <Typography variant="body1" gutterBottom component="div">
                      <ReactMarkdown
                        source={file.releaseNotes}
                        escapeHtml={false}
                      />
                    </Typography>
                  </div>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default FileInfoPage;
