import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { IProductFile } from "../../../../api/Admin/dbProductFilesApi";
import {
  DataTypeProvider,
  SortingState,
  SearchState,
  GroupingState,
  IntegratedFiltering,
  IntegratedSorting,
  IntegratedGrouping,
  TableColumnWidthInfo,
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  TableGroupRow,
  SearchPanel,
  TableColumnResizing,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";
import CheckIcon from "@material-ui/icons/Check";

export interface IFileUserScopePivot {
  userName: string;
  order: string;
  program: string;
  download: boolean;
  activateSupport: boolean;
  activateFull: boolean;
}

export interface IProductFileUsersDialogProps {
  open: boolean;
  file: IProductFile | undefined;
  scopes: IFileUserScopePivot[];
  handleCancel: () => void;
}

const AdminProductFileUsersDialog: React.FC<IProductFileUsersDialogProps> = ({
  open,
  file,
  scopes,
  handleCancel,
}) => {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      checkIcon: {
        width: "24px",
        height: "24px",
      },
    })
  );
  const styles = useStyles();

  const getFileName = (file: string) => {
    if (file) {
      return file.substring(file.lastIndexOf("/") + 1);
    }
    return file;
  };

  const [columns] = useState([
    { name: "userName", title: "User Name" },
    { name: "order", title: "Order" },
    { name: "program", title: "Program" },
    { name: "download", title: "Download" },
    { name: "activateSupport", title: "Support" },
    { name: "activateFull", title: "Full License" },
  ]);

  const [booleanColumns] = useState([
    "download",
    "activateSupport",
    "activateFull",
  ]);
  const BooleanFormatter: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    value,
  }) => {
    return value ? (
      <CheckIcon className={styles.checkIcon}></CheckIcon>
    ) : (
      <div></div>
    );
  };
  const BooleanTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />
  );

  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>([
    { columnName: "userName", width: 200 },
    { columnName: "order", width: 100 },
    { columnName: "program", width: 300 },
    { columnName: "download", width: 120 },
    { columnName: "activateSupport", width: 120 },
    { columnName: "activateFull", width: 120 },
  ]);

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="new project"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        <>
          <Typography variant="h6">{getFileName(file!.url)}</Typography>
        </>
      </DialogTitle>
      <DialogContent>
        <DevExpressGrid rows={scopes} columns={columns}>
          <SearchState />
          <SortingState />
          <GroupingState grouping={[{ columnName: "userName" }]} />
          <BooleanTypeProvider for={booleanColumns} />
          <IntegratedFiltering />
          <IntegratedSorting />
          <IntegratedGrouping />
          <Table />
          <TableColumnResizing
            columnWidths={columnWidths}
            onColumnWidthsChange={setColumnWidths}
          />
          <TableHeaderRow showSortingControls />
          <TableGroupRow />
          <Toolbar />
          <SearchPanel />
        </DevExpressGrid>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Button onClick={() => handleCancel()} type="reset">
            Close
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default AdminProductFileUsersDialog;
