import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  IBlobWithLinks,
  IBlob,
  getBlobs,
  findBlob,
  findUserFiles,
  addOrUpdateBlob,
  deleteBlob,
  linkFileUsers,
  unlinkFileUsers,
  linkProductFiles,
  unlinkProductFiles,
} from "../../../api/Admin/dbFilesApi";
import { getFileInfo } from "../../../api/Admin/filesApi";
import BlobDialog from "./dialogs/BlobDialog";
import BlobLinkedProductsDialog from "./dialogs/BlobLinkedProducts";
import BlobLinkedUsersDialog from "./dialogs/BlobLinkedUsers";
import BlobsGrid from "./BlobsGrid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import { IUserV0, getUsersList } from "../../../api/Account/usersApi";
import { getProducts, IProduct } from "../../../api/Admin/dbProductsApi";
import UserDropDown from "../_shared/UsersDropDown";
import { useHistory } from "react-router-dom";
import * as urls from "../../../types/Constants";

const BlobsPage: React.FC = () => {
  const history = useHistory();

  const [blobs, setBlobs] = useState<IBlobWithLinks[]>([]);
  const [data, setData] = useState<IBlobWithLinks[]>([]);
  const [users, setUsers] = useState<IUserV0[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);

  const [filterProducts, setFilterProducts] = useState<IProduct[]>([]);
  const [filterByProduct, setFilterByProduct] = useState<string>();
  const [filterByUser, setFilterByUser] = useState<string | undefined>();
  const [filterByUserExplicit, setFilterByUserExplicit] = useState<boolean>(
    true
  );
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showLinkedProducts, setShowLinkedProducts] = useState<boolean>(false);
  const [showLinkedUsers, setShowLinkedUsers] = useState<boolean>(false);
  const [showFileDialog, setShowFileDialog] = useState<boolean>(false);
  const [linksDialogFile, setLinksDialogsFile] = useState<
    IBlobWithLinks | undefined
  >();
  const [dialogFile, setDialogFile] = useState<IBlob | undefined>();

  const showMessage = (msg: string | null | undefined) => {
    if (msg) {
      toast.info(msg);
    }
  };
  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const refreshAsync = async () => {
    setIsLoading(true);
    await getBlobs(
      (result) => {
        setBlobs([...result]);
        setIsLoading(false);
      },
      (error) => {
        showError(error);
        setIsLoading(false);
      }
    );
  };

  const refreshUsersAsync = async () => {
    await getUsersList(
      (response) => {
        const orderedUsers: IUserV0[] = response.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setUsers([...orderedUsers]);
      },
      (err) => showError(err)
    );
  };

  const refreshProductsAsync = async () => {
    await getProducts(
      (result) => {
        setProducts([...result]);
      },
      (error) => {
        showError(error);
      }
    );
  };

  const onGoToFile = (fileId: number) => {
    const url = urls.getFileUrl(fileId, "@FileExplorer");
    if (url) {
      history.push(url);
    }
  };

  useEffect(() => {
    refreshAsync();
    refreshUsersAsync();
    refreshProductsAsync();
  }, []);

  function sortListCompare(
    value1: string | null | undefined,
    value2: string | null | undefined
  ): number {
    if (value1 && !value2) return 1;
    if (!value1 && value2) return -1;
    if (value1 && value2) {
      if (value1 === value2) return 0;
      if (value1 > value2) return 1;
      return -1;
    }
    return 0;
  }
  useEffect(() => {
    if (products) {
      const productsWithEmpty: IProduct[] = [
        ...products,
        { productId: "", productName: "  Any", isCommercial: false },
      ];
      setFilterProducts([
        ...productsWithEmpty.sort((p1, p2) =>
          sortListCompare(p1.productName, p2.productName)
        ),
      ]);
    } else {
      setFilterProducts([]);
    }
  }, [products]);

  const applyProductFilter = (files: IBlobWithLinks[]): IBlobWithLinks[] => {
    if (filterByProduct) {
      return files.filter((f) =>
        f.products.find((pf) => pf === filterByProduct)
      );
    }
    return files;
  };

  const applyExplicitUsersFilter = (
    files: IBlobWithLinks[]
  ): IBlobWithLinks[] => {
    if (filterByUser && filterByUserExplicit) {
      return files.filter((f) => f.users.find((uf) => uf === filterByUser));
    }
    return files;
  };

  const applyIimplicitUsersFilter = async (
    files: IBlobWithLinks[],
    onSuccess: (result: IBlobWithLinks[]) => void
  ) => {
    if (filterByUser && !filterByUserExplicit) {
      setIsFiltering(true);
      await findUserFiles(
        filterByUser,
        (fileIds) => {
          files = files.filter((f) => fileIds.find((fid) => f.fileId === fid));
          onSuccess([...files]);
          setIsFiltering(false);
        },
        (error) => {
          showError(error);
          setIsFiltering(false);
        }
      );
    } else {
      onSuccess(files);
    }
  };

  const onApplyFilter = async () => {
    let files: IBlobWithLinks[] = applyProductFilter(blobs);
    files = applyExplicitUsersFilter(files);
    await applyIimplicitUsersFilter(files, (result) => {
      files = result;
      setData(files);
    });
  };

  useEffect(() => {
    if (blobs) {
      onApplyFilter();
    }
  }, [blobs]);

  const openNewFileDialog = () => {
    setDialogFile({
      fileId: 0,
      displayName: "",
      category: "",
      url: "",
      description: "",
      date: new Date(),
      isActive: false,
    });
    setShowFileDialog(true);
  };

  const openLinkedProductsDialog = (file: IBlobWithLinks) => {
    setLinksDialogsFile(file);
    setShowLinkedUsers(false);
    setShowLinkedProducts(true);
  };

  const openLinkedUsersDialog = (file: IBlobWithLinks) => {
    setLinksDialogsFile(file);
    setShowLinkedProducts(false);
    setShowLinkedUsers(true);
  };

  const onAddFile = async (request: IBlob) => {
    await addOrUpdateBlob(
      request,
      () => {
        setShowFileDialog(false);
        toast.success("File added");
        refreshAsync();
      },
      (err) => {
        showError(err);
      }
    );
  };

  const onDeleteFile = async (fileId: number) => {
    await deleteBlob(
      fileId,
      () => {
        setShowFileDialog(false);
        toast.success("File deleted");
        refreshAsync();
      },
      (err) => {
        showError(err);
      }
    );
  };

  const onLinkUsers = async (userIds: string[]) => {
    if (linksDialogFile) {
      await linkFileUsers(
        linksDialogFile.fileId,
        userIds,
        () => {
          showMessage("User(s) updated");
          setShowLinkedUsers(false);
          refreshAsync();
        },
        (error) => showError(error)
      );
    }
  };

  const onUnlinkUsers = async (userIds: string[]) => {
    if (linksDialogFile) {
      await unlinkFileUsers(
        linksDialogFile.fileId,
        userIds,
        () => {
          showMessage("User(s) deleted");
          setShowLinkedUsers(false);
          refreshAsync();
        },
        (error) => showError(error)
      );
    }
  };

  const onLinkProducts = async (productIds: string[]) => {
    if (linksDialogFile) {
      await linkProductFiles(
        linksDialogFile.fileId,
        productIds,
        () => {
          showMessage("Product(s) updated");
          setShowLinkedProducts(false);
          refreshAsync();
        },
        (error) => showError(error)
      );
    }
  };

  const onUnlinkProducts = async (productIds: string[]) => {
    if (linksDialogFile) {
      await unlinkProductFiles(
        linksDialogFile.fileId,
        productIds,
        () => {
          showMessage("Product(s) deleted");
          setShowLinkedProducts(false);
          refreshAsync();
        },
        (error) => showError(error)
      );
    }
  };

  const onRequestAzureFileInfo = async (request: IBlobInfoRequest) => {
    await getFileInfo(
      request.fileUrl,
      (result) => request.onSuccess(result),
      (err) => showError(err)
    );
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        padding: "0px 6px 0px 32px",
      },
      newFileButton: {
        width: "148px",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      grid: {
        padding: "0px 32px 0px 32px",
        height: "100%",
      },
      toolbar: {
        margin: "12px 0px 6px 0px",
      },
    })
  );
  const styles = useStyles();

  const FilesToolbar = () => {
    return (
      <Grid
        item
        container
        className={styles.toolbar}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            className={styles.newFileButton}
            onClick={() => openNewFileDialog()}
          >
            New File
          </Button>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={4}
          xs={12}
          sm={9}
        >
          <Grid item>
            <UserDropDown
              name="userId"
              label="Filter by user"
              users={users}
              value={filterByUser}
              enabled={true}
              allowEmpty={true}
              allowShowId={true}
              onChange={(e) => setFilterByUser(e.target.value)}
              touched={false}
              error={undefined}
            />
          </Grid>
          <Grid item>
            {filterByUser && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filterByUserExplicit}
                    onChange={(e) => setFilterByUserExplicit(e.target.checked)}
                    name="filter-user-explicit"
                    color="primary"
                  />
                }
                label="Explicit Only"
                labelPlacement="top"
              />
            )}
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="platform-label">Filter By Product</InputLabel>
              <Select
                label="Filter By Product"
                name="dropDownFilter"
                value={filterByProduct || ""}
                style={{ width: "200px" }}
                onChange={(e) => setFilterByProduct(e.target?.value || "")}
              >
                {filterProducts &&
                  filterProducts.map((p) => {
                    return (
                      <MenuItem key={p.productId} value={p.productId}>
                        {p.productName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              disabled={isFiltering}
              color="primary"
              variant="outlined"
              onClick={() => onApplyFilter()}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container className={styles.header} direction="column">
        <Typography variant="h2">File Explorer</Typography>
        <Typography variant="body1">
          All files across users, products and projects
        </Typography>
        <FilesToolbar />
      </Grid>

      <Backdrop className={styles.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ToastContainer />
      {isLoading === false && (
        <>
          <Grid container>
            <Grid item className={styles.grid}>
              {blobs && blobs.length > 0 && (
                <BlobsGrid
                  files={data}
                  handleNotification={showMessage}
                  handleOpenFile={(row) => onGoToFile(row.fileId)}
                  handleOpenProducts={(row) => openLinkedProductsDialog(row)}
                  handleOpenUsers={(row) => openLinkedUsersDialog(row)}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
      <BlobDialog
        file={dialogFile}
        open={showFileDialog}
        handleDelete={(fileId) => onDeleteFile(fileId)}
        handleFileInfo={(file) => onRequestAzureFileInfo(file)}
        handleSubmit={(file) => onAddFile(file)}
        handleCancel={() => setShowFileDialog(false)}
      />
      <BlobLinkedProductsDialog
        file={linksDialogFile}
        products={products}
        open={showLinkedProducts}
        handleLinkProducts={onLinkProducts}
        handleUnlinkProducts={onUnlinkProducts}
        handleClose={() => setShowLinkedProducts(false)}
      />
      <BlobLinkedUsersDialog
        file={linksDialogFile}
        users={users}
        open={showLinkedUsers}
        handleLinkUsers={onLinkUsers}
        handleUnlinkUsers={onUnlinkUsers}
        handleClose={() => setShowLinkedUsers(false)}
      />
    </>
  );
};

export default BlobsPage;
