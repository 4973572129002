import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { useHistory, Route, Switch } from "react-router-dom";
import HomePage from "./home/HomePage";
import DiagnosticsPage from "./home/DiagnosticsPage";
import ProductsPage from "./products/ProductsPage";
import ServicesPage from "./services/ServicesPage";
import AccountPage from "./account/my-account/AccountPage";
import ForgotPasswordPage from "./account/ForgotPasswordPage";
import UsersPage from "./admin/users/UsersPage";
import ProjectsPage from "./admin/projects/ProjectsPage";
import ProjectPage from "./admin/projects/ProjectPage";
import AdminProductsPage from "./admin/products/AdminProductsPage";
import BlobsPage from "./admin/files/BlobsPage";
import NotFoundPage from "./NotFoundPage";
import Page from "./Page";
import Header from "./navigation/Header";
import Footer from "./navigation/Footer";
import TermsAndConditions from "./navigation/legal/TermsAndConditions";
import PrivacyPolicy from "./navigation/legal/PrivacyPolicy";
import PurchasingFaqPage from "./faq/purchasingFaq/PurchasingFaqPage";
import SideBarMenu from "./navigation/SideBarMenu";
import MyProductsPage from "./account/my-products/MyProductsPage";
import MyPurchasesPage from "./account/my-purchases/MyPurchasesPage";
import "../styles/app.scss";
import authService from "../auth/authService";
import ErrorPage from "./ErrorPage";
import AppInsightsProvider, {
  trackTrace,
  trackErrorMessage,
} from "../services/AppInsightsProvider";
import { getAppInsights } from "../setup/AppInsightsSetup";
import {
  switchLanguage,
  getDefaultLanguage,
} from "../redux/actions/languageAction";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ISession, ILoggedUser, IKeepAlive } from "../types/Auth";
import { refreshUser } from "../redux/actions/userInfoAction";
import Drawer from "@material-ui/core/Drawer";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { ThemeOptions, LanguageOptions } from "../types/Enums";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {
  Typography,
  Button,
  createStyles,
  Theme,
  Grid,
  Link,
} from "@material-ui/core";
import IResources, { resources } from "./.resources";
import FileInfoPage from "./account/my-products/my-file/FileInfoPage";
import { darkTheme as darkThemeJson } from "../styles/themes/materialDark";
import { lightTheme as lightThemeJson } from "../styles/themes/materialLight";
import * as urls from "../types/Constants";
import MyLicensesPage from "./account/my-licenses/MyLicensesPage";
import WeighBridgePage from "./products/weighbridge/WeighBridgePage";
import RequestTrialPage from "./products/RequestTrialPage";
import moment from "moment";
import {
  b2cHealthCheck,
  projHealthCheck,
  registrationHealthCheck,
} from "../api/testApi";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import Wiki from "./docs/Wiki";
import Manuals from "./docs/Manuals";
import MyDocuments from "./docs/MyDocuments";

toast.configure();

const App: React.FC = () => {
  const dispatch = useDispatch();

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const session: ISession = useSelector((state: RootState) => {
    return state.auth;
  }); //REDUX
  const loggedUser: ILoggedUser = useSelector((state: RootState) => {
    return state.userInfo;
  });

  const keepAlive: IKeepAlive = useSelector((state: RootState) => {
    return state.keepAlive;
  }); //REDUX

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [appInsights, setAppInsights] = useState<any>(null);
  const [themeName, setThemeName] = useState<ThemeOptions>(undefined);

  const history = useHistory();

  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  // BEGIN COOKIES
  const [showCookieConsent, setShowCookieConsent] = useState<boolean>(false);
  const cookieStorage = localStorage;
  const consentPropertyName = "cookie_consent";
  const shouldShowCookieConsent = () =>
    cookieStorage.getItem(consentPropertyName) !== "true";
  const saveCookieConsent = () => {
    cookieStorage.setItem(consentPropertyName, "true");
    setShowCookieConsent(false);
  };
  //END COOKIES

  const keepAliveSuccess = (service: "b2c" | "reg" | "proj") => {
    console.log(`api-${service} healthcheck success at ${new Date()}`);
    trackTrace(
      `React-HealthCheck api-${service} success at ${new Date()}`,
      SeverityLevel.Information
    );
  };

  const keepAliveError = (service: "b2c" | "reg" | "proj", err?: string) => {
    if (err) {
      console.log(`api-${service} healthcheck error at ${new Date()}: ${err}`);
      trackErrorMessage(
        `React-HealthCheck api-${service} failed at ${new Date()}`,
        err
      );
    }
  };

  const tryKeepAlive = async () => {
    const interval = 1200; //~20 minutes
    const now = new Date();

    if (
      moment(now).diff(keepAlive.b2cApi || new Date(0), "seconds") >= interval
    ) {
      await b2cHealthCheck(
        "healthcheck",
        () => keepAliveSuccess("b2c"),
        (err) => keepAliveError("b2c", err)
      );
    }
    if (
      moment(now).diff(keepAlive.projApi || new Date(0), "seconds") >= interval
    ) {
      await projHealthCheck(
        "healthcheck",
        () => keepAliveSuccess("proj"),
        (err) => keepAliveError("proj", err)
      );
    }
    if (
      moment(now).diff(keepAlive.regApi || new Date(0), "seconds") >= interval
    ) {
      await registrationHealthCheck(
        "healthcheck",
        () => keepAliveSuccess("reg"),
        (err) => keepAliveError("reg", err)
      );
    }
  };

  useEffect(() => {
    async function runAsync() {
      await authService.refresh();
    }
    runAsync();
    const defaultTheme: ThemeOptions = (localStorage.getItem("theme") ||
      document.documentElement.getAttribute("data-theme")) as ThemeOptions;
    setThemeName(defaultTheme);

    if (shouldShowCookieConsent()) {
      setShowCookieConsent(true);
    }
    setTimeout(() => tryKeepAlive(), 1500);
  }, []);

  useEffect(() => {
    if (session.loggedIn === true) {
      refreshUser(dispatch);
    }
  }, [session]);

  useEffect(() => {
    if (appInsights && session) {
      appInsights.context.user.id = session.user ?? "unknown";
      appInsights.context.user.accountId = session.objectId ?? "unknown";
      appInsights.context.application.ver = "version 1.0";
    }
  }, [appInsights, session]);

  useEffect(() => {
    if (themeName) {
      document.documentElement.setAttribute("data-theme", themeName);
    }
  }, [themeName]);

  useEffect(() => {
    if (loggedUser?.error) {
      setErrorMessage(loggedUser.error);
      setShowErrorMessage(true);
    }
  }, [loggedUser]);

  const darkTheme = createMuiTheme(darkThemeJson);

  const lightTheme = createMuiTheme(lightThemeJson);

  const createTransition = () => {
    document.documentElement.classList.add("themeTransition");
    window.setTimeout(() => {
      document.documentElement.classList.remove("themeTransition");
    }, 1000);
  };

  function changeTheme(name: ThemeOptions) {
    setThemeName(name);
    localStorage.setItem("theme", name || "dark");
    createTransition();
  }

  function changeLanguage(name: LanguageOptions) {
    if (name) {
      switchLanguage(dispatch, name);
      localStorage.setItem("language", name);
    } else {
      switchLanguage(dispatch, getDefaultLanguage());
    }
  }

  const drawerWidth = 360;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      header: {
        opacity: showCookieConsent ? "0.5" : "1",
      },
      paperLight: {
        backgroundColor: theme.palette.background.default,
        opacity: showCookieConsent ? "0.5" : "1",
      },
      paperDark: {
        backgroundColor: "#343a40",
        opacity: showCookieConsent ? "0.5" : "1",
      },
    })
  );
  const classes = useStyles();

  return (
    <AppInsightsProvider
      instrumentationKey={process.env.APPINSIGHTS_INSTRUMENTATIONKEY}
      after={() => setAppInsights(getAppInsights())}
    >
      <div id="app">
        <ThemeProvider theme={themeName === "dark" ? darkTheme : lightTheme}>
          <CssBaseline />

          <div id="header" className={classes.header}>
            <Header
              onMenuClick={() => setIsSideBarOpen(true)}
              themeName={themeName}
              session={session}
              onSwitchTheme={(name) => changeTheme(name)}
              onSwitchLanguage={(name) => changeLanguage(name)}
              onLogin={() => authService.login()}
              onLogout={() => authService.logout()}
            />
          </div>
          <div
            id="content"
            className={
              themeName === "dark" ? classes.paperDark : classes.paperLight
            }
          >
            <div id="main">
              <Switch>
                <Route
                  path="/"
                  exact
                  render={() => (
                    <HomePage
                      themeName={themeName}
                      session={session}
                      onLogin={() => authService.login()}
                      onLogout={() => authService.logout()}
                      title="Home"
                    />
                  )}
                />

                <Route
                  path="/diagnostics"
                  exact
                  render={(props) => (
                    <Page
                      {...props}
                      component={DiagnosticsPage}
                      title="Diagnostics"
                    />
                  )}
                />

                <Route
                  path={urls.URL_FORGOT_PASSWORD}
                  exact
                  render={(props) => (
                    <Page
                      {...props}
                      component={ForgotPasswordPage}
                      title="Forgot Password"
                    />
                  )}
                />

                <Route
                  path="/error"
                  render={(props) => (
                    <Page {...props} component={ErrorPage} title="Error" />
                  )}
                />

                <Route
                  path="/wiki"
                  render={(props) => (
                    <Page {...props} component={Wiki} title="Wiki" />
                  )}
                />

                <Route
                  path="/documents"
                  render={(props) => (
                    <Page
                      {...props}
                      component={MyDocuments}
                      title="Documentation"
                    />
                  )}
                />

                <Route
                  path="/manuals/:productId?"
                  render={(props) => (
                    <Page {...props} component={Manuals} title="Manuals" />
                  )}
                />

                <Route
                  path="/services"
                  render={() => <ServicesPage themeName={themeName} />}
                />

                <Route
                  path={urls.URL_MYPURCHASES}
                  render={() => (
                    <MyPurchasesPage
                      themeName={themeName}
                      title="My Purchases"
                    />
                  )}
                />

                <Route
                  path="/my-account/files/:fileId/:container?"
                  render={(props) => (
                    <Page
                      {...props}
                      component={FileInfoPage}
                      title="Downloads"
                    />
                  )}
                />

                <Route
                  path="/my-account/my-products/:id?/:showAs?"
                  render={() => <MyProductsPage themeName={themeName} />}
                />

                <Route
                  path={`${urls.URL_MYLICENSES}/:requestData?`}
                  render={() => <MyLicensesPage themeName={themeName} />}
                />

                <Route
                  path={`${urls.URL_PRODUCT_TRIAL}/:requestInfo?`}
                  render={() => <RequestTrialPage themeName={themeName} />}
                />

                <Route
                  path="/my-account"
                  render={() => (
                    <AccountPage themeName={themeName} title="My Account" />
                  )}
                />

                <Route
                  path={urls.URL_USERS}
                  render={(props) => (
                    <Page {...props} component={UsersPage} title="Users" />
                  )}
                />

                <Route
                  path={urls.URL_PRODUCTS_LISTING}
                  render={(props) => (
                    <Page
                      {...props}
                      component={AdminProductsPage}
                      title="Products and Files"
                    />
                  )}
                />

                <Route
                  path={urls.URL_FILE_EXPLORER}
                  render={(props) => (
                    <Page
                      {...props}
                      component={BlobsPage}
                      title="File Explorer"
                    />
                  )}
                />

                <Route
                  path={urls.URL_PROJECTS}
                  render={(props) => (
                    <Page
                      {...props}
                      component={ProjectsPage}
                      title="Projects"
                    />
                  )}
                />

                <Route
                  path={urls.URL_PRODS_WEIGHBRIDGE}
                  render={() => (
                    <WeighBridgePage
                      themeName={themeName}
                      title="WeighBridge"
                    />
                  )}
                />

                <Route
                  path="/products"
                  exact
                  render={(props) => (
                    <Page
                      {...props}
                      component={ProductsPage}
                      title="Products"
                    />
                  )}
                />

                <Route
                  path="/admin/project/:id"
                  render={(props) => (
                    <Page {...props} component={ProjectPage} title="Project" />
                  )}
                />

                <Route
                  path={urls.URL_TERMS_AND_CONDITIONS}
                  render={(props) => (
                    <Page
                      {...props}
                      component={TermsAndConditions}
                      title="Terms And Conditions"
                    />
                  )}
                />

                <Route
                  path={urls.URL_PRIVACY_POLICY}
                  render={(props) => (
                    <Page
                      {...props}
                      component={PrivacyPolicy}
                      title="Privacy Policy"
                    />
                  )}
                />

                <Route
                  path="/logout"
                  render={(props) => (
                    <Page {...props} component={HomePage} title="Home" />
                  )}
                />

                <Route
                  path="/faq/purchasingFaq/:useCase?"
                  render={(props) => (
                    <Page
                      {...props}
                      component={PurchasingFaqPage}
                      title="Purchasing FAQ"
                    />
                  )}
                />

                <Route component={NotFoundPage} />
              </Switch>
            </div>
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="right"
              open={isSideBarOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <SideBarMenu
                onMenuClick={() => setIsSideBarOpen(false)}
                onSwitchTheme={(name) => changeTheme(name)}
                onSwitchLanguage={(name) => changeLanguage(name)}
                onLogin={() => authService.login()}
                onLogout={() => authService.logout()}
                session={session}
                loggedUser={loggedUser}
                drawerWidth={drawerWidth}
                themeName={themeName}
              />
            </Drawer>
          </div>
          <div id="footer">
            <Footer session={session} themeName={themeName} />
          </div>
          {showCookieConsent && (
            <div id="cookie-consent">
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography variant="body2" style={{ maxWidth: "720px" }}>
                  {res.cookiesConsent}
                  <Link
                    color="inherit"
                    href="#"
                    onClick={() => history.push(urls.URL_PRIVACY_POLICY)}
                  >
                    <u>{res.privacyPolicy}</u>
                  </Link>
                </Typography>
                <br />
                <Button variant="contained" onClick={() => saveCookieConsent()}>
                  {res.agreeButtonText}
                </Button>
              </Grid>
            </div>
          )}

          {/* </Sidebar> */}

          <Snackbar
            open={showErrorMessage}
            autoHideDuration={6000}
            onClose={() => setShowErrorMessage(false)}
          >
            <Alert onClose={() => setShowErrorMessage(false)} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
        </ThemeProvider>
      </div>
    </AppInsightsProvider>
  );
};

export default App;
