import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Button,
  Checkbox,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  Dialog,
  CircularProgress,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import SyncIcon from "@material-ui/icons/Sync";
import moment from "moment";

import { IBlobInfo } from "../../../../api/Admin/filesApi";
import { IBlob } from "../../../../api/Admin/dbFilesApi";

export interface IBlobInfoRequest {
  fileUrl: string;
  onSuccess: (result: IBlobInfo) => void;
}

export interface IBlobDialogProps {
  file: IBlob | undefined;
  open: boolean;
  handleFileInfo: (request: IBlobInfo) => void;
  handleDelete: (fileId: number) => void;
  handleSubmit: (request: IBlob) => void;
  handleCancel: () => void;
}

const BlobDialog: React.FC<IBlobDialogProps> = ({
  file,
  open,
  handleFileInfo,
  handleDelete,
  handleSubmit,
  handleCancel,
}) => {
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      category: file?.category,
      displayName: file?.displayName || "",
      url: file?.url,
      version: file?.version,
      description: file?.description,
      language: file?.language,
      platform: file?.platform,
      releaseNotes: file?.releaseNotes,
      size: file?.size,
      sizeMb: file?.size ? formatBytes(file.size) : "",
      date: file?.date,
      dateString: file?.date ? moment(file.date).format("DD/MM/yyyy") : "",
      isActive: file?.isActive || false,
    },
    validationSchema: Yup.object({
      url: Yup.string().required("Required"),
      displayName: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      await handleSubmit({
        fileId: file?.fileId || 0,
        displayName: values.displayName || "",
        category: values.category,
        url: values.url || "",
        version: values.version,
        description: values.description,
        language: values.language,
        platform: values.platform,
        releaseNotes: values.releaseNotes,
        date: values.date,
        size: values.size,
        isActive: values.isActive,
      });
    },
  });

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      formControls: {
        padding: "3px 0px 12px 0px",
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      input: {
        verticalAlign: "top",
        marginTop: "0px",
        paddingTop: "0px",
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
  );
  const styles = useStyles();

  const getFileInfo = () => {
    if (formik.values.url) {
      handleFileInfo({
        fileUrl: formik.values.url,
        onSuccess: (blobInfo) => {
          formik.setFieldValue("isActive", true);
          formik.setFieldValue("size", blobInfo.size);
          formik.setFieldValue("sizeMb", formatBytes(blobInfo.size));
          formik.setFieldValue("date", blobInfo.date);
          formik.setFieldValue(
            "dateString",
            moment(blobInfo.date).format("DD/MM/yyyy")
          );
        },
      });
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  const getFileName = (file: string) => {
    if (file) {
      return file.substring(file.lastIndexOf("/") + 1);
    }
    return file;
  };

  const handleFileChange = (event) => {
    handleChange(event);
    const filename = getFileName(event.target.value);
    formik.setFieldValue("displayName", filename);

    const versionRegex = new RegExp("\\d+\\.\\d+\\.\\d+(\\.\\d+)*");
    const versionMatch = versionRegex.exec(filename);
    if (versionMatch) {
      formik.setFieldValue("version", versionMatch[0]);
    }

    const platformRegex = new RegExp("(x86)|(x64)");
    const platformMatch = platformRegex.exec(filename);
    if (platformMatch) {
      formik.setFieldValue("platform", platformMatch[0]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="new project"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <>
          <Typography variant="h6">
            {(file?.fileId || 0) == 0 ? "New File" : getFileName(file!.url)}
          </Typography>
          <Typography variant="body2">{file?.fileId}</Typography>
        </>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={styles.root}>
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            className={styles.formControls}
          >
            <Grid item xs={11}>
              <FormControl fullWidth>
                <TextField
                  label="File Url"
                  name="url"
                  type="text"
                  value={formik.values.url}
                  onChange={handleFileChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={getFileInfo}>
                <SyncIcon />
              </IconButton>
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                <TextField
                  label="Version"
                  name="version"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.version || ""}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id="language-label">Language</InputLabel>
                <Select
                  labelId="language-label"
                  name="language"
                  value={formik.values.language || ""}
                  onChange={handleChange}
                >
                  <MenuItem value={undefined}>Neutral</MenuItem>
                  <MenuItem value="es">Spanish</MenuItem>
                  <MenuItem value="en">English</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel id="platform-label">Platform</InputLabel>
                <Select
                  labelId="platform-label"
                  name="platform"
                  value={formik.values.platform || ""}
                  onChange={handleChange}
                >
                  <MenuItem value="">Any</MenuItem>
                  <MenuItem value="x86">x86</MenuItem>
                  <MenuItem value="x64">x64</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <FormControl fullWidth>
                <TextField
                  label="Size"
                  disabled={true}
                  name="sizeMb"
                  InputLabelProps={{ shrink: true }}
                  type="text"
                  value={formik.values.sizeMb}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  label="Date"
                  disabled={true}
                  InputLabelProps={{ shrink: true }}
                  name="dateString"
                  type="text"
                  value={formik.values.dateString}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  label="Display Name"
                  name="displayName"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.displayName}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  label="Category"
                  name="category"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.category}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Description"
                  name="description"
                  type="text"
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.description}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Release Notes"
                  name="releaseNotes"
                  multiline
                  rows={6}
                  type="text"
                  value={formik.values.releaseNotes || ""}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.isActive}
                      onChange={handleCheckBoxChange}
                      color="primary"
                      name="isActive"
                    />
                  }
                  label="Active"
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleDelete(file?.fileId || 0)}
                variant="contained"
                type="reset"
                color="secondary"
              >
                Delete
              </Button>
            </Grid>
            <Grid item container direction="row" justify="flex-end" xs={8}>
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>Submit</Grid>
                  {formik.isSubmitting && (
                    <Grid item>
                      <CircularProgress size={18} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default BlobDialog;
