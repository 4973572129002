import { combineReducers } from "redux";
import productsReducer from "./productsReducer";
import authReducer from "./authReducer";
import userInfoReducer from "./userInfoReducer";
import languageReducer from "./languageReducer";
import keepAliveReducer from "./keepAliveReducer";

const rootReducer = combineReducers({
  language: languageReducer,
  products: productsReducer,
  auth: authReducer,
  userInfo: userInfoReducer,
  keepAlive: keepAliveReducer,
});

//Type that can be used in useSelector(...)
export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
