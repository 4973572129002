import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  getOrders,
  getProject,
  cancelProject,
  getProjectUsers,
  updateProject,
  deleteProject,
  updateDevelopmentDetails,
  updateInstallationDetails,
  updatePaymentDetails,
  updateCommissionDetails,
  IProjectRequest,
  IProjectResponse,
  IOrder,
  IDevelopmentDetailsRequest,
  IInstallationDetailsRequest,
  IProjectPaymentRequest,
  IProjectCommissionRequest,
  IProjectUserScope,
  IProgramUserScopePivot,
  IProjectUserScopeRequest,
  addProjectBranch,
  deleteProjectBranch,
  addOrUpdateProjectUserScope,
  deleteProjectUserScope,
} from "../../../api/Admin/dbProjectsApi";
import {
  IProduct,
  getProducts,
  addProductStation,
  deleteProductStation,
} from "../../../api/Admin/dbProductsApi";
import { IUserV0, getUsersList } from "../../../api/Account/usersApi";
import ProjectDetailsDialog from "./dialogs/ProjectDetailsDialog";
import InstallationDialog from "./dialogs/ProjectInstallationDialog";
import ProjectPaymentDialog from "./dialogs/ProjectPaymentDialog";
import ProjectCommissionDialog from "./dialogs/ProjectCommissionDialog";
import ProjectDevelopmentDialog from "./dialogs/ProjectDevelopmentDialog";
import ProjectBranchDialog from "./dialogs/ProjectBranchDialog";
import ProjectUserScopeDialog from "./dialogs/ProjectUserScopeDialog";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Moment from "react-moment";
import {
  Typography,
  Grid,
  CircularProgress,
  Backdrop,
  Stepper,
  Step,
  StepLabel,
  Button,
  IconButton,
  Hidden,
  Breadcrumbs,
  Link,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Collapse,
  ListItemText,
  Avatar,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CheckIcon from "@material-ui/icons/Check";
import { ToastContainer, toast } from "react-toastify";
import {
  DataTypeProvider,
  SortingState,
  IntegratedSorting,
  TableColumnWidthInfo,
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import PersonIcon from "@material-ui/icons/Person";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import * as urls from "../../../types/Constants";
import ProjectCancelDialog from "./dialogs/ProjectCancelDialog";
import ProjectStationDialog from "./dialogs/ProjectStationDialog";

interface IProjectUsersListItemProps {
  key: string;
  scope: IProgramUserScopePivot;
}

const ProjectPage: React.FC = () => {
  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const ConfirmationMsg = ({ closeToast, message, caption, onAccept }) => {
    const closeAndAccept = () => {
      onAccept();
      closeToast();
    };
    return (
      <Grid container direction="column">
        <Typography variant="body2" color="textPrimary">
          {message}
        </Typography>
        {caption && (
          <Typography variant="caption" color="textPrimary">
            {caption}
          </Typography>
        )}
        <Grid item container direction="row" style={{ marginTop: "12px" }}>
          <Button variant="contained" onClick={closeAndAccept}>
            Yes
          </Button>
          <Button onClick={closeToast}>No</Button>
        </Grid>
      </Grid>
    );
  };

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [project, setProject] = useState<IProjectResponse | undefined>();
  const [users, setUsers] = useState<IUserV0[]>([]);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [orders, setOrders] = useState<IOrder[]>([]);

  const refreshAsync = async () => {
    setIsLoading(true);
    await getProject(
      id,
      (result) => {
        setProject(result);
        setIsLoading(false);
      },
      (error) => {
        showError(error);
        setIsLoading(false);
      }
    );
  };

  const refreshUsersAsync = async () => {
    await getUsersList(
      (response) => {
        const orderedUsers: IUserV0[] = response.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setUsers([...orderedUsers]);
      },
      (err) => showError(err)
    );
  };

  const refreshProductsAsync = async () => {
    await getProducts(
      (prods) => {
        setProducts([...prods]);
      },
      (error) => showError(error)
    );
  };

  const refreshOrdersAsync = async () => {
    await getOrders(
      (o) => {
        setOrders([...o]);
      },
      (error) => showError(error)
    );
  };

  const history = useHistory();

  //ORDER
  const [showUpdateOrderDialog, setShowUpdateOrderDialog] = useState<boolean>(
    false
  );
  const onUpdateProject = async (request: IProjectRequest) => {
    await updateProject(
      request,
      () => {
        setShowUpdateOrderDialog(false);
        toast.success("Project updated");
        refreshAsync();
      },
      (err) => {
        showError(err);
      }
    );
  };

  const onDeleteProject = async (projectId: string) => {
    toast(
      <ConfirmationMsg
        closeToast={toast.dismiss}
        onAccept={async () => {
          await deleteProject(
            projectId,
            () => {
              setShowUpdateOrderDialog(false);
              history.push("/admin/projects");
            },
            (error) => showError(error)
          );
        }}
        message={"Delete project with ID " + projectId + "?"}
        caption="Note: All associated scopes and branches will be deleted"
      />,
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      }
    );
  };

  // DEVELOPMENT
  const [showDevelopmentDialog, setShowDevelopmentDialog] = useState<boolean>(
    false
  );
  const onUpdateDevDetails = async (request: IDevelopmentDetailsRequest) => {
    await updateDevelopmentDetails(
      request,
      () => {
        setShowDevelopmentDialog(false);
        toast.success("Project updated");
        refreshAsync();
      },
      (err) => {
        showError(err);
      }
    );
  };

  // INSTALLATION
  const [showInstallationDialog, setShowInstallationDialog] = useState<boolean>(
    false
  );
  const onUpdateInstallation = async (request: IInstallationDetailsRequest) => {
    await updateInstallationDetails(
      request,
      () => {
        setShowInstallationDialog(false);
        toast.success("Project updated");
        refreshAsync();
      },
      (err) => {
        showError(err);
      }
    );
  };

  // PAYMENT
  const [showPaymentDialog, setShowPaymentDialog] = useState<boolean>(false);

  const onUpdatePayment = async (request: IProjectPaymentRequest) => {
    await updatePaymentDetails(
      request,
      () => {
        setShowPaymentDialog(false);
        toast.success("Project updated");
        refreshAsync();
      },
      (err) => {
        showError(err);
      }
    );
  };

  // COMMISSION
  const [showCommissionDialog, setShowCommissionDialog] = useState<boolean>(
    false
  );

  const onUpdateCommission = async (request: IProjectCommissionRequest) => {
    await updateCommissionDetails(
      request,
      () => {
        setShowCommissionDialog(false);
        toast.success("Project updated");
        refreshAsync();
      },
      (err) => {
        showError(err);
      }
    );
  };

  function isProjectComplete(): boolean {
    if (!project?.devEndDate) {
      return false;
    } else if (project.installed === false) {
      return false;
    } else if (project.paid === false) {
      return false;
    } else if (project.commissionUser) {
      return project.commissionPaid === true;
    }
    return true;
  }

  function moveNextStage() {
    if (!project?.devEndDate) {
      setShowDevelopmentDialog(true);
    } else if (project?.installed === false) {
      setShowInstallationDialog(true);
    } else if (project?.paid === false) {
      setShowPaymentDialog(true);
    } else if (project?.commissionUser) {
      setShowCommissionDialog(true);
    }
  }

  //BRANCHES
  const [showNewBranchDialog, setShowNewBranchDialog] = useState<boolean>(
    false
  );
  const handleAddNewBranch = async (branchName: string) => {
    await addProjectBranch(
      project?.programId || "",
      branchName,
      () => {
        setShowNewBranchDialog(false);
        refreshAsync();
      },
      (error) => showError(error)
    );
  };

  const handleBranchDelete = (chipToDelete) => {
    toast(
      <ConfirmationMsg
        closeToast={toast.dismiss}
        onAccept={async () => {
          await deleteProjectBranch(
            project?.programId || "",
            chipToDelete.key,
            () => {
              setShowNewBranchDialog(false);
              refreshAsync();
            },
            (error) => showError(error)
          );
        }}
        message={"Delete " + chipToDelete.key + "?"}
        caption="Note: Branches with active licenses will not be deleted"
      />,
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      }
    );
  };

  //STATIONS
  const [showNewStationDialog, setShowNewStationDialog] = useState<boolean>(
    false
  );
  const handleAddNewStation = async (stationName: string) => {
    await addProductStation(
      project?.productId || "",
      stationName,
      () => {
        setShowNewStationDialog(false);
        refreshAsync();
      },
      (error) => showError(error)
    );
  };

  const handleStationDelete = (chipToDelete) => {
    toast(
      <ConfirmationMsg
        closeToast={toast.dismiss}
        onAccept={async () => {
          await deleteProductStation(
            project?.productId || "",
            chipToDelete.key,
            () => {
              setShowNewStationDialog(false);
              refreshAsync();
            },
            (error) => showError(error)
          );
        }}
        message={"Delete " + chipToDelete.key + "?"}
        caption="Note: Stations with active licenses cannot be deleted"
      />,
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      }
    );
  };

  //USERS
  const [projectUsers, setProjectUsers] = useState<IProgramUserScopePivot[]>(
    []
  );
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [showUserScopeDialog, setShowUserScopeDialog] = useState<boolean>(
    false
  );

  const [dialogUserScope, setDialogUserScope] = useState<
    IProgramUserScopePivot | undefined
  >();

  const openNewUserScope = () => {
    setDialogUserScope({
      userId: undefined,
      download: true,
      activateSupport: false,
      activateFull: false,
    });
    setShowUserScopeDialog(true);
  };

  const openEditUserScope = (scope: IProgramUserScopePivot) => {
    setDialogUserScope({ ...scope });
    setShowUserScopeDialog(true);
  };

  const usersPivot = (scopes: IProjectUserScope[]) => {
    const list: IProgramUserScopePivot[] = [];
    scopes.forEach((scope) => {
      let userScope: IProgramUserScopePivot | undefined = list.find(
        (x) => x.userId === scope.userId
      );
      if (!userScope) {
        userScope = {
          userId: scope.userId,
          userName: scope.userName,
          download: false,
          activateSupport: false,
          activateFull: false,
          activateOffline: false,
        };
        list.push(userScope);
      }
      userScope.activateOffline =
        userScope.activateOffline || scope.scopeId === "OfflineLicense";
      userScope.download = userScope.download || scope.scopeId === "Download";
      userScope.activateSupport =
        userScope.activateSupport || scope.scopeId === "SupportLicense";
      userScope.activateFull =
        userScope.activateFull || scope.scopeId === "FullLicense";
    });
    setProjectUsers(list);
  };

  const refreshProjectUsersAsync = async () => {
    setLoadingUsers(true);
    await getProjectUsers(
      project?.programId || "",
      (scopes) => {
        setLoadingUsers(false);
        usersPivot(scopes);
      },
      (error) => showError(error)
    );
  };

  const onAddOrUpdateUserScope = async (request: IProjectUserScopeRequest) => {
    if (project) {
      await addOrUpdateProjectUserScope(
        project.programId || "",
        request,
        async () => {
          setShowUserScopeDialog(false);
          await refreshProjectUsersAsync();
        },
        (error) => showError(error)
      );
    }
  };

  const onDeleteUserScope = async (request: IProjectUserScopeRequest) => {
    const user = users.find((x) => x.userId === request.userId);
    toast(
      <ConfirmationMsg
        closeToast={toast.dismiss}
        onAccept={async () => {
          await deleteProjectUserScope(
            project!.programId,
            request.userId,
            async () => {
              await refreshProjectUsersAsync();
            },
            (error) => showError(error)
          );
        }}
        message={"Delete all permissions for " + user?.name + "?"}
        caption={undefined}
      />,
      {
        position: "top-right",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      }
    );
  };

  // CANCEL
  const [anchorProjDropDown, setAnchorProjDropDown] = useState(null);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);

  const handleCancelProject = (isCanceled: boolean, cancelReason?: string) => {
    if (project?.programId) {
      cancelProject(
        project.programId,
        { isCanceled: isCanceled, cancelReason: cancelReason },
        () => {
          setShowCancelDialog(false);
          refreshAsync();
        },
        (error) => showError(error)
      );
    }
  };

  const openCancelDialog = () => {
    setAnchorProjDropDown(null);
    setShowCancelDialog(true);
  };

  useEffect(() => {
    refreshAsync();
    refreshUsersAsync();
    refreshProductsAsync();
    refreshOrdersAsync();
  }, []);

  useEffect(() => {
    if (project) {
      refreshProjectUsersAsync();
    } else {
      setProjectUsers([]);
    }
  }, [project]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      page: {
        padding: "32px",
      },
      breadCrums: {
        padding: "0 0 12px 0",
      },
      header: {
        padding: "12px 6px 12px 6px",
      },
      newProductButton: {
        width: "148px",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      content: {
        margin: "16px 0 16px 0px",
      },
      stepper: {
        backgroundColor: theme.palette.background.default,
        margin: "6px 0 6px 0px",
        padding: "0px 6px 24px 6px",
      },
      chipList: {
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
      },
      chip: {
        margin: theme.spacing(0.5),
      },
      checkIcon: {
        width: "24px",
        height: "24px",
      },
      editUserLink: {
        padding: "3px 6px 3px 6px",
      },
      projDropDown: {
        width: 24,
        height: 24,
      },
    })
  );
  const styles = useStyles();

  const SectionHeader = ({ title }) => {
    return (
      <Typography variant="h6" color="primary" className={styles.header}>
        {title}
      </Typography>
    );
  };

  const Navigation = () => {
    return (
      <Breadcrumbs
        className={styles.breadCrums}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          color="inherit"
          href="#"
          onClick={() => history.push(urls.URL_HOME)}
        >
          Home
        </Link>
        <Link
          color="inherit"
          href="#"
          onClick={() => history.push(urls.URL_PROJECTS)}
        >
          Projects
        </Link>
        <Typography color="textPrimary">{project?.programId}</Typography>
      </Breadcrumbs>
    );
  };

  const ProjectSteps = ({ orientation }) => {
    return (
      <Stepper
        activeStep={0}
        orientation={orientation}
        className={styles.stepper}
      >
        <Step key="Ordered" completed={true}>
          <StepLabel>
            <div>
              <Typography>Order Placed</Typography>

              <>
                <Moment format="DD/MM/YYYY">{project?.orderDate}</Moment>
                <IconButton
                  aria-label="view order details"
                  size="small"
                  onClick={() => setShowUpdateOrderDialog(true)}
                >
                  <MoreHorizIcon fontSize="inherit" />
                </IconButton>
              </>
            </div>
          </StepLabel>
        </Step>

        {(project?.isCanceled !== true ||
          project?.devEndDate !== undefined) && (
          <Step
            key="Development"
            completed={
              project?.devEndDate !== null && project?.devEndDate !== undefined
            }
          >
            <StepLabel>
              <div>
                <Typography>Development</Typography>
                {project?.devEndDate ? (
                  <>
                    <Moment format="DD/MM/YYYY">{project?.devEndDate}</Moment>
                    <IconButton
                      aria-label="view development details"
                      size="small"
                      onClick={() => setShowDevelopmentDialog(true)}
                    >
                      <MoreHorizIcon fontSize="inherit" />
                    </IconButton>
                  </>
                ) : (
                  <Typography>
                    {project?.devStartDate ? "Started" : "Not Started"}
                  </Typography>
                )}
              </div>
            </StepLabel>
          </Step>
        )}

        {(project?.isCanceled !== true || project?.installed === true) && (
          <Step key="StepInstallation" completed={project?.installed}>
            <StepLabel>
              <div>
                <Typography>Installed</Typography>
                {project?.installed && (
                  <>
                    <Moment format="DD/MM/YYYY">
                      {project?.installationDate}
                    </Moment>
                    <IconButton
                      aria-label="view installation details"
                      size="small"
                      onClick={() => setShowInstallationDialog(true)}
                    >
                      <MoreHorizIcon fontSize="inherit" />
                    </IconButton>
                  </>
                )}
              </div>
            </StepLabel>
          </Step>
        )}
        {(project?.isCanceled !== true || project?.paid === true) && (
          <Step key="StepPayment" completed={project?.paid}>
            <StepLabel>
              <div>
                <Typography>Paid</Typography>
                {project?.paid && (
                  <>
                    <Moment format="DD/MM/YYYY">{project?.paymentDate}</Moment>
                    <IconButton
                      aria-label="view payment details"
                      size="small"
                      onClick={() => setShowPaymentDialog(true)}
                    >
                      <MoreHorizIcon fontSize="inherit" />
                    </IconButton>
                  </>
                )}
              </div>
            </StepLabel>
          </Step>
        )}

        {project?.commissionUser && (
          <Step key="StepCommission" completed={project?.commissionPaid}>
            <StepLabel>
              <div>
                <Typography>Commission Payment</Typography>
                {project?.commissionPaid && (
                  <>
                    <Moment format="DD/MM/YYYY">
                      {project?.commissionDate}
                    </Moment>
                    <IconButton
                      aria-label="view commission details"
                      size="small"
                      onClick={() => setShowCommissionDialog(true)}
                    >
                      <MoreHorizIcon fontSize="inherit" />
                    </IconButton>
                  </>
                )}
              </div>
            </StepLabel>
          </Step>
        )}

        {project?.isCanceled ? (
          <Step key="StepCanceled" completed={project?.isCanceled}>
            <StepLabel error>
              <div>
                <Typography>Canceled</Typography>
                <>
                  <Moment format="DD/MM/YYYY">
                    {project?.cancellationDate}
                  </Moment>
                  <IconButton
                    aria-label="view commission details"
                    size="small"
                    onClick={() => openCancelDialog()}
                  >
                    <MoreHorizIcon fontSize="inherit" />
                  </IconButton>
                </>
              </div>
            </StepLabel>
          </Step>
        ) : null}
      </Stepper>
    );
  };

  const ProjectBranches = () => {
    const newBranch = { key: "Add New Branch", isNew: true };
    const branchList =
      project?.branches?.map((b) => ({ key: b, isNew: false })) || [];
    const branches = [...branchList, newBranch];
    return (
      <ul className={styles.chipList}>
        {branches?.map((b) => {
          return (
            <li key={b.key}>
              {b.key === newBranch.key ? (
                <Chip
                  label={b.key}
                  icon={<AddCircleOutlineIcon />}
                  color="primary"
                  clickable
                  onClick={() => setShowNewBranchDialog(true)}
                  className={styles.chip}
                />
              ) : (
                <Chip
                  label={b.key}
                  onDelete={() => handleBranchDelete(b)}
                  className={styles.chip}
                />
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const ProjectStations = () => {
    const newStation = { key: "Add New Station", isNew: true };
    const stationList =
      project?.stationNames?.map((b) => ({ key: b, isNew: false })) || [];
    const stations = [...stationList, newStation];
    return (
      <ul className={styles.chipList}>
        {stations?.map((b) => {
          return (
            <li key={b.key}>
              {b.key === newStation.key ? (
                <Chip
                  label={b.key}
                  icon={<AddCircleOutlineIcon />}
                  color="primary"
                  clickable
                  onClick={() => setShowNewStationDialog(true)}
                  className={styles.chip}
                />
              ) : (
                <Chip
                  label={b.key}
                  onDelete={() => handleStationDelete(b)}
                  className={styles.chip}
                />
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const [columns] = useState([
    { name: "userName", title: "User Name" },
    { name: "download", title: "Download" },
    { name: "activateSupport", title: "Support" },
    { name: "activateFull", title: "Full License" },
    { name: "activateOffline", title: "Offline License" },
    { name: "editUserColumn", title: "Action" },
  ]);

  const [booleanColumns] = useState([
    "download",
    "activateSupport",
    "activateFull",
    "activateOffline",
  ]);
  const BooleanFormatter: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    value,
  }) => {
    return value ? (
      <CheckIcon className={styles.checkIcon}></CheckIcon>
    ) : (
      <div></div>
    );
  };
  const BooleanTypeProvider = (props) => (
    <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />
  );

  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>([
    { columnName: "userName", width: 200 },
    { columnName: "download", width: 120 },
    { columnName: "activateSupport", width: 120 },
    { columnName: "activateFull", width: 120 },
    { columnName: "activateOffline", width: 120 },
    { columnName: "editUserColumn", width: 160 },
  ]);

  function UserStepIcon(props) {
    const icons = {
      1: <PersonIcon />,
      2: <PersonIcon />,
      3: <PersonIcon />,
    };

    return <div>{icons[String(props.icon)]}</div>;
  }

  const [editUserColumn] = useState(["editUserColumn"]);
  const EditUserColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    return (
      // <Link href="#" variant="inherit" onClick={() => showEditDialog(row)}></Link>
      <Grid container direction="row" spacing={2}>
        <Link
          href="#"
          variant="inherit"
          className={styles.editUserLink}
          onClick={() => openEditUserScope(row)}
        >
          Edit
        </Link>
        <Link
          href="#"
          variant="inherit"
          className={styles.editUserLink}
          onClick={() => onDeleteUserScope(row)}
        >
          Remove
        </Link>
      </Grid>
    );
  };

  const ProjectUsers = () => {
    const ownership = [
      {
        userId: project?.orderUserId,
        name: project?.orderUserName,
        company: "Purchased By",
      },
      {
        userId: project?.ownerId,
        name: project?.ownerName,
        company: "Represented By",
      },
      { userId: project?.userId, name: project?.userName, company: "End User" },
    ];
    return (
      <Grid container direction="row" justify="flex-start" alignItems="stretch">
        <Grid container direction="column" item xs={2}>
          <Stepper
            activeStep={0}
            orientation="vertical"
            className={styles.stepper}
          >
            {ownership?.map((user, index) => {
              const stepProps: { completed?: boolean } = {
                completed: true,
              };
              const labelProps: {
                optional?: React.ReactNode;
                error?: boolean;
              } = {};
              return (
                <Step key={index} {...stepProps}>
                  <StepLabel StepIconComponent={UserStepIcon} {...labelProps}>
                    <div>
                      <Grid item xs>
                        <Typography variant="caption" color="textSecondary">
                          {user.company}
                        </Typography>
                        <Link
                          href="#"
                          onClick={() => alert(user.userId)}
                          color="textSecondary"
                          variant="caption"
                        >
                          ...
                        </Link>
                      </Grid>
                      <Typography variant="body2">{user.name}</Typography>
                    </div>
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => openNewUserScope()}
          >
            Add User
          </Button>
        </Grid>
        <Grid
          item
          container
          xs={8}
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
          style={{ marginTop: "-16px" }}
        >
          {loadingUsers ? (
            <Typography variant="subtitle2">Loading users...</Typography>
          ) : (
            <>
              <DevExpressGrid rows={projectUsers} columns={columns}>
                <DataTypeProvider
                  for={editUserColumn}
                  formatterComponent={EditUserColumnRenderer}
                />
                <SortingState />
                <BooleanTypeProvider for={booleanColumns} />
                <IntegratedSorting />
                <Table />
                <TableColumnResizing
                  columnWidths={columnWidths}
                  onColumnWidthsChange={setColumnWidths}
                />
                <TableHeaderRow showSortingControls />
              </DevExpressGrid>
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  function ProjectUsersListItem(props: IProjectUsersListItemProps) {
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <ListItem button onClick={() => setOpen(!open)}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary={props.scope?.userName} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button style={{ paddingLeft: "32px" }}>
              {/* <ListItemText primary="Permissions" /> */}
              {props.scope?.download && <Avatar>D</Avatar>}
              {props.scope?.activateFull && <Avatar>F</Avatar>}
              {props.scope?.activateSupport && <Avatar>S</Avatar>}
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="more"
                  onClick={() => openEditUserScope(props.scope)}
                >
                  <MoreHorizIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Collapse>
      </>
    );
  }

  const ProjectUsersList = () => {
    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid container direction="column" item xs={12}></Grid>
        <Grid item xs={12}>
          {loadingUsers ? (
            <Typography variant="subtitle2">Loading users...</Typography>
          ) : (
            <List>
              {projectUsers.map((userScope) => (
                <ProjectUsersListItem
                  key={userScope.userId || ""}
                  scope={userScope}
                />
              ))}
            </List>
          )}
        </Grid>

        <Button
          variant="contained"
          color="secondary"
          onClick={() => openNewUserScope()}
        >
          Add User
        </Button>
      </Grid>
    );
  };

  return (
    <div id="projectPage" className={styles.page}>
      <Grid container className={styles.header} direction="column">
        <Typography variant="h5">{project?.name}</Typography>
        <Typography variant="body1">{project?.programId}</Typography>
        <Typography variant="body2">{project?.orderId}</Typography>
      </Grid>
      <Backdrop className={styles.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      {isLoading === false && (
        <>
          <Grid container direction="column" className={styles.content}>
            <Hidden smDown>
              <Grid item container direction="row">
                <Navigation />
                <IconButton
                  className={styles.projDropDown}
                  size="small"
                  onClick={(e) => setAnchorProjDropDown(e.currentTarget)}
                >
                  <MoreVertIcon />
                </IconButton>
                {project && (
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorProjDropDown}
                    keepMounted
                    open={Boolean(anchorProjDropDown)}
                    onClose={() => setAnchorProjDropDown(null)}
                  >
                    <MenuItem onClick={openCancelDialog}>
                      {project.isCanceled ? "Restore" : "Cancel"}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        onDeleteProject(project.programId);
                        setAnchorProjDropDown(null);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                )}
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <>
                <Grid
                  item
                  container
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <SectionHeader title="Status" />
                  <ProjectSteps orientation="vertical" />

                  {!isProjectComplete() && project?.isCanceled !== true && (
                    <Button
                      variant="contained"
                      color="secondary"
                      className={styles.newProductButton}
                      onClick={() => moveNextStage()}
                    >
                      Next Step &gt;
                    </Button>
                  )}

                  <br />
                  <SectionHeader title="Branches" />
                  <ProjectBranches />
                  <SectionHeader title="WorkStations" />
                  <ProjectStations />
                  <SectionHeader title="Users" />
                  <ProjectUsersList />
                </Grid>
              </>
            </Hidden>
            <Hidden smDown>
              <SectionHeader title="Status" />
              <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item xs={10}>
                  <ProjectSteps orientation="horizontal" />
                </Grid>
                <Grid item xs={2}>
                  {!isProjectComplete() && project?.isCanceled !== true && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => moveNextStage()}
                    >
                      Next Step &gt;
                    </Button>
                  )}
                </Grid>
              </Grid>
              <SectionHeader title="Branches" />
              <ProjectBranches />
              <SectionHeader title="WorkStations" />
              <ProjectStations />
              <SectionHeader title="Users" />
              <ProjectUsers />
            </Hidden>
          </Grid>

          <ProjectDevelopmentDialog
            row={project}
            open={showDevelopmentDialog}
            handleSubmit={onUpdateDevDetails}
            handleCancel={() => setShowDevelopmentDialog(false)}
          />

          <InstallationDialog
            row={project}
            users={users}
            enableUser={true}
            open={showInstallationDialog}
            handleSubmit={onUpdateInstallation}
            handleCancel={() => setShowInstallationDialog(false)}
          />

          <ProjectPaymentDialog
            row={project}
            open={showPaymentDialog}
            users={users}
            handleSubmit={onUpdatePayment}
            handleCancel={() => setShowPaymentDialog(false)}
          />

          <ProjectCommissionDialog
            row={project}
            open={showCommissionDialog}
            users={users}
            handleSubmit={onUpdateCommission}
            handleCancel={() => setShowCommissionDialog(false)}
          />

          <ProjectDetailsDialog
            row={project}
            users={users}
            orders={orders}
            products={products}
            open={showUpdateOrderDialog}
            handleDelete={onDeleteProject}
            handleSubmit={(request) => onUpdateProject(request)}
            handleCancel={() => setShowUpdateOrderDialog(false)}
          />

          <ProjectBranchDialog
            open={showNewBranchDialog}
            handleSubmit={handleAddNewBranch}
            handleCancel={() => setShowNewBranchDialog(false)}
          />

          <ProjectStationDialog
            open={showNewStationDialog}
            handleSubmit={handleAddNewStation}
            handleCancel={() => setShowNewStationDialog(false)}
          />

          <ProjectUserScopeDialog
            scope={dialogUserScope}
            otherScopes={projectUsers}
            users={users}
            open={showUserScopeDialog}
            handleSubmit={onAddOrUpdateUserScope}
            handleCancel={() => setShowUserScopeDialog(false)}
          />

          {project && (
            <ProjectCancelDialog
              project={project}
              open={showCancelDialog}
              handleSubmit={handleCancelProject}
              handleCancel={() => setShowCancelDialog(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProjectPage;
