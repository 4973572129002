import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { TreeView, TreeItem } from "@material-ui/lab";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import PropTypes from "prop-types";
import FolderIcon from "@material-ui/icons/Folder";
import {
  IWikiProduct,
  IWikiPage,
  getWikiListing,
  getWikiContent,
  IWikiFileData,
} from "../../api/wikiApi";
import { getMyProducts, IProductInfo } from "../../api/Account/myProductsApi";
import { IUserV0, getUsersList } from "../../api/Account/usersApi";
import WikiRenderer from "./WikiRenderer";
import { useSelector } from "react-redux";
import { ISession, ILoggedUser } from "../../types/Auth";
import { ToastContainer, toast } from "react-toastify";
import { RootState } from "../../redux/reducers/rootReducer";
import IResources, { resources } from "./.resources";
import UserDropDown from "../admin/_shared/UsersDropDown";

const Wiki: React.FC = () => {
  const [selectedItem, setSelectedItem] = useState<IWikiPage>();
  const [products, setProducts] = useState<IWikiProduct[]>([]);
  const [productDetails, setProductDetails] = useState<IProductInfo[]>([]);
  const [content, setContent] = useState<IWikiFileData[]>([]);
  const [selectedContent, setSelectedContent] = useState<IWikiFileData>();
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IUserV0[]>([]);
  const [viewAsUser, setViewAsUser] = useState<string>();

  const res: IResources = resources;
  const language: string = useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const loggedUser: ILoggedUser = useSelector((state: RootState) => {
    return state.userInfo;
  });

  const session: ISession = useSelector((state: RootState) => {
    return state.auth;
  }); //REDUX

  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getWiki = async (products: IProductInfo[]) => {
    await getWikiListing(
      products.map((x) => x.productId),
      ["Support", "Admin"],
      "Wiki",
      language,
      (wikis) => {
        setSelectedItem(undefined);
        setProducts([
          ...wikis.map((x) => ({
            ...x,
            productName: products.find((p) => p.productId === x.productId)
              ?.productName,
          })),
        ]);
      },
      (error) => {
        showError(error);
        setIsLoading(false);
      }
    );
  };

  const getProducts = async () => {
    setIsLoading(true);
    const viewAs = viewAsUser || session?.objectId || "";
    await getMyProducts(
      viewAs,
      undefined,
      (response) => {
        const products =
          response.products?.filter((x) => Boolean(x.productId)) || [];
        setProductDetails(products);
        getWiki(products);
        setIsLoading(false);
      },
      (error) => (msg) => showError(msg)
    );
  };

  const refreshUsersAsync = async () => {
    await getUsersList(
      (response) => {
        const orderedUsers: IUserV0[] = response
          .filter((u) => u.isSupport || u.isAdmin)
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
        setUsers([...orderedUsers]);
      },
      (err) => showError(err)
    );
  };

  useEffect(() => {
    if (session) {
      getProducts();
    }
  }, []);

  useEffect(() => {
    getProducts();
  }, [session]);

  useEffect(() => {
    if (loggedUser.isAdmin) {
      refreshUsersAsync();
    }
  }, [loggedUser]);

  const useTreeItemStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.secondary,
      "&:hover > $content": {
        backgroundColor: theme.palette.action.hover,
      },
      "&:focus > $content, &$selected > $content": {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        color: "var(--tree-view-color)",
      },
      "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
        backgroundColor: "transparent",
      },
    },
    node: {
      padding: "8px",
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "$expanded > &": {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
      "& $content": {
        paddingLeft: theme.spacing(2),
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: "inherit",
      color: "inherit",
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1,
    },
  }));

  const findPage = (page: IWikiPage, id: string) => {
    if (page.id === id) {
      return page;
    }
    if (page.pages) {
      let match: IWikiPage | undefined;
      page.pages.forEach((subPage) => {
        if (!match) {
          match = findPage(subPage, id);
          if (match) {
            return;
          }
        }
      });
      return match;
    }
    return undefined;
  };

  const handleTreeViewSelectItem = (event, value) => {
    setSelectedItem(undefined);
    setSelectedContent(undefined);
    setIsLoadingPage(false);
    products.forEach((prod) => {
      const match = findPage(prod.root, value);
      if (match) {
        setSelectedItem(match);
        const selectedContent = content?.find((c) => c.id === value);
        if (!selectedContent && match.hasContent) {
          setIsLoadingPage(true);
          getWikiContent(
            match.id,
            (c) => {
              //const wikiContent: IWikiContent = { id: value, content: c };
              c.content = c.content?.replace(
                "@WikiDate",
                `Updated on ${c.date}`
              );
              const file = { ...c, id: value };
              setContent([...content, file]);
              setSelectedContent(file);
              setIsLoadingPage(false);
            },
            (notFound) => {
              const file = {
                ...notFound,
                content: res.notFoundMessage,
                id: value,
              };
              setContent([...content, file]);
              setSelectedContent(file);
              setIsLoadingPage(false);
            },
            (err) => {
              setIsLoadingPage(false);
            }
          );
        } else {
          setSelectedContent(selectedContent);
        }
        return;
      }
    });
  };

  const handleNodeSelect = (event, value) => {
    true;
  };

  function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const {
      labelText,
      labelIcon: LabelIcon,
      labelInfo,
      color,
      bgColor,
      ...other
    } = props;

    return (
      <TreeItem
        label={
          <div className={classes.labelRoot}>
            <LabelIcon color="inherit" className={classes.labelIcon} />
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant="caption" color="inherit">
              {labelInfo}
            </Typography>
          </div>
        }
        onSelect={handleNodeSelect}
        className={classes.node}
        {...other}
      />
    );
  }

  StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
        maxWidth: 400,
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      node: {
        padding: "6px",
      },
      header: {
        padding: "0px 6px 0px 32px",
      },
      renderer: {
        padding: "16px 64px 16px 64px",
      },
      viewAsButton: {
        alignSelf: "center",
        margin: "12px 2px 12px 2px",
        maxWidth: "100px",
      },
    })
  );
  const classes = useStyles();

  const TreeNode: React.ComponentType<IWikiPage> = ({
    id,
    name,
    url,
    pages,
  }) => {
    return (
      <TreeItem nodeId={id} label={name} className={classes.node}>
        {pages &&
          pages.map((subPage, index) => {
            return (
              <TreeNode
                key={subPage.id}
                id={subPage.id}
                name={subPage.name}
                url={subPage.url}
                pages={subPage.pages}
              />
            );
          })}
      </TreeItem>
    );
  };

  return (
    <div>
      <ToastContainer />
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container>
        <Grid item xs={12} sm={2}>
          <>
            <Grid container className={classes.header} direction="column">
              <Typography variant="h3">Wiki Servicios</Typography>
            </Grid>
            {loggedUser?.isAdmin === true && (
              <div>
                <Grid
                  container
                  direction="column"
                  justify="center"
                  alignItems="stretch"
                >
                  <Grid item xs={12}>
                    <UserDropDown
                      name="installationUser"
                      label="User"
                      users={users}
                      value={viewAsUser}
                      enabled={true}
                      allowEmpty={true}
                      allowShowId={true}
                      touched={false}
                      onChange={(e) => setViewAsUser(e.target.value)}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Button
                      onClick={() => getProducts()}
                      className={classes.viewAsButton}
                      type="reset"
                      color="primary"
                      variant="contained"
                    >
                      View As
                    </Button>
                  </Grid>
                </Grid>
                <Divider variant="middle" />
              </div>
            )}
            <TreeView
              className={classes.root}
              defaultExpanded={["3"]}
              onNodeSelect={handleTreeViewSelectItem}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 24 }} />}
            >
              {products.map((prod, index) => {
                return (
                  <StyledTreeItem
                    key={prod.productId}
                    nodeId={prod.productId}
                    labelText={prod.productName || prod.productId}
                    labelIcon={FolderIcon}
                  >
                    {prod.root.pages.map((subPage, index) => {
                      return (
                        <TreeNode
                          key={subPage.id}
                          id={subPage.id}
                          name={subPage.name}
                          url={subPage.url}
                          pages={subPage.pages}
                        />
                      );
                    })}
                  </StyledTreeItem>
                );
              })}
            </TreeView>
          </>
        </Grid>

        <Grid item xs={12} md={10}>
          <Paper>
            <div className={classes.renderer}>
              {selectedItem && (
                <WikiRenderer
                  title={selectedItem.name}
                  url={selectedItem.url}
                  isLoading={isLoadingPage}
                  date={selectedContent?.date}
                  data={selectedContent?.content}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default Wiki;
