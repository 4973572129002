import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IResources, { resources } from "./.resources";
import { RootState } from "../../redux/reducers/rootReducer";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import ForDevelopersWhite from "../../images/services/services_development_white_730x730.png";
import ForDevelopersDark from "../../images/services/services_development_dark_730x730.png";
import ForBigCompaniesWhite from "../../images/services/services_bigcompanies_white_730x730.png";
import ForBigCompaniesDark from "../../images/services/services_bigcompanies_dark_730x730.png";
import ForSmallCompaniesWhite from "../../images/services/services_smallcompanies_white_730x730.png";
import ForSmallCompaniesDark from "../../images/services/services_smallcompanies_dark_730x730.png";
import ForAutomationWhite from "../../images/services/services_intermediaries_white1_730x730.png";
import ForAutomationDark from "../../images/services/services_intermediaries_dark_730x730.png";
import { ThemeOptions } from "../../types/Enums";
import { Divider, Grow, Link } from "@material-ui/core";
import DeveloperIcon from "../../images/user_developer.png";
import DistributorIcon from "../../images/user_distributor.png";
import SmallCompanyIcon from "../../images/user_small_business.png";
import BigCompanyIcon from "../../images/user_big_company.png";

export interface IServicesPage {
  themeName: ThemeOptions;
}

interface IServiceItem {
  title: string;
  description: string;
  image: string;
  direction: "column" | "column-reverse" | "row" | "row-reverse" | undefined;
  link?: string;
  linkText?: string;
  isVisible: boolean;
}

const ServicesPage: React.FC<IServicesPage> = ({ themeName }) => {
  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const history = useHistory();

  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [checked4, setChecked4] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      list: {
        margin: "16px 0px 16px 0px",
        width: "95%",
        //maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
      },
      container: {
        marginTop: "24px",
        backgroundColor: theme.palette.background.default,
      },
      serviceItem: {
        margin: "12px 0 12px 0",
        width: "100%",
      },
      serviceTextArea: {
        padding: "64px",
        [theme.breakpoints.down("md")]: {
          padding: "12px",
        },
      },
      serviceText: {
        fontFamily: "'Titillium Web',Helvetica,Arial,Lucida,sans-serif;",
        fontWeight: 500,
        webkitFontSmoothing: "antialiased",
        fontSize: "100%",
        lineHeight: "1.4em",
      },
      image: {
        width: "480px",
        height: "480px",
        [theme.breakpoints.down("md")]: {
          width: "80%",
          height: "80%",
          maxWidth: "480px",
          maxHeight: "480px",
        },
      },
      purchaseOptionsText: {
        maxWidth: "70%",
        textAlign: "center",
        margin: 12,
        [theme.breakpoints.down("md")]: {
          maxWidth: "80%",
        },
      },
      useCaseSelector: {
        width: "90%",
        margin: "16px 30px 32px 30px",
      },
      circle: {
        margin: 18,
        display: "inline-block",
        borderRadius: "50%",
        width: 130,
        height: 130,
        cursor: "pointer",
        transition: "all .2s ease-in-out",
        "&:hover": {
          transform: "scale(1.1)",
        },
      },
      circleDark: {
        backgroundColor: "#fafafa",
      },
      circleLight: {
        backgroundColor: "#3e3e3e",
      },
      userIconContainer: {
        marginTop: 32,
        marginBottom: 32,
      },
      userIcon: {
        width: 130,
        height: 130,
        zIndex: -1,
      },
      innerCirle: {
        left: 0,
        top: 0,
        position: "relative",
        marginLeft: -15,
        width: 140,
        textAlign: "center",
      },
      dynamicText: {
        marginTop: -64,
        paddingTop: 64,
        paddingLeft: 24,
        paddingRight: 24,
        fontSize: "1.1rem",
        transition: "all .2s ease-in-out",
        color: "inherit",
      },
      circleImage: {
        paddingLeft: 48,
        paddingTop: 8,
      },
    })
  );
  const classes = useStyles();

  const ServiceItem: React.FC<IServiceItem> = ({
    title,
    description,
    image,
    direction,
    link,
    linkText,
    isVisible,
  }) => {
    return (
      <Grow in={isVisible}>
        <Grid
          item
          container
          direction={direction}
          justify="flex-start"
          alignItems="center"
          className={classes.serviceItem}
        >
          <Grid
            item
            container
            direction="column"
            xs={12}
            sm={12}
            md={6}
            lg={7}
            className={classes.serviceTextArea}
          >
            <Typography variant="h3">{title}</Typography>
            <br />
            <Typography variant="body1">
              <div
                className={classes.serviceText}
                dangerouslySetInnerHTML={{ __html: `${description}` }}
              />
            </Typography>
            <br />
            {link && linkText && (
              <Link href="#" color="inherit" onClick={() => history.push(link)}>
                <u>{linkText}</u>
              </Link>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} container justify="center">
            <img alt="logo" src={image} className={classes.image} />
          </Grid>
        </Grid>
      </Grow>
    );
  };

  const toggleSections = (action: () => void) => {
    setChecked1(false);
    setChecked2(false);
    setChecked3(false);
    setChecked4(false);
    action();
  };

  const hasSelection = () => checked1 || checked2 || checked3 || checked4;

  const ServiceOption = ({ text1, text2, image, selected, onClickHandler }) => {
    return (
      <div
        className={
          themeName === "dark"
            ? `${classes.circle} ${classes.circleDark}`
            : `${classes.circle} ${classes.circleLight}`
        }
        style={{ opacity: hasSelection() && !selected ? 0.5 : 1.0 }}
        onClick={() => toggleSections(onClickHandler)}
      >
        <img alt="logo" src={image} className={classes.userIcon} />
        <div className={classes.innerCirle}>
          <Typography
            variant="h5"
            className={classes.dynamicText}
            style={{ fontSize: "0.9rem" }}
          >
            {text1}
          </Typography>
          <Typography variant="h5" className={classes.dynamicText}>
            {text2}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Typography variant="h2">{resources.title}</Typography>

        <Typography className={classes.purchaseOptionsText}>
          <div
            className={classes.serviceText}
            dangerouslySetInnerHTML={{ __html: `${res.purchaseOptionsText1}` }}
          />
        </Typography>

        <Divider variant="middle" />
        <br />

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.useCaseSelector}
        >
          <Grid
            item
            container
            xs={6}
            sm={3}
            justify="center"
            className={classes.userIconContainer}
          >
            <ServiceOption
              text1={res.iAmA}
              text2={res.softwareDeveloper}
              selected={checked1}
              image={DeveloperIcon}
              onClickHandler={() => setChecked1(!checked1)}
            />
          </Grid>
          <Grid
            item
            container
            xs={6}
            sm={3}
            justify="center"
            className={classes.userIconContainer}
          >
            <ServiceOption
              text1={res.iSell}
              text2={res.weighingProducts}
              selected={checked2}
              image={DistributorIcon}
              onClickHandler={() => setChecked2(!checked2)}
            />
          </Grid>
          <Grid
            item
            container
            xs={6}
            sm={3}
            justify="center"
            className={classes.userIconContainer}
          >
            <ServiceOption
              text1={res.iOwnA}
              text2={res.smallBusiness}
              selected={checked3}
              image={SmallCompanyIcon}
              onClickHandler={() => setChecked3(!checked3)}
            />
          </Grid>
          <Grid
            item
            container
            xs={6}
            sm={3}
            justify="center"
            className={classes.userIconContainer}
          >
            <ServiceOption
              text1={res.iWorkFor}
              text2={res.bigCompany}
              selected={checked4}
              image={BigCompanyIcon}
              onClickHandler={() => setChecked4(!checked4)}
            />
          </Grid>
        </Grid>

        <Grid
          id="services-list"
          container
          style={{ width: "100%" }}
          direction="column"
          justify="center"
          alignItems="center"
        >
          {checked1 && (
            <ServiceItem
              title={res.developersHeader}
              direction="row"
              description={res.developersText}
              image={
                themeName === "dark" ? ForDevelopersDark : ForDevelopersWhite
              }
              isVisible={checked1}
            />
          )}

          {checked2 && (
            <ServiceItem
              title={res.distributorsHeader}
              direction="row-reverse"
              description={res.distributorsText}
              image={
                themeName === "dark" ? ForAutomationDark : ForAutomationWhite
              }
              isVisible={checked2}
            />
          )}

          {checked3 && (
            <ServiceItem
              title={res.smallBusinessesHeader}
              direction="row"
              description={res.smallBusinessesText}
              image={
                themeName === "dark"
                  ? ForSmallCompaniesDark
                  : ForSmallCompaniesWhite
              }
              isVisible={checked3}
            />
          )}

          {checked4 && (
            <ServiceItem
              title={res.bigCompaniesHeader}
              direction="row-reverse"
              description={res.bigCompaniesText}
              image={
                themeName === "dark"
                  ? ForBigCompaniesDark
                  : ForBigCompaniesWhite
              }
              isVisible={checked4}
            />
          )}

          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(urls.URL_FAQ_PURCHASING)}
          >
            {res.purchaseOptions}
          </Button> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default ServicesPage;
