/* eslint-disable */
import { setupAxios, processApiRequest } from "../apiHelper";
import { rememberB2CApi } from "../testApi";

//SETUP
const baseUrl = process.env.B2C_API_URL;
const baseApi = setupAxios(baseUrl!);

export interface IUserMembership {
  userId: string;
  fetching: boolean;
  isAdministrator: boolean;
  isCustomer: boolean;
  isDistributor: boolean;
  isSupport: boolean;
}

export interface IUserV0 {
  userId: string;
  name: string;
  companyName?: string;
  flags: number;
  isSupport: boolean;
  isCustomer: boolean;
  isDistributor: boolean;
  isAdmin: boolean;
  isTestUser: boolean;
  isActive: boolean;
  isExternal: boolean;
}

export interface IUserV1 {
  userId: string;
  name: string;
  companyName?: string;
  isSupport: boolean;
  isCustomer: boolean;
  isDistributor: boolean;
  isAdmin: boolean;
  isExternal: boolean;
  isTestUser: boolean;
  lastUpdate?: Date;
  updatedBy?: string;
  parentId?: string;
  isActive: boolean;
  children: IUserV1[];
  country?: string;
  email?: string;
  phoneNumbers?: string;
  language?: string;
  accountType?: string;
  syncStatus: number;
}

export interface IUsersResponseV1 {
  needSynchronize: boolean;
  users: UserV1[];
}

export interface IExternalUserRequest {
  userId: string;
  displayName: string;
  companyName: string;
  parentId: string;
}

export const getUser = async (
  userId: string,
  onSuccess: (result: IUserV1) => void,
  errorMessage: (msg: string) => void
) => {
  //RETRIEVES USERS FROM THE DATABASE (EXTERNAL USERS ARE INCLUDED)
  const url = `users/${userId}`;
  await processApiRequest(baseApi.get<IUserV1>(url), {
    onSuccess: (response) => {
      rememberB2CApi();
      onSuccess(response.data);
    },
    serverError: (msg) =>
      errorMessage("Unexpected error. Please contact support"),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const getB2CUser = async (
  userId: string,
  onSuccess: (result: IUserV1) => void,
  errorMessage: (msg: string) => void
) => {
  //RETRIEVES USER INFO DIRECTLY FROM B2C  (EXTERNAL USERS ARE NOT INCLUDED)
  const url = `users/b2c/${userId}`;
  await processApiRequest(baseApi.get<IUserV1>(url), {
    onSuccess: (response) => {
      rememberB2CApi();
      onSuccess(response.data);
    },
    serverError: (msg) =>
      errorMessage("Unexpected error. Please contact support"),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const getUserMemberships = async (
  onSuccess: (result: IUserMembership) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "users/memberships";
  await processApiRequest(baseApi.get<IUserMembership>(url), {
    onSuccess: (response) => {
      rememberB2CApi();
      onSuccess(response.data);
    },
    serverError: (msg) =>
      errorMessage("Unexpected error. Please contact support"),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const getAllUsers = async (
  onSuccess: (result: IUsersResponseV1) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "users/all";
  await processApiRequest(baseApi.get<IUsersResponseV1>(url), {
    onSuccess: (response) => {
      rememberB2CApi();
      onSuccess(response.data);
    },
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

enum UserGroupFlag {
  IsAdmin = 1,
  IsSupport = 2,
  IsDistributor = 4,
  IsCustomer = 8,
  IsTestUser = 16,
  IsExternal = 32,
  IsActive = 64,
}

export const processUserFlags = (user: IUserV0): IUserV0 => {
  user.isActive =
    (user.flags & UserGroupFlag.IsActive) == UserGroupFlag.IsActive;
  user.isExternal =
    (user.flags & UserGroupFlag.IsExternal) == UserGroupFlag.IsExternal;
  user.isAdmin = (user.flags & UserGroupFlag.IsAdmin) == UserGroupFlag.IsAdmin;
  user.isSupport =
    (user.flags & UserGroupFlag.IsSupport) == UserGroupFlag.IsSupport;
  user.isCustomer =
    (user.flags & UserGroupFlag.IsCustomer) == UserGroupFlag.IsCustomer;
  user.isDistributor =
    (user.flags & UserGroupFlag.IsDistributor) == UserGroupFlag.IsDistributor;
  return user;
};

export const getUsersList = async (
  onSuccess: (result: IUserV0[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "users/list";
  await processApiRequest(baseApi.get<IUserV0[]>(url), {
    onSuccess: (response) => {
      rememberB2CApi();
      onSuccess(response.data.map((u) => processUserFlags(u)));
    },
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const addExternalUser = async (
  request: IExternalUserRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "users";
  await processApiRequest(baseApi.post(url, request), {
    onSuccess: () => onSuccess(),
    notFound: (msg) => errorMessage(msg),
    badRequest: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const deleteExternalUser = async (
  userId: string,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `users/${userId}`;
  await processApiRequest(baseApi.delete(url), {
    onSuccess: () => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};
