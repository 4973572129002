import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { synchronizeUsers, clearCache } from "../../../api/Admin/dbUsersApi";
import {
  IUserV0,
  IUserV1,
  getAllUsers,
  addExternalUser,
  deleteExternalUser,
  IExternalUserRequest,
} from "../../../api/Account/usersApi";
import Moment from "react-moment";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import UserCard from "./UserCard";
import UsersGrid from "./UsersGrid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { ToastContainer, toast } from "react-toastify";
import { transferUser, findUsages } from "../../../api/Admin/projUsersApi";
import UserTransferDialog from "./dialogs/UserTransferDialog";
import ExternalUserDialog from "./dialogs/ExternalUserDialog";
//https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/

const UsersPage: React.FC = () => {
  const [syncInProgress, setSyncInProgress] = useState<boolean>(false);
  const [transferInProgress, setTransferInProgress] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IUserV1[]>([]);
  const [usersList, setUsersList] = useState<IUserV0[]>([]);
  const [lastUpdate, setLastUpdate] = useState<Date | undefined>(undefined);
  const [selection, setSelection] = useState<IUserV1 | undefined>(undefined);
  const [showCard, setShowCard] = useState<boolean>(false);
  const [needSynchronize, setNeedSynchronize] = useState<boolean>(false);
  const [showExternalUserDialog, setShowExternalUserDialog] = useState<boolean>(
    false
  );
  //const [syncResults, setSyncResults] = useState<ISyncResults | undefined>();

  const [showTransferUser, setShowTransferUser] = useState<boolean>(false);

  const popupError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  function sendMessage(text: string | null | undefined) {
    if (text) {
      toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const SyncResultItem = ({ qty, caption }) => (
    <Grid container spacing={1}>
      <Grid item style={{ minWidth: "24px" }}>
        <Typography variant="overline"> {qty}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="overline">{caption}</Typography>
      </Grid>
    </Grid>
  );

  const SyncResults = ({ syncResults }) => (
    <>
      <Typography variant="subtitle2">Sync Complete</Typography>
      {(syncResults?.inserted || 0) > 0 && (
        <SyncResultItem qty={syncResults?.inserted || 0} caption="Inserted" />
      )}
      {(syncResults?.updated || 0) > 0 && (
        <SyncResultItem qty={syncResults?.updated || 0} caption="Updated" />
      )}
      {(syncResults?.deleted || 0) > 0 && (
        <SyncResultItem qty={syncResults?.deleted || 0} caption="Deleted" />
      )}
      {(syncResults?.deactivated || 0) > 0 && (
        <SyncResultItem
          qty={syncResults?.deactivated || 0}
          caption="Deactivated"
        />
      )}
      {(syncResults?.inserted || 0) +
        (syncResults?.updated || 0) +
        (syncResults?.deleted || 0) +
        (syncResults?.deactivated || 0) ===
        0 && (
        <Typography variant="body2">
          No changes. Everything is up to date
        </Typography>
      )}
    </>
  );

  const TransferResults = ({ transferResults }) => (
    <>
      <Typography variant="subtitle2">Transfer Complete</Typography>
      {(transferResults?.userFiles || 0) > 0 && (
        <SyncResultItem
          qty={transferResults?.userFiles || 0}
          caption="User Files"
        />
      )}
      {(transferResults?.orders || 0) > 0 && (
        <SyncResultItem qty={transferResults?.orders || 0} caption="Orders" />
      )}
      {(transferResults?.programs || 0) > 0 && (
        <SyncResultItem
          qty={transferResults?.programs || 0}
          caption="Programs"
        />
      )}
      {(transferResults?.userScopes || 0) > 0 && (
        <SyncResultItem
          qty={transferResults?.userScopes || 0}
          caption="User Scopes"
        />
      )}
      {(transferResults?.licenseOverrides || 0) > 0 && (
        <SyncResultItem
          qty={transferResults?.licenseOverrides || 0}
          caption="License Overrides"
        />
      )}
      {(transferResults?.userFiles || 0) +
        (transferResults?.orders || 0) +
        (transferResults?.programs || 0) +
        (transferResults?.userScopes || 0) +
        (transferResults?.licenseOverrides || 0) ===
        0 && (
        <Typography variant="body2">No changes. No data to transfer</Typography>
      )}
    </>
  );

  const UsagesResults = ({ result }) => (
    <>
      <Typography variant="subtitle2">{selection?.name}</Typography>
      {(result?.userFiles || 0) > 0 && (
        <SyncResultItem qty={result?.userFiles || 0} caption="User Files" />
      )}
      {(result?.orders || 0) > 0 && (
        <SyncResultItem qty={result?.orders || 0} caption="Orders" />
      )}
      {(result?.programs || 0) > 0 && (
        <SyncResultItem qty={result?.programs || 0} caption="Programs" />
      )}
      {(result?.userScopes || 0) > 0 && (
        <SyncResultItem qty={result?.userScopes || 0} caption="User Scopes" />
      )}
      {(result?.licenses || 0) > 0 && (
        <SyncResultItem qty={result?.licenses || 0} caption="Licenses" />
      )}
      {(result?.licenseOverrides || 0) > 0 && (
        <SyncResultItem
          qty={result?.licenseOverrides || 0}
          caption="License Overrides"
        />
      )}

      {(result?.userFiles || 0) +
        (result?.orders || 0) +
        (result?.programs || 0) +
        (result?.userScopes || 0) +
        (result?.licenses || 0) +
        (result?.licenseOverrides || 0) ===
        0 && <Typography variant="body2">User is not in use</Typography>}
    </>
  );

  const getAccountType = (user: IUserV1) => {
    if (user.isAdmin) return "Admin";
    if (user.isDistributor) return "Distributor";
    if (user.isSupport) return "Support";
    if (user.isCustomer) return "Customer";
    return "Unknown";
  };

  const refreshAsync = async () => {
    if (isLoading === true) {
      return;
    }
    setIsLoading(true);
    await clearCache(
      () => true,
      (err) => popupError(err)
    );
    await getAllUsers(
      (response) => {
        setUsers([
          ...response.users.map((u) => ({
            ...u,
            accountType: getAccountType(u),
          })),
        ]);
        const usersV0: IUserV0[] = response.users.map<IUserV0>((u) => ({
          ...u,
        }));
        setUsersList(usersV0);
        setNeedSynchronize(response.needSynchronize);
        const allUpdates = response.users.map(
          (u) => u.lastUpdate || new Date()
        );
        const lastUpdate = allUpdates.sort(function (d1, d2) {
          return d2 - d1;
        })[0];
        setLastUpdate(lastUpdate);
        setIsLoading(false);
      },
      (err) => {
        popupError(err);
        setUsers([]);
        setUsersList([]);
        setNeedSynchronize(false);
        setIsLoading(false);
      }
    );
  };

  const synchronize = async () => {
    setSyncInProgress(true);
    await synchronizeUsers(
      (results) => {
        setSyncInProgress(false);
        toast.info(<SyncResults syncResults={results} />, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        refreshAsync();
      },
      (error) => {
        setSyncInProgress(false);
        popupError(error);
      }
    );
  };

  const openExternalUserDialog = () => {
    setShowExternalUserDialog(true);
  };

  const onAddExternalUser = async (request: IExternalUserRequest) => {
    await addExternalUser(
      request,
      async () => {
        setShowExternalUserDialog(false);
        await refreshAsync();
      },
      (error) => popupError(error)
    );
  };

  const onDeleteExternalUser = async (request: IUserV1) => {
    if (request?.isExternal == true) {
      await deleteExternalUser(
        request.userId,
        async () => {
          setShowExternalUserDialog(false);
          setShowCard(false);
          await refreshAsync();
        },
        (error) => popupError(error)
      );
    } else {
      popupError(
        "Only external customers can be deleted.  Go to Azure B2C portal to remove internal users"
      );
    }
  };

  useEffect(() => {
    refreshAsync();
  }, []);

  const openTransferUserDialog = () => {
    setShowTransferUser(true);
  };

  const onTransferUser = (fromUser: string, toUser: string) => {
    setTransferInProgress(true);
    transferUser(
      fromUser,
      toUser,
      (transferResults) => {
        setTransferInProgress(false);
        toast.info(<TransferResults transferResults={transferResults} />, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        setShowTransferUser(false);
      },
      (error) => {
        popupError(error);
        setTransferInProgress(false);
      }
    );
  };

  const onFindUsages = (userId: string) => {
    findUsages(
      userId,
      (results) => {
        toast.info(<UsagesResults result={results} />, {
          position: "top-right",
          autoClose: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
      },
      (error) => {
        popupError(error);
      }
    );
  };

  function changeSelection(user: IUserV1 | undefined) {
    setSelection(user);
    setShowCard(user !== undefined);
  }

  const gridStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        padding: "0px 6px 0px 32px",
      },
      grid: {
        padding: "0px 32px 0px 32px",
      },
      lastUpdateLabel: {
        padding: "4px 4px 4px 16px",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      toolbar: {
        margin: "12px 0px 6px 0px",
      },
    })
  );
  const useGridStyles = gridStyles();

  function renderRole(user: IUserV1 | undefined) {
    const activeFlag = user?.isActive === true ? "" : " (Inactive)";
    if (user?.isAdmin === true) {
      return "Administrator" + activeFlag;
    } else if (user?.isDistributor === true) {
      return "Distributor" + activeFlag;
    } else if (user?.isSupport === true) {
      return "Support" + activeFlag;
    } else if (user?.isCustomer === true) {
      return "Customer" + activeFlag;
    }
    return "Role: unknown";
  }

  const UsersToolbar = () => {
    return (
      // <Grid
      //   item
      //   container
      //   className={useGridStyles.toolbar}
      //   direction="row"
      //   justify="space-between"
      //   alignItems="center"
      // >
      <div>
        <Grid
          item
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={4}
          xs={12}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={syncInProgress || transferInProgress}
              onClick={() => synchronize()}
            >
              Sync
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={syncInProgress || transferInProgress}
              onClick={() => openExternalUserDialog()}
            >
              New User
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={syncInProgress || transferInProgress}
              onClick={() => openTransferUserDialog()}
            >
              Transfer User
            </Button>
          </Grid>
          <Grid item>
            {lastUpdate && (
              <div className={useGridStyles.lastUpdateLabel}>
                <Typography variant="body1">Last update:</Typography>
                <Moment format="DD/MM/YYYY">{lastUpdate}</Moment>
              </div>
            )}
          </Grid>
          <Grid item>
            {needSynchronize && (
              <Typography color="error">
                Some users are not synronized
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div>
      <Grid container className={useGridStyles.header}>
        <div>
          <Typography variant="h2">Users</Typography>
          <Typography variant="body1">
            Azure B2C and Database users. Press Sync to synronize both systems
          </Typography>
          <ToastContainer />
          <Backdrop className={useGridStyles.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {isLoading === false && <UsersToolbar />}
        </div>
      </Grid>

      <Grid container>
        <Grid item xs={showCard ? 8 : 12} className={useGridStyles.grid}>
          <div>
            {users && users.length > 0 ? (
              <UsersGrid users={users} selectionChanged={changeSelection} />
            ) : null}
          </div>
        </Grid>
        {showCard ? (
          <Grid item xs={4}>
            <UserCard
              selection={selection}
              roleName={renderRole(selection)}
              closeCard={() => setShowCard(false)}
              displayInfoMessage={(msg) => sendMessage(msg)}
              handleFindUsages={onFindUsages}
              handleDelete={onDeleteExternalUser}
            />
          </Grid>
        ) : null}
      </Grid>

      <UserTransferDialog
        open={showTransferUser}
        users={usersList}
        handleSubmit={onTransferUser}
        handleCancel={() => setShowTransferUser(false)}
      />

      <ExternalUserDialog
        open={showExternalUserDialog}
        users={usersList}
        handleSubmit={onAddExternalUser}
        handleCancel={() => setShowExternalUserDialog(false)}
      />
    </div>
  );
};

export default UsersPage;
