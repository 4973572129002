import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getMyProducts, ProductInfo } from "../../../api/Account/myProductsApi";
import { ISession, ILoggedUser } from "../../../types/Auth";
//import { ProductInfo } from "@softion/b2c-service";
import { RootState } from "../../../redux/reducers/rootReducer";
import MyProductsGrid from "./MyProductsGrid";
import {
  Typography,
  Container,
  Button,
  Chip,
  Grid,
  IconButton,
  Breadcrumbs,
  Box,
  Link,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IResources, { resources } from "./.resources";
import { toast, ToastContainer } from "react-toastify";
import UserDropDown from "../../admin/_shared/UsersDropDown";
import { IUserV0, getUsersList } from "../../../api/Account/usersApi";
import { useParams, useHistory } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PersonIcon from "@material-ui/icons/Person";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SearchIcon from "@material-ui/icons/Search";
import SyncIcon from "@material-ui/icons/Sync";
import * as urls from "../../../types/Constants";
import IconLight from "../../../images/downloads/downloads.light.128x128.png";
import IconDark from "../../../images/downloads/downloads.dark.128x128.png";
import { ThemeOptions } from "../../../types/Enums";

export interface IMyProductsPageProps {
  themeName: ThemeOptions;
}

const MyProductsPage: React.FC<IMyProductsPageProps> = ({ themeName }) => {
  const { id, showAs } = useParams();
  const history = useHistory();

  const session: ISession = useSelector((state: RootState) => {
    return state.auth;
  }); //REDUX

  const loggedUser: ILoggedUser = useSelector((state: RootState) => {
    return state.userInfo;
  });

  const res: IResources = resources;
  const language: string = useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });
  // useEffect(() => {
  //   DOESN'T WORK.  It renders the previous language before making the change, instead the change is set in the useSelector()
  //   resources.setLanguage(language);
  // }, [language]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projects, setProjects] = useState<ProductInfo[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [impersonatedUser, setImpersonatedUser] = useState<
    string | null | undefined
  >(null);
  const [users, setUsers] = useState<IUserV0[]>([]);
  const [singleProductName, setSingleProductName] = useState<
    string | undefined
  >();
  const [pageTitle, setPageTitle] = useState<string>();

  const showMessage = (msg: string | null | undefined) => {
    if (msg) {
      toast.info(msg);
    }
  };
  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const refreshUsersAsync = async () => {
    if (loggedUser.isAdmin) {
      await getUsersList(
        (response) => {
          const orderedUsers: IUserV0[] = response.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
          setUsers([...orderedUsers]);
        },
        (err) => showError(err)
      );
    }
  };

  const getProductsAsync = async (user: string | undefined) => {
    const userId = user || session?.objectId;
    if (userId) {
      setIsLoading(true);
      setDialogOpen(false);
      setSingleProductName(undefined);
      setImpersonatedUser(null);
      await getMyProducts(
        userId,
        id,
        (response) => {
          setProjects(response.products || []);
          setSingleProductName(response.productName);
          //response.products?[0]
          if (userId !== null) {
            setImpersonatedUser(response.userName);
          }
          setIsLoading(false);
        },
        (error) => {
          showError(error);
          setProjects([]);
          setIsLoading(false);
        }
      );
    } else {
      setProjects([]);
    }
  };

  const getTitle = () => {
    if (loggedUser.isCustomer) return res.titleCustomer;
    if (loggedUser.isAdmin) return res.titleAdmin;
    if (loggedUser.isDistributor) return res.titleDistributor;
    if (loggedUser.isSupport) return res.titleSupport;
    return "";
  };

  useEffect(() => {
    getProductsAsync(showAs);
    refreshUsersAsync();
    resources.setLanguage(language);
  }, []);

  useEffect(() => {
    getProductsAsync(showAs);
  }, [session]);

  useEffect(() => {
    if (loggedUser?.userId) {
      setPageTitle(getTitle());
    }
  }, [loggedUser]);

  const [viewAsAccount, setViewAsAccount] = useState<string | undefined>();

  const themedStyles = makeStyles((theme: Theme) =>
    createStyles({
      input: {
        marginLeft: theme.spacing(1),
        width: 320,
        flex: 1,
      },
      chip: {
        margin: "10px 0px 10px 0px",
      },
      topContainer: { paddingTop: "24px" },

      gridContainer: {
        padding: "32px",
        borderRadius: "26px",
      },

      [theme.breakpoints.down("sm")]: {
        gridContainer: {
          padding: "16px",
          borderRadius: "8px",
        },
      },
      searchButton: {
        padding: "2px",
        margin: "0px",
        width: "32px",
        height: "32px",
      },
      clearButton: {
        padding: "0px",
        margin: "0px",
      },
      breadCrums: {
        padding: "0 0 12px 0",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      titleIcon: {
        width: "48px",
        height: "48px",
        margin: "2px 12px 2px 2px",
      },
    })
  );
  const styles = themedStyles();

  const goToPage = (url: string | undefined) => {
    if (url) {
      history.push(url);
    }
  };
  const goToDetails = (
    projectFile: IProjectFile,
    groupName: string | undefined
  ) => {
    //productId#
    if (projectFile) {
      goToPage(urls.getFileUrl(projectFile.fileId, groupName));
    }
  };

  const Navigation = () => {
    return (
      <Breadcrumbs
        className={styles.breadCrums}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="#" onClick={() => goToPage(urls.URL_HOME)}>
          Home
        </Link>
        <Link
          color="inherit"
          href="#"
          onClick={() => goToPage(urls.URL_MYPURCHASES)}
        >
          {res.myOrders}
        </Link>
        <Typography color="textPrimary">{singleProductName}</Typography>
      </Breadcrumbs>
    );
  };

  return session.loggedIn ? (
    <Container className={styles.topContainer}>
      <div>
        {id && singleProductName && <Navigation />}
        <Grid container justify="space-between" alignItems="flex-end">
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <img
                src={themeName === "dark" ? IconDark : IconLight}
                className={styles.titleIcon}
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">{pageTitle}</Typography>
            </Grid>
          </Grid>
          {/* <Grid item xs={10} sm={8}>
            <Typography variant="h2" gutterBottom>
              {pageTitle}
            </Typography>
          </Grid> */}

          <Grid container direction="row" spacing={4}>
            {id && singleProductName && (
              <Grid item>
                <Chip
                  label={singleProductName}
                  icon={<LocalOfferIcon />}
                  color="primary"
                  className={styles.chip}
                />
              </Grid>
            )}

            {loggedUser.isAdmin && impersonatedUser ? (
              <Grid item>
                <Chip
                  label={impersonatedUser}
                  icon={<PersonIcon />}
                  color="primary"
                  className={styles.chip}
                />
              </Grid>
            ) : null}
          </Grid>

          <Grid container>
            {loggedUser.isAdmin === true ? (
              <Grid item sm={4} md={8} lg={12}>
                {dialogOpen ? (
                  <form
                    noValidate
                    autoComplete="off"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "95%;",
                      maxWidth: "300px",
                    }}
                  >
                    <Grid
                      xs={12}
                      item
                      container
                      direction="row"
                      alignItems="center"
                      justify="flex-start"
                    >
                      <Grid item xs={8}>
                        <UserDropDown
                          name="userId"
                          label="View As"
                          users={users}
                          value={viewAsAccount}
                          enabled={true}
                          allowEmpty={true}
                          allowShowId={true}
                          onChange={(e) => setViewAsAccount(e.target.value)}
                          touched={false}
                          error={undefined}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          type="submit"
                          className={styles.searchButton}
                          aria-label="search"
                          onClick={() => getProductsAsync(viewAsAccount)}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          className={styles.searchButton}
                          aria-label="refresh"
                          onClick={() => refreshUsersAsync()}
                        >
                          <SyncIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setDialogOpen(true)}
                  >
                    {res.viewAs}
                  </Button>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {loggedUser.isCustomer && !id ? (
          <Grid container direction="column" alignItems="flex-start">
            <Typography variant="h6" color="primary">
              {res.welcomeTextCustomer}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {res.introCustomer}
            </Typography>
          </Grid>
        ) : null}

        <Backdrop className={styles.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <ToastContainer />

        {!isLoading && (
          <Box className={styles.gridContainer}>
            <MyProductsGrid
              onMessage={(msg) => showMessage(msg)}
              projects={projects}
              resources={res}
              onGoToDetails={goToDetails}
            />
          </Box>
        )}
      </div>
    </Container>
  ) : (
    <div>Your session has expired</div>
  );
};

export default MyProductsPage;
