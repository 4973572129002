import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import { IProjectResponse } from "../../../../api/Admin/dbProjectsApi";

export interface IProjectCancelDialogProps {
  project: IProjectResponse;
  open: boolean;
  handleSubmit: (isCanceled: boolean, cancelReason?: string) => void;
  handleCancel: () => void;
}

const ProjectCancelDialog: React.FC<IProjectCancelDialogProps> = ({
  project,
  open,
  handleSubmit,
  handleCancel,
}) => {
  const formik = useFormik({
    initialValues: {
      cancelReason: project.cancelReason,
    },
    validationSchema: Yup.object({
      cancelReason: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      await handleSubmit(true, values.cancelReason);
    },
  });

  useEffect(() => {
    if (open) {
      formik.setFieldValue("cancelReason", project?.cancelReason);
    }
  }, [open]);

  const useStyles = makeStyles({
    root: {
      // flexGrow: 1,
      padding: "18px",
    },
    input: {
      verticalAlign: "top",
      marginTop: "12px",
      width: "100%",
    },
  });
  const styles = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="development details"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        {project.isCanceled ? "Restore Project" : "Cancel Project"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={styles.root}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="flex-start"
          >
            {!project.isCanceled ? (
              <>
                <Typography variant="body2">
                  A canceled project can no longer be used when activating
                  licenses
                </Typography>

                <TextField
                  label="Cancel Reason"
                  type="text"
                  name="cancelReason"
                  multiline
                  rows={4}
                  className={styles.input}
                  error={
                    Boolean(formik.errors.cancelReason) &&
                    Boolean(formik.touched.cancelReason)
                  }
                  InputLabelProps={{ shrink: true }}
                  value={formik.values.cancelReason}
                  onChange={handleChange}
                />
                {formik.touched.cancelReason && formik.errors.cancelReason && (
                  <Typography variant="caption" color="error">
                    {formik.errors.cancelReason}
                  </Typography>
                )}
              </>
            ) : (
              <Typography variant="body2">
                Project will be reactivated for listing and product activation
                <br />
                Do you want to continue?
              </Typography>
            )}

            <Grid item />
            <Grid container direction="row" justify="flex-end">
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>

              {project.isCanceled ? (
                <Button
                  disabled={formik.isSubmitting}
                  onClick={() => handleSubmit(false, "")}
                  type="reset"
                  color="secondary"
                >
                  Restore Project
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>Cancel Project</Grid>
                    {formik.isSubmitting && (
                      <Grid item>
                        <CircularProgress size={18} />
                      </Grid>
                    )}
                  </Grid>
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectCancelDialog;
