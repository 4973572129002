import React from "react";
import { useHistory } from "react-router-dom";
import IResources, { resources } from "./.resources";
import { RootState } from "../../redux/reducers/rootReducer";
import { useSelector } from "react-redux";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import WeighBridgeImage from "../products/weighbridge/weighbridge_header2.png";

const ProductsPage: React.FC = () => {
  const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    title: {
      marginTop: 32,
    },
    container: {
      minHeight: "65vh",
    },
  });
  const classes = useStyles();

  const history = useHistory();
  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const goToUrl = (url: string) => {
    //productId#
    if (url) {
      history.push(url);
    }
  };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.title}
      >
        <Typography variant="h2">{resources.title}</Typography>
      </Grid>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Grid
          item
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <Card className={classes.root}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Weighbridge"
                  height="140"
                  image={WeighBridgeImage}
                  onClick={() => goToUrl("/products/weighbridge")}
                  title="Weighbridge Software"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Weighbridge Software
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {resources.weighbridgeText}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default ProductsPage;
