import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

const Page: React.FC = ({ title, ...rest }) => {
  useEffect(() => {
    document.title = "Softion Software Solutions - " + title;
  }, []);

  return <Route {...rest} />;
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Page;
