import React, { useState } from "react";
import { IProduct } from "../../../api/Admin/dbProductsApi";
import {
  DataTypeProvider,
  SortingState,
  SearchState,
  IntegratedFiltering,
  IntegratedSorting,
  TableColumnWidthInfo,
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  SearchPanel,
  TableColumnResizing,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";

import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { Typography } from "@material-ui/core";

//https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/

export interface IProductsGridProps {
  products: IProduct[];
  selectionChanged: (row: IProduct) => void;
}

const AdminProductsGrid: React.FC<IProductsGridProps> = ({
  products,
  selectionChanged,
}) => {
  const [columns] = useState([
    { name: "productName", title: "Product Name" },
    { name: "lastUpdate", title: "Last Update" },
    { name: "isCommercial", title: "Type" },
  ]);

  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>([
    { columnName: "productName", width: 400 },
    { columnName: "lastUpdate", width: 180 },
    { columnName: "isCommercial", width: 180 },
  ]);

  const useStyles = makeStyles((theme) => ({
    avatar: {
      width: "34px",
      height: "34px",
    },
    purpleAvatar: {
      backgroundColor: theme.palette.primary.main,
      width: "34px",
      height: "34px",
    },
    productNameLink: {
      margin: "0 0 0 12px",
    },
  }));
  const styles = useStyles();

  const [productNameColumn] = useState(["productName"]);
  const ProductNameColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      {row.isCommercial ? (
        <Avatar className={styles.purpleAvatar}>C</Avatar>
      ) : (
        <Avatar className={styles.avatar}>NC</Avatar>
      )}
      <Link
        href="#"
        variant="inherit"
        className={styles.productNameLink}
        onClick={() => selectionChanged(row)}
      >
        {row.productName}
      </Link>
    </Grid>
  );

  const [lastUpdateColumn] = useState(["lastUpdate"]);
  const LastUpdateColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => <Moment format="DD/MM/YYYY">{row.lastUpdate}</Moment>;

  const [isCommercialColumn] = useState(["isCommercial"]);
  const IsCommercialColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => (
    <div>
      {row.isCommercial ? (
        <Typography>Commercial</Typography>
      ) : (
        <Typography>Custom</Typography>
      )}
    </div>
  );

  return (
    <DevExpressGrid rows={products} columns={columns}>
      <SearchState />
      <SortingState />
      <DataTypeProvider
        for={productNameColumn}
        formatterComponent={ProductNameColumnRenderer}
      />
      <DataTypeProvider
        for={lastUpdateColumn}
        formatterComponent={LastUpdateColumnRenderer}
      />
      <DataTypeProvider
        for={isCommercialColumn}
        formatterComponent={IsCommercialColumnRenderer}
      />
      <IntegratedFiltering />
      <IntegratedSorting />
      <Table />
      <TableColumnResizing
        columnWidths={columnWidths}
        onColumnWidthsChange={setColumnWidths}
      />
      <TableHeaderRow showSortingControls />
      <Toolbar />
      <SearchPanel />
    </DevExpressGrid>
  );
};

export default AdminProductsGrid;
