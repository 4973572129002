export const darkTheme = {
  palette: {
    type: "dark",
    primary: {
      //main: "#75a2f5", //blue
      main: "#ff9e4a", //orange
      //main: "#f8f661",  //yellow
    },
    secondary: {
      main: "#e6e6e6",
    },
    background: {
      default: "#343a40",
    },
  },
  typography: {
    h1: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
      color: "white",
    },
    h2: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.2,
      color: "whitesmoke", // "#ff9e4a",
    },
    h3: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
      color: "#ff9e4a",
    },
    h4: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
      color: "white",
    },
    h5: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
      color: "white",
    },
    h6: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 200,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
      color: "whitesmoke",
    },
  },
};
