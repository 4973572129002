import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  myAccountTitle: string;
  myAccountSubtitle: string;
  personalInfo: string;
  loginInfo: string;
  changePassword: string;
  changeDetails: string;
  userName: string;
  displayName: string;
  companyName: string;
  privacyInfo: string;
  contactUsText: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    myAccountTitle: "My Account",
    myAccountSubtitle:
      "The following information is the personal details we have from you",
    personalInfo: "Personal Info",
    loginInfo: "Login Info",
    changePassword: "Change Password",
    changeDetails: "Change Details...",
    userName: "Username:",
    displayName: "Name:",
    companyName: "Company:",
    privacyInfo:
      "Your information is private and we do not sell or share it with any third party.  We hold your details for tech support and offer you any updates we publish in the future.  For more details you can refer to the Privacy Policy",
    contactUsText:
      "Please contact us if the information is outdated or you want to transfer this account to another user",
  },

  es: {
    myAccountTitle: "Mi Cuenta",
    myAccountSubtitle:
      "La siguiente información son los datos personales que tenemos de su cuenta",
    personalInfo: "Información Personal",
    loginInfo: "Inicio Sesión",
    changePassword: "Cambiar Contraseña",
    changeDetails: "Modificar Datos...",
    userName: "Nombre Usuario:",
    displayName: "Nombre:",
    companyName: "Empresa:",
    privacyInfo:
      "Su información es privada y no vendemos ni compartimos ésta con ningun tercero. Almacenamos estos datos con propositos de soporte tecnico y ofrecer cualquier actualización que este disponible en un futuro.  Para mas detalles vea nuestro Aviso de Privacidad",
    contactUsText:
      "Por favor pongase en contacto si la inforamación ha cambiado o desea transferir esta cuenta a un nuevo usuario",
  },
});
