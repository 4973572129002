/* eslint-disable */
import { setupAxios, processApiRequest } from "../apiHelper";

//SETUP
const baseUrl = process.env.B2C_API_URL;
const baseApi = setupAxios(baseUrl!);

export interface IBlobInfo {
  location: string;
  name: string;
  exists: boolean;
  contentType?: string;
  size?: number;
  date?: Date;
}

export const getFileInfo = async (
  request: string,
  onSuccess: (result: IBlobInfo) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "files/" + btoa(request);
  await processApiRequest(baseApi.get<IBlobInfo[]>(url), {
    onSuccess: (response) => onSuccess(response.data),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};
