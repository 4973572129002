import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getMyProducts,
  IProductAndName,
} from "../../api/Account/myProductsApi";
import { ISession, ILoggedUser } from "../../types/Auth";
//import { ProductInfo } from "@softion/b2c-service";
import { RootState } from "../../redux/reducers/rootReducer";
import {
  Typography,
  Container,
  Chip,
  Grid,
  Box,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { getWikiListing } from "../../api/wikiApi";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IResources, { resources } from "./.resources";
import { toast, ToastContainer } from "react-toastify";
import { IUserV0, getUsersList } from "../../api/Account/usersApi";
import { useHistory } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import { ThemeOptions } from "../../types/Enums";
import MyDocumentsGrid, { IMyDocumentRow } from "./MyDocumentsGrid";

export interface IMyDocumentsPageProps {
  themeName: ThemeOptions;
}

const MyDocumentsPage: React.FC<IMyDocumentsPageProps> = ({ themeName }) => {
  const history = useHistory();

  const session: ISession = useSelector((state: RootState) => {
    return state.auth;
  }); //REDUX

  const loggedUser: ILoggedUser = useSelector((state: RootState) => {
    return state.userInfo;
  });

  const res: IResources = resources;
  const language: string = useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [products, setProducts] = useState<IProductAndName[]>([]);
  const [manuals, setManuals] = useState<IMyDocumentRow[]>([]);
  const [impersonatedUser, setImpersonatedUser] = useState<
    string | null | undefined
  >(null);
  const [users, setUsers] = useState<IUserV0[]>([]);

  const showMessage = (msg: string | null | undefined) => {
    if (msg) {
      toast.info(msg);
    }
  };
  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const goToPage = (productId: string | undefined) => {
    if (productId) {
      history.push(`/manuals/${productId}`);
    }
  };

  const refreshUsersAsync = async () => {
    if (loggedUser.isAdmin) {
      await getUsersList(
        (response) => {
          const orderedUsers: IUserV0[] = response.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
          setUsers([...orderedUsers]);
        },
        (err) => showError(err)
      );
    }
  };

  const getWiki = async (products: IProductAndName[]) => {
    let scopes: string[] = ["Public"];
    if (loggedUser.isAdmin) scopes = [...scopes, "Admin"];
    else if (loggedUser.isDistributor) scopes = [...scopes, "Distributor"];
    else if (loggedUser.isSupport) scopes = [...scopes, "Support"];
    else if (loggedUser.isCustomer) scopes = [...scopes, "Customer"];
    await getWikiListing(
      products.map((x) => x.productId || ""),
      scopes,
      "Manuals",
      language,
      (wikis) => {
        setManuals(
          wikis.map((x) => ({
            productId: x.productId,
            productName:
              products.find((p) => p.productId === x.productId)?.productName ||
              "",
            documentName: x.displayName || "",
            onOpen: (p) => goToPage(p || ""),
          }))
        );
        setIsLoading(false);
      },
      (error) => {
        showError(error);
        setIsLoading(false);
      }
    );
  };

  const getProductsAsync = async (user: string | undefined) => {
    const userId = user || session?.objectId;
    if (userId) {
      setIsLoading(true);
      setImpersonatedUser(null);
      await getMyProducts(
        userId,
        undefined,
        (response) => {
          const myProducts: IProductAndName[] = response.products.map((x) => ({
            productId: x.productId || "",
            productName: x.productName,
          }));
          setProducts(myProducts);
          if (userId !== null) {
            setImpersonatedUser(response.userName);
          }
          getWiki(myProducts);
        },
        (error) => {
          showError(error);
          setProducts([]);
          setManuals([]);
          setIsLoading(false);
        }
      );
    } else {
      setProducts([]);
      setManuals([]);
    }
  };

  useEffect(() => {
    resources.setLanguage(language);
  }, []);

  useEffect(() => {
    if (loggedUser?.userId) {
      if (loggedUser.isAdmin) {
        refreshUsersAsync();
      }
      getProductsAsync(loggedUser.userId);
    }
  }, [loggedUser]);

  const [viewAsAccount, setViewAsAccount] = useState<string | undefined>();

  const themedStyles = makeStyles((theme: Theme) =>
    createStyles({
      input: {
        marginLeft: theme.spacing(1),
        width: 320,
        flex: 1,
      },
      chip: {
        margin: "10px 0px 10px 0px",
      },
      topContainer: { paddingTop: "24px" },

      gridContainer: {
        padding: "32px",
        borderRadius: "26px",
      },

      [theme.breakpoints.down("sm")]: {
        gridContainer: {
          padding: "16px",
          borderRadius: "8px",
        },
      },
      searchButton: {
        padding: "2px",
        margin: "0px",
        width: "32px",
        height: "32px",
      },
      clearButton: {
        padding: "0px",
        margin: "0px",
      },
      breadCrums: {
        padding: "0 0 12px 0",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      titleIcon: {
        width: "48px",
        height: "48px",
        margin: "2px 12px 2px 2px",
      },
    })
  );
  const styles = themedStyles();

  return session.loggedIn ? (
    <Container className={styles.topContainer}>
      <div>
        <Grid container justify="space-between" alignItems="flex-end">
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h4">{res.documentation}</Typography>
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={4}>
            {loggedUser.isAdmin && impersonatedUser ? (
              <Grid item>
                <Chip
                  label={impersonatedUser}
                  icon={<PersonIcon />}
                  color="primary"
                  className={styles.chip}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Backdrop className={styles.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <ToastContainer />

        {!isLoading && (
          <Box className={styles.gridContainer}>
            <MyDocumentsGrid documents={manuals} resources={res} />
          </Box>
        )}
      </div>
    </Container>
  ) : (
    <div>Your session has expired</div>
  );
};

export default MyDocumentsPage;
