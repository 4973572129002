import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  titleCustomer: string;
  titleSupport: string;
  titleDistributor: string;
  titleAdmin: string;
  welcomeTextCustomer: string;
  introCustomer: string;
  viewAs: string;
  fileDetails: string;
  fileSize: string;
  fileReleaseNotes: string;
  fileDate: string;
  fileShare: string;
  fileDownload: string;
  fileVersion: string;
  orderDate: string;
  orderLatestVersion: string;
  copyToClipboard: string;
  shareByEmail: string;
  closeDialog: string;
  myOrders: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    titleCustomer: "My Downloads",
    titleSupport: "My Downloads",
    titleDistributor: "My Downloads",
    titleAdmin: "Downloads",
    welcomeTextCustomer: "Thank you for your purchase",
    introCustomer:
      "The following are products acquired in one or more of your orders, you can also find training and troubleshooting videos.  Come back later to find updated versions of your products when available.  If the file you are looking for is not listed please contact us at support@softion.com",
    viewAs: "View As...",
    fileDetails: "Details",
    fileSize: "Size",
    fileReleaseNotes: "Release Notes",
    fileDate: "Date",
    fileShare: "Share",
    fileDownload: "Download",
    fileVersion: "Version",
    orderDate: "Order Date",
    orderLatestVersion: "Latest Version",
    copyToClipboard: "Copy to clipboard",
    shareByEmail: "Email",
    closeDialog: "Close",
    myOrders: "Orders",
  },

  es: {
    titleCustomer: "Mis Descargas",
    titleSupport: "Mis Descargas",
    titleDistributor: "Mis Descargas",
    titleAdmin: "Visor de descargas",
    welcomeTextCustomer: "Gracias por adquirir nuestros productos",
    introCustomer:
      "Estos son los productos que ha adquirido en una o varias ordenes, también podrá encontrar videos de tutoriales y resolución de problemas en algunos casos.  Regrese pronto para ver si hay nuevas versiones de sus productos.  Si el archivo que busca no se encuentra, por favor mandenos un correo a support@softion.com",
    viewAs: "Mostrar como...",
    fileDetails: "Detalles",
    fileSize: "Tamaño",
    fileReleaseNotes: "Notas de Revisión",
    fileDate: "Fecha",
    fileShare: "Compartir",
    fileDownload: "Descargar",
    fileVersion: "Version",
    orderDate: "Fecha Orden",
    orderLatestVersion: "Ultima Version",
    copyToClipboard: "Copiar al portapapeles",
    shareByEmail: "Correo electronico",
    closeDialog: "Cerrar",
    myOrders: "Ordenes",
  },
});
