import * as types from "../actions/constants";
import { IKeepAlive } from "../../types/Auth";

const cookieStorage = localStorage;

const initialState: IKeepAlive = {
  b2cApi: undefined,
  projApi: undefined,
  regApi: undefined,
};

const COOKIE_B2C = "healthcheck-b2c";
const COOKIE_PROJ = "healthcheck-prj";
const COOKIE_REG = "healthcheck-reg";

const keepAliveReducer = (state: IKeepAlive = initialState, action) => {
  if (!state.b2cApi) {
    const lastB2CUpdate = new Date(
      Number(cookieStorage.getItem(COOKIE_B2C) || "0")
    );
    state = { ...state, b2cApi: lastB2CUpdate };
  }
  if (!state.projApi) {
    const lastProjUpdate = new Date(
      Number(cookieStorage.getItem(COOKIE_PROJ) || "0")
    );
    state = { ...state, projApi: lastProjUpdate };
  }
  if (!state.regApi) {
    const lastRegUpdate = new Date(
      Number(cookieStorage.getItem(COOKIE_REG) || "0")
    );
    state = { ...state, regApi: lastRegUpdate };
  }
  const now = new Date();
  switch (action.type) {
    case types.HEALTH_B2CAPI:
      cookieStorage.setItem(COOKIE_B2C, `${now.valueOf()}`);
      console.log(`b2c-apiu@${now.toTimeString()}`);
      return { ...state, b2cApi: now };
    case types.HEALTH_PROJAPI:
      cookieStorage.setItem(COOKIE_PROJ, `${now.valueOf()}`);
      console.log(`prj-apiu@${now.toTimeString()}`);
      return { ...state, projApi: now };
    case types.HEALTH_REGAPI:
      cookieStorage.setItem(COOKIE_REG, `${now.valueOf()}`);
      console.log(`reg-apiu@${now.toTimeString()}`);
      return { ...state, regApi: now };
    default:
      return state;
  }
};

export default keepAliveReducer;
