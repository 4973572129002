import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers/rootReducer";
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";

const client = axios.create({
  //all axios can be used, shown in axios documentation
  baseURL: process.env.B2C_API_URL,
  responseType: "json",
});

const configureStore = (initialState) => {
  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(axiosMiddleware(client))
  );
};

export default configureStore;
