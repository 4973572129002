/* eslint-disable */
import { setupAxios, processApiRequest } from "../apiHelper";
import { rememberProjApi } from "../testApi";

//SETUP
const baseUrl = process.env.PROJECTS_API;
const baseApi = setupAxios(baseUrl!);

export interface IProduct {
  productId: string;
  productName: string;
  productDescription?: string;
  isCommercial: boolean;
  lastUpdate?: Date;
}

export interface IProductRequest {
  productId: string;
  productName: string;
  productDescription?: string;
  isCommercial: boolean;
}

export const getProducts = async (
  onSuccess: (result: IProduct[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "products";
  await processApiRequest(baseApi.get<IProduct[]>(url), {
    onSuccess: (response) => {
      rememberProjApi();
      onSuccess(response.data);
    },
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

//######
export const getProduct = async (
  productId: string,
  onSuccess: (result: IProduct) => void,
  errorMessage: (msg: string) => void
) => {
  const url = `products/${productId}`;
  await processApiRequest(baseApi.get<IProduct>(url), {
    onSuccess: (response) => {
      rememberProjApi();
      onSuccess(response.data);
    },
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const addProduct = async (
  request: IProductRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "products";
  await processApiRequest(baseApi.post<IProduct>(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage("Not found"),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const updateProduct = async (
  request: IProductRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "products";
  await processApiRequest(baseApi.put<IProduct>(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage("Not found"),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const deleteProduct = async (
  productId: string,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "products/" + productId;
  await processApiRequest(baseApi.delete(url), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage("Not found"),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const addProductStation = async (
  productId: string,
  stationName: string,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `products/${productId}/stations/${stationName}`;
  await processApiRequest(baseApi.post(url), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const deleteProductStation = async (
  productId: string,
  stationName: string,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `products/${productId}/stations/${stationName}`;
  await processApiRequest(baseApi.delete(url), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};
