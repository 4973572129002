/* eslint-disable */
import { setupAxios, processApiRequest, IResponseHandler } from "./apiHelper";
import * as types from "../redux/actions/constants";
//SETUP
const baseUrl = process.env.B2C_API_URL;
const baseApi = setupAxios(baseUrl!);

export const rememberB2CApi = () => {
  import("../redux/Store").then((store) => {
    store.default.dispatch({
      type: types.HEALTH_B2CAPI,
    });
  });
};
export const rememberProjApi = () => {
  import("../redux/Store").then((store) => {
    store.default.dispatch({
      type: types.HEALTH_PROJAPI,
    });
  });
};
export const rememberRegApi = () => {
  import("../redux/Store").then((store) => {
    store.default.dispatch({
      type: types.HEALTH_REGAPI,
    });
  });
};

export const b2cHealthCheck = async (
  endpoint: string,
  onSuccess: any,
  onClientError: any
) => {
  //const request = baseApi.get<string>("healthcheck");
  const request = setupAxios(process.env.B2C_API_URL || "").get<string>(
    endpoint
  );
  const result = await processApiRequest(request, {
    onSuccess: (response) => {
      rememberB2CApi();
      onSuccess(
        response.data ? JSON.stringify(response.data, null, 2) : "200 - OK"
      );
    },
    notFound: (response) => onClientError("404 - Not Found"),
    forbidden: (response) => onClientError("403 - Forbidden"),
    serverError: (response) => onClientError("500 - Server Error"),
    errorTitle: "Error running healthcheck endpoint",
  });
};

export const projHealthCheck = async (
  endpoint: string,
  onSuccess: any,
  onClientError: any
) => {
  //const request = baseApi.get<string>("healthcheck");
  const request = setupAxios(process.env.PROJECTS_API || "").get<string>(
    endpoint
  );
  const result = await processApiRequest(request, {
    onSuccess: (response) => {
      rememberProjApi();
      onSuccess(
        response.data ? JSON.stringify(response.data, null, 2) : "200 - OK"
      );
    },
    notFound: (response) => onClientError("404 - Not Found"),
    forbidden: (response) => onClientError("403 - Forbidden"),
    serverError: (response) => onClientError("500 - Server Error"),
    errorTitle: "Error running healthcheck endpoint",
  });
};

export const registrationHealthCheck = async (
  endpoint: string,
  onSuccess: any,
  onClientError: any
) => {
  const request = setupAxios(
    process.env.REGISTRATION_API_URL || ""
  ).get<string>(endpoint);
  const result = await processApiRequest(request, {
    onSuccess: (response) => {
      rememberRegApi();
      onSuccess(
        response.data ? JSON.stringify(response.data, null, 2) : "200 - OK"
      );
    },
    notFound: (response) => onClientError("404 - Not Found"),
    forbidden: (response) => onClientError("403 - Forbidden"),
    serverError: (response) => onClientError("500 - Server Error"),
    errorTitle: "Error running healthcheck endpoint",
  });
};

export const clearB2CApiCache = async (
  onSuccess: () => void,
  onClientError: (msg: string) => void
) => {
  const url = "/healthcheck/clear-cache";
  const request = setupAxios(process.env.B2C_API_URL!).get(url);
  const result = await processApiRequest(request, {
    onSuccess: (response) => onSuccess(),
    notFound: (response) => onClientError("404 - Not Found"),
    forbidden: (response) => onClientError("403 - Forbidden"),
    serverError: (response) => onClientError("500 - Server Error"),
    errorTitle: "Error running clear-cache endpoint",
  });
};

export const clearProjApiCache = async (
  onSuccess: () => void,
  onClientError: (msg: string) => void
) => {
  const url = "/healthcheck/clear-cache";
  const request = setupAxios(process.env.PROJECTS_API!).get(url);
  const result = await processApiRequest(request, {
    onSuccess: (response) => onSuccess(),
    notFound: (response) => onClientError("404 - Not Found"),
    forbidden: (response) => onClientError("403 - Forbidden"),
    serverError: (response) => onClientError("500 - Server Error"),
    errorTitle: "Error running clear-cache endpoint",
  });
};

export const clearRegApiCache = async (
  onSuccess: () => void,
  onClientError: (msg: string) => void
) => {
  const url = "/healthcheck/clear-cache";
  const request = setupAxios(process.env.REGISTRATION_API_URL!).get(url);
  const result = await processApiRequest(request, {
    onSuccess: (response) => onSuccess(),
    notFound: (response) => onClientError("404 - Not Found"),
    forbidden: (response) => onClientError("403 - Forbidden"),
    serverError: (response) => onClientError("500 - Server Error"),
    errorTitle: "Error running clear-cache endpoint",
  });
};
