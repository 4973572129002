/* eslint-disable */
import { setupAxios, processApiRequest } from "../apiHelper";
import { multipleOrdersWithBranches } from "./data/myLicensesApi.data";
import { IUserV0 } from "./usersApi";
import { rememberRegApi } from "../testApi";

export interface ILicense {
  licenseId: number;
  programId: string;
  date: Date;
  requestCode: string;
  licensedTo: string;
  licenseKey: string;
  licenseType: string;
  location?: string;
  installationType?: string;
  status: number;
  lastUpdate?: Date;
  station: number;
  parentId?: number;
  productId: string;
  branchName: string;
  userId: string;
  requestorId?: string;
  isActive: boolean;
}
export interface IUserHistoryLicense {
  licenseId: number;
  programId: string;
  date: Date;
  requestCode: string;
  licensedTo: string;
  licenseKey: string;
  licenseType: string;
  location?: string;
  installationType?: string;
  status: number;
  lastUpdate?: Date;
  station: number;
  stationName?: string;
  parentId?: number;
  productId: string;
  branchName?: string;
  userId: string;
  userName?: string;
  requestorId?: string;
  requestorName?: string;
  isActive: boolean;
  isExternal: boolean;
}

export interface ILicense2 {
  licenseId: number;
  programId: string;
  programName: string;
  date: Date;
  requestCode: string;
  licensedTo: string;
  licenseKey: string;
  licenseType: string;
  location?: string;
  installationType?: string;
  status: number;
  lastUpdate?: Date;
  station: number;
  stationName?: string;
  parentId?: number;
  productId: string;
  productName: string;
  branchName?: string;
  userId: string;
  requestorId?: string;
  requestorName?: string;
  isActive: boolean;
  isOrphan: boolean;
  isExternal: boolean;
}

export interface IUserProgramAndLicenseItem {
  branch?: string;
  stationId: number;
  maxLicenses: number;
  licenses: IUserHistoryLicense[];
}

export interface IUserProgramsAndLicenses {
  locations: IUserProgramAndLicenseItem[];
  userId: string;
  programId: string;
  programName: string;
  productId: string;
  productName: string;
  branches: string[];
  stations: number;
  stationNames: string[];
  isCanceled: boolean;
  maxLicenses: number;
  scopes: string[];
  requestorScopes: string[];
  orphanLicenses: ILicense[];
}

export interface IOfflineLicenseInstallRequest {
  productId: string;
  branchName: string;
  station: number;
  requestCode: string;
  email?: string;
  userId: string;
  licenseType?: string;
  requestorId?: string;
  language?: string;
  notes?: string;
}

export interface IOfflineLicenseResult {
  productId: string;
  requestCode: string;
  licensedTo: string;
  licenseKey: string;
  licenseType: string;
}

export interface IOfflineLicenseUninstallRequest {
  licenseKey: string;
  productId: string;
  language: string;
}

export interface IMyLicensesResponse {
  userProgramsAndLicenses: IUserProgramsAndLicenses[];
  users: IUserV0[];
}

//SETUP
const baseUrl = process.env.REGISTRATION_API_URL;
const baseApi = setupAxios(baseUrl!);

export const getLicensesForUser = async (
  userId: string,
  onSuccess: (result: IMyLicensesResponse) => void,
  errorMessage: (msg: string) => void
) => {
  //onSuccess(multipleOrdersWithBranches);
  const url = `licenseHistory/${userId}`;
  await processApiRequest(baseApi.get<IMyLicensesResponse>(url), {
    onSuccess: (response) => {
      rememberRegApi();
      onSuccess(response.data);
    },
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const getLicensesForProject = async (
  userId: string,
  productId: string,
  onSuccess: (result: IMyLicensesResponse) => void,
  errorMessage: (msg: string) => void
) => {
  //onSuccess(multipleOrdersWithBranches);
  const url = `licenseHistory/products/${productId}?userId=${userId}`;
  await processApiRequest(baseApi.get<IMyLicensesResponse>(url), {
    onSuccess: (response) => {
      rememberRegApi();
      onSuccess(response.data);
    },
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const activateLicenseOffline = async (
  request: IOfflineLicenseInstallRequest,
  onSuccess: (result: IOfflineLicenseResult) => void,
  errorMessage: (msg: string) => void
) => {
  //onSuccess(multipleOrdersWithBranches);
  const url = "license/offline";
  await processApiRequest(baseApi.post<IOfflineLicenseResult>(url, request), {
    onSuccess: (response) => onSuccess(response.data),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const uninstallLicenseOffline = async (
  request: IOfflineLicenseUninstallRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  //onSuccess(multipleOrdersWithBranches);
  const url = "license/offline";
  await processApiRequest(
    baseApi.put<IOfflineLicenseUninstallRequest>(url, request),
    {
      onSuccess: () => onSuccess(),
      badRequest: (msg) => errorMessage(msg),
      notFound: (msg) => errorMessage(msg),
      forbidden: (msg) =>
        errorMessage("You are not authorized to permform this action"),
    }
  );
};
