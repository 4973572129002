import * as types from "../actions/constants";
import { ILoggedUser, noLoggedUser } from "../../types/Auth";

const initialState: ILoggedUser = noLoggedUser();

const userInfoReducer = (state: ILoggedUser = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_MEMBERSHIP:
    case types.GET_USER_MEMBERSHIP_SUCCESS:
    case types.GET_USER_MEMBERSHIP_FAIL:
      return { ...action.data };
    case types.LOGOUT_SUCCESS:
      return noLoggedUser();
    default:
      return state;
  }
};

export default userInfoReducer;
