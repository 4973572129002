import React, { useState, useEffect } from "react";
import AdminProductsGrid from "./AdminProductsGrid";
import {
  getProducts,
  addProduct,
  updateProduct,
  deleteProduct,
  IProduct,
  IProductRequest,
} from "../../../api/Admin/dbProductsApi";
import {
  IProductFile,
  IFileUserScope,
  addProductFile,
  getProductFiles,
  updateProductFile,
  deleteProductFile,
  getUsersForFile,
} from "../../../api/Admin/dbProductFilesApi";
import { getFileInfo } from "../../../api/Admin/filesApi";
import AdminProductDialog from "./dialogs/AdminProductDialog";
import AdminProductFileDialog, {
  IBlobInfoRequest,
} from "./dialogs/AdminProductFileDialog";
import AdminProductFileUsersDialog, {
  IFileUserScopePivot,
} from "./dialogs/AdminProductFileUsersDialog";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import { ToastContainer, toast } from "react-toastify";
import AdminProductCard from "./AdminProductCard";
import AdminProductFilesCard from "./AdminProductFilesCard";

interface IProductWithFiles {
  product: IProduct;
  files: IProductFile[] | undefined;
}

interface IProductFileRequest {
  productUrl: IProductFile;
  onSuccess: () => void;
}

const AdminProductsPage: React.FC = () => {
  const [products, setProducts] = useState<IProductWithFiles[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCard, setShowCard] = useState<boolean>(false);
  const [selection, setSelection] = useState<IProduct | undefined>(undefined);
  const [selectionFiles, setSelectionFiles] = useState<
    IProductFile[] | undefined
  >([]);
  const [showNewProduct, setShowNewProduct] = useState<boolean>(false);
  const [showFileDialog, setShowFileDialog] = useState<boolean>(false);

  const refreshAsync = async () => {
    setIsLoading(true);
    setSelection(undefined);
    setSelectionFiles(undefined);
    await getProducts(
      (result) => {
        const productWithFiles: IProductWithFiles[] = result.map((x) => ({
          product: x,
          files: undefined,
        }));
        setProducts(productWithFiles);
        setIsLoading(false);
      },
      (error) => {
        alert(error);
        setIsLoading(false);
      }
    );
  };

  const [dialogProduct, setDialogProduct] = useState<IProduct | undefined>(
    undefined
  );

  const [dialogFile, setDialogFile] = useState<IProductFile | undefined>(
    undefined
  );

  const showMessage = (msg: string | null | undefined) => {
    if (msg) {
      toast.info(msg);
    }
  };
  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    refreshAsync();
  }, []);

  const changeSelection = async (product: IProduct | undefined) => {
    setSelection(product);
    setShowCard(product !== undefined);
    if (product) {
      const productWithFiles = products.find(
        (p) => p.product.productId === product?.productId
      );
      if (productWithFiles) {
        if (productWithFiles.files) {
          setSelectionFiles(productWithFiles.files);
        } else {
          setSelectionFiles(undefined);
          await getProductFiles(
            product.productId,
            (result) => {
              productWithFiles.files = [...result];
              setSelectionFiles(productWithFiles.files);
            },
            (err) => showError(err)
          );
        }
      }
    }
  };

  // PRODUCTS  ***************************

  const onAddProduct = async (request: IProductRequest) => {
    await addProduct(
      request,
      () => {
        setShowNewProduct(false);
        toast.success("Product added");
        refreshAsync();
      },
      (err) => {
        showError(err);
      }
    );
  };

  const onUpdateProduct = async (request: IProductRequest) => {
    await updateProduct(
      request,
      () => {
        setShowNewProduct(false);
        toast.success("Product updated");
        refreshAsync();
      },
      (err) => {
        showError(err);
      }
    );
  };

  const onDeleteProduct = async () => {
    if (selection) {
      await deleteProduct(
        selection.productId,
        () => {
          setShowCard(false);
          toast.success("Product deleted");
          refreshAsync();
        },
        (err) => {
          showError(err);
        }
      );
    }
  };

  const toggleNewProductDialog = (
    product: IProduct | undefined,
    open: boolean
  ) => {
    setDialogProduct(product);
    setShowNewProduct(open);
  };

  const openNewProductDialog = () => {
    toggleNewProductDialog(
      {
        productId: "",
        productName: "",
        isCommercial: false,
        productDescription: "",
        lastUpdate: new Date(),
      },
      true
    );
  };

  const addOrUpdateProduct = async (
    request: IProductRequest,
    isNewProduct: boolean
  ) => {
    if (isNewProduct) {
      await onAddProduct(request);
    } else {
      await onUpdateProduct(request);
    }
  };

  //  END PRODUCTS ********************

  //  FILES ***************************
  const onRefreshFiles = async () => {
    const prod = products.find(
      (p) => p.product.productId === selection?.productId
    );
    if (prod) {
      prod.files = undefined;
      changeSelection(prod.product);
    }
  };

  const onAddFile = async (request: IProductFileRequest) => {
    await addProductFile(
      request.productUrl,
      () => {
        onRefreshFiles();
        request.onSuccess();
      },
      (err) => {
        showError(err);
      }
    );
  };

  const onUpdateFile = async (request: IProductFileRequest) => {
    await updateProductFile(
      request.productUrl,
      () => {
        onRefreshFiles();
        request.onSuccess();
      },
      (err) => {
        showError(err);
      }
    );
  };

  const onDeleteFile = async (request: IProductFileRequest) => {
    await deleteProductFile(
      request.productUrl,
      () => {
        onRefreshFiles();
        request.onSuccess();
      },
      (err) => {
        showError(err);
      }
    );
  };

  const onRequestFileInfo = async (request: IBlobInfoRequest) => {
    await getFileInfo(
      request.fileUrl,
      (result) => request.onSuccess(result),
      (err) => showError(err)
    );
  };

  const [showFileScopesDialog, setShowFileScopesDialog] = useState<boolean>(
    false
  );
  const [dialogFileScopes, setDialogFileScopes] = useState<
    IFileUserScopePivot[]
  >([]);
  const [dialogFileScopesFile, setDialogFileScopesFile] = useState<
    IProductFile | undefined
  >();

  const usersPivot = (program: IProductFile, scopes: IFileUserScope[]) => {
    const list: IFileUserScopePivot[] = [];
    scopes.forEach((scope) => {
      let userOrder: IFileUserScopePivot | undefined = list.find(
        (x) =>
          x.userName === scope.userName &&
          x.order === scope.orderId &&
          x.program === scope.programName
      );
      if (!userOrder) {
        userOrder = {
          userName: scope.userName,
          order: scope.orderId,
          program: scope.programName,
          download: false,
          activateSupport: false,
          activateFull: false,
        };
        list.push(userOrder);
      }
      userOrder.download = userOrder.download || scope.scopeId === "Download";
      userOrder.activateSupport =
        userOrder.activateSupport || scope.scopeId === "SupportLicense";
      userOrder.activateFull =
        userOrder.activateFull || scope.scopeId === "FullLicense";
    });
    setDialogFileScopes(list);
    setDialogFileScopesFile(program);
  };

  const onGetFileUsers = async (request: IProductFile) => {
    setShowFileScopesDialog(false);
    await getUsersForFile(
      request.productId,
      request.productFileId,
      (result: IFileUserScope[]) => {
        if (result.length === 0) {
          showMessage("No users assigned to this product");
        } else {
          usersPivot(request, result);
          setShowFileScopesDialog(true);
        }
      },
      (err) => {
        showError(err);
      }
    );
  };

  const closeFileScopeDialog = () => {
    setDialogFileScopes([]);
    setDialogFileScopesFile(undefined);
    setShowFileScopesDialog(false);
  };

  const openNewFileDialog = () => {
    setDialogFile(undefined);
    setShowFileDialog(true);
  };

  const openEditFileDialog = (file: IProductFile) => {
    setDialogFile({ ...file });
    setShowFileDialog(true);
  };

  const addOrUpdateFile = (file: IProductFile) => {
    const fileRequest: IProductFileRequest = {
      productUrl: file,
      onSuccess: () => setShowFileDialog(false),
    };
    if (file.productFileId == 0) {
      onAddFile(fileRequest);
    } else {
      onUpdateFile(fileRequest);
    }
  };

  const deleteFile = () => {
    const fileRequest: IProductFileRequest = {
      productUrl: dialogFile!,
      onSuccess: () => setShowFileDialog(false),
    };
    onDeleteFile(fileRequest);
  };

  //  END FILES ***************************

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        padding: "0px 6px 0px 32px",
      },
      newProductButton: {
        width: "148px",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      toolbar: {
        margin: "12px 0px 6px 0px",
      },
      grid: {
        padding: "0px 32px 0px 32px",
        height: "100%",
      },
    })
  );
  const styles = useStyles();

  const ProductsToolbar = () => {
    return (
      <Grid
        item
        container
        className={styles.toolbar}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            className={styles.newProductButton}
            onClick={() => openNewProductDialog()}
          >
            New Product
          </Button>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={4}
          xs={12}
          sm={9}
        >
          {/*  ADD HERE ANY FILTERS OR ACTIONS ON THE GRID  */}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container className={styles.header} direction="column">
        <Typography variant="h2">Products</Typography>
        <Typography variant="body1">
          Registered products and linked downloads
        </Typography>
        <ProductsToolbar />
      </Grid>

      <Backdrop className={styles.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ToastContainer />
      {isLoading === false && (
        <>
          <Grid container>
            <Grid item xs={showCard ? 8 : 12} className={styles.grid}>
              {products && products.length > 0 && (
                <AdminProductsGrid
                  products={products.map((p) => p.product)}
                  selectionChanged={(row) => changeSelection(row)}
                />
              )}
            </Grid>
            {showCard ? (
              <Grid item xs={4}>
                <Grid container direction="column">
                  <Grid item>
                    <AdminProductCard
                      selection={selection}
                      closeCard={() => setShowCard(false)}
                      displayInfoMessage={(msg) => showMessage(msg)}
                      handleEdit={() => toggleNewProductDialog(selection, true)}
                      handleDelete={() => onDeleteProduct()}
                    />
                  </Grid>
                  <Grid item>
                    <AdminProductFilesCard
                      files={selectionFiles}
                      showUsers={onGetFileUsers}
                      onAddFile={openNewFileDialog}
                      onUpdateFile={openEditFileDialog}
                      onRefreshFiles={onRefreshFiles}
                      closeCard={() => setShowCard(false)}
                      displayInfoMessage={(msg) => toast.info(msg)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </>
      )}
      {dialogProduct && (
        <AdminProductDialog
          row={dialogProduct}
          open={showNewProduct}
          handleSubmit={addOrUpdateProduct}
          handleCancel={() => setShowNewProduct(false)}
        />
      )}
      {selection && (
        <AdminProductFileDialog
          file={dialogFile}
          productId={selection.productId}
          productName={selection.productName}
          open={showFileDialog}
          handleFileInfo={onRequestFileInfo}
          handleDelete={deleteFile}
          handleSubmit={addOrUpdateFile}
          handleCancel={() => setShowFileDialog(false)}
        />
      )}
      {dialogFileScopesFile && dialogFileScopes && (
        <AdminProductFileUsersDialog
          open={showFileScopesDialog}
          scopes={dialogFileScopes}
          file={dialogFileScopesFile}
          handleCancel={() => closeFileScopeDialog()}
        />
      )}
    </>
  );
};

export default AdminProductsPage;
