import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import IResources, { resources } from "./.resources";
import { Grid, Typography, Link, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BWIcon from "../../images/iconBW64x64.svg";
import { useHistory } from "react-router-dom";
import * as urls from "../../types/Constants";

const Footer: React.FC = () => {
  useSelector((state: RootState) => {
    return state.userInfo;
  });

  const history = useHistory();

  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const useStyles = makeStyles({
    contactUsItem: {
      padding: "6px 0px 6px 0px",
      color: "silver",
    },
    serviceItem: {
      padding: "6px 0px 6px 0px",
      color: "silver",
    },
    emailItem: {
      padding: "6px",
      color: "silver",
    },
    legalContainer: {
      backgroundColor: "black",
      padding: "6px 100px 6px 100px",
    },
    copyrightItem: {
      padding: "8px",
      textAlign: "center",
      color: "silver",
      width: "100%",
    },
    legalItem: {
      padding: "8px",
      textAlign: "center",
      minWidth: "200px",
      color: "silver",
    },
  });
  const styles = useStyles();

  //const markup=()=>
  function renderEmail() {
    return {
      __html:
        "contact" + decodeURIComponent(escape(window.atob("QHNvZnRpb24uY29t"))),
    };
  }

  const CompanyInfoComponent = () => {
    return (
      <Grid
        item
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item container direction="row">
          <img
            alt="logo"
            src={BWIcon}
            style={{ width: 32, height: 32, opacity: 0.8 }}
          />
          <div
            className={styles.emailItem}
            dangerouslySetInnerHTML={renderEmail()}
          ></div>
        </Grid>
        <Grid item>
          <Typography className={styles.contactUsItem} variant="body2">
            {res.companyId}
          </Typography>
          <Typography className={styles.contactUsItem} variant="body2">
            {res.companyAddress}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const ServicesComponent = () => {
    return (
      <Grid
        item
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Typography variant="body1">{res.frequentQuestions}</Typography>
        <Link href="#" onClick={() => history.push(urls.URL_FAQ_PURCHASING)}>
          <Typography variant="body2" className={styles.serviceItem}>
            <u>{res.faqPurchasing}</u>
          </Typography>
        </Link>
        <Typography variant="body2" className={styles.serviceItem}>
          {res.faqLicensing}
        </Typography>
        <Typography variant="body2" className={styles.serviceItem}>
          Contact Support
        </Typography>
      </Grid>
    );
  };

  const ContactNumbersComponent = () => {
    return (
      <Grid
        item
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Typography variant="body1">{res.contactNumbers}</Typography>
        <Typography className={styles.contactUsItem} variant="body2">
          {res.phoneUk}
        </Typography>
        <Typography
          className={styles.contactUsItem}
          variant="body2"
          gutterBottom={false}
        >
          {res.phoneMex}
        </Typography>
      </Grid>
    );
  };

  const TermsAndConditionsComponent = () => {
    return (
      <Link
        href="#"
        onClick={() => history.push(urls.URL_TERMS_AND_CONDITIONS)}
      >
        <Typography className={styles.legalItem} variant="caption">
          {res.termsAndConditions}
        </Typography>
      </Link>
    );
  };

  const PrivacyPolicyComponent = () => {
    return (
      <Link href="#" onClick={() => history.push(urls.URL_PRIVACY_POLICY)}>
        <Typography className={styles.legalItem} variant="caption">
          {res.privacyPolicy}
        </Typography>
      </Link>
    );
  };

  const CopyrightComponent = () => {
    return (
      <Typography className={styles.copyrightItem} variant="caption">
        Copyright &copy; {new Date().getFullYear()} - Softion Solutions Ltd
      </Typography>
    );
  };

  return (
    <Grid container style={{ height: "100%", width: "100%" }}>
      <Hidden smDown>
        <Grid
          item
          container
          direction="row"
          style={{
            color: "white",
            padding: "32px 100px 48px 100px",
            margin: "0px",
          }}
        >
          <Grid item container xs={4}>
            <CompanyInfoComponent />
          </Grid>
          <Grid item container xs={4}>
            <ServicesComponent />
          </Grid>
          <Grid item container xs={4}>
            <ContactNumbersComponent />
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid
          item
          container
          direction="column"
          style={{
            color: "white",
            padding: "32px",
            margin: "0px",
          }}
        >
          <Grid item>
            <CompanyInfoComponent />
          </Grid>
          <Grid item>
            <ContactNumbersComponent />
          </Grid>
        </Grid>
      </Hidden>

      <Grid
        className={styles.legalContainer}
        item
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Hidden smDown>
          <Grid container item xs={4} justify="center">
            <CopyrightComponent />
          </Grid>
          <Grid container item xs={4} justify="center">
            <TermsAndConditionsComponent />
          </Grid>
          <Grid container item xs={4} justify="center">
            <PrivacyPolicyComponent />
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid container item xs={12} justify="center">
            <TermsAndConditionsComponent />
          </Grid>
          <Grid container item xs={12} justify="center">
            <PrivacyPolicyComponent />
          </Grid>
          <Grid container item xs={12} justify="center">
            <CopyrightComponent />
          </Grid>
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default Footer;
