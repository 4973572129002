import React from "react";
import { Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from "@material-ui/icons/People";
import Grid from "@material-ui/core/Grid";
import { IProductFile } from "../../../api/Admin/dbProductFilesApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Chip from "@material-ui/core/Chip";
import GetAppIcon from "@material-ui/icons/GetApp";
import Divider from "@material-ui/core/Divider";

//https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/

export interface IAdminProductFilesCardProps {
  files: IProductFile[] | undefined;
  showUsers: (request: IProductFile) => void;
  onAddFile: () => void;
  onUpdateFile: (request: IProductFile) => void;
  onRefreshFiles: () => void;
  closeCard: () => void;
  displayInfoMessage: (text: string | null | undefined) => void;
}

const AdminProductFilesCard: React.FC<IAdminProductFilesCardProps> = ({
  files,
  showUsers,
  onAddFile,
  onUpdateFile,
  onRefreshFiles,
  displayInfoMessage,
}) => {
  const cardStyles = makeStyles((theme: Theme) =>
    createStyles({
      card: {
        margin: "32px",
      },
      copyButton: {
        margin: "0px 2px 0px 6px",
      },
      inline: {
        display: "inline",
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
      },
      fileButton: {
        width: "32px",
        height: "32px",
        margin: "2px 4px 2px 6px",
      },
      actionButton: {
        margin: "12px 12px 12px 0px",
      },
    })
  );
  const useCardStyles = cardStyles();
  const copyToClipboard = (text: string | null | undefined) => {
    if (text) {
      navigator.clipboard
        .writeText(text || "")
        .then(() => displayInfoMessage("Copied to clipboard: " + text));
    }
  };

  const [expanded, setExpanded] = React.useState<string | false | undefined>(
    undefined
  );

  const handleChange = (fileUrl: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? fileUrl : false);
  };

  const FileListItem = ({ file }) => {
    const fileName: string = file.displayName; // file.url.split("\\").pop().split("/").pop();
    return (
      <Accordion
        expanded={expanded === file.url}
        onChange={handleChange(file.url)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="file-content"
          id="fileName"
        >
          <Typography variant="subtitle2" className={useCardStyles.heading}>
            {fileName}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Divider />
            <Grid container direction="column">
              <Grid item>
                <Typography variant="body2">{file.description}</Typography>
              </Grid>
              <Grid item>
                <Grid
                  item
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    {file.version && <Chip label={file.version} />}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <IconButton
                      href={file.url}
                      target="_blank"
                      className={useCardStyles.fileButton}
                    >
                      <GetAppIcon></GetAppIcon>
                    </IconButton>
                    <IconButton
                      onClick={() => copyToClipboard(file.url)}
                      className={useCardStyles.fileButton}
                    >
                      <FileCopyIcon></FileCopyIcon>
                    </IconButton>
                    <IconButton
                      onClick={() => onUpdateFile(file)}
                      className={useCardStyles.fileButton}
                    >
                      <EditIcon></EditIcon>
                    </IconButton>

                    <IconButton
                      onClick={() => showUsers(file)}
                      className={useCardStyles.fileButton}
                    >
                      <PeopleIcon></PeopleIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <Card className={useCardStyles.card}>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          Files
        </Typography>
        {files ? (
          <>
            {files.map((x, index) => (
              <FileListItem key={index} file={x} />
            ))}
            <br />
            <Button
              className={useCardStyles.actionButton}
              variant="contained"
              color="primary"
              onClick={() => onAddFile()}
            >
              Add
            </Button>
            <Button
              variant="contained"
              className={useCardStyles.actionButton}
              onClick={() => onRefreshFiles()}
            >
              Refresh
            </Button>
          </>
        ) : (
          <CircularProgress color="inherit" />
        )}
      </CardContent>
    </Card>
  );
};

export default AdminProductFilesCard;
