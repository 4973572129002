import React from "react";
import { useLocation } from "react-router-dom";
import MailToLink from "./MailToLink";
import Moment from "react-moment";
import Typography from "@material-ui/core/Typography";

export interface IErrorPageProps {
  errorMessage?: string | null;
  errorTitle?: string | null;
  errorCode?: number | null;
}

const ErrorPage: React.FC = () => {
  const location = useLocation();
  const props: IErrorPageProps | null | undefined = location.state;

  const isAccessDenied = () => {
    return (
      props?.errorCode && (props?.errorCode === 401 || props?.errorCode === 403)
    );
  };

  return (
    <div className="error-page">
      {isAccessDenied() ? (
        <div>
          <h2>Unauthorized</h2>
          <h4>
            Please contact support to request access for your account &nbsp;
            <MailToLink emailAddress="support@softion.com" />
          </h4>
        </div>
      ) : (
        <div>
          <Typography variant="h5">A server error has occurred</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            If this error persists please contact support at &nbsp;
            <MailToLink emailAddress="support@softion.com" />
          </Typography>

          <br></br>
          <br></br>
          <Typography variant="h6">Error Details</Typography>
          <Typography variant="caption">
            <Moment>{new Date()}</Moment>
          </Typography>
          <Typography variant="subtitle1" color="error">
            {props?.errorTitle || "Unknown server error"}
          </Typography>
          {/* <span className="error-title">
            {props?.errorTitle || "Unknown server error"}
          </span> */}
          <br></br>
          <br></br>
          <Typography variant="subtitle1" color="textSecondary">
            {props?.errorMessage || "Error details not available"}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ErrorPage;

/*  USAGE:
    import { useHistory } from "react-router-dom";
    ...
    const history = useHistory();   //Note: outside function and inside a component only
    ...
    history.push({
      pathname: "/error",
      state: {
        errorMessage: "message",
        errorTitle: "title",
      },
    });

    //For access outside the component use browserHistory

*/
