import React, { useState } from "react";
import { IUserV1 } from "../../../api/Account/usersApi";
import Grid from "@material-ui/core/Grid";
import PersonIcon from "@material-ui/icons/Person";
import BusinessIcon from "@material-ui/icons/Business";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Checkbox from "@material-ui/core/Checkbox";
import PhoneIcon from "@material-ui/icons/Phone";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import {
  DataTypeProvider,
  TreeDataState,
  SortingState,
  SearchState,
  SelectionState,
  CustomTreeData,
  IntegratedFiltering,
  IntegratedSorting,
  TableColumnWidthInfo,
  FilteringState,
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
  SearchPanel,
  TableColumnResizing,
  Toolbar,
  TableColumnVisibility,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";

//https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/

export interface IUsersGridProps {
  users: IUserV1[];
  selectionChanged: (user: IUserV1 | undefined) => void;
}

declare type fontColor =
  | "inherit"
  | "disabled"
  | "action"
  | "primary"
  | "secondary"
  | "error"
  | undefined;

declare type textColor =
  | "initial"
  | "inherit"
  | "primary"
  | "secondary"
  | "textPrimary"
  | "textSecondary"
  | "error";

const UsersGrid: React.FC<IUsersGridProps> = ({ users, selectionChanged }) => {
  const [columns] = useState([
    { name: "name", title: "Name" },
    { name: "companyName", title: "Company Name" },
    { name: "email", title: "Email" },
    { name: "accountType", title: "Account Type" },
    { name: "isActive", title: "A" },
  ]);

  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>([
    // { columnName: "name", width: 200 },
    // { columnName: "companyName", width: 200 },
    // { columnName: "email", width: 300 },
    // { columnName: "accountType", width: 120 },
    // { columnName: "isActive", width: 60 },
  ]);

  const [defaultHiddenColumnNames] = useState(["email", "isActive"]);

  const useGridStyles = makeStyles({
    link: {
      padding: "2px 4px 2px 10px",
    },
  });
  const gridStyles = useGridStyles();

  const getChildRows = (row, rows) => {
    const childRows = rows.filter(
      (r) => r.parentId === (row ? row.userId : null)
    );
    return childRows.length ? childRows : null;
  };

  function getDisplayColor(user: IUserV1 | undefined): fontColor {
    if (user?.isExternal === true) {
      return "primary";
    }
    const color: fontColor =
      user?.syncStatus !== 0
        ? "error"
        : user?.isTestUser
        ? "disabled"
        : "inherit";
    return color;
  }

  function getTextColor(user: IUserV1 | undefined): textColor {
    if (user?.isExternal === true) {
      return "textPrimary";
    }
    const color: textColor =
      user?.syncStatus !== 0
        ? "error"
        : user?.isTestUser
        ? "textSecondary"
        : "inherit";
    return color;
  }

  const [isActiveColumn] = useState(["isActive"]);
  const IsActiveColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    const user: IUserV1 | undefined = users.find(
      (e) => e.userId === row.userId
    );
    return <Checkbox checked={user?.isActive || false} />;
  };

  const [nameColumn] = useState(["name"]);
  function renderUserIcon(user: IUserV1 | undefined) {
    const color = getDisplayColor(user);
    if (user?.isActive === false) {
      return <NotInterestedIcon color={color} />;
    } else if (user?.isExternal === true) {
      return <PersonOutlineIcon color={color} />;
    } else if (user?.isAdmin === true) {
      return <SupervisorAccountIcon color={color} />;
    } else if (user?.isDistributor === true) {
      return <BusinessIcon color={color} />;
    } else if (user?.isSupport === true) {
      return <PhoneIcon color={color} />;
    } else if (user?.isCustomer === true) {
      return <PersonIcon color={color} />;
    }
    return null;
  }

  const NameColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    const user: IUserV1 | undefined = users.find(
      (e) => e.userId === row.userId
    );
    const color = getTextColor(user);
    return (
      <Grid container>
        <Grid item>
          <Badge color="error" variant="dot" invisible={user?.syncStatus === 0}>
            {renderUserIcon(user)}
          </Badge>
        </Grid>
        <Grid item>
          <Link
            className={gridStyles.link}
            color={color}
            href="#"
            onClick={() => {
              selectionChanged(user);
            }}
          >
            {user?.name}
          </Link>
        </Grid>
      </Grid>
    );
  };

  return (
    <DevExpressGrid rows={users} columns={columns}>
      <DataTypeProvider
        for={nameColumn}
        formatterComponent={NameColumnRenderer}
      />
      <DataTypeProvider
        for={isActiveColumn}
        formatterComponent={IsActiveColumnRenderer}
      />
      {/* <DataTypeProvider
          for={employeeColumns}
          formatterComponent={EmployeeFormatter}
        /> */}

      <TreeDataState />
      <FilteringState />
      <SearchState />
      <SortingState />
      {/*
       */}
      <SelectionState />
      {/* <PagingState
          defaultCurrentPage={0}
          defaultPageSize={pageSizes[1]}
        /> */}

      <CustomTreeData getChildRows={getChildRows} />
      <IntegratedFiltering />
      <IntegratedSorting />

      {/* <IntegratedSelection /> */}
      {/* 
            
            
            <IntegratedPaging /> */}

      <Table />
      <TableColumnVisibility
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />

      <TableColumnResizing
        columnWidths={columnWidths}
        onColumnWidthsChange={setColumnWidths}
      />
      <TableHeaderRow showSortingControls />
      {/* <TableFilterRow /> */}
      <TableTreeColumn for="name" />
      {/* <TableSelection showSelectAll /> */}
      <Toolbar />
      <SearchPanel />
      <ColumnChooser />

      {/* <PagingPanel
          pageSizes={pageSizes}
        /> */}
    </DevExpressGrid>
  );
};

export default UsersGrid;
