import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import {
  IUserV0,
  IExternalUserRequest,
} from "../../../../api/Account/usersApi";
import UserDropDown from "../../_shared/UsersDropDown";

export interface IExternalUserDialogProps {
  open: boolean;
  users: IUserV0[];
  handleSubmit: (request: IExternalUserRequest) => void;
  handleCancel: () => void;
}

const ExternalUserDialog: React.FC<IExternalUserDialogProps> = ({
  open,
  users,
  handleSubmit,
  handleCancel,
}) => {
  const formik = useFormik({
    initialValues: {
      userId: "",
      displayName: "",
      companyName: "",
      parentId: "",
    },
    validationSchema: Yup.object({
      userId: Yup.string().required(),
      displayName: Yup.string().required(),
      companyName: Yup.string().required(),
      parentId: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      await handleSubmit({
        userId: values.userId,
        displayName: values.displayName,
        companyName: values.companyName,
        parentId: values.parentId,
      });
    },
  });

  useEffect(() => {
    if (open) {
      formik.setFieldValue("userId", "");
      formik.setFieldValue("displayName", "");
      formik.setFieldValue("companyName", "");
      formik.setFieldValue("parentId", "");
    }
  }, [open]);

  const useStyles = makeStyles({
    root: {
      // flexGrow: 1,
      padding: "18px",
    },
    input: {
      verticalAlign: "top",
      marginTop: "12px",
    },
  });
  const styles = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="transfer user"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Create External User</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={styles.root}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="flex-start"
          >
            <Typography variant="body2">
              An external user acts like a placeholder and reference when been
              asked not to create an Azure account for the customer.
              <br />
              User will have no access to documents, downloads or activate their
              products, however the content and activations are available
              throught their parent company (distributor or admin directly)
            </Typography>

            <FormControl fullWidth>
              <TextField
                label="Unique User Id"
                name="userId"
                type="text"
                value={formik.values.userId}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                label="Display Name"
                name="displayName"
                type="text"
                value={formik.values.displayName}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                label="Company Name"
                name="companyName"
                type="text"
                value={formik.values.companyName}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl
              fullWidth
              error={Boolean(formik.errors.parentId) && formik.touched.parentId}
            >
              <UserDropDown
                name="parentId"
                label="Parent"
                users={users.filter((u) => u.isDistributor)}
                value={formik.values.parentId}
                enabled={true}
                allowEmpty={true}
                allowShowId={true}
                onChange={handleChange}
                touched={formik.touched.parentId}
                error={formik.errors.parentId}
              />
            </FormControl>

            <Grid item />
            <Grid container direction="row" justify="flex-end">
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>Submit</Grid>
                  {formik.isSubmitting && (
                    <Grid item>
                      <CircularProgress size={18} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ExternalUserDialog;
