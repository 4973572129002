import React, { useState } from "react";
import { Chip, Grid, Link, Typography } from "@material-ui/core";
import {
  DataTypeProvider,
  SortingState,
  SearchState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  TableColumnWidthInfo,
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  SearchPanel,
  TableColumnResizing,
  Toolbar,
  TableColumnVisibility,
} from "@devexpress/dx-react-grid-material-ui";
import Moment from "react-moment";
import { ILicense2 } from "../../../api/Account/myLicensesApi";
import WarningIcon from "@material-ui/icons/Warning";

export interface IMyLicensesGridProps {
  rows: ILicense2[];
  onOpenDialog: (row: ILicense2) => void;
}

const MyLicensesGrid: React.FC<IMyLicensesGridProps> = ({
  rows,
  onOpenDialog,
}) => {
  const [columns] = useState([
    { name: "productName", title: "Application" },
    { name: "branchName", title: "Branch" },
    { name: "station", title: "Station" },
    { name: "date", title: "Date" },
    { name: "status", title: "Status" },
  ]);

  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>([
    { columnName: "productName", width: 480 },
    { columnName: "branchName", width: 120 },
    { columnName: "station", width: 100 },
    { columnName: "date", width: 120 },
    { columnName: "status", width: 200 },
  ]);

  const [defaultHiddenColumnNames] = useState(["userId"]);

  const [nameColumn] = useState(["productName"]);
  const NameColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => (
    <Grid container direction="column" alignItems="flex-start">
      <Link
        href="#"
        color={row.isCanceled === true ? "secondary" : "primary"}
        onClick={() => onOpenDialog(row)}
        variant="inherit"
      >
        {row.isExternal ? (
          <div>{`${row.programName} (${row.userName})`}</div>
        ) : (
          <div>{row.programName}</div>
        )}
      </Link>
      <Typography variant="caption">{row.licenseKey}</Typography>
      {row.isOrphan && (
        <Grid item container direction="row">
          <WarningIcon color="error" fontSize="small" />
          <Typography color="error">Attention Required</Typography>
        </Grid>
      )}
    </Grid>
  );

  const [dateColumn] = useState(["date"]);
  const DateColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => <Moment format="MMMM/YYYY">{row.date}</Moment>;

  const [statusColumn] = useState(["status"]);
  const StatusColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    /*  
        None,
        Requested,
        Active,
        Rejected,
        UninstallRequest,
        Uninstalled,
        NotFound
    */
    if (row.Status === 1 || row.status === 2) {
      return <Chip color="primary" label="Active" />;
    }
    if (row.Status === 4 || row.status === 5) {
      return <Chip label="Uninstalled" />;
    }
    return <Chip color="secondary" label="Unknown" />;
  };

  return (
    <React.Fragment>
      <DevExpressGrid rows={rows} columns={columns}>
        <SearchState />
        <SortingState
          defaultSorting={[{ columnName: "date", direction: "desc" }]}
        />
        <FilteringState />

        <DataTypeProvider
          for={dateColumn}
          formatterComponent={DateColumnRenderer}
        />

        <DataTypeProvider
          for={statusColumn}
          formatterComponent={StatusColumnRenderer}
        />

        <DataTypeProvider
          for={nameColumn}
          formatterComponent={NameColumnRenderer}
        />

        <IntegratedSorting />
        <IntegratedFiltering />
        <Table />
        <TableColumnResizing
          columnWidths={columnWidths}
          onColumnWidthsChange={setColumnWidths}
        />
        <TableHeaderRow showSortingControls />
        <TableColumnVisibility
          defaultHiddenColumnNames={defaultHiddenColumnNames}
        />
        <Toolbar />
        <SearchPanel />
      </DevExpressGrid>
    </React.Fragment>
  );
};

export default MyLicensesGrid;
