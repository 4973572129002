/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component, Fragment } from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ai, getAppInsights } from "../setup/AppInsightsSetup";
import { withRouter } from "react-router-dom";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

class AppInsightsProvider extends Component<any> {
  state = {
    initialized: false,
  };

  componentDidMount() {
    const { history } = this.props;
    const { initialized } = this.state;
    const appInsightsInstrumentationKey = this.props.instrumentationKey;
    if (
      !initialized &&
      Boolean(appInsightsInstrumentationKey) &&
      Boolean(history)
    ) {
      ai.initialize(appInsightsInstrumentationKey, history);
      this.setState({ initialized: true });
    }
    this.props.after();
  }

  render() {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

export const trackTrace = (message: string, severity: SeverityLevel) => {
  if (message) {
    const instance = getAppInsights();
    instance.trackTrace({
      message: message,
      severity: severity,
    });
  }
};

export const trackError = (error: Error) => {
  const instance = getAppInsights();
  instance.trackException({
    exception: error,
  });
};

export const trackErrorMessage = (
  errorTitle: string | null | undefined,
  errorMessage: string | null
) => {
  if (errorMessage) {
    const instance = getAppInsights();
    instance.trackException({
      exception: new Error(
        `${errorTitle || "Unhandled exception"}: ${errorMessage}`
      ),
    });
  }
};

export default withRouter(withAITracking(ai.reactPlugin, AppInsightsProvider));
