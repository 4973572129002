import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  header: string;
  subHeader: string;
  login: string;
  logout: string;
  learnMore: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    header: "Weighing automation software",
    subHeader:
      "We develop software for any industrial process connected to scales, printers, and other hardware",
    login: "Login",
    logout: "Logout",
    learnMore: "Learn more",
  },
  es: {
    header: "Software para automatización de pesaje",
    subHeader:
      "Desarrollamos software para procesos industriales conectados a indicadores de peso, impresoras y otro hardware",
    login: "Iniciar sesión",
    logout: "Cerrar sesión",
    learnMore: "Conocer mas",
  },
});
