import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IUserV0 } from "../../../../api/Account/usersApi";
import {
  Button,
  Switch,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  Grid,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import {
  IProjectResponse,
  IProjectPaymentRequest,
} from "../../../../api/Admin/dbProjectsApi";
import * as Yup from "yup";
import CurrencyTextBox from "../../_shared/CurrencyTextBox";
import UserDropDown from "../../_shared/UsersDropDown";

export interface IProjectPaymentDialogProps {
  row: IProjectResponse | undefined;
  open: boolean;
  users: IUserV0[];
  handleSubmit: (request: IProjectPaymentRequest) => void;
  handleCancel: () => void;
}

const ProjectPaymentDialog: React.FC<IProjectPaymentDialogProps> = ({
  row,
  open,
  users,
  handleSubmit,
  handleCancel,
}) => {
  const [hasCommission, setHasCommission] = useState<boolean>(
    row?.commissionUser !== null && row?.commissionUser !== undefined
  );

  const formik = useFormik({
    //enableReinitialize: true,
    initialValues: {
      paid: row?.paid || false,
      paymentAmount: row?.paymentAmount || 0,
      paymentDate: row?.paymentDate,
      paymentCcy: row?.paymentCcy || undefined,
      commissionUser: row?.commissionUser || row?.installationUser || undefined,
    },
    validationSchema: Yup.object({
      paid: Yup.boolean(),
      paymentDate: Yup.date().when("paid", {
        is: true,
        then: Yup.date().required(),
      }),
      paymentAmount: Yup.number().when("paid", {
        is: true,
        then: Yup.number().required(),
      }),
      paymentCcy: Yup.string().when("paid", {
        is: true,
        then: Yup.string().required(),
      }),
    }),
    onSubmit: async (values) => {
      await handleSubmit({
        programId: row?.programId || "",
        paid: values.paid,
        paymentAmount: values.paymentAmount,
        paymentDate: values.paymentDate,
        paymentCcy: values.paymentCcy,
        commissionUser: hasCommission ? values.commissionUser : undefined,
      });
    },
  });

  useEffect(() => {
    if (open) {
      formik.setFieldValue("paid", row?.paid || false);
      formik.setFieldValue("paymentAmount", row?.paymentAmount);

      if (row?.paymentDate) {
        formik.setFieldValue("paymentDate", row?.paymentDate);
      } else {
        formik.setFieldValue(
          "paymentDate",
          row?.installationDate ||
            row?.devEndDate ||
            row?.devStartDate ||
            row?.orderDate ||
            new Date()
        );
      }
      formik.setFieldValue("paymentCcy", row?.paymentCcy);
      formik.setFieldValue("commissionUser", row?.commissionUser);
      setHasCommission(
        row?.commissionUser !== null && row?.commissionUser !== undefined
      );
    }
  }, [open]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      formControls: {
        padding: "3px 0px 12px 0px",
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      input: {
        verticalAlign: "top",
        marginTop: "0px",
        paddingTop: "0px",
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
  );
  const styles = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  const handleCommissionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHasCommission(event.target.checked);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle id="form-dialog-title">{row?.name}</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={formik.handleSubmit} className={styles.root}>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              className={styles.formControls}
            >
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      name="paid"
                      color="primary"
                      checked={formik.values.paid}
                      onChange={handleCheckBoxChange}
                    />
                  }
                  label="Paid"
                />
              </Grid>

              {formik.values.paid && (
                <>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <DatePicker
                        name="paymentDate"
                        label="Date"
                        value={formik.values.paymentDate}
                        onChange={(dt) =>
                          formik.setFieldValue("paymentDate", dt)
                        }
                      />
                    </FormControl>
                    {formik.errors.paymentDate && formik.touched.paymentDate && (
                      <Typography variant="caption" color="error">
                        {formik.errors.paymentDate}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <FormControl fullWidth>
                      <CurrencyTextBox
                        nameAmount="paymentAmount"
                        nameCcy="paymentCcy"
                        label="Amount"
                        amount={formik.values.paymentAmount}
                        ccy={formik.values.paymentCcy}
                        onChangeAmount={handleChange}
                        onChangeCcy={handleChange}
                        touched={formik.touched.paymentAmount}
                        error={formik.errors.paymentAmount}
                      />
                    </FormControl>
                    {formik.errors.paymentCcy && formik.touched.paymentCcy && (
                      <Typography variant="caption" color="error">
                        {formik.errors.paymentCcy}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          disabled={!formik.values.paid}
                          checked={hasCommission}
                          onChange={handleCommissionChange}
                        />
                      }
                      label="Commission"
                      labelPlacement="right"
                    />
                  </Grid>
                </>
              )}
              {formik.values.paid && hasCommission && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <UserDropDown
                      name="commissionUser"
                      label="Pay To"
                      users={users}
                      value={formik.values.commissionUser}
                      enabled={true}
                      allowEmpty={true}
                      allowShowId={true}
                      onChange={handleChange}
                      touched={formik.touched.commissionUser}
                      error={formik.errors.commissionUser}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>

            <Grid container justify="flex-end">
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>Submit</Grid>
                  {formik.isSubmitting && (
                    <Grid item>
                      <CircularProgress size={18} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </form>
        </MuiPickersUtilsProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectPaymentDialog;
