import * as types from "./constants";
import { LanguageOptions } from "../../types/Enums";

export const getDefaultLanguage = (): LanguageOptions => {
  const defaultLanguage =
    localStorage.getItem("language") ||
    navigator.language?.substring(0, 2) ||
    "en";
  return defaultLanguage as LanguageOptions;
};

export const switchLanguage = async (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: any,
  language: LanguageOptions
) => {
  dispatch({
    type: types.SWITCH_LANGUAGE,
    data: language,
  });
};
