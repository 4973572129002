/* eslint-disable */
import { setupAxios, processApiRequest } from "../apiHelper";
import { bool, number, string } from "yup";
import { IUserV0 } from "../Account/usersApi";

//SETUP
const baseUrl = process.env.PROJECTS_API;
const baseApi = setupAxios(baseUrl!);

export interface IOrder {
  orderId: string;
  userId: string;
  userName: string;
  date: Date;
  notes?: string;
}

//TODO: REMOVE
export interface IDevelopmentDetailsRequest {
  programId: string;
  devStartDate?: Date;
  devEndDate?: Date;
  devNotes?: string;
}

//TODO: REMOVE
export interface IInstallationDetailsRequest {
  programId: string;
  installationUser?: string;
  installationDate?: Date;
  installationNotes?: string;
  isInstalled: boolean;
}

//TODO: REMOVE
export interface IProjectPaymentRequest {
  programId: string;
  paid: boolean;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentCcy?: string;
  commissionUser?: string;
}

//TODO: REMOVE
export interface IProjectCommissionRequest {
  programId: string;
  commissionPaid: boolean;
  commissionUser?: string;
  commissionDate?: Date;
  commissionAmount?: number;
  commissionCcy?: string;
  commissionNotes?: string;
}

//TODO: REMOVE
export interface IProjectResponse {
  programId: string;
  name: string;
  orderId: string;
  orderDate: Date;
  orderUserId: string;
  orderUserName: string;
  userId: string;
  userName: string;
  ownerId: string;
  ownerName: string;
  productId: string;
  productName: string;
  salePrice: number;
  saleCcy?: string;
  paid: boolean;
  installed: boolean;
  installationDate?: Date;
  installationUser?: string;
  installationNotes?: string;
  devStartDate?: Date;
  devEndDate?: Date;
  devNotes?: string;
  notes?: string;
  licenses: number;
  discount?: number;
  discountCode?: string;
  stations: number;
  stationNames: string[];
  paymentDate?: Date;
  paymentCcy?: string;
  paymentAmount?: number;
  //commission?: number;
  commissionPaid?: boolean;
  commissionDate?: Date;
  commissionAmount?: number;
  commissionUser?: string;
  commissionCcy?: string;
  commissionNotes?: string;
  isActive: boolean;
  isCommercialProduct?: boolean;
  maxLicenses: number;
  branches?: string[];
  isCanceled: boolean;
  cancellationDate?: Date;
  cancelReason?: string;
}

enum ProjectStatusFlags {
  IsActive = 1,
  IsDevComplete = 2,
  IsInstalled = 4,
  IsPaid = 8,
  HasCommission = 16,
  IsCommissionPaid = 32,
  IsCancelled = 64,
}

export interface IProjectV0 {
  programId: string;
  name: string;
  orderId: string;
  orderDate: Date;
  orderUserId: string;
  userId: string;
  ownerId: string;
  productId: string;
  stations: number;
  branches: string[];
  statusFlags: number;
  isActive: boolean;
  isDevComplete: boolean;
  isInstalled: boolean;
  isPaid: boolean;
  hasCommission: boolean;
  isCommissionPaid: boolean;
  isCancelled: boolean;
}

export interface IProjectV0Ex extends IProjectV0 {
  pending: string[];
  userCompanyName?: string;
  ownerCompanyName?: string;
  ownershipCollapsed: IUserV0[];
  ownershipExpanded: IUserV0[];
  userName: string;
  ownerName: string;
  orderUserName: string;
  productName: string;
}

// TODO: REMOVE
export interface IProjectRequest {
  programId: string;
  programName: string;
  orderId: string;
  orderDate: Date;
  orderUserId: string;
  userId: string;
  ownerId: string;
  productId: string;
  salePrice: number;
  saleCcy?: string;
  notes?: string;
  discount?: number;
  discountCode?: string;
}

export interface IProjectUserScope {
  userId: string;
  userName: string;
  scopeId: string;
}

export interface IProgramUserScopePivot {
  userId: string | undefined;
  userName?: string;
  download: boolean;
  activateOffline: boolean;
  activateSupport: boolean;
  activateFull: boolean;
  editUserColumn?: number;
}

/* TODO: REMOVE */
export interface IProjectUserScopeRequest {
  userId: string;
  download: boolean;
  activateFull: boolean;
  activateSupport: boolean;
  activateOffline: boolean;
}

export interface ICancelProgramRequest {
  isCanceled: boolean;
  cancelReason?: string;
}

export const getOrders = async (
  onSuccess: (result: IOrder[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "orders";
  await processApiRequest(baseApi.get<IOrder[]>(url), {
    onSuccess: (response) => onSuccess(response.data),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const getProjects = async (
  onSuccess: (result: IProjectResponse[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "projects/all";
  await processApiRequest(baseApi.get<IProjectResponse[]>(url), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

const transformStatusFlags = (project: IProjectV0): IProjectV0 => {
  project.isActive =
    (project.statusFlags & ProjectStatusFlags.IsActive) ==
    ProjectStatusFlags.IsActive;
  project.isDevComplete =
    (project.statusFlags & ProjectStatusFlags.IsDevComplete) ==
    ProjectStatusFlags.IsDevComplete;
  project.isInstalled =
    (project.statusFlags & ProjectStatusFlags.IsInstalled) ==
    ProjectStatusFlags.IsInstalled;
  project.isPaid =
    (project.statusFlags & ProjectStatusFlags.IsPaid) ==
    ProjectStatusFlags.IsPaid;
  project.hasCommission =
    (project.statusFlags & ProjectStatusFlags.HasCommission) ==
    ProjectStatusFlags.HasCommission;
  project.isCommissionPaid =
    (project.statusFlags & ProjectStatusFlags.IsCommissionPaid) ==
    ProjectStatusFlags.IsCommissionPaid;
  project.isCancelled =
    (project.statusFlags & ProjectStatusFlags.IsCancelled) ==
    ProjectStatusFlags.IsCancelled;
  return project;
};

export const listProjects = async (
  onSuccess: (result: IProjectV0[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "projects/list";
  await processApiRequest(baseApi.get<IProjectV0[]>(url), {
    onSuccess: (response) =>
      onSuccess(response.data.map((p) => transformStatusFlags(p))),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const getProject = async (
  projectId: string,
  onSuccess: (result: IProjectResponse) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "projects/" + projectId;
  await processApiRequest(baseApi.get<IProjectResponse>(url), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const getProjectUsers = async (
  programId: string,
  onSuccess: (result: IProjectUserScope[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = `scopes/projects/${programId}`;
  await processApiRequest(baseApi.get<IProjectUserScope[]>(url), {
    onSuccess: (response) => onSuccess(response.data),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const updateDevelopmentDetails = async (
  request: IDevelopmentDetailsRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `projects/${request.programId}/dev-details`;
  await processApiRequest(baseApi.put(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    serverError: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const updateInstallationDetails = async (
  request: IInstallationDetailsRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `projects/${request.programId}/installation-details`;
  await processApiRequest(baseApi.put(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    serverError: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const updatePaymentDetails = async (
  request: IProjectPaymentRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `projects/${request.programId}/payment-details`;
  await processApiRequest(baseApi.put(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    serverError: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const updateCommissionDetails = async (
  request: IProjectCommissionRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `projects/${request.programId}/commission-details`;
  await processApiRequest(baseApi.put(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    serverError: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const getProgramsForOrder = async (
  orderId: string,
  onSuccess: (result: IOrder[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "projects?orderId=" + orderId;
  await processApiRequest(baseApi.get<IOrder[]>(url), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const addProject = async (
  request: IProjectRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "projects";
  await processApiRequest(baseApi.post<IProjectResponse>(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage("Not found"),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const updateProject = async (
  request: IProjectRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "projects";
  await processApiRequest(baseApi.put<IProjectResponse>(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage("Not found"),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const deleteProject = async (
  projectId: string,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "projects/" + projectId;
  await processApiRequest(baseApi.delete(url), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage("Not found"),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const addProjectBranch = async (
  programId: string,
  branchName: string,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `projects/${programId}/branches/${branchName}`;
  await processApiRequest(baseApi.post(url), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const deleteProjectBranch = async (
  programId: string,
  branchName: string,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `projects/${programId}/branches/${branchName}`;
  await processApiRequest(baseApi.delete(url), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const addOrUpdateProjectUserScope = async (
  programId: string,
  request: IProjectUserScopeRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `scopes/projects/${programId}`;
  await processApiRequest(baseApi.put(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const deleteProjectUserScope = async (
  programId: string,
  userId: string,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `scopes/projects/${programId}?userId=${userId}`;
  await processApiRequest(baseApi.delete(url), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const cancelProject = async (
  programId: string,
  request: ICancelProgramRequest,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `projects/${programId}/cancel`;
  await processApiRequest(baseApi.put(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};
