import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useFormik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  IProgramUserScopePivot,
  IProjectUserScopeRequest,
} from "../../../../api/Admin/dbProjectsApi";
import * as Yup from "yup";
import { IUserV0 } from "../../../../api/Account/usersApi";
import UserDropDown from "../../_shared/UsersDropDown";

export interface IProjectUserScopeDialogProps {
  scope: IProgramUserScopePivot | undefined;
  otherScopes: IProgramUserScopePivot[] | undefined;
  users: IUserV0[];
  open: boolean;
  handleSubmit: (request: IProjectUserScopeRequest) => void;
  handleCancel: () => void;
}

const ProjectUserScopeDialog: React.FC<IProjectUserScopeDialogProps> = ({
  scope,
  otherScopes,
  users,
  open,
  handleSubmit,
  handleCancel,
}) => {
  const [isUpdateOperation, setIsUpdateOperation] = useState<boolean>(false);
  const [selectedScope, setSelectedScope] = useState<
    IProgramUserScopePivot | undefined
  >();

  const formik = useFormik({
    initialValues: {
      userId: scope?.userId,
      download: scope?.download || false,
      activateFull: scope?.activateFull || false,
      activateSupport: scope?.activateSupport || false,
      activateOffline: scope?.activateOffline || false,
    },
    validationSchema: Yup.object({
      userId: Yup.string().required(),
      download: Yup.bool(),
      activateOffline: Yup.bool(),
      activateFull: Yup.bool().when("activateSupport", {
        is: true,
        then: Yup.bool().oneOf(
          [false],
          "You can select only one of support or full license mode"
        ),
      }),
      activateSupport: Yup.bool(),
    }).test("at-least-one", null, (obj) => {
      if (
        obj?.download ||
        obj?.activateFull ||
        obj?.activateSupport ||
        obj?.activateOffline
      ) {
        return true;
      }
      return new Yup.ValidationError(
        "Select at least one option",
        null,
        "at-least-one"
      );
    }),
    onSubmit: async (values) => {
      await handleSubmit({
        userId: values.userId || "",
        download: values.download,
        activateFull: values.activateFull,
        activateSupport: values.activateSupport,
        activateOffline: values.activateOffline,
      });
    },
  });

  const checkIsUpdateOperation = (user: string | undefined) => {
    setIsUpdateOperation(false);
    if (user) {
      const existing = otherScopes?.find((s) => s.userId === user);
      if (existing) {
        setIsUpdateOperation(true);
      }
      setSelectedScope(existing);
    }
  };

  useEffect(() => {
    if (open) {
      formik.setFieldValue("userId", scope?.userId);
      checkIsUpdateOperation(scope?.userId);
    }
  }, [open]);

  useEffect(() => {
    formik.setFieldValue("download", selectedScope?.download || false);
    formik.setFieldValue("activateFull", selectedScope?.activateFull || false);
    formik.setFieldValue(
      "activateOffline",
      selectedScope?.activateOffline || false
    );
    formik.setFieldValue(
      "activateSupport",
      selectedScope?.activateSupport || false
    );
  }, [selectedScope]);

  const useStyles = makeStyles({
    root: {
      // flexGrow: 1,
      padding: "18px",
    },
    input: {
      verticalAlign: "top",
      marginTop: "12px",
    },
  });
  const styles = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
    if (name === "userId") {
      checkIsUpdateOperation(value);
    }
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  const UserControl = () => {
    return (
      <FormControl
        fullWidth
        error={formik.errors.userId && formik.touched.userId}
      >
        <UserDropDown
          name="userId"
          label="User"
          users={users}
          value={formik.values.userId}
          enabled={true}
          allowEmpty={true}
          allowShowId={true}
          onChange={handleChange}
          touched={formik.touched.userId}
          error={formik.errors.userId}
        />
      </FormControl>
    );
  };

  const ScopeControl = ({ caption, name, value, showError, errorMessage }) => {
    return (
      <>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              name={name}
              checked={value}
              onChange={handleCheckBoxChange}
            />
          }
          label={caption}
          labelPlacement="end"
        />
        {showError && (
          <Typography variant="caption" color="error">
            {" "}
            {errorMessage}
          </Typography>
        )}
      </>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="user scope"
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle id="form-dialog-title">User Permissions</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={styles.root}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="flex-start"
          >
            <UserControl />

            {isUpdateOperation && (
              <Typography
                variant="body2"
                color="primary"
                style={{ alignSelf: "center" }}
              >
                User permissions will be overwritten
              </Typography>
            )}

            <ScopeControl
              caption="Download"
              name="download"
              value={formik.values.download}
              showError={formik.errors.download && formik.touched.download}
              errorMessage={formik.errors.download}
            />
            <ScopeControl
              caption="Full License"
              name="activateFull"
              value={formik.values.activateFull}
              showError={
                formik.errors.activateFull && formik.touched.activateFull
              }
              errorMessage={formik.errors.activateFull}
            />
            <ScopeControl
              caption="Support License"
              name="activateSupport"
              value={formik.values.activateSupport}
              showError={
                formik.errors.activateSupport && formik.touched.activateSupport
              }
              errorMessage={formik.errors.activateSupport}
            />
            <ScopeControl
              caption="Offline License"
              name="activateOffline"
              value={formik.values.activateOffline}
              showError={
                formik.errors.activateOffline && formik.touched.activateOffline
              }
              errorMessage={formik.errors.activateOffline}
            />

            <Grid item />
            <Grid container direction="row" justify="flex-end">
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>Submit</Grid>
                  {formik.isSubmitting && (
                    <Grid item>
                      <CircularProgress size={18} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectUserScopeDialog;
