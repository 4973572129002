import React, { useState, useEffect } from "react";
import ProjectsGrid from "./ProjectsGrid";
import { IProduct, getProducts } from "../../../api/Admin/dbProductsApi";
import {
  addProject,
  listProjects,
  IOrder,
  IProjectRequest,
  IProjectV0,
  IProjectV0Ex,
} from "../../../api/Admin/dbProjectsApi";

import { IUserV0, getUsersList } from "../../../api/Account/usersApi";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ProjectDetailsDialog from "./dialogs/ProjectDetailsDialog";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ToastContainer, toast } from "react-toastify";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

enum ProjectFilterEnum {
  None = 0,
  Incomplete = 1,
  IncompleteAndThisYear = 2,
}

const ProjectsPage: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [projects, setProjects] = useState<IProjectV0[]>([]);
  const [projects2, setProjects2] = useState<IProjectV0Ex[]>([]);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [users, setUsers] = useState<IUserV0[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [advancedFilter, setAdvancedFilter] = useState<ProjectFilterEnum>(0);
  const advancedFilterList: { id: number; name: string }[] = [];
  for (const n in ProjectFilterEnum) {
    if (typeof ProjectFilterEnum[n] === "number") {
      advancedFilterList.push({
        id: ProjectFilterEnum[n] as number,
        name: n as string,
      });
    }
  }

  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const refreshUsersAsync = async () => {
    await getUsersList(
      (response) => {
        const orderedUsers: IUserV0[] = response.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setUsers([...orderedUsers]);
      },
      (err) => showError(err)
    );
  };

  const refreshProductsAsync = async () => {
    await getProducts(
      (response) => {
        setProducts([...response]);
      },
      (err) => {
        alert(err);
      }
    );
  };

  const refreshAsync = async () => {
    setIsLoading(true);
    await listProjects(
      (result) => {
        setProjects([...result]);
        setIsLoading(false);
      },
      (error) => {
        showError(error);
        setIsLoading(false);
      }
    );
  };

  const newProjectTemplate: IProjectRequest = {
    programId: "",
    programName: "",
    orderId: "",
    orderDate: new Date(),
    orderUserId: "",
    userId: "",
    ownerId: "",
    productId: "",
    salePrice: 0,
    saleCcy: "USD",
    notes: "",
    discount: undefined,
    discountCode: undefined,
    stations: 1,
  };

  const getWhatsPending = (row: IProjectV0): string[] => {
    if (row.isCancelled) {
      return [];
    }
    const missingStages = [
      row.isDevComplete ? null : "Development",
      row.isInstalled ? null : "Installation",
      row.isPaid ? null : "Payment",
      row.hasCommission && row.isCommissionPaid === false ? "Commission" : null,
    ];
    return missingStages.filter((x) => Boolean(x));
  };

  useEffect(() => {
    const projectOrders: IOrder[] = [];
    projects.forEach((x) => {
      const projectUser = users.find((u) => u.userId == x.orderUserId);
      const projectOrder: IOrder = {
        orderId: x.orderId,
        date: x.orderDate,
        userId: x.orderUserId,
        userName: projectUser?.name || "",
      };
      if (!projectOrders.find((p) => p.orderId === projectOrder.orderId)) {
        projectOrders.push(projectOrder);
      }
    });
    setOrders(projectOrders);

    const projectsExtended: IProjectV0Ex[] = projects.map((x) => {
      const orderUser = users.find((u) => u.userId === x.orderUserId);
      const ownerUser = users.find((u) => u.userId === x.ownerId);
      const endUser = users.find((u) => u.userId === x.userId);
      const product = products.find((p) => p.productId === x.productId);
      const ownership = [
        {
          userId: x.orderUserId,
          name: orderUser?.name,
          company: "Purchased By",
        },
        x.orderUserId == x.ownerId
          ? null
          : {
              userId: x.ownerId,
              name: ownerUser?.name,
              company: x.userId == x.ownerId ? "End User" : "Represented By",
            },
        x.userId == x.ownerId
          ? null
          : { userId: x.userId, name: endUser?.name, company: "End User" },
      ];

      const ownershipExpanded = [
        {
          userId: x.orderUserId,
          name: orderUser?.name,
          company: "Purchased By",
        },
        {
          userId: x.ownerId,
          name: ownerUser?.name,
          company: "Represented By",
        },
        { userId: x.userId, name: endUser?.name, company: "End User" },
      ];
      return {
        ...x,
        userName: endUser?.name,
        ownerName: ownerUser?.name,
        orderUserName: orderUser?.name,
        pending: getWhatsPending(x),
        productName: product?.name,
        ownershipCollapsed: ownership.filter((x) => x !== null),
        ownershipExpanded: ownershipExpanded,
        userCompanyName: endUser?.companyName,
        ownerCompanyName: ownerUser?.companyName,
      };
    });
    setProjects2(projectsExtended);
  }, [projects]);

  useEffect(() => {
    refreshUsersAsync();
    refreshProductsAsync();
  }, []);

  useEffect(() => {
    if (users && users.length > 0 && products && products.length > 0) {
      refreshAsync();
    }
  }, [users, products]);

  // NEW ORDER
  const [showProjectDialog, setShowProjectDialog] = useState<boolean>(false);
  const [selection, setSelection] = useState<IProjectRequest | undefined>();

  const showCreateProject = () => {
    const projectRequest: IProjectRequest = { ...newProjectTemplate };
    setSelection(undefined);
    setSelection(projectRequest);
    setShowProjectDialog(true);
  };

  const onAddProject = async (request: IProjectRequest) => {
    await addProject(
      request,
      () => {
        setShowProjectDialog(false);
        setSelection(undefined);
        toast.success("Project added");
        refreshAsync();
      },
      (err) => {
        showError(err);
      }
    );
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        padding: "0px 6px 0px 32px",
      },
      newProductButton: {
        width: "148px",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      toolbar: {
        margin: "12px 0px 6px 0px",
      },
      advancedFilter: {
        margin: "0 12px 0 12px",
      },
    })
  );
  const styles = useStyles();

  const ProjectsToolbar = () => {
    return (
      <Grid
        item
        container
        className={styles.toolbar}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            className={styles.newProductButton}
            onClick={() => showCreateProject()}
          >
            New Project
          </Button>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={4}
          xs={12}
          sm={9}
        >
          <Grid item>
            <FormControl>
              <InputLabel id="filter-label">Advanced Filter</InputLabel>
              <Select
                label="Advanced Filter"
                name="dropDownFilter"
                value={advancedFilter || ""}
                style={{ width: "200px" }}
                onChange={(e) => setAdvancedFilter(e.target?.value || "")}
              >
                {advancedFilterList.map((x) => {
                  return (
                    <MenuItem key={x.name} value={x.id}>
                      {x.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              className={styles.newProductButton}
              onClick={() => showCreateProject()}
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container className={styles.header} direction="column">
        <Typography variant="h2">Projects</Typography>

        <ProjectsToolbar />
      </Grid>
      <Backdrop className={styles.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer />
      {isLoading === false && (
        <>
          {projects2 && (
            <div>
              <ProjectsGrid users={users} projects={projects2} />
            </div>
          )}

          {selection && (
            <ProjectDetailsDialog
              row={selection}
              users={users}
              orders={orders}
              products={products}
              open={showProjectDialog}
              handleDelete={() => ({})}
              handleSubmit={(request) => onAddProject(request)}
              handleCancel={() => setShowProjectDialog(false)}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProjectsPage;
