/* eslint-disable */
import { string } from "yup";
import { setupAxios, processApiRequest } from "../apiHelper";

//SETUP
const baseUrl = process.env.PROJECTS_API;
const baseApi = setupAxios(baseUrl!);

export interface IUserTransferResult {
  userFiles: number;
  orders: number;
  programs: number;
  userScopes: number;
  licenseOverrides: number;
}

export interface IUserUsagesResult {
  userFiles: number;
  orders: number;
  programs: number;
  userScopes: number;
  licenses: number;
  licenseOverrides: number;
}

export const findUsages = async (
  userId: string,
  onSuccess: (result: IUserUsagesResult) => void,
  errorMessage: (msg: string) => void
) => {
  const url = `users/usages/${userId}`;
  await processApiRequest(baseApi.get(url), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const transferUser = async (
  fromUser: string,
  toUser: string,
  onSuccess: (result: IUserTransferResult) => void,
  errorMessage: (msg: string) => void
) => {
  const url = `users/${fromUser}?transferToUserId=${toUser}`;
  await processApiRequest(baseApi.put(url), {
    onSuccess: (response) => onSuccess(response.data.changes),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};
