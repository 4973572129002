import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  products: string;
  services: string;
  themeDark: string;
  themeLight: string;
  login: string;
  logout: string;
  accountMyProducts: string;
  accountMyOrders: string;
  accountMyLicenses: string;
  accountDetails: string;
  switchToDarkMode: string;
  switchToLightMode: string;
  language: string;
  users: string;
  contactUs: string;
  companyId: string;
  companyAddress: string;
  contactNumbers: string;
  phoneUk: string;
  phoneMex: string;
  privacyPolicy: string;
  termsAndConditions: string;
  frequentQuestions: string;
  faqLicensing: string;
  faqPurchasing: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    products: "Products",
    services: "Services",
    themeDark: "Dark mode",
    themeLight: "Light mode",
    switchToDarkMode: "Switch to dark mode",
    switchToLightMode: "Switch to light mode",
    language: "Language",
    login: "Login",
    logout: "Logout",
    accountMyProducts: "My Products",
    accountMyOrders: "My Orders",
    accountMyLicenses: "My Licenses",
    accountDetails: "Account details",
    users: "Users",
    contactUs: "Contact us",
    companyId:
      "Softion Solutions Ltd. is a limited company registered in England and Wales.  Registered Office: THE SIDINGS, VICTORIA AVE, SWANAGE, DORSET, UK, BH19 1AU",
    companyAddress: "",
    contactNumbers: "Contact",
    phoneUk: "London, UK (+44)7498 220 318",
    phoneMex: "Zapopan, Mexico (+52)33 3634 9050",
    privacyPolicy: "Privacy Policy",
    termsAndConditions: "Terms and Conditions",
    frequentQuestions: "Frequent Questions",
    faqLicensing: "Licensing Options",
    faqPurchasing: "Purchasing Options",
  },
  es: {
    products: "Productos",
    services: "Servicios",
    themeDark: "Modo obscuro",
    themeLight: "Modo claro",
    switchToDarkMode: "Cambiar a modo obscuro",
    switchToLightMode: "Cambiar a modo claro",
    language: "Idioma",
    login: "Iniciar sesión",
    logout: "Cerrar sesión",
    accountMyProducts: "Mis Productos",
    accountMyOrders: "Mis Ordenes",
    accountMyLicenses: "Mis Licencias",
    accountDetails: "Mi cuenta",
    users: "Usuarios",
    contactUs: "Contacto",
    companyId:
      "Softion Solutions Ltd. es una empresa registrada en Inglaterra y Wales en Reino Unido.  Nuestra oficina se encuentra registrada en THE SIDINGS, VICTORIA AVE, SWANAGE, DORSET, UK, BH19 1AU",
    companyAddress: "",
    contactNumbers: "Contacto",
    phoneUk: "Londres, Reino Unido (+44)7498 220 318",
    phoneMex: "Zapopan, México (+52)33 3634 9050",
    privacyPolicy: "Aviso de Privacidad",
    termsAndConditions: "Términos y condiciones",
    frequentQuestions: "Preguntas Frecuentes",
    faqLicensing: "Opciones de Licenciamiento",
    faqPurchasing: "Opciones de Compra",
  },
});
