export const URL_HOME = "/";
export const URL_MYPURCHASES = "/my-account/my-purchases";
export const URL_PRIVACY_POLICY = "/legal/privacypolicy";
export const URL_TERMS_AND_CONDITIONS = "/legal/termsandconditions";
export const URL_PROJECTS = "/admin/projects";
export const URL_USERS = "/admin/users";
export const URL_FILE_EXPLORER = "/admin/files";
export const URL_PRODUCTS_LISTING = "/admin/products";
export const URL_FAQ_PURCHASING = "/faq/purchasingFaq";
export const URL_FORGOT_PASSWORD = "/forgot-password";
export const URL_MYLICENSES = "/my-account/licenses";
export const URL_WIKI = "/wiki";
export const URL_DOCUMENTS = "/documents";
export const URL_PRODS_WEIGHBRIDGE = "/products/weighbridge";
export const URL_PRODUCT_TRIAL = "/product-trial";

const URL_FILES = "/my-account/files";
export const getFileUrl = (fileId: number, container?: string) => {
  if (container) {
    return `${URL_FILES}/${fileId}/${container}`;
  }
  return `${URL_FILES}/${fileId}`;
};

const URL_DOWNLOADS = "/my-account/my-products";
export const getMyDownloads = (userId: string, programId?: string) => {
  if (programId) {
    const url = `${URL_DOWNLOADS}/${programId}`;
    if (userId) {
      return url + "/" + userId;
    }
    return url;
  }
  return `${URL_DOWNLOADS}/${userId}`;
};

export const getProjectLicensesUrl = (
  programId: string,
  productId: string,
  userId: string,
  productName: string
) => {
  const requestData = {
    programId: programId,
    productId: productId,
    userId: userId,
    productName: productName,
  }; // `${programId};${productId};${userId};${productName}`;
  return `${URL_MYLICENSES}/${btoa(JSON.stringify(requestData))}`;
};

const URL_PROJECT = "/admin/project";
export const getProjectUrl = (programId: string) => {
  return `${URL_PROJECT}/${programId}`;
};
