import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import {
  DataTypeProvider,
  SortingState,
  SearchState,
  FilteringState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  SearchPanel,
  TableColumnVisibility,
  Toolbar,
} from "@devexpress/dx-react-grid-material-ui";

import Moment from "react-moment";
import { IPurchaseOrderWithDetails } from "../../../api/Account/myOrdersApi";
import IResources, { resources } from "./.resources";
import { IUserV0 } from "../../../api/Account/usersApi";
import { ILoggedUser } from "../../../types/Auth";
import * as urls from "../../../types/Constants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

//https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/

export interface IPurchaseOrderRow extends IPurchaseOrderWithDetails {
  id: string;
  ownerName?: string;
  ownerCompany?: string;
  endUserName?: string;
  endUserCompany?: string;
  singleProductOrder: string;
  index: number;
  hasChildren: boolean;
  parentId?: string;
}

export interface IMyPurchasesGridProps {
  orders: IPurchaseOrderRow[];
  user: IUserV0;
  treeView: boolean;
  loggedUser: ILoggedUser;
  handleGoToUrl: (msg: string) => void;
  //handleNotification: (msg: string | null | undefined) => void;
}

const MyPurchasesGrid: React.FC<IMyPurchasesGridProps> = ({
  orders,
  user,
  handleGoToUrl,
}) => {
  const res: IResources = resources;

  const [hiddenCustomerColumnNames] = useState(["date", "singleProductOrder"]);
  const [hiddenDistColumnNames] = useState([
    "date",
    "singleProductOrder",
    "endUserCompany",
    "endUserName",
    "ownerName",
    "licenses",
  ]);

  const [columnsCustomer] = useState([
    { name: "orderId", title: res.orderId },
    { name: "date", title: res.date },
    { name: "singleProductOrder", title: res.product },
  ]);

  const [columnsDist] = useState([
    { name: "orderId", title: res.orderId },
    { name: "date", title: res.date },
    { name: "endUserName", title: res.customerName },
    { name: "endUserCompany", title: res.companyName },
    { name: "ownerName", title: res.representedBy },
    { name: "singleProductOrder", title: res.product },
    { name: "licenses", title: "" },
  ]);

  const useStyles = makeStyles(() => ({
    acordionContainer: {
      width: "100%",
      margin: 0,
      padding: 0,
    },
    productLink: {
      wordWrap: "break-word",
      whiteSpace: "normal",
      textAlign: "center",
      width: "100%",
      height: "auto",
      margin: "12px 0 12px 0",
    },
    companyText: {
      wordWrap: "break-word",
      whiteSpace: "normal",
      maxWidth: "100%",
    },
  }));
  const styles = useStyles();

  const [orderIdColumn] = useState(["orderId"]);
  const OrderIdColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    return (
      <div className={styles.acordionContainer}>
        <Accordion>
          <AccordionSummary
            style={{ width: "100%" }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Grid container direction="column" className={styles.companyText}>
              <Typography variant="h6">{row.orderId}</Typography>
              <Typography variant="caption">{row.endUserCompany}</Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <div></div>
            <Divider />
            <Grid container direction="column">
              <Grid item container direction="row" alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="body1" style={{ marginRight: 12 }}>
                    {res.date}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">
                    <Moment format="DD/MM/YYYY">{row.date}</Moment>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body1" style={{ marginRight: 12 }}>
                    {res.customerName}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{row.endUserName}</Typography>
                </Grid>

                {row.details.map((program) => {
                  const productUrl = urls.getMyDownloads(
                    row?.endUserId || "",
                    program.programId
                  );
                  const licensesUrl = urls.getProjectLicensesUrl(
                    program.programId,
                    program.productId,
                    row.endUserId,
                    program.productName
                  );
                  return (
                    <>
                      <Grid item xs={4}>
                        <Typography variant="body1" style={{ marginRight: 12 }}>
                          {res.product}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Link
                          key={productUrl}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleGoToUrl(productUrl);
                          }}
                          className={styles.productLink}
                          color="primary"
                        >
                          {program.productName}
                        </Link>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body1" style={{ marginRight: 12 }}>
                          {res.licenses}
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Link
                          key={licensesUrl}
                          href={licensesUrl}
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();
                            handleGoToUrl(licensesUrl);
                          }}
                        >
                          {res.showLicenses}
                        </Link>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const [customerOrderIdColumn] = useState(["orderId"]);
  const CustomerOrderIdColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    return (
      <div className={styles.acordionContainer}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container direction="column">
              <Typography variant="h5">{row.orderId}</Typography>
              <Typography variant="body2">
                <Moment format="DD/MM/YYYY">{row.date}</Moment>
              </Typography>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container direction="row" alignItems="center">
              {row.details.map((program) => {
                const productUrl = urls.getMyDownloads(
                  row?.endUserId || "",
                  program.programId
                );
                const licensesUrl = urls.getProjectLicensesUrl(
                  program.programId,
                  program.productId,
                  row.endUserId,
                  program.productName
                );
                return (
                  <>
                    <Grid item xs={4}>
                      <Typography variant="body1" style={{ marginRight: 12 }}>
                        {res.product}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Link
                        key={productUrl}
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleGoToUrl(productUrl);
                        }}
                        className={styles.productLink}
                        color="primary"
                      >
                        {program.productName}
                      </Link>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" style={{ marginRight: 12 }}>
                        {res.licenses}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Link
                        key={licensesUrl}
                        href={licensesUrl}
                        color="primary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleGoToUrl(licensesUrl);
                        }}
                      >
                        {res.showLicenses}
                      </Link>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const renderColumns = () => {
    if (user?.isCustomer) {
      return columnsCustomer;
    }
    return columnsDist;
  };

  return (
    <div id="my-purchases-list">
      <DevExpressGrid rows={orders} columns={renderColumns()}>
        <SearchState />
        <SortingState />
        {user?.isCustomer === true ? (
          <DataTypeProvider
            for={customerOrderIdColumn}
            formatterComponent={CustomerOrderIdColumnRenderer}
          />
        ) : (
          <DataTypeProvider
            for={orderIdColumn}
            formatterComponent={OrderIdColumnRenderer}
          />
        )}

        <FilteringState />
        <IntegratedFiltering />
        <Table />
        <TableHeaderRow showSortingControls />
        <Toolbar />
        {user?.isCustomer === true ? (
          <TableColumnVisibility
            defaultHiddenColumnNames={hiddenCustomerColumnNames}
          />
        ) : (
          <TableColumnVisibility
            defaultHiddenColumnNames={hiddenDistColumnNames}
          />
        )}
        <SearchPanel />
      </DevExpressGrid>
    </div>
  );
};

export default MyPurchasesGrid;
