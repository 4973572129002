import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useFormik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Link from "@material-ui/core/Link";
import { IProduct, IProductRequest } from "../../../../api/Admin/dbProductsApi";
import * as Yup from "yup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography } from "@material-ui/core";

export interface IAdminProductDialogProps {
  row: IProduct;
  open: boolean;
  handleSubmit: (request: IProductRequest, isNewProduct: boolean) => void;
  handleCancel: () => void;
}

const AdminProductDialog: React.FC<IAdminProductDialogProps> = ({
  row,
  open,
  handleSubmit,
  handleCancel,
}) => {
  const [isNewProduct, setIsNewProduct] = useState<boolean>(true);

  const createUuid = () => {
    let dt = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  useEffect(() => {
    const existing = row?.productId && row.productId !== "";
    setIsNewProduct(!existing);
  }, [row]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      productId: row.productId,
      productName: row.productName,
      isCommercial: row.isCommercial,
      productDescription: row.productDescription,
    },
    validationSchema: Yup.object({
      productName: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      await handleSubmit(
        {
          productId: values.productId,
          productName: values.productName,
          isCommercial: values.isCommercial,
          productDescription: values.productDescription,
        },
        isNewProduct
      );
    },
  });

  const generateGuid = () => {
    const guid = createUuid();
    formik.setFieldValue("productId", guid.toUpperCase());
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      formControls: {
        padding: "3px 0px 12px 0px",
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      input: {
        verticalAlign: "top",
        marginTop: "0px",
        paddingTop: "0px",
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
  );
  const styles = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="new project"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        {isNewProduct ? "New Product" : row.productName}
      </DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={formik.handleSubmit} className={styles.root}>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              className={styles.formControls}
            >
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <TextField
                    label="Product Guid"
                    name="productId"
                    disabled={row.productId !== ""}
                    type="text"
                    value={formik.values.productId}
                    onChange={handleChange}
                  />
                  {isNewProduct && (
                    <FormHelperText>
                      <Link href="#" onClick={() => generateGuid()}>
                        Generate Guid
                      </Link>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Name"
                    name="productName"
                    type="text"
                    value={formik.values.productName}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={5}>
                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isCommercial}
                        onChange={handleCheckBoxChange}
                        name="isCommercial"
                        color="primary"
                      />
                    }
                    label="Commercial Product"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={7}>
                {formik.values.isCommercial && (
                  <Typography variant="caption">
                    Commercial products are shared across customers, therefore a
                    SUPPORT order and program must be created for each support
                    and admin user that will have access to installations
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Description"
                    name="productDescription"
                    type="text"
                    multiline
                    rows={6}
                    variant="outlined"
                    value={formik.values.productDescription}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container justify="flex-end">
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>Submit</Grid>
                  {formik.isSubmitting && (
                    <Grid item>
                      <CircularProgress size={18} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </form>
        </MuiPickersUtilsProvider>
      </DialogContent>
    </Dialog>
  );
};

export default AdminProductDialog;
