import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  title: string;
  weighbridgeText: string;

  header: string;
  developers: string;
  distributors: string;
  businesses: string;
  developersText: string;
  distributorsText: string;
  businessesText: string;
  stayTunned: string;
  trialVersion: string;
  workEmail: string;
  firstName: string;
  lastName: string;
  companyName: string;
  whoAmI: string;
  iAmIndividual: string;
  iAmCustomer: string;
  iAmDistributor: string;
  iAmDeveloper: string;
  message: string;
  afterRequestText: string;
  submitButton: string;
  goBackButton: string;
  language: string;
  disclaimer: string;
  pleaseContactSupport: string;
  doneText: string;
  tooManyRequests: string;
  fillInTheForm: (days: number) => string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    title: "Products",
    weighbridgeText: "Truck weighing and ticketing system",
    header: "We offer a wide range of weighing products and services",
    developers: "Developers",
    distributors: "Scales businesses",
    businesses: "Small to large businesses",
    developersText:
      "Tools such as Excel plugins and Windows services accessible through an api-service",
    distributorsText:
      "Commercial and personalized software, you offer the scales, we offer the software",
    businessesText:
      "Personalized software for any business where their scales are the heart of their operations",
    stayTunned: "Come back later for specific products and further details",
    trialVersion: "Trial Version",
    workEmail: "Work Email",
    firstName: "First Name",
    lastName: "Last Name",
    companyName: "Company Name",
    whoAmI: "Tell us about yourself",
    iAmIndividual: "Individual looking to automate a process",
    iAmCustomer: "Existing customer looking to download the latest version",
    iAmDistributor:
      "I have a customer and looking to integrate it into their process",
    iAmDeveloper:
      "I am a developer and need some ideas about a similar product like this",
    message: "Any specific requirements?",
    afterRequestText:
      "Thank you for your interest.  Check your inbox in a few minutes to find the download link.",
    submitButton: "Start Trial",
    goBackButton: "Go Back",
    language: "en",
    pleaseContactSupport:
      "Please try again later or contact support at support@softion.com",
    doneText: "Email sent",
    tooManyRequests: "Please try again in one minute",
    disclaimer:
      "When you download a product, you confirm that you have read our Terms of Use and privacy statement. Privacy and data protection is important. With this form here, you will be able to make choices as to how Softion Solutions Ltd may contact you. In summary, only product information will be sent by email and we ensure your data is safe and you will not receive any marketing emails",
    fillInTheForm: (days) =>
      `Please fill in the form to get your free ${days}-day trial license`,
  },
  es: {
    title: "Productos",
    weighbridgeText: "Sistema de pesaje de camiones con impresión de etiquetas",
    header: "Ofrecemos una amplia variedad de productos para el pesaje",
    developers: "Desarrolladores",
    distributors: "Negocios de basculas",
    businesses: "Pequeñas y grandes empresas",
    developersText:
      "Herramientas como Excel Plugins y servicios de windows accessibles mediante una interface api",
    distributorsText:
      "Productos comerciales y personalizados, ustedes ofrecen las baculas y nosotros el desarrollo",
    businessesText:
      "Software personalizado para cualquier negocio que tenga operaciones con pesaje",
    stayTunned:
      "Regrese pronto para ver mas productos especificos y sus detalles",
    trialVersion: "Version de Prueba",
    workEmail: "Correo de su Empresa",
    firstName: "Nombre",
    lastName: "Apellidos",
    companyName: "Nombre de su Empresa",
    whoAmI: "Platiquenos de usted",
    iAmIndividual: "Busco automatizar un proceso en mi empresa",
    iAmCustomer: "Cliente existente buscando la ultima version del producto",
    iAmDistributor: "Quiero integrar el software con un cliente",
    iAmDeveloper:
      "Soy un desarrollador buscando ideas para un producto similar a este",
    message: "Algún requerimiento en particular?",
    afterRequestText:
      "Muchas gracias por su interes en nuestros productos. Revise su bandeja de entrada en unos minutos para encontrar el link de descarga de su producto",
    submitButton: "Enviar solicitud",
    goBackButton: "Regresar",
    language: "es",
    pleaseContactSupport:
      "Por favor intente mas tarde o contacte nuestro soporte tecnico en support@softion.com",
    doneText: "Correo enviado",
    tooManyRequests: "Por favor intente de nuevo en un minuto",
    disclaimer:
      "Al descargar un producto, acepta que ha leido nuestros terminos y condiciones y el anuncio de privacidad.  La privacidad y proteccion de datos es importante, con este formulario elige como Softion Solutions Ltd lo contactara en un futuro.  Usted recibirá informacion del producto y no usaremos su correo para objetivos de marketing",
    fillInTheForm: (days) =>
      `Por favor llene los siguientes datos para descargar su programa con ${days} dias de evaluación`,
  },
});
