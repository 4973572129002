import {
  Backdrop,
  CircularProgress,
  Typography,
  Divider,
  Grid,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import ReactMarkdownWithHtml from "react-markdown/with-html";
import * as gfm from "remark-gfm";
import Moment from "react-moment";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Document, Page } from "react-pdf";
import IResources, { resources } from "./.resources";
import { SizeMe } from "react-sizeme";
import { pdfjs } from "react-pdf";
import MarkdownPreview from "@uiw/react-markdown-preview";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface IWikiRendererProps {
  title?: string;
  url?: string;
  date?: Date;
  data?: string;
  isLoading: boolean;
}

const WikiRenderer: React.FC<IWikiRendererProps> = ({
  title,
  url,
  date,
  data,
  isLoading,
}) => {
  const [hasContent, setHasContent] = useState<boolean>(false);
  const [isPdf, setIsPdf] = useState<boolean>(false);
  const [isMarkdown, setIsMarkdown] = useState<boolean>(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfPages, setPdfPages] = useState<number[]>([]);

  const res: IResources = resources;

  useEffect(() => {
    setHasContent(Boolean(data));
  }, [data]);

  useEffect(() => {
    setIsMarkdown(false);
    setIsPdf(false);
    if (url) {
      const extension = url.split(".").pop();
      if (extension === "pdf") {
        setIsPdf(true);
      } else if (extension === "md") {
        setIsMarkdown(true);
      }
    }
    setHasContent(Boolean(data));
  }, [url]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPdfPages(Array.from(Array(numPages).keys()));
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      divider: {
        margin: "32px",
      },
    })
  );
  const styles = useStyles();

  return (
    <>
      {hasContent && (
        <>
          <Typography variant="overline">
            <Moment format="DD/MMM/YYYY">{date}</Moment>
          </Typography>

          <Divider variant="middle" />
        </>
      )}

      <div className={styles.divider} />
      <Backdrop className={styles.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {isLoading === false && isMarkdown && <MarkdownPreview source={data} />}
      {isLoading === false && isPdf && (
        <SizeMe>
          {({ size }) => (
            <>
              <div style={{ border: "1px solid black " }}>
                <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    pageNumber={pageNumber}
                    width={size.width ? size.width - 64 : 1}
                  />
                </Document>
              </div>
              <Grid container>
                <Grid item>
                  <Typography variant="subtitle1">{res.page}</Typography>
                  <ButtonGroup variant="text" size="small">
                    {pdfPages.map((n, index) => {
                      return (
                        <Button
                          key={n + 1}
                          onClick={() => setPageNumber(n + 1)}
                          variant={n === pageNumber - 1 ? "contained" : "text"}
                          color={n === pageNumber - 1 ? "primary" : "default"}
                        >
                          {n + 1}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </Grid>
              </Grid>
            </>
          )}
        </SizeMe>
      )}
    </>
  );
};

export default WikiRenderer;
