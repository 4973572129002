/* eslint-disable */
import { AxiosRequestConfig } from "axios";
import { setupAxios, processApiRequest } from "../apiHelper";

//SETUP
const baseUrl = process.env.PROJECTS_API;
const baseApi = setupAxios(baseUrl!);

export interface IBlob {
  fileId: number;
  displayName: string;
  category?: string;
  url: string;
  version?: string;
  language?: string;
  platform?: string;
  description?: string;
  releaseNotes?: string;
  date?: Date;
  size?: number;
  isActive: boolean;
}

export interface IBlobWithLinks {
  fileId: number;
  displayName: string;
  category?: string;
  url: string;
  version?: string;
  language?: string;
  platform?: string;
  description?: string;
  date?: Date;
  size?: number;
  isActive: boolean;
  isInUse: boolean;
  products: string[];
  users: string[];
  usageType?: string;
}

export interface IBlobListing {
  fileId: number;
  fileName: string;
  displayName: string;
  category?: string;
  url: string;
  version?: string;
  language?: string;
  platform?: string;
  description?: string;
  date?: Date;
  size?: number;
  isActive: boolean;
  isInUse: boolean;
  products: string[];
  productCount: number;
  users: string[];
  userCount: number;
  usageType?: string;
}

export interface ILinkUserFileRequest {
  keepExisting: boolean;
  userIds: string[];
}

export interface ILinkProductFileRequest {
  keepExisting: boolean;
  productIds: string[];
}

export const getBlobs = async (
  onSuccess: (result: IBlobWithLinks[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "files";
  await processApiRequest(baseApi.get(url), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const addOrUpdateBlob = async (
  file: IBlob,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "files";
  await processApiRequest(baseApi.post<IBlob>(url, file), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const deleteBlob = async (
  fileId: number,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "files/" + fileId;
  await processApiRequest(baseApi.delete(url), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const findBlob = async (
  fileUrl: string,
  userId: string | undefined,
  onSuccess: (file: IBlob) => void,
  onNotAuthorized: () => void,
  onNotFound: () => void,
  errorMessage: (msg: string) => void
) => {
  let url = "files/" + encodeURIComponent(fileUrl);
  if (userId) {
    url = url + `?viewAs=${userId}`;
  }
  await processApiRequest(baseApi.get<IBlob>(url), {
    onSuccess: (response) => onSuccess(response.data),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => onNotFound(),
    forbidden: (msg) => onNotAuthorized(),
  });
};

export const findUserFiles = async (
  userId: string,
  onSuccess: (fileIds: number[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = `files/user/${userId}`;
  await processApiRequest(baseApi.get<number[]>(url), {
    onSuccess: (response) => onSuccess(response.data),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

// USERS

export const linkFileUsers = async (
  fileId: number,
  userIds: string[],
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `files/${fileId}/users`;
  const request: ILinkUserFileRequest = {
    keepExisting: false,
    userIds: userIds,
  };
  await processApiRequest(baseApi.post<ILinkUserFileRequest>(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const unlinkFileUsers = async (
  fileId: number,
  userIds: string[],
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `files/${fileId}/users`;
  const config: AxiosRequestConfig = {
    data: JSON.stringify(userIds),
    headers: {
      contentType: "application/json",
    },
  };
  await processApiRequest(baseApi.delete(url, { data: userIds }), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

//PRODUCTS

export const linkProductFiles = async (
  fileId: number,
  productIds: string[],
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `files/${fileId}/products`;
  const request: ILinkProductFileRequest = {
    keepExisting: false,
    productIds: productIds,
  };
  await processApiRequest(baseApi.post<ILinkProductFileRequest>(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const unlinkProductFiles = async (
  fileId: number,
  productIds: string[],
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = `files/${fileId}/products`;
  await processApiRequest(baseApi.delete(url, { data: productIds }), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};
