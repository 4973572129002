/* eslint-disable */
import { setupAxios, processApiRequest, IResponseHandler } from "./apiHelper";

//SETUP
const baseUrl = process.env.B2C_API_URL;
const baseApi = setupAxios(baseUrl!);

export interface IWikiFileData {
  id?: string;
  location: string;
  name: string;
  exists: boolean;
  contentType?: string;
  size?: number;
  date?: Date;
  content?: string;
}

export interface IWikiPage {
  id: string;
  name: string;
  url?: string;
  hasContent: boolean;
  pages: IWikiPage[];
}

export interface IWikiProduct {
  productId: string;
  productName?: string;
  displayName?: string;
  container: string;
  root: IWikiPage;
  isPublic: boolean;
}

export const getPublicListing = (
  productIds: string[],
  scopes: string[],
  language: string,
  section: string,
  onSuccess: (wikiProducts: IWikiProduct[]) => void,
  onError: (err: any) => void
) => {
  const url = "/wiki/public";
  const data = {
    section: section,
    products: productIds,
    language: language,
    scopes: scopes,
  };
  return processApiRequest(baseApi.post<IWikiProduct[]>(url, data), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) => onError(msg),
    forbidden: (msg) => onError("Please login to access this content"),
    serverError: (msg) => onError(msg),
  });
};

export const getWikiListing = (
  productIds: string[],
  scopes: string[],
  section: string,
  language: string,
  onSuccess: (wikiProducts: IWikiProduct[]) => void,
  onError: (err: any) => void
) => {
  const url = "/wiki";
  const data = {
    section: section,
    products: productIds,
    language: language,
    scopes: scopes,
  };
  return processApiRequest(baseApi.post<IWikiProduct[]>(url, data), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) => onError(msg),
    forbidden: (msg) => onError("Please login to access this content"),
    serverError: (msg) => onError(msg),
  });
};

export const getWikiContent = (
  id: string,
  onSuccess: (content: IWikiFileData) => void,
  onNotFound: (content: IWikiFileData) => void,
  onError: (err: any) => void
) => {
  //const url = `/wiki/${btoa(location)}`;
  const url = `/wiki/${id}`;
  return processApiRequest(baseApi.get<IWikiFileData>(url), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) =>
      onNotFound({
        location: "",
        name: "",
        exists: false,
      }),
    forbidden: (msg) => onError(msg),
    serverError: (msg) => onError(msg),
  });
};
