/* eslint-disable  @typescript-eslint/no-explicit-any */
import axios, {
  AxiosPromise,
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import authService from "../auth/authService";
import { b2cScopes } from "../auth/authHelper";
import browserHistory from "../helpers/browserHistory";
import { trackTrace, trackErrorMessage } from "../services/AppInsightsProvider";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

//For Azure AD use generateToken = async r => adalGetToken(new AuthenticationContext(adalConfig), adalConfig.clientId).then(token) => { r.headers.Authorization = "Bearer {token}"; return r; }

const generateToken = async (r: AxiosRequestConfig) => {
  const token = await authService.fetchAccessToken([...b2cScopes.LOGIN.scopes]); // localStorage.getItem("token");
  if (token) {
    if (!r.headers) {
      r.headers = {};
    }
    r.headers["Authorization"] = "Bearer " + token;
  }
  return r;
};

export interface IResponseHandler {
  onSuccess?: (response: AxiosResponse<any>) => void;
  badRequest?: (msg: string) => void;
  tooManyRequests?: () => void;
  notFound?: (msg: string) => void;
  forbidden?: (msg: string) => void;
  serverError?: (msg: string) => void;
  errorTitle?: string | null;
}

export const setupAxios = (url: string): AxiosInstance => {
  const baseApi = axios.create({
    baseURL: url,
  });
  baseApi.interceptors.request.use(generateToken);

  //baseApi.interceptors.response.use((response) => response, errorResponseHandler);
  axios.defaults.headers.post["Content-Type"] = "application/json";
  return baseApi;
};

export const errorRedirect = (
  errorTitle: string | null | undefined,
  errorCode: number | null,
  errorOrMessage: any
) => {
  let errorMessage: string | null = "";
  if (errorOrMessage) {
    if (errorOrMessage.response) {
      errorMessage = errorOrMessage.toJSON();
    } else if (typeof errorOrMessage === "string") {
      errorMessage = errorOrMessage;
    }
    console.log(errorMessage);
  }

  trackErrorMessage(errorTitle, errorMessage);

  if (process.env.URL_REWRITE_ENABLED === "true") {
    browserHistory.push({
      pathname: "/error",
      state: {
        errorMessage: errorMessage,
        errorTitle: errorTitle,
        errorCode: errorCode,
      },
    });
    browserHistory.go(0);
  }
};

interface IBadRequestResponse {
  status: number;
  title: string;
  traceId: string;
  type: string;
  errors: any;
}

function isPipelineBadRequest(response: any): response is IBadRequestResponse {
  return response.errors;
}

export const processApiResponse = async (
  response: AxiosResponse<any> | Error,
  responseHandling: IResponseHandler
) => {
  if (response) {
    const httpResponse = response as AxiosResponse<any>;
    const error = response as Error;
    let message = httpResponse?.data || "";
    const errorCode: number = httpResponse?.status || 0;
    switch (errorCode) {
      case 0:
        //const err:unknown = response;
        if (error && error.message) {
          message = error.message;
        } else if (httpResponse && httpResponse.data) {
          message = JSON.stringify(httpResponse.data);
        } else {
          message = JSON.stringify(response);
        }
        if (responseHandling.serverError) {
          if (message) {
            trackErrorMessage(
              null,
              `Service is not running as http response was 0: ${message}`
            );
          }
          responseHandling.serverError(message);
          return true;
        } else {
          console.log("0 - Network Error: " + message);
          errorRedirect(responseHandling.errorTitle, errorCode, message);
        }
        break;
      case 200:
      case 201:
      case 202:
        if (responseHandling.onSuccess) {
          responseHandling.onSuccess(httpResponse);
        }
        return true;
      case 400:
        if (httpResponse.data && isPipelineBadRequest(httpResponse.data)) {
          message = "Some data is missing";
        }
        if (responseHandling.badRequest) {
          responseHandling.badRequest(message);
          if (message) {
            trackTrace(`400-Bad Request: ${message}`, SeverityLevel.Warning);
          }
          return true;
        }
        break;
      case 401:
      case 403:
        if (responseHandling.forbidden) {
          responseHandling.forbidden(message);
          return true;
        }
        console.log(errorCode + " - Unauthorized");
        errorRedirect(responseHandling.errorTitle, errorCode, "Unauthorized");
        break;
      case 404:
        if (responseHandling.notFound) {
          if (message) {
            trackTrace(`404-Not Found: ${message}`, SeverityLevel.Warning);
          }
          responseHandling.notFound(message);
          return true;
        }
        console.log("404 - Not found: " + message);
        errorRedirect(responseHandling.errorTitle, errorCode, message);
        break;
      case 429:
        if (responseHandling.tooManyRequests) {
          responseHandling.tooManyRequests();
        } else if (responseHandling.badRequest) {
          responseHandling.badRequest("Try again later");
        }
        break;
      case 500:
        if (responseHandling.serverError) {
          if (message) {
            trackErrorMessage(null, `500-Server Error: ${message}`);
          }
          responseHandling.serverError(message);
          return true;
        }
        console.log("500 - Internal Server Error: " + message);
        errorRedirect(responseHandling.errorTitle, errorCode, message);
        break;
    }

    return false;
  }
};

export const processApiRequest = async (
  request: AxiosPromise,
  responseHandling: IResponseHandler
) => {
  try {
    const response = await request;
    processApiResponse(response, responseHandling);
  } catch (error) {
    processApiResponse(
      error && error.response ? error.response : error,
      responseHandling
    );
  }
};

export const validateCaptcha = (value: string, handler: IResponseHandler) => {
  const RECAPTCHA_SERVER_KEY = "6LcW9csbAAAAAIH3E8FhM8KADi3nf50lpZrODTq5"; // process.env.RECAPTCHA_SERVER_KEY

  // Validate Human
  const request = setupAxios("https://www.google.com/recaptcha");
  const config: any = {
    errorHandle: false,
    headers: {
      accept: "application/json",
      contentType: "application/x-www-form-urlencoded; charset=utf-8",
    },
    validateStatus: function (status) {
      return status >= 200 && status < 500;
    },
  };
  const payload = `secret=${RECAPTCHA_SERVER_KEY}&response=${value}`;
  return processApiRequest(
    request.post("/api/siteverify", payload, config),
    handler
  );
};
