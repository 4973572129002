import React, { useRef } from "react";
import { ProjectFile } from "@softion/b2c-service";
import ReactMarkdown from "react-markdown";
import Button from "@material-ui/core/Button";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Moment from "react-moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import prettyBytes from "pretty-bytes";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import IResources from "./.resources";

export interface IFileInformationDialogProps {
  open: boolean;
  file: ProjectFile;
  resources: IResources;
  onMessage: (message: string | null | undefined) => void;
  onClose: () => void;
}

const FileInformationDialog: React.FC<IFileInformationDialogProps> = ({
  open,
  file,
  resources,
  onMessage,
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  const themedStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
      },
      chipsContainer: {
        display: "flex",
        justifyContent: "left",
        flexWrap: "wrap",
        "& > *": {
          margin: theme.spacing(0.5),
        },
      },
    })
  );
  const useThemedStyles = themedStyles();

  const anchorRef = useRef<HTMLDivElement>(null);
  const [openDropDown, setOpenDropDown] = React.useState(false);
  // const [copiedToClipboard, setCopiedToClipboard] = React.useState(false);

  const shareToEmail = () => {
    const body = "Descarga: " + file.url + "\r\n\r\n" + file.releaseNotes;
    const mailTo =
      "mailto:me@me.com?subject=" +
      encodeURI(file.name || "") +
      "&body=" +
      encodeURI(body);
    window.location.href = mailTo;
    setOpenDropDown(false);
  };

  const shareToClipboard = () => {
    navigator.clipboard.writeText(file.url || "").then(() => {
      onMessage("Copied to clipboard: " + file.url);
    });
    setOpenDropDown(false);
  };

  return (
    <div>
      {file ? (
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth={true}
          maxWidth="lg"
          aria-labelledby="confirmation-dialog-title"
          open={open}
          keepMounted
        >
          <DialogTitle id="confirmation-dialog-title">
            <Grid container spacing={2}>
              <Grid item>
                <Avatar aria-label="user">
                  {(file.displayName || " ").substring(0, 1)}
                </Avatar>
              </Grid>
              <Grid item>
                <Grid container>{file.displayName}</Grid>
                <Grid container>
                  <Typography variant="subtitle1">{file.productId}</Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                    >
                      {file.date ? (
                        <Moment format="DD/MM/YYYY">{file.date}</Moment>
                      ) : (
                        "Date: n/a"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                    >
                      {file.size ? prettyBytes(file.size) : "Size: n/a"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {file.version && (
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                      >
                        ver {file.version}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <div className={useThemedStyles.chipsContainer}>
                  {file.platform ? (
                    <Chip color="primary" label={file.platform} />
                  ) : null}
                  {file.language ? (
                    <Chip color="secondary" label={file.language} />
                  ) : null}
                </div>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <div>
              <Grid container className={useThemedStyles.root}>
                <Grid item xs={12}>
                  <div>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                    >
                      {file.description}
                    </Typography>
                    {file.releaseNotes ? (
                      <div>
                        <Divider />
                        <Typography variant="h5" gutterBottom component="div">
                          {resources.fileReleaseNotes}
                        </Typography>
                        <Typography
                          variant="body1"
                          gutterBottom
                          component="div"
                        >
                          <ReactMarkdown
                            source={file.releaseNotes}
                            escapeHtml={false}
                          />
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{resources.closeDialog}</Button>
            {file.isActive || false ? (
              <div>
                <ButtonGroup
                  variant="contained"
                  color="secondary"
                  ref={anchorRef}
                  aria-label="split button"
                >
                  <Button size="small">{resources.fileShare}</Button>
                  <Button
                    color="secondary"
                    size="small"
                    aria-controls={
                      openDropDown ? "split-button-menu" : undefined
                    }
                    aria-expanded={openDropDown ? "true" : undefined}
                    aria-label="share link"
                    aria-haspopup="menu"
                    onClick={() => setOpenDropDown((prevOpen) => !prevOpen)}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={openDropDown}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener
                          onClickAway={() => setOpenDropDown(false)}
                        >
                          <MenuList id="split-button-menu">
                            <MenuItem onClick={() => shareToEmail()}>
                              {resources.shareByEmail}
                            </MenuItem>
                            <MenuItem onClick={() => shareToClipboard()}>
                              {resources.copyToClipboard}
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            ) : null}
            {file.isActive || false ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                href={file.url}
                target="_blank"
                onClick={handleClose}
              >
                {resources.fileDownload}
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      ) : null}
      {/* <Snackbar
        open={copiedToClipboard}
        autoHideDuration={6000}
        onClose={() => setCopiedToClipboard(false)}
      >
        <Alert onClose={() => setCopiedToClipboard(false)} severity="success">
          Copied to clipboard
        </Alert>
      </Snackbar> */}
    </div>
  );
};

export default FileInformationDialog;
