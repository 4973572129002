export interface ISession {
  user: string | null;
  token: string | null;
  accessToken: string | null;
  objectId: string | null;
  loggedIn: boolean;
}

export const noSession = (): ISession => ({
  user: null,
  token: null,
  accessToken: null,
  objectId: null,
  loggedIn: false,
});

export interface IToken {
  token: string | null;
  isExpired?: boolean | null;
  expirationTime?: Date | null;
  exp?: number | null;
}

export const noToken = (): IToken => ({
  token: null,
  isExpired: null,
  expirationTime: null,
  exp: null,
});

export interface ILoggedUser {
  userId: string | null;
  isAdmin: boolean;
  isCustomer: boolean;
  isDistributor: boolean;
  isSupport: boolean;
  fetching: boolean;
  error?: string | null | undefined;
}

export const noLoggedUser = (): ILoggedUser => ({
  userId: null,
  isAdmin: false,
  isCustomer: false,
  isDistributor: false,
  isSupport: false,
  fetching: false,
  error: null,
});

export interface IKeepAlive {
  b2cApi?: Date;
  projApi?: Date;
  regApi?: Date;
}
