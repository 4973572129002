import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { getOrders } from "../../../api/Account/myOrdersApi";
import { RootState } from "../../../redux/reducers/rootReducer";
import { ILoggedUser } from "../../../types/Auth";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import MyPurchasesGrid, { IPurchaseOrderRow } from "./MyPurchasesGrid";
import UserDropDown from "../../admin/_shared/UsersDropDown";
import {
  IUserV0,
  getUsersList,
  processUserFlags,
} from "../../../api/Account/usersApi";
import SearchIcon from "@material-ui/icons/Search";
import IResources, { resources } from "./.resources";
import { useHistory } from "react-router-dom";
import MyPurchasesList from "./MyPurchasesList";
import IconLight from "../../../images/orders/orders.light.128x128.png";
import IconDark from "../../../images/orders/orders.dark.128x128.png";
import { ThemeOptions } from "../../../types/Enums";

interface IMyPurchasesPageProps {
  themeName: ThemeOptions;
}

const MyPurchasesPage: React.FC<IMyPurchasesPageProps> = ({ themeName }) => {
  const loggedUser: ILoggedUser = useSelector((state: RootState) => {
    return state.userInfo;
  });
  const history = useHistory();
  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const [orders, setOrders] = useState<IPurchaseOrderRow[]>([]);
  const [ordersCompact, setOrdersCompact] = useState<IPurchaseOrderRow[]>([]);
  const [users, setUsers] = useState<IUserV0[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showViewAs, setShowViewAs] = useState<boolean>(false);
  const [viewAsAccount, setViewAsAccount] = useState<string | undefined>();
  const [viewAsUser, setViewAsUser] = useState<IUserV0 | undefined>();
  const [useTreeView, setUseTreeView] = useState<boolean>(false);

  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const refreshAsync = async (userId: string | undefined) => {
    if (userId) {
      if (loggedUser.isAdmin && userId === loggedUser.userId) {
        const requestor = users.find((u) => u.userId === userId);
        setViewAsUser(requestor);
        setOrders([]);
      } else {
        setIsLoading(true);
        await getOrders(
          userId,
          (result) => {
            const visibleUsers = result.users.map((u) => processUserFlags(u));
            const viewAs = visibleUsers.find((u) => u.userId === userId);
            setViewAsUser(viewAs);

            const orderRows: IPurchaseOrderRow[] = []; //allow parent/child rows for browser view
            const compactOrderRows: IPurchaseOrderRow[] = []; //single rows for compact view

            result.orders.forEach((x) => {
              const owner = visibleUsers.find((u) => u.userId === x.ownerId);
              const endUser = visibleUsers.find(
                (u) => u.userId === x.endUserId
              );

              const masterRow: IPurchaseOrderRow = {
                ...x,
                id: x.orderId,
                ownerName: owner?.name || "?",
                ownerCompany: owner?.companyName,
                endUserName: endUser?.name || "?",
                endUserCompany: endUser?.companyName,
                index: 0,
                singleProductOrder: "",
                hasChildren: false,
              };
              if (x.details.length > 0 && x.details[0].programName) {
                masterRow.singleProductOrder = x.details[0].programName;
              }
              compactOrderRows.push(masterRow);

              if (x.details.length == 1) {
                //SINGLE ROW
                const singleRowOrder: IPurchaseOrderRow = {
                  ...masterRow,
                };
                orderRows.push(singleRowOrder);
              } else {
                //DROP DOWN CHILDREN
                const multiRowParentOrder: IPurchaseOrderRow = {
                  ...masterRow,
                  id: x.orderId,
                  index: 0,
                  singleProductOrder: "",
                  hasChildren: true,
                };
                orderRows.push(multiRowParentOrder);
                x.details.forEach((p, index) => {
                  const multiRowChildOrder: IPurchaseOrderRow = {
                    ...x,
                    id: `${x.orderId}-${index}`,
                    orderId: "",
                    index: index,
                    ownerName: owner?.name || "?",
                    ownerCompany: owner?.companyName,
                    endUserName: endUser?.name || "?",
                    endUserCompany: endUser?.companyName,
                    singleProductOrder: p.programName || "?",
                    hasChildren: false,
                    parentId: x.orderId,
                  };
                  orderRows.push(multiRowChildOrder);
                });
              }
            });
            setUseTreeView(Boolean(orderRows.find((o) => o.hasChildren)));
            setOrders(orderRows);
            setOrdersCompact(compactOrderRows);
            setIsLoading(false);
          },
          (error) => {
            showError(error);
            setIsLoading(false);
          }
        );
      }
    }
  };
  const refreshUsersAsync = async () => {
    await getUsersList(
      (response) => {
        const orderedUsers: IUserV0[] = response.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        setUsers([...orderedUsers]);
      },
      (err) => showError(err)
    );
  };

  useEffect(() => {
    if (loggedUser?.userId) {
      if (loggedUser.isAdmin) {
        refreshUsersAsync();
      } else {
        refreshAsync(loggedUser.userId);
      }
    }
  }, [loggedUser]);

  useEffect(() => {
    refreshAsync(loggedUser?.userId || "");
  }, [users]);

  const useStyles = makeStyles((theme: Theme) => ({
    topContainer: { paddingTop: "24px" },
    header: {
      padding: "0px 6px 0px 32px",
    },
    newFileButton: {
      width: "148px",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    grid: {
      padding: "0px 32px 0px 32px",
      height: "100%",
    },
    gridMobile: {
      padding: "0px",
      height: "100%",
    },
    searchButton: {
      width: "32px",
      height: "32px",
    },
    titleIcon: {
      width: "48px",
      height: "48px",
      margin: "2px 12px 2px 2px",
    },
  }));
  const styles = useStyles();

  const goToUrl = (url: string) => {
    //productId#
    if (url) {
      history.push(url);
    }
  };

  const ViewAsControl = () => {
    return (
      <Grid container>
        <div>
          {showViewAs ? (
            <Grid
              item
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              style={{ minWidth: "292px" }}
              sm={4}
              md={8}
              lg={12}
            >
              <Grid item xs={10}>
                <UserDropDown
                  name="userId"
                  label="View As"
                  users={users}
                  value={viewAsAccount}
                  enabled={true}
                  allowEmpty={true}
                  allowShowId={true}
                  onChange={(e) => setViewAsAccount(e.target.value)}
                  touched={false}
                  error={undefined}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  type="submit"
                  className={styles.searchButton}
                  aria-label="search"
                  onClick={() => refreshAsync(viewAsAccount || "")}
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowViewAs(true)}
            >
              {res.viewAs}
            </Button>
          )}
        </div>
      </Grid>
    );
  };

  return (
    <Container className={styles.topContainer} maxWidth="lg">
      <Grid
        container
        className={styles.header}
        alignItems="flex-start"
        justify="flex-start"
        direction="column"
      >
        <Grid
          item
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item>
            <img
              src={themeName === "dark" ? IconDark : IconLight}
              className={styles.titleIcon}
            />
          </Grid>
          <Grid item>
            <Typography variant="h4">
              {viewAsUser?.isCustomer ? res.myPurchases : res.myOrders}
            </Typography>
            {loggedUser.isCustomer ? (
              <Typography variant="subtitle1" gutterBottom>
                {res.introCustomer}
              </Typography>
            ) : null}
            {(loggedUser.isDistributor || loggedUser.isSupport) && (
              <Typography variant="subtitle1" gutterBottom>
                {res.introDistributor}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Grid item container>
          {loggedUser.isAdmin === true && <ViewAsControl />}
        </Grid>
      </Grid>

      <Backdrop className={styles.backdrop} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ToastContainer />
      {isLoading === false && (
        <>
          <Hidden smDown>
            <Grid container>
              <Grid item className={styles.grid}>
                {orders && viewAsUser && (
                  <MyPurchasesGrid
                    orders={orders}
                    user={viewAsUser}
                    treeView={useTreeView}
                    loggedUser={loggedUser}
                    handleGoToUrl={goToUrl}
                  />
                )}
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid container>
              <Grid item className={styles.gridMobile}>
                {ordersCompact && viewAsUser && (
                  <MyPurchasesList
                    orders={ordersCompact}
                    user={viewAsUser}
                    treeView={useTreeView}
                    loggedUser={loggedUser}
                    handleGoToUrl={goToUrl}
                  />
                )}
              </Grid>
            </Grid>
          </Hidden>
        </>
      )}
    </Container>
  );
};

export default MyPurchasesPage;
