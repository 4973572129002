export const lightTheme = {
  palette: {
    type: "light",

    primary: {
      //main: "#7845a1", //purple
      //main: "#c85811", //softion logo orange
      main: "#7845a1",
    },
    // secondary: {
    //   //main: "#e2d5cc",  //beige/grey
    //   //main: "#f1e09e",  //beige/yellow
    //   //main: "#ebd4c2",  //beige
    //   //main: "#cc0099", //purple 2
    //   //main: "#cb0f10", //softion logo red
    //   //main: "#a8abad", //softion logo dark gray
    // },
  },
  typography: {
    h1: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 300,
      fontSize: "3.75rem",
      lineHeight: 1.167,
      letterSpacing: "-0.01562em",
      color: "black",
    },
    h2: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "3rem",
      lineHeight: 1.2,
      color: "black", //"#7845a1",
    },
    h3: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: 1.167,
      letterSpacing: "0em",
      color: "#7845a1",
    },
    h4: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "2rem",
      lineHeight: 1.235,
      letterSpacing: "0.00735em",
      color: "black",
    },
    h5: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: 1.334,
      letterSpacing: "0em",
      color: "black",
    },
    h6: {
      fontFamily: "Roboto,Helvetica, Arial, sans-serif",
      fontWeight: 200,
      fontSize: "1.25rem",
      lineHeight: 1.6,
      letterSpacing: "0.0075em",
      color: "black",
    },
  },
};
