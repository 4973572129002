import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Grid from "@material-ui/core/Grid";
import { IProduct } from "../../../api/Admin/dbProductsApi";

//https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/

export interface IAdminProductCardProps {
  selection?: IProduct;
  closeCard: () => void;
  displayInfoMessage: (text: string | null | undefined) => void;
  handleEdit: () => void;
  handleDelete: () => void;
}

const AdminProductCard: React.FC<IAdminProductCardProps> = ({
  selection,
  closeCard,
  displayInfoMessage,
  handleEdit,
  handleDelete,
}) => {
  const cardStyles = makeStyles({
    card: {
      margin: "32px",
    },
    copyButton: {
      margin: "0px 2px 0px 6px",
    },
  });
  const useCardStyles = cardStyles();

  const copyToClipboard = (text: string | null | undefined) => {
    if (text) {
      navigator.clipboard
        .writeText(text || "")
        .then(() => displayInfoMessage("Copied to clipboard: " + text));
    }
  };

  const LabelWithCopyButton = ({ label, copyAction }) => {
    return (
      <Grid container>
        {label ? (
          <React.Fragment>
            <Grid item>
              <Typography gutterBottom variant="overline">
                {label}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={useCardStyles.copyButton}
                size="small"
                aria-label="copy"
                onClick={() => copyAction()}
              >
                <FileCopyIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </React.Fragment>
        ) : null}
      </Grid>
    );
  };

  return (
    <Card className={useCardStyles.card}>
      <CardActionArea>
        {selection ? (
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {selection?.productName}
            </Typography>
            <LabelWithCopyButton
              label={selection?.productId}
              copyAction={() => copyToClipboard(selection?.productId)}
            />

            <Typography variant="body2">
              {selection?.productDescription}
            </Typography>
          </CardContent>
        ) : null}
      </CardActionArea>
      <CardActions>
        <Button size="small" color="secondary" onClick={() => handleDelete()}>
          Delete
        </Button>
        <Button size="small" color="inherit" onClick={() => handleEdit()}>
          Edit
        </Button>
        <Button size="small" color="primary" onClick={() => closeCard()}>
          Close
        </Button>
      </CardActions>
    </Card>
  );
};

export default AdminProductCard;
