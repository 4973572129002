import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  orderId: string;
  date: string;
  viewAs: string;
  customerName: string;
  companyName: string;
  myOrders: string;
  myPurchases: string;
  product: string;
  representedBy: string;
  welcomeTextCustomer: string;
  introCustomer: string;
  introDistributor: string;
  licenses: string;
  showLicenses: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    orderId: "Order",
    date: "Date",
    viewAs: "Mostrar Como",
    customerName: "Contact",
    companyName: "Company",
    myOrders: "Orders",
    myPurchases: "My Purchases",
    product: "Product",
    representedBy: "Represented By",
    welcomeTextCustomer: "Thank you for your purchase",
    introCustomer:
      "The following is a summary of all orders submitted to us directly or through an intermediary",
    introDistributor:
      "The following is a summary of all customer and business partner orders",
    licenses: "Licences",
    showLicenses: "Show Licenses",
  },
  es: {
    orderId: "No. Orden",
    date: "Fecha",
    viewAs: "View As",
    customerName: "Contacto",
    companyName: "Empresa",
    myOrders: "Ordenes",
    myPurchases: "Mis Compras",
    product: "Producto",
    representedBy: "Representado Por",
    welcomeTextCustomer: "Gracias por su compra",
    introCustomer:
      "A continuación podrá encontrar los productos que ha adquirido con nosotros o a través de algún distribuidor",
    introDistributor:
      "A continuación se muestran todas las ordenes de clientes y distribuidores",
    licenses: "Licencias",
    showLicenses: "Ver licencias",
  },
});
