import React from "react";
import { RootState } from "../../redux/reducers/rootReducer";
import { ISession } from "../../types/Auth";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IResources, { resources } from "./.resources";
import { useSelector } from "react-redux";
import { Grid, Paper, Button, Hidden, Typography } from "@material-ui/core";
import HomeImageLight from "../../images/home_page_white.png";
import HomeImageDark from "../../images/home_page_dark.png";
import { ThemeOptions } from "../../types/Enums";
import { useHistory } from "react-router-dom";

export interface IHomePageProps {
  themeName: ThemeOptions;
  session: ISession;
  onLogin(): void;
  onLogout(): void;
}

const HomePage: React.FC<IHomePageProps> = ({
  themeName,
  session,
  onLogin,
  onLogout,
}) => {
  const history = useHistory();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        backgroundColor: theme.palette.background.default,
        margin: "0px",
        padding: "16px",
      },
      headerText: {
        //backgroundColor: "transparent !important" as any,
      },
      headerGrid: {
        width: "85%",
      },
      actionButton: {
        margin: "72px 32px 72px 0px",
        width: "142px",
        [theme.breakpoints.down("sm")]: {
          margin: "16px",
          width: "142px",
          alignContent: "center",
        },
      },
      homeImage: {
        [theme.breakpoints.down("sm")]: {
          maxWidth: "95%",
          alignContent: "center",
          maxHeight: "95vw",
        },
        [theme.breakpoints.up("md")]: {
          maxHeight: "90%",
          maxWidth: "80%",
          height: "auto",
        },
      },
    })
  );
  const styles = useStyles();

  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const LearnMoreComponent = () => {
    return (
      <Button
        variant="contained"
        color="primary"
        className={styles.actionButton}
        onClick={() => history.push("/services")}
      >
        {res.learnMore}
      </Button>
    );
  };

  const LoginLogoutComponent = () => {
    if (session?.loggedIn !== true) {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={onLogin}
          className={styles.actionButton}
        >
          {res.login}
        </Button>
      );
    } else {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={onLogout}
          className={styles.actionButton}
        >
          {res.logout}
        </Button>
      );
    }
  };

  return (
    <Paper className={styles.paper} elevation={0}>
      <Hidden smDown>
        <Grid container style={{ height: "100%", minHeight: "720px" }}>
          <Grid
            container
            item
            xs={6}
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
          >
            <Grid item className={styles.headerGrid}>
              <Typography variant="h1" className={styles.headerText}>
                {res.header}
              </Typography>
            </Grid>
            <Grid item className={styles.headerGrid}>
              <Typography variant="h6">{res.subHeader}</Typography>
            </Grid>

            <Grid item container spacing={4} className={styles.headerGrid}>
              <LearnMoreComponent />
              <LoginLogoutComponent />
            </Grid>
          </Grid>

          <Grid
            container
            item
            xs={6}
            direction="column"
            spacing={2}
            justify="center"
            alignItems="center"
          >
            <img
              src={themeName === "dark" ? HomeImageDark : HomeImageLight}
              className={styles.homeImage}
            />
          </Grid>
        </Grid>
      </Hidden>

      <Hidden mdUp>
        <Grid
          container
          direction="column"
          spacing={2}
          justify="center"
          alignItems="flex-start"
        >
          <Grid item className={styles.headerGrid}>
            <Typography variant="h2" className={styles.headerText}>
              {res.header}
            </Typography>
          </Grid>
          <Grid item className={styles.headerGrid}>
            <Typography variant="body2" className={styles.subHeaderText}>
              {res.subHeader}
            </Typography>
          </Grid>
          <Grid item container justify="center">
            <img
              src={themeName === "dark" ? HomeImageDark : HomeImageLight}
              className={styles.homeImage}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            <LearnMoreComponent />
            <LoginLogoutComponent />
          </Grid>
        </Grid>
      </Hidden>
    </Paper>
  );
};

export default HomePage;
