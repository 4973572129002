import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { IUserProgramsAndLicenses } from "../../../api/Account/myLicensesApi";
import { Avatar, Badge } from "@material-ui/core";

export interface IArchitectureMapProps {
  programAndLicenses: IUserProgramsAndLicenses[];
}
interface IStationInfo {
  key: string;
  id: number;
  name: string;
  programIds: string[];
}

interface ICellData {
  active?: number;
  total?: number;
  value: string;
  isColumnHeader?: boolean;
  stationId?: number;
  isRowHeader?: boolean;
  isStationData?: boolean;
}

const LicensesArchitectureMap: React.FC<IArchitectureMapProps> = ({
  programAndLicenses,
}) => {
  const [rows, setRows] = useState<ICellData[][]>();

  const themedStyles = makeStyles((theme: Theme) =>
    createStyles({
      cell: {
        padding: 4,
        textAlign: "center",
      },
      rowHeader: {
        padding: 4,
        textAlign: "right",
      },
      avatar: {
        width: 26,
        height: 26,
      },
      shape: {
        backgroundColor: theme.palette.primary.main,
        width: 24,
        height: 24,
      },
      shapeCircle: {
        borderRadius: "50%",
      },
    })
  );
  const styles = themedStyles();

  useEffect(() => {
    const stationsList: IStationInfo[] = [];
    let rowIndex = 0;
    const rowsList: ICellData[][] = [];
    const DEFAULTSTATION = ""; // "Licenses";

    programAndLicenses.forEach((x) => {
      for (let i = 0; i <= x.stations - 1; i++) {
        let stationName = "";
        if (x.stations === 1 && x.stationNames.length === 0) {
          stationName = DEFAULTSTATION;
        } else if (i >= x.stationNames.length) {
          stationName = x.stations === 1 ? DEFAULTSTATION : "?";
        } else {
          stationName = x.stationNames[i];
        }
        const station = {
          id: i + 1,
          key: `${i + 1}.${stationName}`,
          name: stationName,
          programIds: [x.programId],
        };
        const existing = stationsList.find((l) => l.key === station.key);
        if (!existing) {
          stationsList.push(station);
        } else if (!existing.programIds.find((pId) => pId === x.programId)) {
          existing.programIds = [...existing.programIds, x.programId];
        }
      }
    });
    const headerRow: ICellData[] = stationsList.map<ICellData>((station) => ({
      isColumnHeader: true,
      stationId: station.id,
      value: station.name,
    }));
    headerRow.unshift({ value: "" });
    rowsList[rowIndex] = headerRow;
    programAndLicenses.forEach((x) => {
      const branches =
        x.branches && x.branches.filter((bf) => Boolean(bf)).length > 0
          ? [...x.branches]
          : [""];
      branches.forEach((b) => {
        rowIndex++;
        let columnIndex = 0;
        const row: ICellData[] = [];
        row[columnIndex] = {
          isRowHeader: true,
          value: `${x.programName} ${b ? "(" + b + ")" : ""}`,
        };
        stationsList.forEach((st) => {
          columnIndex++;
          const location = x.locations.find((loc) => {
            const branchMatch = (loc.branch || "") === (b || "");
            const stationMatch =
              loc.stationId === st.id &&
              Boolean(st.programIds.find((pId) => pId === x.programId));
            return branchMatch && stationMatch;
          });
          if (location) {
            const licenseCount = location.licenses.filter((ll) => ll.isActive)
              .length;
            row[columnIndex] = {
              isStationData: true,
              total: location.licenses.length,
              active: licenseCount,
              value: `${location.licenses.length}`,
            };
          } else {
            row[columnIndex] = { value: "" };
          }
        });
        rowsList[rowIndex] = row;
      });
    });

    setRows(rowsList);
  }, []);

  const LicenseCell = ({ cell }) => {
    const cellData: ICellData = cell;
    if (!cell || (cell.isStationData && !cell?.value)) {
      return <div>&nbsp;</div>;
    }
    return (
      <div>
        {cellData.isColumnHeader && (
          <div className={styles.cell}>
            <span>{`WS ${cellData.stationId}`}</span>
            <br />
            <span>{cellData.value}</span>
          </div>
        )}
        {cellData.isRowHeader && (
          <div className={styles.rowHeader}>{cellData.value}</div>
        )}
        {cellData.isStationData && (
          <div className={styles.cell}>
            <Badge color="secondary" badgeContent={cellData.active}>
              <Avatar className={styles.avatar}>{cellData.value}</Avatar>
            </Badge>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      {rows && (
        <table>
          {rows.map((row, index) => {
            return (
              <tr key={index}>
                {row.map((cell, index) => {
                  return (
                    <td key={index} className={styles.cell}>
                      <LicenseCell cell={cell} />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </table>
      )}
    </div>
  );
};

export default LicensesArchitectureMap;
