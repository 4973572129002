import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  page: string;
  notFoundMessage: string;
  documentation: string;
  product: string;
  document: string;
  manuals: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    page: "Page",
    notFoundMessage:
      "# Not Found\r\n\r\nContent has expired, reload the page and try again",
    documentation: "Documentation",
    product: "Product",
    document: "Document",
    manuals: "Manuals",
  },
  es: {
    page: "Pagina",
    notFoundMessage:
      "# No se encontro el documento\r\n\r\nEl contenido ha expirado, por favor vuelva a cargar la pagina e intente de nuevo",
    documentation: "Documentación",
    product: "Producto",
    document: "Documento",
    manuals: "Manuales",
  },
});
