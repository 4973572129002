/* eslint-disable */
import { AxiosRequestConfig } from "axios";
import { setupAxios, processApiRequest } from "../apiHelper";
import { IUserV0 } from "./usersApi";
import { rememberProjApi } from "../testApi";

//SETUP
const baseUrl = process.env.PROJECTS_API;
const baseApi = setupAxios(baseUrl!);

export interface IPurchaseOrderDetails {
  productId: string;
  productName?: string;
  programId: string;
  programName?: string;
  isCancelled: boolean;
  isComplete: boolean;
}

export interface IPurchaseOrderWithDetails {
  orderId: string;
  orderUserId: string;
  ownerId: string;
  endUserId: string;
  date: Date;
  details: IPurchaseOrderDetails[];
}

interface IMyPurchasesResult {
  orders: IPurchaseOrderWithDetails[];
  users: IUserV0[];
}

export const getOrders = async (
  userId: string,
  onSuccess: (result: IMyPurchasesResult) => void,
  errorMessage: (msg: string) => void
) => {
  const url = `orders/users/${userId}`;
  await processApiRequest(baseApi.get<IMyPurchasesResult>(url), {
    onSuccess: (response) => {
      rememberProjApi();
      onSuccess(response.data);
    },
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};
