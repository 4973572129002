import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import IResources, { resources } from "./.resources";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import LanguageIcon from "@material-ui/icons/Language";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import PersonIcon from "@material-ui/icons/Person";
import Badge from "@material-ui/core/Badge";
import CheckIcon from "@material-ui/icons/Check";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import { ISession, ILoggedUser } from "../../types/Auth";
import { ThemeOptions, LanguageOptions } from "../../types/Enums";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import * as urls from "../../types/Constants";

export interface ISideBarProps {
  onMenuClick(): void;
  onSwitchTheme(name: ThemeOptions): void;
  onSwitchLanguage(name: LanguageOptions): void;
  onLogin(): void;
  onLogout(): void;
  drawerWidth: number;
  session?: ISession;
  loggedUser?: ILoggedUser;
  themeName: "dark" | "light" | undefined;
}

const SideBarMenu: React.FC<ISideBarProps> = ({
  onMenuClick,
  onSwitchTheme,
  onSwitchLanguage,
  onLogin,
  onLogout,
  drawerWidth,
  session,
  loggedUser,
  themeName,
}) => {
  //const [isSideBarOpen, setIsSideBarOpen] = useState(true);

  const [languageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
  const [accountMenuOpen, setAccountMenuOpen] = useState<boolean>(false);
  const [adminMenuOpen, setAdminMenuOpen] = useState<boolean>(false);

  const [userError, setUserError] = useState<boolean>();

  useEffect(() => {
    setUserError(false);
    if (loggedUser?.error) {
      setUserError(true);
    }
  }, [loggedUser]);

  const res: IResources = resources;
  const language: string = useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const switchLanguage = (name: LanguageOptions) => {
    onSwitchLanguage(name);
    onMenuClick();
  };

  const switchTheme = (name: ThemeOptions) => {
    onSwitchTheme(name);
    onMenuClick();
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
      },
      title: {
        flexGrow: 1,
      },
      hide: {
        display: "none",
      },
      menuLink: {
        fontFamily: theme.typography.body1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.text.primary,
      },
      drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-start",
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
      },
      contentShift: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      },
      nested: {
        paddingLeft: theme.spacing(4),
      },
    })
  );
  const classes = useStyles();
  const theme = useTheme();

  const CheckboxForListItem = () => {
    return (
      <ListItemIcon>
        <CheckIcon fontSize="small" />
      </ListItemIcon>
    );
  };

  return (
    <div>
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => onMenuClick()}>
          {theme.direction === "rtl" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem button key="Services">
          <NavLink
            to="/services"
            exact
            className={classes.menuLink}
            onClick={() => onMenuClick()}
          >
            {res.services}
          </NavLink>
        </ListItem>
      </List>
      {session && session.loggedIn === true ? (
        <>
          {loggedUser?.isAdmin && (
            <>
              <Divider />
              <List>
                <ListItem onClick={() => setAdminMenuOpen(!adminMenuOpen)}>
                  <ListItemIcon>
                    <SupervisorAccountIcon />
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                  {adminMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={adminMenuOpen} timeout="auto" unmountOnExit>
                  <List>
                    <ListItem
                      button
                      key="Projects"
                      disabled={userError}
                      className={classes.nested}
                      onClick={() => onMenuClick()}
                    >
                      <NavLink
                        exact
                        to="/admin/projects"
                        className={classes.menuLink}
                        onClick={() => onMenuClick()}
                      >
                        Projects
                      </NavLink>
                    </ListItem>
                    <ListItem
                      button
                      key="Products"
                      className={classes.nested}
                      disabled={userError}
                      onClick={() => onMenuClick()}
                    >
                      <NavLink
                        exact
                        to="/admin/products"
                        className={classes.menuLink}
                        onClick={() => onMenuClick()}
                      >
                        Products and Files
                      </NavLink>
                    </ListItem>
                    <ListItem
                      button
                      key="Users"
                      className={classes.nested}
                      disabled={userError}
                      onClick={() => onMenuClick()}
                    >
                      <NavLink
                        exact
                        to="/admin/users"
                        className={classes.menuLink}
                        onClick={() => onMenuClick()}
                      >
                        Users
                      </NavLink>
                    </ListItem>
                    <ListItem
                      button
                      key="FileExplorer"
                      className={classes.nested}
                      disabled={userError}
                      onClick={() => onMenuClick()}
                    >
                      <NavLink
                        exact
                        to="/admin/files"
                        className={classes.menuLink}
                        onClick={() => onMenuClick()}
                      >
                        File Explorer
                      </NavLink>
                    </ListItem>
                    <ListItem
                      button
                      key="Diagnostics"
                      className={classes.nested}
                      onClick={() => onMenuClick()}
                    >
                      <NavLink
                        to="/diagnostics"
                        exact
                        className={classes.menuLink}
                        onClick={() => onMenuClick()}
                      >
                        Diagnostics
                      </NavLink>
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </>
          )}
          <Divider />
          <List>
            <ListItem onClick={() => setAccountMenuOpen(!accountMenuOpen)}>
              <ListItemIcon>
                <Badge
                  color="error"
                  badgeContent="!"
                  invisible={userError === false}
                >
                  <PersonIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Account" />
              {accountMenuOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={accountMenuOpen} timeout="auto" unmountOnExit>
              <List>
                <ListItem
                  button
                  key="AccountDetails"
                  disabled={userError}
                  className={classes.nested}
                >
                  <NavLink
                    exact
                    to="/my-account"
                    className={classes.menuLink}
                    onClick={() => onMenuClick()}
                  >
                    {res.accountDetails}
                  </NavLink>
                </ListItem>
                <ListItem
                  button
                  key="MyOrders"
                  className={classes.nested}
                  disabled={userError}
                >
                  <NavLink
                    exact
                    to="/my-account/my-purchases"
                    className={classes.menuLink}
                    onClick={() => onMenuClick()}
                  >
                    {res.accountMyOrders}
                  </NavLink>
                </ListItem>
                <ListItem
                  button
                  key="MyProducts"
                  className={classes.nested}
                  disabled={userError}
                  onClick={() => onMenuClick()}
                >
                  <NavLink
                    exact
                    to="/my-account/my-products"
                    className={classes.menuLink}
                    onClick={() => onMenuClick()}
                  >
                    {res.accountMyProducts}
                  </NavLink>
                </ListItem>

                {loggedUser?.isCustomer && (
                  <ListItem
                    button
                    key="MyLicenses"
                    className={classes.nested}
                    disabled={userError}
                  >
                    <NavLink
                      exact
                      to={urls.URL_MYLICENSES}
                      className={classes.menuLink}
                      onClick={() => onMenuClick()}
                    >
                      {res.accountMyLicenses}
                    </NavLink>
                  </ListItem>
                )}
              </List>
              <Divider />
              <List>
                <ListItem button key="Logout" className={classes.nested}>
                  <NavLink
                    to="/logout"
                    className={classes.menuLink}
                    exact
                    onClick={() => onLogout()}
                  >
                    {res.logout}
                  </NavLink>
                </ListItem>
              </List>
            </Collapse>
          </List>
        </>
      ) : (
        <>
          <Divider />
          <List>
            <ListItem button key="Login">
              <NavLink
                to="#"
                className={classes.menuLink}
                exact
                onClick={() => onLogin()}
              >
                {res.login}
              </NavLink>
            </ListItem>
          </List>
        </>
      )}
      <Divider />
      <List>
        <ListItem onClick={() => setLanguageMenuOpen(!languageMenuOpen)}>
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText primary={res.language} />
          {languageMenuOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={languageMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              key="English"
              className={classes.nested}
              onClick={() => switchLanguage("en")}
            >
              <ListItemText primary="English" />
              {language === "en" ? <CheckboxForListItem /> : null}
            </ListItem>
            <ListItem
              button
              key="Spanish"
              className={classes.nested}
              onClick={() => switchLanguage("es")}
            >
              <ListItemText primary="Español" />
              {language === "es" ? <CheckboxForListItem /> : null}
            </ListItem>
          </List>
        </Collapse>
      </List>
      <List>
        <ListItem
          onClick={() => switchTheme(themeName === "dark" ? "light" : "dark")}
        >
          <ListItemIcon>
            <WbSunnyIcon />
          </ListItemIcon>
          <ListItemText
            primary={themeName === "dark" ? res.themeLight : res.themeDark}
          />
        </ListItem>
      </List>
    </div>
  );
};

SideBarMenu.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
};

export default SideBarMenu;
