/* eslint-disable no-empty */
import * as Msal from "msal";
import {
  isIE,
  b2cScopes,
  b2cPolicies,
  b2cApplications,
  requiresInteraction,
} from "./authHelper";
import { ISession, noSession } from "../types/Auth";
import * as types from "../redux/actions/constants";

//MSAL CONFIGURATION
const msalConfig: Msal.Configuration = {
  auth: {
    clientId: b2cApplications.portal || "",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    validateAuthority: false,
    redirectUri: process.env.PUBLIC_URL,
    // postLogoutRedirectUri:,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE(),
  },
  system: {
    loadFrameTimeout: 90000,
    logger: new Msal.Logger(
      (logLevel, message) => {
        console.log(message);
      },
      {
        level: Msal.LogLevel.Verbose,
        piiLoggingEnabled: true,
      }
    ),
    telemetry: {
      applicationName: "react.softion.com",
      applicationVersion: "version",
      telemetryEmitter: (events) => {
        console.log("telemetry events:", events);
      },
    },
  },
};

//SINGLETON MSAL SERVICE
class AuthService {
  userAgentApplication: Msal.UserAgentApplication;

  constructor() {
    this.userAgentApplication = new Msal.UserAgentApplication(msalConfig);

    this.userAgentApplication.handleRedirectCallback((error, response) => {
      if (error) {
        // FAIL - Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        console.log(error);
        if (error.errorMessage.indexOf("AADB2C90118") > -1) {
          try {
            // PASSWORD RESET
            this.userAgentApplication.loginRedirect(
              b2cPolicies.authorities.forgotPassword
            );
          } catch (err) {
            console.log(err);
          }
        }
      } else if (response) {
        // PASS - We need to reject id tokens that were not issued with the default sign-in policy.
        // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
        if (
          response.tokenType === "id_token" &&
          response.idToken.claims["tfp"] !== b2cPolicies.names?.signUpSignIn
        ) {
          // LOGOUT
          this.userAgentApplication.logout();
          import("../redux/Store").then((store) => {
            store.default.dispatch({
              type: types.LOGOUT_SUCCESS,
              auth: noSession(),
            });
          });
        } else if (
          response.tokenType === "id_token" &&
          response.idToken.claims["tfp"] === b2cPolicies.names?.signUpSignIn
        ) {
          // LOGIN
          import("../redux/Store").then((store) => {
            store.default.dispatch({
              type: types.LOGIN_SUCCESS,
              auth: {
                user: response.idToken?.name,
                token: response.idToken?.rawIdToken,
                objectId: response.idTokenClaims?.oid,
                accessToken: null,
                loggedIn: true,
              },
            });
          });
          console.log("id_token acquired at: " + new Date().toString());
        } else if (response.tokenType === "access_token") {
          console.log("access_token acquired at: " + new Date().toString());
        } else {
          console.log("Token type is: " + response.tokenType);
        }
      }
    });
  }

  // API CALLS - NEED TO RETRIEVE/RENEW ACCESS TOKEN
  fetchAccessToken = async (scopeList: string[] | undefined) => {
    const accessTokenRequest: Msal.AuthenticationParameters = {
      scopes: scopeList,
    };
    try {
      if (!this.userAgentApplication.getAccount()) {
        //RETURN NOTHING IF USER LOGGED OUT
        return null;
      }
      //GET STORED ACCESS TOKEN FROM LOCAL/SESSION STORAGE
      const response = await this.userAgentApplication.acquireTokenSilent(
        accessTokenRequest
      );
      return response.accessToken;
    } catch (error) {
      console.log(error);
      if (requiresInteraction(error)) {
        //ASK FOR CREDENTIALS
        this.userAgentApplication.acquireTokenRedirect(accessTokenRequest);
      } else {
        //RETURN NULL AND LET THE API RETURN NOT AUTHORIZED
        return null;
      }
    }
  };

  // UI - REFRESH CURRENT SESSION STATUS
  refresh = async () => {
    const store = await import("../redux/Store");
    const state = { ...store.default.getState() };
    const currentSession: ISession = state.auth;

    //GET CACHED ACCOUNT INFORMATION
    const account = this.userAgentApplication.getAccount();
    let sessionData: ISession = noSession();
    if (account) {
      //CHECK ITS A VALID SESSION WE HAVE A TOKEN IN THE CACHE
      try {
        const response = await this.userAgentApplication.acquireTokenSilent({
          scopes: b2cScopes.REFRESH.scopes,
        });
        if (response) {
          sessionData = {
            user: response.idToken?.name,
            token: response.idToken?.rawIdToken,
            objectId: response.idTokenClaims?.oid,
            accessToken: response.accessToken,
            loggedIn: true,
          };
        }
      } catch {}
    }

    //UPDATE THE UI THROUGH REDUX
    if (currentSession.loggedIn !== sessionData?.loggedIn) {
      store.default.dispatch({
        type: types.GET_USER_SUCCESS,
        auth: {
          ...state.auth,
          user: sessionData?.user,
          token: sessionData?.token,
          objectId: sessionData?.objectId,
          accessToken: sessionData?.accessToken,
          loggedIn: sessionData?.loggedIn || false,
        },
      });
    }
  };

  login = async () => {
    try {
      // VERIFY USER ALREADY HAS A TOKEN
      const scopes: string[] = b2cScopes.REFRESH.scopes;
      const accessToken = await this.userAgentApplication.acquireTokenSilent({
        scopes: scopes,
      });
      if (accessToken) {
        return;
      }
    } catch {}
    console.log(
      "Could not find a token for the current user. User will be redirected to login page..."
    );
    const loginScopes: string[] = b2cScopes.LOGIN.scopes;
    return this.userAgentApplication.loginRedirect({
      scopes: loginScopes,
      prompt: "select_account",
    });
  };

  logout = () => {
    this.userAgentApplication.logout();
    this.refresh();
  };
}

const authService = new AuthService();
export default authService;
