import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import { IUserV0 } from "../../../../api/Account/usersApi";
import UserDropDown from "../../_shared/UsersDropDown";

export interface IUserTransferDialogProps {
  open: boolean;
  users: IUserV0[];
  handleSubmit: (fromUser: string, toUser: string) => void;
  handleCancel: () => void;
}

const UserTransferDialog: React.FC<IUserTransferDialogProps> = ({
  open,
  users,
  handleSubmit,
  handleCancel,
}) => {
  const formik = useFormik({
    initialValues: {
      fromUser: "",
      toUser: "",
    },
    validationSchema: Yup.object({
      fromUser: Yup.string().required(),
      toUser: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      await handleSubmit(values.fromUser, values.toUser);
    },
  });

  useEffect(() => {
    if (open) {
      formik.setFieldValue("fromUser", "");
      formik.setFieldValue("toUser", "");
    }
  }, [open]);

  const useStyles = makeStyles({
    root: {
      // flexGrow: 1,
      padding: "18px",
    },
    input: {
      verticalAlign: "top",
      marginTop: "12px",
    },
  });
  const styles = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="transfer user"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Transfer User</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={styles.root}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="flex-start"
          >
            <Typography variant="body2">
              Create a new B2C user, execute the transfer and disable login for
              the first user
              <br />
              Transfer will update all database tables to point to the new user
              except for historical data such as UserHistory and Licenses
            </Typography>

            <FormControl
              fullWidth
              error={Boolean(formik.errors.fromUser) && formik.touched.fromUser}
            >
              <UserDropDown
                name="fromUser"
                label="From User"
                users={users}
                value={formik.values.fromUser}
                enabled={true}
                allowEmpty={true}
                allowShowId={true}
                onChange={handleChange}
                touched={formik.touched.fromUser}
                error={formik.errors.fromUser}
              />
            </FormControl>
            <FormControl
              fullWidth
              error={Boolean(formik.errors.toUser) && formik.touched.toUser}
            >
              <UserDropDown
                name="toUser"
                label="Installed By"
                users={users}
                value={formik.values.toUser}
                enabled={true}
                allowEmpty={true}
                allowShowId={true}
                onChange={handleChange}
                touched={formik.touched.toUser}
                error={formik.errors.toUser}
              />
            </FormControl>
            <Grid item />
            <Grid container direction="row" justify="flex-end">
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>Submit</Grid>
                  {formik.isSubmitting && (
                    <Grid item>
                      <CircularProgress size={18} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UserTransferDialog;
