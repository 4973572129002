import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useFormik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";

export interface IProjectStationDialogProps {
  open: boolean;
  handleSubmit: (request: string) => void;
  handleCancel: () => void;
}

const ProjectStationDialog: React.FC<IProjectStationDialogProps> = ({
  open,
  handleSubmit,
  handleCancel,
}) => {
  const formik = useFormik({
    initialValues: {
      stationName: "",
    },
    validationSchema: Yup.object({
      stationName: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      await handleSubmit(values.stationName);
    },
  });

  const [showRules, setShowRules] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      formik.setFieldValue("stationName", "");
    }
  }, [open]);

  const useStyles = makeStyles({
    root: {
      // flexGrow: 1,
      padding: "18px",
    },
    input: {
      verticalAlign: "top",
      marginTop: "12px",
    },
  });
  const styles = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="station name"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Add WorkStation</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={styles.root}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="flex-start"
          >
            <Typography variant="body2">
              Workstations can be added and removed until they have been used
              for license activation, remove is not allowed afterwards. When
              workstations change, it means the original design has changed and
              to understand the true intentions manual database is expected
              <br />
            </Typography>
            {!showRules ? (
              <Link href="#" onClick={() => setShowRules(true)}>
                See Database Rules
              </Link>
            ) : (
              <ul>
                <li>
                  <Typography variant="body2">
                    <b>ProgramStations</b> - No ProgramStations mean 1 station
                    is infered and no entry is required for it unless a specific
                    name wants to be used, when a second station is added then
                    the number of stations goes up to 2 and so on
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    <b>Licenses</b> - Any licenses must be redirected to the
                    right station in case of re-ordering. This is to keep data
                    integrity and license counters updated
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2">
                    <b>LicenseOverride</b> - any specific rules set in
                    ProgramStations must be reflected here if applicable
                  </Typography>
                </li>
              </ul>
            )}
            <TextField
              label="Station Name"
              type="text"
              name="stationName"
              className={styles.input}
              InputLabelProps={{ shrink: true }}
              value={formik.values.stationName}
              onChange={handleChange}
            />
            <Grid item />
            <Grid container direction="row" justify="flex-end">
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>Submit</Grid>
                  {formik.isSubmitting && (
                    <Grid item>
                      <CircularProgress size={18} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectStationDialog;
