import React, { useState, useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IUserV0 } from "../../../../api/Account/usersApi";
import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  InputLabel,
  NativeSelect,
  FormControl,
  Grid,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import {
  IOrder,
  IProjectRequest,
  IProjectResponse,
} from "../../../../api/Admin/dbProjectsApi";
import { IProduct } from "../../../../api/Admin/dbProductsApi";
import * as Yup from "yup";
import UserDropDown from "../../_shared/UsersDropDown";
import CurrencyTextBox from "../../_shared/CurrencyTextBox";
import Link from "@material-ui/core/Link";

export interface IProjectDetailsDialogDialogProps {
  row: IProjectResponse | undefined;
  users: IUserV0[];
  products: IProduct[];
  orders: IOrder[];
  open: boolean;
  handleDelete: (projectId: string) => void;
  handleSubmit: (request: IProjectRequest, isNewProject: boolean) => void;
  handleCancel: () => void;
}

const ProjectDetailsDialog: React.FC<IProjectDetailsDialogDialogProps> = ({
  row,
  users,
  orders,
  products,
  open,
  handleDelete,
  handleSubmit,
  handleCancel,
}) => {
  const [isNewProject, setIsNewProject] = useState<boolean>(true);
  const [order, setOrder] = useState<IOrder | undefined>();
  const [productsNullable, setProductsNullable] = useState<IProduct[]>([]);

  const createUuid = () => {
    let dt = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  useEffect(() => {
    const existing = row?.programId && row.programId !== "";
    setIsNewProject(!existing);
    const po = orders.find((x) => x.orderId === row?.orderId);
    setOrder(po);
  }, [row]);

  useEffect(() => {
    const productsWithNull: IProduct[] = [
      { productId: "", productName: "", isCommercial: false },
      ...products,
    ];
    setProductsNullable(productsWithNull);
  }, [products]);

  const formik = useFormik({
    //enableReinitialize: true,
    initialValues: {
      programId: row?.programId,
      programName: row?.name || "",
      orderId: row?.orderId,
      orderDate: row?.orderDate,
      orderUserId: row?.orderUserId,
      userId: row?.userId,
      ownerId: row?.ownerId,
      productId: row?.productId,
      salePrice: row?.salePrice,
      saleCcy: row?.saleCcy,
      discount: row?.discount || undefined,
      discountCode: row?.discountCode || "",
      notes: row?.notes || "",
    },
    validationSchema: Yup.object({
      orderId: Yup.string().required(),
      orderDate: Yup.date().required(),
      orderUserId: Yup.string().required(),
      //installationUser: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      await handleSubmit(
        {
          programId: values.programId || "",
          programName: values.programName || "",
          orderId: values.orderId || "",
          orderDate: values.orderDate || new Date(),
          orderUserId: values.orderUserId || "",
          userId: values.userId || "",
          ownerId: values.ownerId || "",
          productId: values.productId || "",
          salePrice: values.salePrice || 0,
          saleCcy: values.saleCcy || "",
          discount: values.discount || undefined,
          discountCode: values.discountCode,
          notes: values.notes,
        },
        isNewProject
      );
    },
  });

  useEffect(() => {
    if (open) {
      formik.setFieldValue("programId", row?.programId);
      formik.setFieldValue("programName", row?.name || "");
      formik.setFieldValue("orderId", row?.orderId);
      formik.setFieldValue("orderDate", row?.orderDate);
      formik.setFieldValue("orderUserId", row?.orderUserId);
      formik.setFieldValue("userId", row?.userId);
      formik.setFieldValue("ownerId", row?.ownerId);
      formik.setFieldValue("productId", row?.productId);
      formik.setFieldValue("salePrice", row?.salePrice);
      formik.setFieldValue("saleCcy", row?.saleCcy);
      formik.setFieldValue("discount", row?.discount || undefined);
      formik.setFieldValue("discountCode", row?.discountCode || "");
      formik.setFieldValue("notes", row?.notes || "");
    }
  }, [open]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      formControls: {
        padding: "3px 0px 12px 0px",
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      input: {
        verticalAlign: "top",
        marginTop: "0px",
        paddingTop: "0px",
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
  );
  const styles = useStyles();

  const generateGuid = () => {
    const guid = createUuid();
    formik.setFieldValue("programId", guid.toUpperCase());
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  const handleOrderChange = (event) => {
    const { name, value } = event.target;
    const po = orders.find((x) => x.orderId === value);
    if (po) {
      formik.setFieldValue("orderDate", po.date); // this call formik to set your value
      formik.setFieldValue("orderUserId", po.userId);
    }
    formik.setFieldValue(name, value); // this call formik to set your value
    setOrder(po);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="project"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {isNewProject ? "New Project" : row?.name}
      </DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={formik.handleSubmit} className={styles.root}>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              className={styles.formControls}
            >
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Program Id"
                    name="programId"
                    disabled={isNewProject === false}
                    type="text"
                    value={formik.values.programId}
                    onChange={handleChange}
                  />
                  {isNewProject && (
                    <FormHelperText>
                      <Link href="#" onClick={() => generateGuid()}>
                        Generate Guid
                      </Link>
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Program Name"
                    name="programName"
                    type="text"
                    value={formik.values.programName}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Order Details
                </Typography>
              </Grid>

              <Grid item xs={4} sm={2}>
                <FormControl fullWidth>
                  <TextField
                    label="Order No."
                    name="orderId"
                    type="text"
                    value={formik.values.orderId}
                    onChange={handleOrderChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={8} sm={2}>
                <FormControl fullWidth>
                  <DatePicker
                    name="orderDate"
                    label="Date"
                    disabled={order !== undefined}
                    value={formik.values.orderDate}
                    onChange={(dt) => formik.setFieldValue("orderDate", dt)}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <CurrencyTextBox
                    nameAmount="salePrice"
                    nameCcy="saleCcy"
                    label="Amount"
                    amount={formik.values.salePrice}
                    ccy={formik.values.saleCcy}
                    onChangeAmount={handleChange}
                    onChangeCcy={handleChange}
                    touched={formik.touched.salePrice}
                    error={formik.errors.salePrice}
                  />
                </FormControl>
                {formik.touched.saleCcy && formik.errors.saleCcy && (
                  <Typography variant="caption" color="error">
                    {formik.errors.saleCcy}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={4} sm={2}>
                <FormControl fullWidth>
                  <TextField
                    label="Discount %"
                    name="discount"
                    type="number"
                    value={formik.values.discount}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={8} sm={2}>
                <FormControl fullWidth>
                  <TextField
                    label="Discount Code"
                    name="discountCode"
                    type="text"
                    value={formik.values.discountCode}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.orderUserId) &&
                    formik.touched.orderUserId
                  }
                >
                  <UserDropDown
                    name="orderUserId"
                    label="Purchased By"
                    users={users.filter((u) => u.isDistributor || u.isAdmin)}
                    value={formik.values.orderUserId}
                    enabled={order === undefined}
                    allowEmpty={true}
                    allowShowId={true}
                    onChange={handleChange}
                    touched={formik.touched.orderUserId}
                    error={formik.errors.orderUserId}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.ownerId) && formik.touched.ownerId
                  }
                >
                  <UserDropDown
                    name="ownerId"
                    label="Represented By"
                    users={users.filter((u) => u.isDistributor || u.isAdmin)}
                    value={formik.values.ownerId}
                    enabled={true}
                    allowEmpty={true}
                    allowShowId={true}
                    onChange={handleChange}
                    touched={formik.touched.ownerId}
                    error={formik.errors.ownerId}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl
                  fullWidth
                  error={Boolean(formik.errors.userId) && formik.touched.userId}
                >
                  <UserDropDown
                    name="userId"
                    label="End User"
                    users={users.filter((u) => u.isSupport || u.isCustomer)}
                    value={formik.values.userId}
                    enabled={true}
                    allowEmpty={true}
                    allowShowId={true}
                    onChange={handleChange}
                    touched={formik.touched.userId}
                    error={formik.errors.userId}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Product Details
                </Typography>
              </Grid>

              <Grid item xs={12} sm={8}>
                <InputLabel id="user-label">Product</InputLabel>
                <NativeSelect
                  value={formik.values.productId}
                  onChange={handleChange}
                  inputProps={{
                    name: "productId",
                    id: "productId",
                  }}
                >
                  {productsNullable.map((item, index) => (
                    <option key={index} value={item.productId}>
                      {item.productName}
                    </option>
                  ))}
                </NativeSelect>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Project Details"
                    name="notes"
                    type="text"
                    multiline
                    rows={6}
                    variant="outlined"
                    value={formik.values.notes}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item xs={4}>
                {!isNewProject && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(row?.programId || "")}
                  >
                    Delete
                  </Button>
                )}
              </Grid>

              <Grid item container xs={8} justify="flex-end">
                <Button
                  disabled={formik.isSubmitting}
                  onClick={() => handleCancel()}
                  type="reset"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>Submit</Grid>
                    {formik.isSubmitting && (
                      <Grid item>
                        <CircularProgress size={18} />
                      </Grid>
                    )}
                  </Grid>
                </Button>
              </Grid>
            </Grid>
          </form>
        </MuiPickersUtilsProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectDetailsDialog;
