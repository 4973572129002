import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  IProjectResponse,
  IDevelopmentDetailsRequest,
} from "../../../../api/Admin/dbProjectsApi";
import * as Yup from "yup";

export interface IProjectDevelopmentDialogProps {
  row: IProjectResponse | undefined;
  open: boolean;
  handleSubmit: (request: IDevelopmentDetailsRequest) => void;
  handleCancel: () => void;
}

const ProjectDevelopmentDialog: React.FC<IProjectDevelopmentDialogProps> = ({
  row,
  open,
  handleSubmit,
  handleCancel,
}) => {
  function isNullDate(value: Date | null | undefined): boolean {
    if (value !== null && value !== undefined) {
      return false;
    }
    return true;
  }

  const formik = useFormik({
    //enableReinitialize: true,
    initialValues: {
      hasStartDate: !isNullDate(row?.devStartDate),
      devStartDate: row?.devStartDate || new Date(),
      hasEndDate: !isNullDate(row?.devEndDate),
      devEndDate: row?.devEndDate || new Date(),
      devNotes: row?.devNotes || "",
    },
    validationSchema: Yup.object({
      devStartDate: Yup.date().default(() => new Date()),
      devEndDate: Yup.date().when(
        "devStartDate",
        (startDate, schema) => startDate && schema.min(startDate)
      ),
    }),
    onSubmit: async (values) => {
      await handleSubmit({
        programId: row?.programId || "",
        devStartDate: values.hasStartDate ? values.devStartDate : undefined,
        devEndDate:
          values.hasStartDate && values.hasEndDate
            ? values.devEndDate
            : undefined,
        devNotes: values.devNotes,
      });
    },
  });

  useEffect(() => {
    if (open) {
      formik.setFieldValue("hasStartDate", !isNullDate(row?.devStartDate));
      if (isNullDate(row?.devStartDate)) {
        formik.setFieldValue("devStartDate", row?.orderDate || new Date());
      } else {
        formik.setFieldValue("devStartDate", row?.devStartDate || new Date());
      }
      formik.setFieldValue("hasEndDate", !isNullDate(row?.devEndDate));
      formik.setFieldValue("devEndDate", row?.devEndDate || new Date());
      if (isNullDate(row?.devEndDate)) {
        formik.setFieldValue(
          "devEndDate",
          row?.devStartDate || row?.orderDate || new Date()
        );
      } else {
        formik.setFieldValue("devEndDate", row?.devEndDate || new Date());
      }
      formik.setFieldValue("devNotes", row?.devNotes || "");
    }
  }, [open]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      formControls: {
        padding: "3px 0px 12px 0px",
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      input: {
        verticalAlign: "top",
        marginTop: "0px",
        paddingTop: "0px",
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
    })
  );
  const styles = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };
  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="development details"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">{row?.name}</DialogTitle>
      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <form onSubmit={formik.handleSubmit} className={styles.root}>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              className={styles.formControls}
            >
              <Grid item xs={6} sm={2}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      name="hasStartDate"
                      checked={formik.values.hasStartDate}
                      onChange={handleCheckBoxChange}
                    />
                  }
                  label="Dev. Started"
                  labelPlacement="top"
                />
              </Grid>
              {formik.values.hasStartDate && (
                <>
                  <Grid item xs={6} sm={4}>
                    <DatePicker
                      name="devStartDate"
                      label="Date"
                      value={formik.values.devStartDate}
                      onChange={(dt) =>
                        formik.setFieldValue("devStartDate", dt)
                      }
                    />
                    {formik.touched.devStartDate && formik.errors.devStartDate && (
                      <Typography variant="caption" color="error">
                        {formik.errors.devStartDate}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={2}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          name="hasEndDate"
                          checked={formik.values.hasEndDate}
                          onChange={handleCheckBoxChange}
                        />
                      }
                      label="Dev. Finished"
                      labelPlacement="top"
                    />
                  </Grid>
                  {formik.values.hasEndDate && (
                    <Grid item xs={6} sm={4}>
                      <DatePicker
                        name="devEndDate"
                        label="Date"
                        value={formik.values.devEndDate}
                        onChange={(dt) =>
                          formik.setFieldValue("devEndDate", dt)
                        }
                      />
                      {formik.touched.devEndDate && formik.errors.devEndDate && (
                        <Typography variant="caption" color="error">
                          {formik.errors.devEndDate}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    label="Development Notes"
                    name="devNotes"
                    type="text"
                    multiline
                    rows={6}
                    variant="outlined"
                    value={formik.values.devNotes}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container justify="flex-end">
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleCancel()}
                type="reset"
                color="primary"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={formik.isSubmitting}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>Submit</Grid>
                  {formik.isSubmitting && (
                    <Grid item>
                      <CircularProgress size={18} />
                    </Grid>
                  )}
                </Grid>
              </Button>
            </Grid>
          </form>
        </MuiPickersUtilsProvider>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectDevelopmentDialog;
