import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  newLicenseTitle: string;
  newLicenseSubTitle: string;
  newLicenseWarning: string;
  newLicenseStep1: string;
  newLicenseStep2: string;
  newLicenseStep3: string;
  branchName: string;
  stationName: string;
  productName: string;
  next: string;
  back: string;
  submit: string;
  confirmDetails: string;
  date: string;
  licenseTitle: string;
  licenseKey: string;
  licensedTo: string;
  active: string;
  uninstalled: string;
  maxLicensesExceeded: string;
  emailLicenseTo: string;
  licenseSuccessfullyGenerated: string;
  licenseStationReminder: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    newLicenseTitle: "New License",
    newLicenseSubTitle:
      "Follow the instructions step by step to generate an Offline license",
    newLicenseWarning:
      "Use this activation method only for Offline licenses, if you have internet connection in your workstation please activate your product directly in the application.  You will not be able to undo an incorrect license",
    newLicenseStep1: "Make sure you are at the right workstation",
    newLicenseStep2: "Open the application and go to License menu",
    newLicenseStep3: "Copy the unique code displayed for your product",
    branchName: "Branch",
    stationName: "Workstation",
    productName: "Product",
    next: "Next",
    back: "Back",
    submit: "Generate License",
    confirmDetails: "Please confirm the details are correct:",
    date: "Date:",
    licenseTitle: "License Activation",
    licenseKey: "License Key:",
    licensedTo: "Licensed To:",
    active: "Active",
    uninstalled: "Uninstalled",
    maxLicensesExceeded:
      "Max. number of licenses has been exceeded.  Please contact support",
    emailLicenseTo: "Email license to (optional)",
    licenseSuccessfullyGenerated:
      "License successfully generated.  Please copy the following details to the application License Activation window",
    licenseStationReminder:
      "If you have any trouble with the activation, please ensure you are activating the product at the right workstation: {0}",
  },

  es: {
    newLicenseTitle: "Nueva Licencia",
    newLicenseSubTitle: "Siga los pasos para generar una licencia sin conexión",
    newLicenseWarning:
      "Use este modo de activación unicamente cuando trabaje Offline, si su estación de trabajo tiene acceso a internet active el programa directamente desde la aplicación.  No podrá revertir una licencia incorrectamente generada",
    newLicenseStep1:
      "Asegurese de estar en la estación de trabajo que quiere activar",
    newLicenseStep2: "Abra el programa y vaya al menu Licencia",
    newLicenseStep3: "Copie el codigo único de producto",
    branchName: "Sucursal",
    stationName: "Estación Trabajo",
    productName: "Producto",
    next: "Siguiente",
    back: "Atras",
    submit: "Generar Licencia",
    confirmDetails: "Confirme que los datos sean correctos:",
    date: "Fecha:",
    licenseTitle: "Activación",
    licenseKey: "Número Licencia:",
    licensedTo: "Registrado a nombre de:",
    active: "Activa",
    uninstalled: "Desinstalada",
    maxLicensesExceeded:
      "Ha excedido el número máximo de licencias.  Por favor de contactar a soporte técnico",
    emailLicenseTo: "Enviar licencia por email (opcional)",
    licenseSuccessfullyGenerated:
      "Licencia generada exitosamente. Por favor copie los siguientes datos a la ventana de Activación de Licencia dentro del programa",
    licenseStationReminder:
      "Si experimenta cualquier problema con la activación, asegurese de que está activando el modulo correcto: {0}",
  },
});
