import * as jwtDecode from "jwt-decode";

const portalApiScope =
  "https://softion.onmicrosoft.com/portal-api/Read.Products";

export const b2cScopes = {
  LOGIN: {
    scopes: [portalApiScope],
  },
  PORTAL_API: {
    //USE THIS SCOPE TO CALL PORTAL API ENDPOINTS
    scopes: [portalApiScope],
  },
  REFRESH: {
    //USE THIS SCOPE FOR CHECKING THE USER IS IN A VALID SESSION
    scopes: [portalApiScope],
  },
};

export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_SignUpSignIn",
    forgotPassword: "B2C_1_PasswordReset",
    resourceOwner: "B2C_1_ROPC",
  },
  authorities: {
    signUpSignIn: {
      authority:
        "https://softion.b2clogin.com/softion.onmicrosoft.com/B2C_1_SignUpSignIn",
    },
    forgotPassword: {
      authority:
        "https://softion.b2clogin.com/softion.onmicrosoft.com/B2C_1_PasswordReset",
    },
  },
};

export const b2cApplications = {
  portal: process.env.B2C_APPLICATION, // "4b1c18f8-62be-4998-b0c0-f1c1d35c11b7"
  portalApi: "4b1c18f8-62be-4998-b0c0-f1c1d35c11b7",
};

export const requiresInteraction = (error) => {
  let errorWithCode: string | null = null;
  if (!error) {
    return false;
  }
  if (error.errorCode) {
    errorWithCode = error.errorCode;
  } else if (error.message) {
    errorWithCode = error.message;
  }
  return (
    errorWithCode &&
    (errorWithCode.indexOf("consent_required") > -1 ||
      errorWithCode.indexOf("interaction_required") > -1 ||
      errorWithCode.indexOf("login_required") > -1)
  );
};

export const getTokenFromCache = () => {
  const token = sessionStorage.getItem("msal.idtoken");
  const noToken = { token: null, isExpired: null, exp: null };
  if (!token) {
    return noToken;
  }
  try {
    const jsonToken = jwtDecode(token);
    console.log(jsonToken);
    if (jsonToken) {
      const d = new Date(0);
      d.setUTCSeconds(jsonToken.exp);
      return {
        token: token,
        isExpired: Date.now() >= jsonToken.exp * 1000,
        expirationTime: d,
        exp: jsonToken.exp,
      };
    }
    return noToken;
  } catch (Error) {
    return noToken;
  }
};

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;
  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;
  return msie || msie11;
};
