import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Chip,
} from "@material-ui/core";

import {
  ILicense2,
  IOfflineLicenseUninstallRequest,
} from "../../../../api/Account/myLicensesApi";
import IResources, { resources } from "./.resources";
import Moment from "react-moment";

export interface ILicenseDialogProps {
  license: ILicense2;
  isAdmin: boolean;
  open: boolean;
  handleUninstall: (request: IOfflineLicenseUninstallRequest) => void;
  handleCancel: () => void;
}

const LicenseDialog: React.FC<ILicenseDialogProps> = ({
  license,
  isAdmin,
  open,
  handleUninstall,
  handleCancel,
}) => {
  const res: IResources = resources;

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      actionButton: {
        margin: "0 4px 0 4px",
      },
      indentedText: {
        margin: "0 0 0 6px",
      },
    })
  );
  const styles = useStyles();

  const StatusChip = () => {
    switch (license.status) {
      case 1:
      case 2:
        return <Chip color="primary" label={`Status: ${res.active}`} />;
      case 4:
      case 5:
        return <Chip label={`Status: ${res.uninstalled}`} />;
    }
    return <Chip color="secondary" label="Unknown Status" />;
  };

  const onUninstall = () => {
    handleUninstall({
      licenseKey: license.licenseKey,
      productId: license.productId,
      language: "en",
    });
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={() => handleCancel()}
      aria-labelledby="license"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <Typography variant="h4">{res.licenseTitle}</Typography>
        <StatusChip />
      </DialogTitle>
      <DialogContent>
        <Grid
          item
          id="grid-grid"
          container
          className={styles.root}
          direction="row"
          alignItems="flex-start"
          justify="flex-start"
          spacing={2}
        >
          <Grid item xs={4}>
            {res.date}
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">
              <Moment format="DD/MMM/YYYY HH:mm">{license.date}</Moment>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            {res.productName}
          </Grid>
          <Grid item container direction="column" xs={8}>
            <Typography variant="body2">{license.productName}</Typography>
            <Typography variant="caption">{license.productId}</Typography>
          </Grid>
          {isAdmin && (
            <>
              <Grid item xs={4}>
                Program ID
              </Grid>
              <Grid item container direction="column" xs={8}>
                <Typography variant="body2">{license.programName}</Typography>
              </Grid>
            </>
          )}
          {license.branchName && (
            <>
              <Grid item xs={4}>
                {res.branchName}
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{license.branchName}</Typography>
              </Grid>
            </>
          )}

          {license.stationName && (
            <>
              <Grid item xs={4}>
                {res.stationName}
              </Grid>
              <Grid item xs={8} container direction="row">
                <Typography variant="body2">{license.station}</Typography>
                <Typography
                  variant="body2"
                  className={styles.indentedText}
                >{`(${license.stationName})`}</Typography>
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            Request Code
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{license.requestCode}</Typography>
          </Grid>
          <Grid item xs={4}>
            {res.licensedTo}
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{license.licensedTo}</Typography>
          </Grid>
          <Grid item xs={4}>
            {res.licenseKey}
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2">{license.licenseKey}</Typography>
          </Grid>
          {license.requestorId && license.requestorId !== license.userId && (
            <>
              <Grid item xs={4}>
                Grantor
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">
                  {license.requestorName || license.requestorId}
                </Typography>
              </Grid>
            </>
          )}
          {isAdmin && (
            <>
              <Grid item xs={4}>
                License Type
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2">{license.licenseType}</Typography>
              </Grid>
              <Grid item xs={4}>
                Installation Type
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2">
                  {license.installationType}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                Location
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{license.location}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={4}>
            {isAdmin && license.isActive && (
              <Button
                onClick={onUninstall}
                className={styles.actionButton}
                color="secondary"
                variant="contained"
                type="reset"
              >
                Uninstall
              </Button>
            )}
          </Grid>

          <Grid item container direction="row" justify="flex-end" xs={8}>
            <Button
              onClick={() => handleCancel()}
              className={styles.actionButton}
              type="reset"
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default LicenseDialog;
