import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  titleCustomer: string;
  titleSupport: string;
  titleDistributor: string;
  titleAdmin: string;
  viewAs: string;
  newLicenseTitle: string;
  newLicenseSubtitle: string;
  architectureHeader: string;
  licenseSuccessfullyGenerated: string;
  orders: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    titleCustomer: "My Licenses",
    titleSupport: "Licenses",
    titleDistributor: "Licenses",
    titleAdmin: "License Viewer",
    viewAs: "View As...",
    newLicenseTitle: "New License",
    newLicenseSubtitle:
      "Make sure you are at the right workstation and open your application, navigate to License menu and find the unique application code",
    architectureHeader: "Sites and workstations map",
    licenseSuccessfullyGenerated: "License generated successfully",
    orders: "Orders",
  },

  es: {
    titleCustomer: "Mis Licencias",
    titleSupport: "Licencias",
    titleDistributor: "Licencias",
    titleAdmin: "Visor de Licencias",
    viewAs: "Mostrar como...",
    newLicenseTitle: "Nueva Licencia",
    newLicenseSubtitle:
      "Asegurese de estar en la estación de trabajo que quiere activar, abra el programa y vaya al menu Licencia.  Encuentre el codigo único de producto",
    architectureHeader: "Mapa de plantas y estaciones de trabajo",
    licenseSuccessfullyGenerated: "Licencia generada exitosamente",
    orders: "Ordenes",
  },
});
