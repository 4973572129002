import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Divider,
  Select,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";

import { IBlobInfo } from "../../../../api/Admin/filesApi";
import { IProduct } from "../../../../api/Admin/dbProductsApi";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { IBlobWithLinks } from "../../../../api/Admin/dbFilesApi";
import DeleteIcon from "@material-ui/icons/Delete";

export interface IBlobInfoRequest {
  fileUrl: string;
  onSuccess: (result: IBlobInfo) => void;
}

export interface IBlobLinkedProductsDialogProps {
  file: IBlobWithLinks | undefined;
  products: IProduct[];
  open: boolean;
  handleLinkProducts: (productIds: string[]) => void;
  handleUnlinkProducts: (productIds: string[]) => void;
  handleClose: () => void;
}

const BlobLinkedProductsDialog: React.FC<IBlobLinkedProductsDialogProps> = ({
  file,
  products,
  open,
  handleLinkProducts,
  handleUnlinkProducts,
  handleClose,
}) => {
  const [dropDownProduct, setDropDownProduct] = useState<string | undefined>();
  const [productsList, setProductsList] = useState<string[]>([]);

  const addProduct = () => {
    if (dropDownProduct && !productsList.find((x) => x === dropDownProduct)) {
      setProductsList([...productsList, dropDownProduct]);
      setDropDownProduct(undefined);
    }
  };

  const deleteProduct = (productId: string) => {
    if (productsList.find((x) => x === productId)) {
      setProductsList([...productsList.filter((p) => p !== productId)]);
    }
  };

  const linkAll = () => {
    handleLinkProducts(productsList);
  };

  const unlinkAll = () => {
    if (file?.products) {
      handleUnlinkProducts(file?.products);
    }
  };

  useEffect(() => {
    if (file?.products) {
      setProductsList([...file.products]);
    } else {
      setProductsList([]);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="new project"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">
        <>
          <Typography variant="h6">Linked Products</Typography>
          <Typography variant="subtitle2">{file?.displayName} </Typography>
        </>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" justify="center">
          <Grid item xs={10}>
            <FormControl fullWidth>
              <InputLabel id="platform-label">Products</InputLabel>
              <Select
                label="Products"
                name="dropDownFilter"
                value={dropDownProduct}
                onChange={(e) => setDropDownProduct(e.target.value)}
              >
                {products &&
                  products.map((p) => {
                    return (
                      <MenuItem key={p.productId} value={p.productId}>
                        {p.productName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button color="primary" onClick={() => addProduct()}>
              Add
            </Button>
          </Grid>
        </Grid>
        <br />
        <Divider variant="middle" />
        <List>
          {productsList &&
            productsList.map((x) => {
              const product = products.find((p) => p.productId == x);
              return (
                <ListItem key={x}>
                  <ListItemText
                    primary={product?.productName}
                    secondary={product?.productDescription}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      size="small"
                      onClick={() => deleteProduct(x)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={4}>
            <Button onClick={unlinkAll} variant="contained" color="secondary">
              Unlink All
            </Button>
          </Grid>

          <Grid
            item
            container
            xs={8}
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <Button onClick={linkAll} variant="contained" color="primary">
              Save Changes
            </Button>
            <Button onClick={handleClose}>Close</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default BlobLinkedProductsDialog;
