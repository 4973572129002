import * as types from "../actions/constants";
import { getDefaultLanguage } from "../actions/languageAction";

const languageReducer = (state = getDefaultLanguage(), action) => {
  switch (action.type) {
    case types.SWITCH_LANGUAGE:
      return action.data;
    default:
      return state;
  }
};

export default languageReducer;
