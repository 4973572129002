import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Grow,
  IconButton,
  GridList,
  GridListTile,
} from "@material-ui/core";
import IResources, { resources } from "./.resources";
import { RootState } from "../../../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import TicketsImage from "./tickets.png";
import TransactionsImage from "./transactions1.png";
import Header1Image from "./weighbridge_header4.png";
import ReportsImage from "./reports.png";
import ScaleImage from "./devices.png";
import BarCodeIcon from "./barcode16x16.svg";
import ExcelIcon from "./excel.svg";
import WeightIcon from "./weight16x16.svg";
import TruckEmptyIcon from "./truck-load-half.svg";
import TruckFullIcon from "./truck-load-full.svg";
import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
import SettingsInputHdmiIcon from "@material-ui/icons/SettingsInputHdmi";
import BuildIcon from "@material-ui/icons/Build";
import PersonIcon from "@material-ui/icons/Person";
import WebIcon from "@material-ui/icons/Web";
import DeviceHubIcon from "@material-ui/icons/DeviceHub";

import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTheme } from "@material-ui/core/styles";

import MoneyIcon from "@material-ui/icons/Money";

import { makeStyles, Theme } from "@material-ui/core";
import { EmailOutlined } from "@material-ui/icons";
import { ThemeOptions } from "../../../types/Enums";
import { IRequestTrialParams } from "../RequestTrialPage";

interface IPopupItem {
  data: any;
  isVisible: boolean;
}

interface IProductButton {
  text: string;
  image: any;
  id: string;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  data?: any;
}

export interface IWeighBridgePageProps {
  themeName: ThemeOptions;
}

const WeighBridgePage: React.FC<IWeighBridgePageProps> = ({ themeName }) => {
  const useStyles = makeStyles((theme: Theme) => ({
    topContainer: { paddingTop: "24px" },
    header: {
      padding: "0px 6px 0px 32px",
    },
    headerImage: {
      maxHeight: 320,
    },
    headerBandDark: {
      backgroundColor: "#4777C6",
      //backgroundColor: "#37517e",
      marginTop: -16,
    },
    headerBandLight: {
      backgroundColor: "#444444",
      //backgroundColor: "#333333",
      marginTop: -16,
    },
    title: {
      fontFamily:
        "'Open Sans Condensed','HelveticaNeue-CondensedBold',Helvetica,'Arial Narrow',Calibri,Arial,'Lucida Grande',sans-serif",
      fontSize: 40,
      fontWeight: "bold",
      textAlign: "center",
    },
    paragraph: {
      fontFamily: "'Segoe UI','Helvetica Neue',Helvetica,Arial,sans-serif;",
      fontSize: 16,
      textAlign: "center",
    },
    kendoParagraph: {
      fontFamily: "Metric,system-ui,-apple-system,sans-serif;",
      fontSize: 24,
      fontWeight: 300,
      textAlign: "center",
    },
    ticketsImage: {
      maxWidth: "90%",
      width: "580px",
      margin: "32px",
    },
    gridListItem: {
      padding: 16,
    },
    contentRight: {
      marginLeft: 100,
      marginRight: 32,
    },
    contentLeft: {
      marginLeft: 32,
      marginRight: 100,
    },
    gridItemIcon: {
      width: 32,
      height: 32,
      opacity: 0.9,
    },
    [theme.breakpoints.down("md")]: {
      contentRight: {
        marginLeft: 50,
        marginRight: 32,
      },
      contentLeft: {
        marginLeft: 32,
        marginRight: 50,
      },
    },
    [theme.breakpoints.down("sm")]: {
      contentLeft: {
        marginLeft: 24,
        marginRight: 24,
        padding: 0,
      },
      contentRight: {
        marginLeft: 24,
        marginRight: 24,
        padding: 0,
      },
      headerImage: {
        maxHeight: 220,
      },
    },

    buttonText: {
      fontSize: 16,
    },
    featureButton: {
      margin: "16px",
    },
    iconButton: {
      borderRadius: 8,
    },
    darkFont: {
      color: "black",
    },
    lightFont: {
      color: "white",
    },
  }));
  const styles = useStyles();

  const history = useHistory();

  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const [buttonGoodsIn, setButtonGoodsIn] = useState<boolean>(false);
  const [buttonGoodsOut, setButtonGoodsOut] = useState<boolean>(false);
  const [buttonPublics, setButtonPublics] = useState<boolean>(false);

  const [buttonScales, setButtonScales] = useState<boolean>(false);
  const [buttonPrinters, setButtonPrinters] = useState<boolean>(false);
  const [buttonTicketRs232, setButtonTicketRs232] = useState<boolean>(false);
  const [buttonTicketWindows, setButtonTicketWindows] =
    useState<boolean>(false);
  const [buttonEmail, setButtonEmail] = useState<boolean>(false);

  const showOperationsButton = (action: () => void) => {
    setButtonGoodsIn(false);
    setButtonGoodsOut(false);
    setButtonPublics(false);
    action();
  };

  const showDeviceButton = (action: () => void) => {
    setButtonScales(false);
    setButtonPrinters(false);
    action();
  };

  const showTicketsButton = (action: () => void) => {
    setButtonTicketRs232(false);
    setButtonTicketWindows(false);
    setButtonEmail(false);
    action();
  };

  const gotoTrial = () => {
    const request: IRequestTrialParams = {
      productId: "9F9C2932-E034-498D-9815-A905EF04F583",
      productName: "Weighbridge",
      templateId: "weighbridge-trial",
      trialDays: 30,
    };
    const requestBase64 = btoa(JSON.stringify(request));
    history.push(`/product-trial/${requestBase64}`);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [dialogImage, setDialogImage] = useState<string>(); // useState<ImgHTMLAttributes<HTMLImageElement> | undefined>(undefined);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleOpenDialog = (image: string | undefined) => {
    //  image: ImgHTMLAttributes<HTMLImageElement>) => {
    setDialogImage(image);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogImage(undefined);
  };

  const PopupItem: React.FC<IPopupItem> = ({ data, isVisible }) => {
    return (
      <Grow in={isVisible}>
        <p className={styles.kendoParagraph}>{data}</p>
      </Grow>
    );
  };

  const FeatureButton = ({ text, image, id, handleClick }) => {
    return (
      <div className={styles.featureButton}>
        <IconButton
          aria-describedby={id}
          aria-label={text}
          className={styles.iconButton}
          disableFocusRipple
          style={{ outline: "none" }}
          onClick={handleClick}
        >
          <div>
            <p className={styles.buttonText}>{text}</p>
            {image}
          </div>
        </IconButton>
      </div>
    );
  };

  interface IProductSectionProps {
    title: string;
    description: string;
    alignLeft: boolean;
    image: string;
    buttons: IProductButton[];
  }

  const ProductSection = ({
    id,
    title,
    description,
    backgroundColor,
    alignLeft,
    image,
    handleImageClick,
    buttons,
  }) => {
    const [showButton, setShowButton] = useState<boolean>(false);
    const [buttonText, setButtonText] = useState();

    const handleButtonClick = (button: IProductButton) => {
      setButtonText(button?.data);
      setShowButton(Boolean(button?.data));
    };

    return (
      <Grid
        item
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={6}
        style={{ backgroundColor: backgroundColor }}
      >
        <Grid item xl md sm={12} />
        {alignLeft === false && mobileScreen === false && (
          <Grid item xl={4} md={5} sm={12}>
            <img
              src={image}
              className={styles.ticketsImage}
              onClick={handleImageClick}
            />
          </Grid>
        )}
        <Grid item xl={4} md={5} sm={12} style={{ margin: 32 }}>
          <div className={styles.contentLeft}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <p className={styles.title}>{title}</p>
              </Grid>
              <Grid item>
                <p className={styles.kendoParagraph}>{description}</p>
              </Grid>
              <Grid item container direction="row" justify="center">
                {buttons.map((b) => (
                  <div key={b.id}>
                    <FeatureButton
                      text={b.text}
                      image={b.image}
                      id={b.id}
                      handleClick={() => handleButtonClick(b)}
                    />
                  </div>
                ))}
              </Grid>
              <Grid item container id={id} direction="row" justify="center">
                {showButton && <Grow in={id}>{buttonText}</Grow>}
              </Grid>
            </Grid>
          </div>
        </Grid>
        {(alignLeft === true || mobileScreen === true) && (
          <Grid item xl={4} md={5} sm={12}>
            <img
              src={image}
              className={styles.ticketsImage}
              onClick={handleImageClick}
            />
          </Grid>
        )}
        <Grid item xl md sm={12} />
      </Grid>
    );
  };

  const GridItemSection = ({ key, image, title, text }) => {
    return (
      <Grid container direction="row" justify="center" alignItems="flex-start">
        <Grid
          item
          xs={12}
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={styles.gridListItem}
        >
          {image}
          {/* <img
          alt={key}
          src={image}
          style={{ width: 32, height: 32, opacity: 0.9 }}
        /> */}
          <Typography
            variant="body2"
            style={{ wordWrap: "break-word", maxWidth: 280 }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item className={styles.gridListItem}>
          <Typography variant="body1">{text}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div style={{ marginLeft: -16, marginRight: -16 }}>
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Grid
          item
          container
          direction="row"
          className={
            themeName === "dark"
              ? styles.headerBandDark
              : styles.headerBandLight
          }
        >
          <Grid item xs>
            <img src={Header1Image} className={styles.headerImage} />
          </Grid>
          <Grid
            container
            item
            xs
            direction="column"
            spacing={4}
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="h1"
                style={{ color: "white", textAlign: "center" }}
              >
                Weighbridge
              </Typography>
              <Typography
                variant="h4"
                style={{ color: "white", textAlign: "center" }}
              >
                Software
              </Typography>
            </Grid>
            <Grid item>
              {mobileScreen === false && (
                <Typography variant="h5" style={{ color: "white" }}>
                  {resources.productDescription}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => gotoTrial()}
                className={
                  themeName === "dark" ? styles.darkFont : styles.lightFont
                }
              >
                {resources.trial}
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={3} md={2} xs={1} />
        </Grid>

        <ProductSection
          id="operations-selection1"
          title={resources.operations}
          description={resources.operationsText}
          image={TransactionsImage}
          backgroundColor="transparent"
          alignLeft={true}
          handleImageClick={() => handleOpenDialog(TransactionsImage)}
          buttons={[
            {
              id: "goods-in-button",
              text: resources.inbound,
              image: (
                <img
                  alt="open"
                  src={TruckFullIcon}
                  style={{ width: 24, height: 24, opacity: 0.8 }}
                />
              ),
              data: <p className={styles.paragraph}>{resources.inboundText}</p>,
            },
            {
              id: "goods-out-button",
              text: resources.outbound,
              image: (
                <img
                  alt="open"
                  src={TruckEmptyIcon}
                  style={{ width: 24, height: 24, opacity: 0.8 }}
                />
              ),
              data: (
                <p className={styles.paragraph}>{resources.outboundText}</p>
              ),
            },
            {
              id: "public-button",
              text: resources.public,
              image: <LocalShippingIcon />,
              data: <p className={styles.paragraph}>{resources.publicText}</p>,
            },
          ]}
        />

        <ProductSection
          id="devices-section"
          title={resources.devices}
          description={resources.devicesText}
          image={ScaleImage}
          backgroundColor={themeName === "dark" ? "#333333" : "#f2f2f2"}
          alignLeft={false}
          handleImageClick={() => true}
          buttons={[
            {
              id: "scales-button",
              text: resources.scales,
              image: (
                <img
                  alt="open"
                  src={WeightIcon}
                  style={{ width: 24, height: 24 }}
                />
              ),
              data: (
                <p className={styles.paragraph}>
                  {resources.scalesText}
                  <br />
                  <a href="#">Learn more</a>
                </p>
              ),
            },
            {
              id: "printers-button",
              text: resources.printers,
              image: <PrintIcon />,
              data: (
                <p className={styles.paragraph}>{resources.printersText}</p>
              ),
            },
          ]}
        />

        <ProductSection
          id="tickets-section"
          title={resources.tickets}
          description={resources.ticketsText}
          image={TicketsImage}
          backgroundColor="transparent"
          alignLeft={true}
          handleImageClick={() => handleOpenDialog(TicketsImage)}
          buttons={[
            {
              id: "rs232-button",
              text: resources.ticketsRs232,
              image: (
                <img
                  alt="open"
                  src={BarCodeIcon}
                  style={{ width: 24, height: 24, opacity: 0.8 }}
                />
              ),
              data: (
                <p className={styles.paragraph}>{resources.ticketsRs232Text}</p>
              ),
            },
            {
              id: "win-printer-button",
              text: resources.ticketsWin,
              image: <PrintIcon />,
              data: (
                <p className={styles.paragraph}>{resources.ticketsWinText}</p>
              ),
            },
            {
              id: "email-button",
              text: resources.ticketsEmail,
              image: <EmailOutlined />,
              data: (
                <p className={styles.paragraph}>{resources.ticketsEmailText}</p>
              ),
            },
          ]}
        />

        <ProductSection
          id="reports-section"
          title={resources.reports}
          description={resources.reportsText}
          image={ReportsImage}
          backgroundColor="transparent"
          alignLeft={false}
          handleImageClick={() => handleOpenDialog(ReportsImage)}
          buttons={[]}
        />

        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{
            backgroundColor: themeName === "dark" ? "#f8f8f8" : "#323a45",
            padding: 48,
          }}
        >
          <Grid item>
            <Typography
              variant="h3"
              className={
                themeName === "dark" ? styles.darkFont : styles.lightFont
              }
            >
              {resources.trialDays}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h6"
              className={
                themeName === "dark" ? styles.darkFont : styles.lightFont
              }
            >
              {resources.demoAvailable}
            </Typography>
          </Grid>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => gotoTrial()}
            className={
              themeName === "dark" ? styles.darkFont : styles.lightFont
            }
          >
            {resources.requestTrial}
          </Button>
        </Grid>

        <Grid container direction="column" justify="center" alignItems="center">
          <GridList
            cellHeight={240}
            cols={3}
            spacing={4}
            style={{ maxWidth: "60%", marginTop: 48 }}
          >
            {/* <GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
                <ListSubheader component="div">December</ListSubheader>
              </GridListTile> */}
            {/* {tileData.map((tile) => ( */}

            <GridListTile key="fields">
              <GridItemSection
                //key="fields"
                title={resources.customFields}
                image={<WebIcon className={styles.gridItemIcon} />}
                text={resources.customFieldsText}
              />
            </GridListTile>
            <GridListTile key="devices">
              <GridItemSection
                //key="devices"
                title={resources.multipleScales}
                image={<DeviceHubIcon className={styles.gridItemIcon} />}
                text={resources.multipleScalesText}
              />
            </GridListTile>
            <GridListTile key="stored-tare">
              <GridItemSection
                //key="stored-tare"
                title={resources.storedTare}
                image={<LocalShippingIcon className={styles.gridItemIcon} />}
                text={resources.storedTareText}
              />
            </GridListTile>
            <GridListTile key="manual">
              <GridItemSection
                //key="manual"
                title={resources.offline}
                image={<BuildIcon className={styles.gridItemIcon} />}
                text={resources.offlineText}
              />
            </GridListTile>
            <GridListTile key="indicators">
              <GridItemSection
                //key="indicators"
                title={resources.indicatorsSpecs}
                image={<MoneyIcon className={styles.gridItemIcon} />}
                text={resources.indicatorsSpecsText}
              />
            </GridListTile>
            <GridListTile key="protocols">
              <GridItemSection
                //key="protocols"
                title={resources.protocols}
                image={
                  <SettingsInputHdmiIcon className={styles.gridItemIcon} />
                }
                text={resources.protocolsText}
              />
            </GridListTile>
            <GridListTile key="users">
              <GridItemSection
                //key="users"
                title={resources.users}
                image={<PersonIcon className={styles.gridItemIcon} />}
                text={resources.usersText}
              />
            </GridListTile>
            <GridListTile key="excel">
              <GridItemSection
                //key="excel"
                title={resources.excel}
                image={
                  <img
                    alt="excel"
                    src={ExcelIcon}
                    className={styles.gridItemIcon}
                  />
                }
                text={resources.excelText}
              />
            </GridListTile>
            <GridListTile key="pdf">
              <GridItemSection
                //key="pdf"
                title={resources.email}
                image={<EmailOutlined className={styles.gridItemIcon} />}
                text={resources.emailText}
              />
            </GridListTile>
          </GridList>
        </Grid>

        <Dialog
          fullScreen={fullScreen}
          maxWidth="xl"
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <IconButton>
              <CloseIcon onClick={handleCloseDialog} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {dialogImage && <img src={dialogImage} width="75%" height="75%" />}
          </DialogContent>
        </Dialog>
      </Grid>
    </div>
  );
};
export default WeighBridgePage;
