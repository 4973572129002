import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  agreeButtonText: string;
  cookiesConsent: string;
  privacyPolicy: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    agreeButtonText: "Agree",
    privacyPolicy: "Privacy Policy",
    cookiesConsent:
      "This website uses cookies to ensure you get the best experience on our website.   To find out more about the cookies we use, see our ",
  },
  es: {
    agreeButtonText: "Aceptar",
    privacyPolicy: "Políticas de uso",
    cookiesConsent:
      "Utilizamos cookies para brindarte una mejor experiencia en línea, algunas de las cookies que utilizamos identifican tus hábitos de navegación. Al continuar en este sitio, aceptas el uso de dichas cookies. Para conocer más acerca de nuestra política de uso de cookies visita ",
  },
});
