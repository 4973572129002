import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  FormControl,
  Grid,
  Button,
  TextField,
  Dialog,
  CircularProgress,
  DialogTitle,
  DialogContent,
  Typography,
  NativeSelect,
  InputLabel,
  Chip,
  IconButton,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  IOfflineLicenseInstallRequest,
  IOfflineLicenseResult,
  IUserProgramAndLicenseItem,
  IUserProgramsAndLicenses,
} from "../../../../api/Account/myLicensesApi";
import ActivationImage from "../../../../images/licenses/activate-light.png";
import IResources, { resources } from "./.resources";
import { RootState } from "../../../../redux/reducers/rootReducer";
import WarningIcon from "@material-ui/icons/Warning";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CheckIcon from "@material-ui/icons/Check";
import HelpIcon from "@material-ui/icons/Help";
import { IUserV0 } from "../../../../api/Account/usersApi";
import FileCopyIcon from "@material-ui/icons/FileCopy";

export interface INewLicenseDialogProps {
  programsAndLicenses: IUserProgramsAndLicenses[];
  user?: IUserV0;
  onBehalfOf?: IUserV0;
  open: boolean;
  handleSubmit: (
    request: IOfflineLicenseInstallRequest,
    onSuccess: (result: IOfflineLicenseResult) => void,
    onFail: () => void
  ) => void;
  handleClose: (result: boolean) => void;
  handleNotification: (msg?: string) => void;
}

interface IKeyValuePair {
  key: string;
  value?: string;
}
interface IStation {
  id: number;
  name?: string;
}

const NewLicenseDialog: React.FC<INewLicenseDialogProps> = ({
  programsAndLicenses,
  user,
  onBehalfOf,
  open,
  handleSubmit,
  handleClose,
  handleNotification,
}) => {
  const DEFAULTSTATIONNAME = "Default";

  const [confirmView, setConfirmView] = useState<boolean>(false);
  const [products, setProducts] = useState<IKeyValuePair[]>([]);
  const [branches, setBranches] = useState<string[]>([]);
  const [stations, setStations] = useState<IStation[]>([]);
  const [productName, setProductName] = useState<string>();
  const [usedLicenses, setUsedLicenses] = useState<number>(0);
  const [maxLicenses, setMaxLicenses] = useState<number>(3);
  const [availableLicenses, setAvailableLicenses] = useState<number>(0);
  const [canGoNext, setCanGoNext] = useState<boolean>(false);
  const [validRequestCode, setValidRequestCode] = useState<boolean>(false);
  const [showHow, setShowHow] = useState<boolean>(false);
  const [licenseType, setLicenseType] = useState<string>();
  const [licenseResult, setLicenseResult] = useState<IOfflineLicenseResult>();

  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      productId: "",
      branchName: "",
      station: 1,
      requestCode: "",
      email: "",
      notes: "",
    },
    validationSchema: Yup.object({
      productId: Yup.string().required("Required"),
      requestCode: Yup.string().required("Required"),
    }),
    onSubmit: async () => {
      await handleSubmit(
        {
          productId: formik.values.productId,
          branchName: formik.values.branchName,
          station: formik.values.station,
          requestCode: formik.values.requestCode,
          licenseType: licenseType,
          email: formik.values.email,
          userId: onBehalfOf?.userId || user?.userId || "",
          requestorId: user?.userId,
          notes: formik.values.notes,
        },
        (result) => {
          //new license
          setLicenseResult(result);
        },
        () => {
          //error
          setConfirmView(false);
        }
      );
    },
  });

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      formControls: {
        padding: "3px 0px 12px 0px",
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
      input: {
        verticalAlign: "top",
        marginTop: "0px",
        paddingTop: "0px",
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      image: {
        width: 320,
        opacity: 0.8,
      },
      warning: {
        margin: "0 0 16px 0",
      },
      actionButton: {
        margin: "0 4px 0 4px",
      },
      copyButton: {
        margin: "0 0 0 4px",
      },
    })
  );
  const styles = useStyles();

  const shareToClipboard = (msg?: string) => {
    if (msg && msg !== "") {
      navigator.clipboard.writeText(msg!).then(() => {
        handleNotification(`Copied to clipboard: ${msg}`);
      });
    }
  };

  const resetSelection = () => {
    setMaxLicenses(0);
    setUsedLicenses(0);
    setAvailableLicenses(0);
    setLicenseType(undefined);
  };

  const refreshLicenses = (
    productId: string,
    branchName: string | undefined,
    stationId: number
  ) => {
    if (productId) {
      const prog = programsAndLicenses.find(
        (f) =>
          f.productId == productId &&
          f.stations >= stationId &&
          ((f.branches.length === 0 && (branchName || "") === "") ||
            f.branches.find((b) => b == branchName))
      );
      if (prog) {
        if (prog.scopes.find((sc) => sc === "FullLicense")) {
          setLicenseType("F");
        } else if (prog.scopes.find((sc) => sc === "SupportLicense")) {
          setLicenseType("D");
        }
        const location = prog.locations.find(
          (x) =>
            (x.branch || "") === (branchName || "") && x.stationId === stationId
        );
        if (location) {
          const usedLicenses = location.licenses.filter((l) => l.isActive)
            .length;
          setMaxLicenses(location.maxLicenses);
          setUsedLicenses(usedLicenses);
          setAvailableLicenses(location.maxLicenses - usedLicenses);
          return;
        }
      }
    }
    resetSelection();
  };

  const refreshBranches = (productId: string) => {
    const branchList: string[] = [];
    const stationsList: IStation[] = [];
    let selectedBranch: string | undefined = undefined;
    resetSelection();
    if (productId) {
      programsAndLicenses
        .filter((f) => f.productId == productId)
        .forEach((p) => {
          p.branches.forEach((b) => {
            if (!branchList.find((l) => l === b)) {
              branchList.push(b);
            }
          });

          if (stationsList.length === 0) {
            const progStations =
              p.stationNames.length >= 1
                ? [...p.stationNames]
                : [DEFAULTSTATIONNAME];
            progStations
              .map<IStation>((ps, index) => ({ id: index + 1, name: ps }))
              .forEach((kv) => stationsList.push(kv));
          }
          selectedBranch = branchList.length > 0 ? branchList[0] : undefined;
          refreshLicenses(productId, selectedBranch, 1);
        });
    }
    setBranches([...branchList]);
    setStations([...stationsList]);
    formik.setFieldValue("branchName", selectedBranch);
    formik.setFieldValue("station", 1);
  };

  const preValidate = (requestCode?: string) => {
    if (requestCode) {
      const regEx = new RegExp(
        "^[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}-[a-zA-Z0-9]{6}"
      );
      if (regEx.test(requestCode)) {
        setCanGoNext(availableLicenses > 0);
        setValidRequestCode(true);
        return;
      }
    }
    setValidRequestCode(false);
    setCanGoNext(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
    if (name === "requestCode") {
      preValidate(value);
    }
  };

  const handleBranchChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
    refreshLicenses(formik.values.productId, value, formik.values.station);
  };

  const handleStationChange = (event) => {
    const { name, value } = event.target;
    const stationId = parseInt(value);
    formik.setFieldValue(name, stationId); // this call formik to set your value
    refreshLicenses(
      formik.values.productId,
      formik.values.branchName,
      stationId
    );
  };

  const handleProductChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
    const selection = products.find((p) => p.key == value);
    setProductName(selection?.value);
    refreshBranches(value);
  };

  useEffect(() => {
    preValidate(formik.values.requestCode);
  }, [availableLicenses]);

  useEffect(() => {
    if (open) {
      formik.setFieldValue("productId", "");
      formik.setFieldValue("branchName", "");
      formik.setFieldValue("requestCode", "");
      formik.setFieldValue("station", 1);
      formik.setFieldValue("email", onBehalfOf?.email || "");
      setShowHow(false);
      setConfirmView(false);
      setLicenseResult(undefined);
      setLicenseType(undefined);
      const progList: IKeyValuePair[] = [{ key: "", value: undefined }];
      programsAndLicenses.forEach((p) => {
        if (
          !progList.find((i) => i.key === p.productId) &&
          (user?.isAdmin === true ||
            p.requestorScopes.find((sc) => sc === "OfflineLicense"))
        ) {
          progList.push({ key: p.productId, value: p.productName });
        }
      });
      setProducts([...progList]);
      refreshBranches(formik.values.productId);
    }
  }, [open]);

  const findLicenseLocation: () =>
    | IUserProgramAndLicenseItem
    | undefined = () => {
    let licenseLocation: IUserProgramAndLicenseItem | undefined = undefined;
    programsAndLicenses
      .filter((f) => f.productId == formik.values.productId)
      .forEach((p) => {
        if (!licenseLocation) {
          licenseLocation = p.locations.find(
            (l) =>
              l.branch === formik.values.branchName &&
              l.stationId === formik.values.station
          );
        }
      });
    return licenseLocation;
  };

  useEffect(() => {
    if (confirmView) {
      const licenseLocation = findLicenseLocation();
      if (licenseLocation) {
        setMaxLicenses(licenseLocation.maxLicenses);
        setUsedLicenses(licenseLocation.licenses.length);
        setAvailableLicenses(
          licenseLocation.maxLicenses - licenseLocation.licenses.length
        );
      }
    }
  }, [confirmView]);

  const HowToView = ({ gridSize }) => {
    return (
      <Grid
        item
        container
        xs={gridSize}
        direction="column"
        alignItems="flex-start"
        justify="flex-start"
      >
        <Grid item>
          <ul style={{ paddingLeft: 16 }}>
            <li>{res.newLicenseStep1}</li>
            <li>{res.newLicenseStep2}</li>
            <li>{res.newLicenseStep3}</li>
          </ul>
        </Grid>
        <Grid item>
          <img alt="logo" src={ActivationImage} className={styles.image} />
        </Grid>
      </Grid>
    );
  };

  const ConfirmView = ({ gridSize }) => {
    return (
      <Grid
        item
        container
        xs={gridSize}
        direction="row"
        alignItems="flex-start"
        justify="flex-start"
        spacing={2}
      >
        <Grid item xs={12}>
          {res.confirmDetails}
        </Grid>
        {/* {onBehalfOf?.userId && (
          <> */}
        <Grid item xs={4}>
          User:
        </Grid>
        <Grid item container direction="column" xs={8}>
          {user?.userId !== onBehalfOf?.userId ? (
            <Typography variant="body2">
              {`${user?.name} on Behalf of ${onBehalfOf?.name}`}
            </Typography>
          ) : (
            <Typography variant="body2">{onBehalfOf?.name}</Typography>
          )}
          <Typography variant="caption">{onBehalfOf?.companyName}</Typography>
        </Grid>
        {/* </>
        )} */}

        <Grid item xs={4}>
          {res.productName}
        </Grid>
        <Grid item container direction="column" xs={8}>
          <Typography variant="body2">{productName}</Typography>
          <Typography variant="caption">{formik.values.productId}</Typography>
        </Grid>
        <Grid item xs={4}>
          {res.branchName}
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">{formik.values.branchName}</Typography>
        </Grid>
        <Grid item xs={4}>
          {res.stationName}
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">{formik.values.station}</Typography>
        </Grid>
        <Grid item xs={4}>
          Request Code
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">{formik.values.requestCode}</Typography>
        </Grid>
        <Grid item xs={4}>
          Mode
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2">{licenseType}</Typography>
        </Grid>
        <Grid item xs={4}>
          {res.emailLicenseTo}
        </Grid>
        <Grid item xs={8}>
          <Typography variant="body2"> {formik.values.email} </Typography>
        </Grid>
        <Grid item xs={1}>
          <WarningIcon color="primary" />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="caption" className={styles.warning}>
            {res.newLicenseWarning}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const CongratulationsView = ({ gridSize }) => {
    return (
      <Grid
        item
        container
        xs={gridSize}
        direction="row"
        alignItems="flex-start"
        justify="flex-start"
        spacing={2}
      >
        <Grid item xs={12}>
          {res.licenseSuccessfullyGenerated}
        </Grid>

        <Grid item xs={3}>
          {res.licensedTo}
        </Grid>
        <Grid item container direction="row" xs={9}>
          <Typography variant="body2">{licenseResult?.licensedTo}</Typography>
          <IconButton
            size="small"
            aria-label="copy"
            className={styles.copyButton}
            onClick={() => shareToClipboard(licenseResult?.licensedTo)}
          >
            <FileCopyIcon fontSize="small" />
          </IconButton>
        </Grid>

        <Grid item xs={3}>
          Revision:
        </Grid>
        <Grid
          item
          container
          direction="row"
          xs={formik.values.branchName ? 3 : 9}
        >
          <Typography variant="body2">{licenseResult?.licenseType}</Typography>
        </Grid>

        {formik.values.branchName && (
          <>
            <Grid item xs={3}>
              {res.branchName}
            </Grid>
            <Grid item container direction="row" xs={3}>
              <Typography variant="body2">
                {formik.values.branchName}
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={3}>
          {res.licenseKey}
        </Grid>
        <Grid item container direction="row" xs={9}>
          <Typography variant="body2">{licenseResult?.licenseKey}</Typography>
          <IconButton
            size="small"
            aria-label="copy"
            className={styles.copyButton}
            onClick={() => shareToClipboard(licenseResult?.licenseKey)}
          >
            <FileCopyIcon fontSize="small" />
          </IconButton>
        </Grid>

        {stations.length > 1 && (
          <Grid item container direction="column" xs={12}>
            <Typography variant="caption">
              {res.licenseStationReminder.replace(
                "{0}",
                stations.find((s) => s.id === formik.values.station)?.name ||
                  "?"
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="new project"
      fullWidth={true}
      maxWidth={showHow ? "md" : "sm"}
    >
      <DialogTitle id="form-dialog-title">
        <>
          <Typography variant="h3">{res.newLicenseTitle}</Typography>
          {!licenseResult && (
            <Typography variant="body2">{res.newLicenseSubTitle}</Typography>
          )}
        </>
      </DialogTitle>
      <DialogContent>
        {licenseResult ? (
          <Grid
            container
            spacing={2}
            xs={12}
            direction="row"
            alignItems="flex-end"
          >
            <CongratulationsView gridSize={12} />
            <Grid item xs={4}>
              <Button
                disabled={formik.isSubmitting}
                onClick={() => handleClose(true)}
                className={styles.actionButton}
                type="reset"
                color="primary"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        ) : (
          <form onSubmit={formik.handleSubmit} className={styles.root}>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="flex-start"
              className={styles.formControls}
            >
              {confirmView ? (
                <ConfirmView gridSize={showHow ? 7 : 12} />
              ) : (
                <Grid
                  item
                  container
                  xs={showHow ? 7 : 12}
                  spacing={4}
                  direction="row"
                  alignItems="flex-start"
                  justify="flex-start"
                >
                  <Grid item xs={12}>
                    <InputLabel id="user-label">{res.productName}</InputLabel>
                    <NativeSelect
                      value={formik.values.productId}
                      style={{ width: "100%" }}
                      onChange={handleProductChange}
                      inputProps={{
                        name: "productId",
                        id: "productId",
                      }}
                    >
                      {products.map((item) => (
                        <option key={item.key} value={item.key}>
                          {item.value}
                        </option>
                      ))}
                    </NativeSelect>
                  </Grid>

                  {(branches?.length > 0 || stations?.length > 0) && (
                    <>
                      <Grid item xs={8}>
                        {branches?.length > 0 && (
                          <>
                            <InputLabel id="user-label">
                              {res.branchName}
                            </InputLabel>
                            <NativeSelect
                              value={formik.values.branchName}
                              style={{ width: "100%" }}
                              onChange={handleBranchChange}
                              inputProps={{
                                name: "branchName",
                                id: "branchName",
                              }}
                            >
                              {branches.map((item) => (
                                <option key={item} value={item}>
                                  {item}
                                </option>
                              ))}
                            </NativeSelect>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        {stations?.length > 1 && (
                          <>
                            <InputLabel id="user-label">
                              {res.stationName}
                            </InputLabel>
                            <NativeSelect
                              value={formik.values.station}
                              style={{ width: "100%" }}
                              onChange={handleStationChange}
                              inputProps={{
                                name: "station",
                                id: "station",
                              }}
                            >
                              {stations.map((item) => (
                                <option key={item.id} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </NativeSelect>
                          </>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={10}>
                    <FormControl
                      fullWidth
                      error={
                        Boolean(formik.errors.requestCode) &&
                        formik.touched.requestCode
                      }
                    >
                      <TextField
                        label="Request Code"
                        name="requestCode"
                        type="text"
                        onBlur={formik.handleBlur}
                        //InputLabelProps={{ shrink: true }}
                        value={formik.values.requestCode || ""}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>
                  <Grid
                    xs={2}
                    item
                    container
                    direction="column"
                    alignItems="flex-end"
                  >
                    {validRequestCode ? (
                      <CheckIcon style={{ marginTop: 16 }} />
                    ) : (
                      <IconButton href="#" onClick={() => setShowHow(!showHow)}>
                        <HelpIcon color={showHow ? "primary" : "inherit"} />
                      </IconButton>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      error={
                        Boolean(formik.errors.requestCode) &&
                        formik.touched.requestCode
                      }
                    >
                      <TextField
                        label={res.emailLicenseTo}
                        name="email"
                        type="email"
                        value={formik.values.email}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        label="Notes (optional)"
                        name="notes"
                        type="text"
                        value={formik.values.notes || ""}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    {maxLicenses > 0 && (
                      <div>
                        {availableLicenses > 0 ? (
                          <>
                            <Chip label={`${usedLicenses} in use`} />
                            <Chip
                              label={`${availableLicenses} available`}
                              color="primary"
                            />
                            <Chip label={`Mode ${licenseType}`} />
                          </>
                        ) : (
                          <>
                            <Chip label={`${usedLicenses} in use`} />
                            <Typography color="error">
                              {res.maxLicensesExceeded}
                            </Typography>
                          </>
                        )}
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}

              {showHow && <HowToView gridSize={5} />}
            </Grid>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <Button
                  disabled={formik.isSubmitting}
                  onClick={() => handleClose(false)}
                  className={styles.actionButton}
                  type="reset"
                  color="primary"
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item container direction="row" justify="flex-end" xs={8}>
                {confirmView ? (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={styles.actionButton}
                      startIcon={<ArrowBackIosIcon />}
                      onClick={() => setConfirmView(false)}
                    >
                      {res.back}
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={styles.actionButton}
                      disabled={formik.isSubmitting}
                    >
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>{res.submit}</Grid>
                        {formik.isSubmitting && (
                          <Grid item>
                            <CircularProgress size={18} />
                          </Grid>
                        )}
                      </Grid>
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!canGoNext}
                    className={styles.actionButton}
                    endIcon={<NavigateNextIcon />}
                    onClick={() => setConfirmView(true)}
                  >
                    {res.next}
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default NewLicenseDialog;
