import * as types from "../actions/constants";
import { ISession, noSession } from "../../types/Auth";

const initialState: ISession = noSession();

const authReducer = (state: ISession = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
    case types.GET_USER_SUCCESS:
      return action.auth;
    case types.LOGOUT_SUCCESS:
      return noSession();
    default:
      return state;
  }
};

export default authReducer;
