import React, { useEffect } from "react";

const ForgotPasswordPage: React.FC = () => {
  useEffect(() => {
    window.location.assign(
      "https://softion.b2clogin.com/softion.onmicrosoft.com/b2c_1_passwordreset/oauth2/v2.0/authorize?response_type=id_token&scope=openid%20profile&client_id=2cee1c2a-0771-4998-9df7-d4e64a552516&redirect_uri=https%3A%2F%2Fwww.softion.com%2F&&response_mode=fragment"
    );
  }, []);

  return (
    <div id="forgot'password-page">
      <div>Redirecting to Microsoft security portal...</div>
    </div>
  );
};

export default ForgotPasswordPage;
