import React from "react";
import Moment from "react-moment";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { IUserV1 } from "../../../api/Account/usersApi";
import Badge from "@material-ui/core/Badge";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Grid from "@material-ui/core/Grid";

//https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/

export interface IUserCardProps {
  selection?: IUserV1;
  roleName?: string;
  closeCard: () => void;
  displayInfoMessage: (text: string | null | undefined) => void;
  handleFindUsages: (userId: string) => void;
  handleDelete: (request: IUserV1) => void;
}

const UserCard: React.FC<IUserCardProps> = ({
  selection,
  roleName,
  closeCard,
  displayInfoMessage,
  handleFindUsages,
  handleDelete,
}) => {
  // function renderUserImage(user: IUserV1 | undefined) {
  //   if (user?.isAdmin === true) {
  //     return AdminImage;
  //   } else if (user?.isDistributor === true) {
  //     return DistributorImage;
  //   } else if (user?.isSupport === true) {
  //     return SupportImage;
  //   } else if (user?.isCustomer === true) {
  //     return CustomerImage;
  //   }
  //   return null;
  // }

  const StatusTooltip = () => {
    if (selection?.syncStatus === 1) {
      return <Typography>User not listed in Azure B2C Directory</Typography>;
    } else if (selection?.syncStatus === 2) {
      return <Typography>User not in the database</Typography>;
    }
    return (
      <Typography>
        <span>User last updated on: </span>
        {selection?.lastUpdate ? (
          <Moment format="DD/MM/YYYY">{selection.lastUpdate}</Moment>
        ) : null}
      </Typography>
    );
  };

  const cardStyles = makeStyles({
    card: {
      margin: "32px",
    },
    copyButton: {
      margin: "0px 2px 0px 6px",
    },
  });
  const useCardStyles = cardStyles();

  const copyToClipboard = (text: string | null | undefined) => {
    if (text) {
      navigator.clipboard
        .writeText(text || "")
        .then(() => displayInfoMessage("Copied to clipboard: " + text));
    }
  };

  const LabelWithCopyButton = ({ label, copyAction }) => {
    return (
      <Grid container>
        {label ? (
          <React.Fragment>
            <Grid item>
              <Typography gutterBottom variant="body2">
                {label}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={useCardStyles.copyButton}
                size="small"
                aria-label="copy"
                onClick={() => copyAction()}
              >
                <FileCopyIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </React.Fragment>
        ) : null}
      </Grid>
    );
  };

  return (
    <Card className={useCardStyles.card}>
      <CardContent>
        {selection && (
          <>
            <Badge
              color="error"
              badgeContent="!"
              style={{ padding: "2px 4px 2px 0px" }}
              invisible={selection?.syncStatus === 0}
            >
              <Tooltip arrow title={<StatusTooltip />}>
                <Typography gutterBottom variant="h5" component="h2">
                  {selection?.name}
                </Typography>
              </Tooltip>
            </Badge>

            <Typography gutterBottom variant="subtitle1" component="h2">
              {selection?.companyName}
            </Typography>
            <Typography gutterBottom variant="subtitle2" component="h2">
              <span>Access level: </span>
              {roleName}
            </Typography>
            <LabelWithCopyButton
              label={selection?.email}
              copyAction={() => copyToClipboard(selection?.email)}
            />
            <LabelWithCopyButton
              label={selection?.userId}
              copyAction={() => copyToClipboard(selection?.userId)}
            />
            <Typography gutterBottom variant="subtitle1" component="h2">
              {selection?.phoneNumbers}
            </Typography>
            <Typography gutterBottom variant="subtitle1" component="h2">
              {selection?.country}
            </Typography>
            <Typography gutterBottom variant="subtitle1" component="h2">
              {selection?.language}
            </Typography>
          </>
        )}
      </CardContent>

      <CardActions>
        {selection?.isExternal === true && (
          <Button
            size="small"
            color="secondary"
            onClick={() => handleDelete(selection)}
          >
            Delete
          </Button>
        )}
        {selection?.userId && (
          <Button
            size="small"
            color="primary"
            onClick={() => handleFindUsages(selection.userId)}
          >
            Find Usages
          </Button>
        )}

        <Button size="small" color="primary" onClick={() => closeCard()}>
          Close
        </Button>
      </CardActions>
    </Card>
  );
};

export default UserCard;
