import LocalizedStrings, {
  IStrings,
  LocalizedStringMethods,
} from "react-localization";

export default interface IResources extends LocalizedStringsMethods {
  title: string;
  subTitle: string;
  useCaseAllEngineering: string;
  useCaseAllEngineeringStep1: string;
  useCaseAllEngineeringStep2: string;
  useCaseAllEngineeringStep3: string;
  useCaseAllEngineeringStep4: string;
  useCaseAllEngineeringStep5: string;
  useCaseAllEngineeringStep6: string;
  useCaseOldSoftware: string;
  useCaseOldSoftwareStep1: string;
  useCaseOldSoftwareStep2: string;
  useCaseOldSoftwareStep3: string;
  useCaseOldSoftwareStep4: string;
  useCaseOldSoftwareStep5: string;
  useCaseOldSoftwareStep6: string;
  useCaseOldSoftwareStep7: string;
  useCaseDeveloper: string;
  useCaseDeveloperStep1: string;
  useCaseDeveloperStep2: string;
  useCaseDeveloperStep3: string;
  useCaseDeveloperStep4: string;
  useCaseDeveloperStep5: string;
  useCaseDeveloperStep6: string;
  useCaseIntermediary: string;
  useCaseIntermediaryStep1: string;
  useCaseIntermediaryStep2: string;
  useCaseIntermediaryStep3: string;
  useCaseIntermediaryStep4: string;
  useCaseIntermediaryStep5: string;
  useCaseIntermediaryStep6: string;
  useCaseIntermediaryStep7: string;
  customer: string;
  developer: string;
  thirdParty: string;
  contactUs: string;
  intermediary: string;
  intermediaryAsCustomer: string;
  us: string;
  quote: string;
  knowYourSoftware: string;
  knowYourHardware: string;
  placeOrder: string;
  installation: string;
  installSoftware: string;
  installHardware: string;
  development: string;
  publish: string;
  confirmFinalVersion: string;
  redirect: string;
}

export const resources: IStrings = new LocalizedStrings({
  en: {
    title: "Purchasing Options",
    subTitle:
      "<p>We are a network of experts specialised in different areas of software, hardware and engineering. . Please contact us to offer you software demos and put you in touch a professional to give you further information on hardware and engineering.&nbsp; Please select the relevant situation from the following options:</p>",
    useCaseAllEngineering:
      "I need to automate a process and I need to buy equipment and software",
    useCaseAllEngineeringStep1:
      "Send us an email with what you need or have in mind.  You can tell us about any existing hardware you want to re-use",
    useCaseAllEngineeringStep2:
      "We will put you in touch with the right person to offer equipment and engineering",
    useCaseAllEngineeringStep3:
      "They will provide a quote and ETA including the software",
    useCaseAllEngineeringStep4:
      "The order covers hardware, software and installation services",
    useCaseAllEngineeringStep5:
      "They will schedule one or mutiple installation visits",
    useCaseAllEngineeringStep6:
      "The software is available for download on the website.  Optionally someone can set it up for you in one of their visits",
    useCaseDeveloper:
      "I am a software developer and building an app that communicates with scales and printers",
    useCaseDeveloperStep1:
      "Gather information of the hardware to connect, like scale indicator brand and model, printers and whether you are using barcode and printing in wet/harsh environment",
    useCaseDeveloperStep2:
      "Send us the information and please include whether you are using Excel, or any other application with your prefered protocol like HTTP, named-pipes, etc.",
    useCaseDeveloperStep3:
      "We will provide a quote and ETA. We may have products ready to download and use out of the box, or we can build something personalized",
    useCaseDeveloperStep4:
      "The order is for the software, it may also have hardware and installation services",
    useCaseDeveloperStep5:
      "Optional in case we are building something tailor-made",
    useCaseDeveloperStep6:
      "The software is available for download on the website.  Optionally you can contact us for assistance or we can send someone to help with the connections",
    useCaseOldSoftware: "I have an obsolete software and need a new one",
    useCaseOldSoftwareStep1:
      "Gather information about the existing software, e.g. screenshots, vendor website, version, etc. Tell us how it interacts with other systems",
    useCaseOldSoftwareStep2: "Send us the information",
    useCaseOldSoftwareStep3: "We will send you an estimate and ETA",
    useCaseOldSoftwareStep4: "We will contact you to discuss final details",
    useCaseOldSoftwareStep5:
      "We will provide a Demo pre-release version so we can make last minute adjustments",
    useCaseOldSoftwareStep6:
      "Confirm you are happy with the latest changes in order to complete the final stage of development",
    useCaseOldSoftwareStep7:
      "The software is available for download on the website.  Optionally we can send someone to install it if you need assistance",
    useCaseIntermediary:
      "I am automating or selling equipment to one of our clients and I need the software",
    useCaseIntermediaryStep1:
      "Tell us about your project and what kind of software you need",
    useCaseIntermediaryStep2: "We send you a quote and ETA",
    useCaseIntermediaryStep3:
      "We will start and complete development based on your timing and deliverables",
    useCaseIntermediaryStep4:
      "We will be in constant communication to discuss details and status updates",
    useCaseIntermediaryStep5:
      "You install the equipment and setup the environment",
    useCaseIntermediaryStep6:
      "We publish the software, training videos and documentation",
    useCaseIntermediaryStep7:
      "The customer or yourself (for confidentiality purposes) may access the website to download and activate their products.  We can provide remote or on-site support to help with the connections",
    customer: "Customer",
    developer: "Developer",
    thirdParty: "Third Party",
    contactUs: "Contact Us",
    intermediary: "Intermediary",
    intermediaryAsCustomer: "You",
    us: "Us",
    quote: "Quote",
    knowYourSoftware: "Know your Software",
    knowYourHardware: "Know your Hardware",
    placeOrder: "Place Order",
    installation: "Installation",
    installSoftware: "Install Software",
    installHardware: "Install Hardware",
    development: "Development",
    publish: "Publish Software",
    confirmFinalVersion: "Confirm Final Version",
    redirect: "Redirect",
  },

  es: {
    title: "Opciones de Compra",
    subTitle:
      "<p>Somos una red de especialistas en diferentes &aacute;reas de software, hardware e ingenier&iacute;a.&nbsp; Por favor p&oacute;ngase en contacto con nosotros para ofrecerle demos de nuestros productos y opcionalmente ponerlo en contacto con alg&uacute;n especialista en hardware o ingenier&iacute;a de procesos. &nbsp;</p><p>Seleccione la opci&oacute;n mas adecuada a lo que necesita:</p>",

    useCaseAllEngineering:
      "Mi empresa quiere automatizar un proceso y necesito comprar equipo y software",
    useCaseAllEngineeringStep1:
      "Envíenos un correo con lo que tiene en mente.  Incluya información de equipo existente que quiera utilizar",
    useCaseAllEngineeringStep2:
      "Lo pondremos en contacto con la persona indicada que le puede ofrecer equipo e ingenieria necesarios",
    useCaseAllEngineeringStep3:
      "Ellos le enviarán una cotización con tiempo de entrega y el software incluido",
    useCaseAllEngineeringStep4:
      "La orden incluye hardware, software y servicios de instalación",
    useCaseAllEngineeringStep5:
      "Ellos agendarán con usted una o varias visitas para completar la instalación",
    useCaseAllEngineeringStep6:
      "El software estará disponible para descargar en la página web.  Opcionalmente se puede instalar en una de las visitas de nuestros técnicos",
    useCaseDeveloper:
      "Soy un programador y solo quiero obtener el peso de un indicador e imprimir etiquetas",
    useCaseDeveloperStep1:
      "Identifique el equipo que tiene, como marca y modelo de indicador, tipo de etiquetas, e.g. código de barras, si es un ambiente húmedo, etc",
    useCaseDeveloperStep2:
      "Envíenos la información e díganos si esta usando Excel u otra aplicación con su protocolo preferido de comunicación, e.g. HTTP, named-pipes, etc",
    useCaseDeveloperStep3:
      "Le enviaremos una cotizacion y tiempo de entrega.  Posiblemente ya exista un producto que ya este listo para usarse, o podemos hacer algo personalizado",
    useCaseDeveloperStep4:
      "La orden incluye el software y opcionalmente hardware y servicios de instalación",

    useCaseDeveloperStep5:
      "Este paso es opcional en caso de desarrollar algo a la medida",
    useCaseDeveloperStep6:
      "El software estará disponible para descargar en la página web.  Opcionalmente se puede hablar con nosotros para asistencia o podemos enviar a un técnico para apoyar con las conexiones",
    useCaseOldSoftware:
      "Tengo un software obsoleto y necesito uno nuevo con mejoras",
    useCaseOldSoftwareStep1:
      "Obtenga información de su software como fabricante, version, pagina web, manuales de usuario.  Incluya tambien información de como interactua con otros sistemas",
    useCaseOldSoftwareStep2: "Envíenos la información",
    useCaseOldSoftwareStep3:
      "Le enviaremos una cotización y tiempo de entrega, asi como requerimientos de hardware",
    useCaseOldSoftwareStep4:
      "Nos pondremos en contacto para ajustar detalles finales",
    useCaseOldSoftwareStep5:
      "Enviaremos una version Beta como demo para su aceptación y hacer ultimos ajustes",
    useCaseOldSoftwareStep6:
      "Confirme que está contento con los ultimos cambios para concluir con la última etapa de desarrollo",
    useCaseOldSoftwareStep7:
      "El software estará disponible para descargar en la página web.  Opcionalmente se puede enviar a un técnico para la instalación si require asistencia",

    useCaseIntermediary:
      "Quiero automatizar o vender equipo a un cliente y necesito el software",
    useCaseIntermediaryStep1:
      "Díganos de que trata su proyecto y que tipo de software necesita",
    useCaseIntermediaryStep2:
      "Le enviaremos una cotización y el tiempo de entrega",
    useCaseIntermediaryStep3:
      "Se iniciará y completará el desarrollo basado en sus tiempos y entregas con el cliente",
    useCaseIntermediaryStep4:
      "Estaremos en constante comunicación para ver detalles y avances",
    useCaseIntermediaryStep5:
      "Ustedes instalan el equipo y preparan las estaciones de trabajo",
    useCaseIntermediaryStep6:
      "Nosotros publicamos el software, videos de capacitación y documentación",
    useCaseIntermediaryStep7:
      "El cliente o ustedes (por confidencialidad) podrán acceder al portal para descargar y activar sus productos.  Nosotros podemos ofrecer ayuda remota o en planta para las conexiones y configuraciones",

    customer: "Cliente",
    developer: "Programador",
    thirdParty: "Tercero",
    contactUs: "Contáctenos",
    intermediary: "Intermediario",
    intermediaryAsCustomer: "Ustedes",
    us: "Nosotros",
    quote: "Cotización",
    knowYourSoftware: "Conozca su Software",
    knowYourHardware: "Conozca su Hardware",
    placeOrder: "Realice su Pedido",
    installation: "Instalación",
    installSoftware: "Instalación de Software",
    installHardware: "Instalación de Hardware",
    development: "Desarrollo",
    publish: "Publicar Software",
    confirmFinalVersion: "Confirmar Version Final",
    redirect: "Redirigir",
  },
});
