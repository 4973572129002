import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import IResources, { resources } from "./.resources";
import { RootState } from "../../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import TrialImage from "./download_trial.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, FormControl, TextField } from "@material-ui/core";
import { ThemeOptions } from "../../types/Enums";
import { IDownloadTrialRequest, downloadTrial } from "../../api/downloadsApi";
import { ToastContainer, toast } from "react-toastify";
import { AxiosResponse } from "axios";
import Chip from "@material-ui/core/Chip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Slide from "@material-ui/core/Slide";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ReCAPTCHA from "react-google-recaptcha";
import PulseLoader from "react-spinners/PulseLoader";

interface IRequestTrialPage {
  themeName: ThemeOptions;
}

interface IRequestTrial {
  workEmail: string;
  firstName: string;
  lastName: string;
  companyName: string;
  industry?: string;
  message?: string;
  whoAmI?: string;
  captcha?: string;
}

export interface IRequestTrialParams {
  productId: string;
  productName: string;
  templateId: string;
  trialDays: number;
}

const RequestTrialPage: React.FC<IRequestTrialPage> = ({ themeName }) => {
  const { requestInfo } = useParams();

  const [productId, setProductId] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [templateId, setTemplateId] = useState<string>();
  const [trialDays, setTrialDays] = useState<number>(14);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [iAmNoRobot, setIAmNoRobot] = useState<string>();

  const useStyles = makeStyles({
    form: {
      maxWidth: 445,
    },
    image: {
      maxHeight: 480,
      maxWidth: 480,
    },
    actionButton: {
      marginLeft: 8,
      marginRight: 8,
    },
    title: {
      marginTop: 32,
      marginBottom: 32,
    },
    container: {
      minHeight: "65vh",
    },
  });
  const classes = useStyles();

  const history = useHistory();

  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    //setLang(state.language);
    return state.language;
  });

  const showMessage = (msg: string | null | undefined) => {
    if (msg) {
      toast.info(msg);
    }
  };

  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSubmit = async (request: IRequestTrial) => {
    const trialRequest: IDownloadTrialRequest = {
      contactName: request.firstName + " " + request.lastName,
      companyName: request.companyName,
      productId: productId,
      email: request.workEmail,
      language: res.language,
      useTemplate: templateId || "",
      industry: request.industry,
      message: request.message,
      whoAmI: request.whoAmI,
      captcha: request.captcha,
      variables: [{ variableName: "TrialDays", value: `${trialDays}` }],
    };
    await downloadTrial(trialRequest, {
      onSuccess: (response: AxiosResponse<any>) => setIsSuccessful(true),
      notFound: (msg: string) => showError(res.pleaseContactSupport),
      badRequest: (msg: string) => showError(msg),
      tooManyRequests: () => showError(res.tooManyRequests),
      serverError: (msg: string) => showError(res.pleaseContactSupport),
      errorTitle: "Request Trial",
    });
  };

  const formik = useFormik({
    //enableReinitialize: false,
    initialValues: {
      workEmail: "",
      firstName: "",
      lastName: "",
      companyName: "",
      industry: "",
      message: "",
      whoAmI: "",
    },
    validationSchema: Yup.object({
      workEmail: Yup.string().required("Required"),
      firstName: Yup.string().required("Required"),
    }),
    onSubmit: async () => {
      await handleSubmit({
        workEmail: formik.values.workEmail,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        companyName: formik.values.companyName,
        industry: formik.values.industry,
        message: formik.values.message,
        whoAmI: formik.values.whoAmI,
        captcha: iAmNoRobot,
      });
    },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value); // this call formik to set your value
  };

  useEffect(() => {
    const request: IRequestTrialParams = JSON.parse(atob(requestInfo));
    setProductId(request.productId);
    setProductName(request.productName);
    setTemplateId(request.templateId);
    setTrialDays(request.trialDays);
  }, []);

  const onCaptchaChange = async (value: string) => {
    setIAmNoRobot(value);
  };

  function onCaptchaExpired() {
    setIAmNoRobot(undefined);
  }

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.title}
      >
        <Typography variant="h2">{productName}</Typography>
        <Typography variant="h4">{res.trialVersion}</Typography>
      </Grid>

      <ToastContainer />

      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={6}>
          <img src={TrialImage} className={classes.image} />
        </Grid>
        <Grid item xs={6}>
          <form onSubmit={formik.handleSubmit} className={classes.form}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              className={classes.container}
            >
              <Grid item>
                <Typography variant="body1">
                  {res.fillInTheForm(trialDays)}
                </Typography>
              </Grid>
              <Grid item>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.workEmail) && formik.touched.workEmail
                  }
                >
                  <TextField
                    label={res.workEmail}
                    name="workEmail"
                    type="text"
                    onBlur={formik.handleBlur}
                    disabled={isSuccessful || formik.isSubmitting}
                    value={formik.values.workEmail || ""}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item container direction="row">
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={
                      Boolean(formik.errors.firstName) &&
                      formik.touched.firstName
                    }
                  >
                    <TextField
                      label={res.firstName}
                      name="firstName"
                      type="text"
                      disabled={isSuccessful || formik.isSubmitting}
                      onBlur={formik.handleBlur}
                      //InputLabelProps={{ shrink: true }}
                      value={formik.values.firstName || ""}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={
                      Boolean(formik.errors.lastName) && formik.touched.lastName
                    }
                  >
                    <TextField
                      label={res.lastName}
                      name="lastName"
                      type="text"
                      onBlur={formik.handleBlur}
                      disabled={isSuccessful || formik.isSubmitting}
                      value={formik.values.lastName || ""}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.companyName) &&
                    formik.touched.companyName
                  }
                >
                  <TextField
                    label={res.companyName}
                    name="companyName"
                    type="text"
                    onBlur={formik.handleBlur}
                    disabled={isSuccessful || formik.isSubmitting}
                    //InputLabelProps={{ shrink: true }}
                    value={formik.values.companyName || ""}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel id="who-am-i-label">{res.whoAmI}</InputLabel>
                  <Select
                    labelId="who-am-i-label"
                    id="demo-simple-select"
                    name="whoAmI"
                    onBlur={formik.handleBlur}
                    disabled={isSuccessful || formik.isSubmitting}
                    value={formik.values.whoAmI}
                    onChange={handleChange}
                  >
                    <MenuItem value="Individual">{res.iAmIndividual}</MenuItem>
                    <MenuItem value="Customer">{res.iAmCustomer}</MenuItem>
                    <MenuItem value="Distributor">
                      {res.iAmDistributor}
                    </MenuItem>
                    <MenuItem value="Developer">{res.iAmDeveloper}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl
                  fullWidth
                  error={
                    Boolean(formik.errors.companyName) &&
                    formik.touched.companyName
                  }
                >
                  <TextField
                    label={res.message}
                    multiline
                    rows={3}
                    name="message"
                    type="text"
                    onBlur={formik.handleBlur}
                    disabled={isSuccessful || formik.isSubmitting}
                    //InputLabelProps={{ shrink: true }}
                    value={formik.values.message || ""}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                {/* <div
                  style={{ marginTop: 8 }}
                  className="g-recaptcha"
                  data-sitekey="6LcW9csbAAAAAIvYO6o22wAiNfp85tcqV4mPAERC"
                ></div> */}

                {isSuccessful === false && (
                  <div style={{ marginTop: 8 }}>
                    <ReCAPTCHA
                      sitekey="6LcW9csbAAAAAIvYO6o22wAiNfp85tcqV4mPAERC"
                      onChange={onCaptchaChange}
                      onExpired={onCaptchaExpired}
                    />
                  </div>
                )}
              </Grid>
              <Grid item style={{ marginTop: 32 }}>
                {isSuccessful === true ? (
                  <Slide
                    direction="left"
                    in={isSuccessful}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Typography variant="body2">
                      {res.afterRequestText}
                    </Typography>
                  </Slide>
                ) : (
                  <Typography variant="body2">{res.disclaimer}</Typography>
                )}
              </Grid>
              <Grid item container style={{ marginTop: 32, marginLeft: 16 }}>
                {isSuccessful === true ? (
                  <Slide
                    direction="left"
                    in={isSuccessful}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div>
                      <Chip
                        style={{ height: 36 }}
                        icon={<CheckCircleIcon />}
                        label={res.doneText}
                        variant="outlined"
                        color="primary"
                      />

                      <Chip
                        style={{ height: 36, marginLeft: 32 }}
                        clickable={true}
                        onClick={() => history.goBack()}
                        label={res.goBackButton}
                      />
                    </div>
                  </Slide>
                ) : (
                  <div>
                    {formik.isSubmitting ? (
                      <PulseLoader
                        color={themeName === "dark" ? "#f8f8f8" : "#323a45"}
                      />
                    ) : (
                      <Button
                        variant="contained"
                        color="secondary"
                        //className={classes.actionButton}
                        type="submit"
                        disabled={
                          iAmNoRobot === undefined || formik.isSubmitting
                        }
                      >
                        {res.submitButton}
                      </Button>
                    )}
                    <Button
                      style={{ marginLeft: 32 }}
                      variant="contained"
                      className={classes.actionButton}
                      disabled={formik.isSubmitting}
                      onClick={() => history.goBack()}
                    >
                      {res.goBackButton}
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};
export default RequestTrialPage;
