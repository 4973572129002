import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import IResources, { resources } from "./.resources";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Timeline from "@material-ui/lab/Timeline/Timeline";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Hidden, Paper } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { RootState } from "../../../redux/reducers/rootReducer";
import { useParams } from "react-router-dom";

//declare type partyEnum = "Customer" | "Third Party" | "Softion" | "Developer";

interface IPurchaseStep {
  actor: string;
  actionDetails: string;
  action: string;
}

interface IPurchaseCase {
  case: string;
  steps: IPurchaseStep[];
}

const PurchasingFaqPage: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const { useCase } = useParams();

  const res: IResources = resources;
  useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const useCases: IPurchaseCase[] = [
    {
      case: res.useCaseAllEngineering,
      steps: [
        {
          actor: res.customer,
          action: res.contactUs,
          actionDetails: res.useCaseAllEngineeringStep1,
        },
        {
          actor: res.us,
          action: res.redirect,
          actionDetails: res.useCaseAllEngineeringStep2,
        },
        {
          actor: res.thirdParty,
          action: res.quote,
          actionDetails: res.useCaseAllEngineeringStep3,
        },
        {
          actor: res.customer,
          action: res.placeOrder,
          actionDetails: res.useCaseAllEngineeringStep4,
        },
        {
          actor: res.thirdParty,
          action: res.installHardware,
          actionDetails: res.useCaseAllEngineeringStep5,
        },
        {
          actor: res.us,
          action: res.installSoftware,
          actionDetails: res.useCaseAllEngineeringStep6,
        },
      ],
    },
    {
      case: res.useCaseIntermediary,
      steps: [
        {
          actor: res.intermediaryAsCustomer,
          action: res.contactUs,
          actionDetails: res.useCaseIntermediaryStep1,
        },
        {
          actor: res.us,
          action: res.quote,
          actionDetails: res.useCaseIntermediaryStep2,
        },
        {
          actor: res.intermediaryAsCustomer,
          action: res.placeOrder,
          actionDetails: res.useCaseIntermediaryStep3,
        },
        {
          actor: res.us,
          action: res.development,
          actionDetails: res.useCaseIntermediaryStep4,
        },
        {
          actor: res.intermediaryAsCustomer,
          action: res.installHardware,
          actionDetails: res.useCaseIntermediaryStep5,
        },
        {
          actor: res.us,
          action: res.publish,
          actionDetails: res.useCaseIntermediaryStep6,
        },
        {
          actor: res.customer,
          action: res.installSoftware,
          actionDetails: res.useCaseIntermediaryStep7,
        },
      ],
    },
    {
      case: res.useCaseOldSoftware,
      steps: [
        {
          actor: res.customer,
          action: res.knowYourSoftware,
          actionDetails: res.useCaseOldSoftwareStep1,
        },
        {
          actor: res.customer,
          action: res.contactUs,
          actionDetails: res.useCaseOldSoftwareStep2,
        },
        {
          actor: res.us,
          action: res.quote,
          actionDetails: res.useCaseOldSoftwareStep3,
        },
        {
          actor: res.customer,
          action: res.placeOrder,
          actionDetails: res.useCaseOldSoftwareStep4,
        },
        {
          actor: res.us,
          action: res.development,
          actionDetails: res.useCaseOldSoftwareStep5,
        },
        {
          actor: res.customer,
          action: res.confirmFinalVersion,
          actionDetails: res.useCaseOldSoftwareStep6,
        },
        {
          actor: res.us,
          action: res.installation,
          actionDetails: res.useCaseOldSoftwareStep7,
        },
      ],
    },
    {
      case: res.useCaseDeveloper,
      steps: [
        {
          actor: res.developer,
          action: res.knowYourHardware,
          actionDetails: res.useCaseDeveloperStep1,
        },
        {
          actor: res.developer,
          action: res.contactUs,
          actionDetails: res.useCaseDeveloperStep2,
        },
        {
          actor: res.us,
          action: res.quote,
          actionDetails: res.useCaseDeveloperStep3,
        },
        {
          actor: res.developer,
          action: res.placeOrder,
          actionDetails: res.useCaseDeveloperStep4,
        },
        {
          actor: res.us,
          action: res.development,
          actionDetails: res.useCaseDeveloperStep5,
        },
        {
          actor: res.developer,
          action: res.installation,
          actionDetails: res.useCaseDeveloperStep6,
        },
      ],
    },
  ];

  const changeSelectedItem = (index: number) => {
    setSelectedIndex(index);
  };

  useEffect(() => {
    if (useCase) {
      switch (useCase) {
        case "developer":
          setSelectedIndex(3);
          break;
        case "customer":
          setSelectedIndex(0);
          break;
        case "distributor":
          setSelectedIndex(1);
          break;
      }
    }
  }, []);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        marginTop: "24px",
        backgroundColor: theme.palette.background.default,
      },
      useCasesContainer: {
        minHeight: 350,
        height: "100%",
      },
      paper: {
        padding: "6px 16px",
        minWidth: 160,
      },
      secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
      },
      useCasesList: {
        alignContent: "right",
      },
      headerText: {
        padding: 16,
        maxWidth: "72%",
        textAlign: "center",
      },
      centeredText: {
        textAlign: "center",
      },
      selectedItemText: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
      },
      listItemText: {
        fontWeight: "normal",
      },
      acordionContainer: {
        width: "100%",
      },
      acordion: {
        minWidth: "80%",
        maxWidth: "80%",
        alignContent: "center",
      },
      timeline: {
        minWidth: "80%",
        maxWidth: "80%",
      },
    })
  );
  const classes = useStyles();

  const Accordion = withStyles({
    root: {
      margin: "auto",
      border: "1px solid rgba(0, 0, 0, .125)",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: "rgba(0, 0, 0, .03)",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      marginBottom: -1,
      minHeight: 56,
      "&$expanded": {
        minHeight: 56,
      },
    },
    content: {
      textAlign: "center",
      "&$expanded": {
        margin: "12px 0",
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  const isRightAligned = (party: string) => {
    return (
      party !== res.customer &&
      party !== res.developer &&
      party !== res.intermediaryAsCustomer
    );
    // return (
    //   party === res.customer ||
    //   party === res.developer ||
    //   party === res.intermediary
    // );
  };
  const getIcon = (party: string) => {
    if (party === res.customer || party === res.developer) {
      return (
        <TimelineDot color="primary">
          <PersonIcon />
        </TimelineDot>
      );
    }
    if (party === res.us) {
      return (
        <TimelineDot color="secondary">
          <PersonIcon />
        </TimelineDot>
      );
    }
    if (party === res.thirdParty || party === res.intermediary) {
      return (
        <TimelineDot color="secondary" variant="outlined">
          <PersonIcon />
        </TimelineDot>
      );
    }
    return (
      <TimelineDot color="grey">
        <PersonIcon />
      </TimelineDot>
    );
  };

  const TimeLineActor = ({ step }) => {
    return (
      <Typography variant="body2" color="textSecondary">
        {step.actor}
      </Typography>
    );
  };

  const TimeLineAction = ({ step }) => {
    return (
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h6">{step.action}</Typography>
        <Typography variant="caption">{step.actionDetails}</Typography>
      </Paper>
    );
  };

  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.container}
      >
        <Typography variant="h2">{res.title}</Typography>
        <Typography variant="body1" className={classes.headerText}>
          <div dangerouslySetInnerHTML={{ __html: `${res.subTitle}` }} />
        </Typography>

        {useCases.map((x, index) => {
          return (
            <div key={index} className={classes.acordionContainer}>
              {(selectedIndex === -1 || selectedIndex === index) && (
                <Accordion
                  square
                  className={classes.acordion}
                  expanded={selectedIndex === index}
                  onChange={() =>
                    changeSelectedItem(index === selectedIndex ? -1 : index)
                  }
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <PersonIcon />
                      <Typography className={classes.centeredText}>
                        {x.case}
                      </Typography>
                      {selectedIndex === index ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {selectedIndex === index && (
                      <Timeline align="left" className={classes.timeline}>
                        {x.steps.map((step, index) => {
                          return (
                            <TimelineItem key={index}>
                              <Hidden smDown>
                                <TimelineOppositeContent>
                                  {isRightAligned(step.actor) ? (
                                    <TimeLineActor step={step} />
                                  ) : (
                                    <TimeLineAction step={step} />
                                  )}
                                </TimelineOppositeContent>
                              </Hidden>

                              <TimelineSeparator>
                                {getIcon(step.actor)}
                                {index !== x.steps.length - 1 && (
                                  <TimelineConnector />
                                )}
                              </TimelineSeparator>
                              <TimelineContent>
                                <Hidden smDown>
                                  {isRightAligned(step.actor) ? (
                                    <TimeLineAction step={step} />
                                  ) : (
                                    <TimeLineActor step={step} />
                                  )}
                                </Hidden>
                                <Hidden mdUp>
                                  <>
                                    <TimeLineActor step={step} />
                                    <TimeLineAction step={step} />
                                  </>
                                </Hidden>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        })}
                      </Timeline>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </div>
          );
        })}
      </Grid>
    </Container>
    // <Grid
    //   item
    //   container
    //   direction="row"
    //   justify="center"
    //   alignItems="stretch"
    //   className={classes.useCasesContainer}
    // >
    //   <Grid
    //     item
    //     container
    //     justify="center"
    //     alignItems="center"
    //     md={selectedIndex >= 0 ? 4 : 12}
    //     sm={12}
    //   >
    //     <List component="nav" aria-label="use cases">
    //       {useCases.map((x, index) => {
    //         return (
    //           <ListItem
    //             key={index}
    //             button
    //             selected={selectedIndex === index}
    //             onClick={() => changeSelectedItem(index)}
    //           >
    //             <ListItemIcon>
    //               <PersonIcon />
    //             </ListItemIcon>
    //             <ListItemText
    //               primary={x.case}
    //               className={
    //                 selectedIndex === index
    //                   ? classes.selectedItemText
    //                   : classes.listItemText
    //               }
    //             />
    //           </ListItem>
    //         );
    //       })}
    //     </List>
    //   </Grid>
    //   <Grid item md={selectedIndex >= 0 ? 8 : 12} sm={12}>
    //     {selectedIndex >= 0 && (
    //       <Timeline align="alternate">
    //         {useCases[selectedIndex].steps.map((x, index) => {
    //           return (
    //             <TimelineItem key={index}>
    //               <TimelineOppositeContent>
    //                 {isRightAligned(x.actor) ? (
    //                   <Typography variant="body2" color="textSecondary">
    //                     {x.actor}
    //                   </Typography>
    //                 ) : (
    //                   <Paper elevation={3} className={classes.paper}>
    //                     <Typography variant="h6">{x.action}</Typography>
    //                     <Typography variant="caption">
    //                       {x.actionDetails}
    //                     </Typography>
    //                   </Paper>
    //                 )}
    //               </TimelineOppositeContent>
    //               <TimelineSeparator>
    //                 {getIcon(x.actor)}
    //                 <TimelineConnector />
    //               </TimelineSeparator>
    //               <TimelineContent>
    //                 {isRightAligned(x.actor) ? (
    //                   <Paper elevation={3} className={classes.paper}>
    //                     <Typography variant="h6">{x.action}</Typography>
    //                     <Typography variant="caption">
    //                       {x.actionDetails}
    //                     </Typography>
    //                   </Paper>
    //                 ) : (
    //                   <Typography variant="body2" color="textSecondary">
    //                     {x.actor}
    //                   </Typography>
    //                 )}
    //               </TimelineContent>
    //             </TimelineItem>
    //           );
    //         })}
    //       </Timeline>
    //     )}
    //   </Grid>
    // </Grid>
  );
};

export default PurchasingFaqPage;
