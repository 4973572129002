export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const LOAD_PRODUCTS_SUCCESS = "LOAD_PRODUCTS_SUCCESS";

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_COMPLETE = "GET_USER_COMPLETE";

export const GET_USER_MEMBERSHIP = "GET_USER_MEMBERSHIP";
export const GET_USER_MEMBERSHIP_SUCCESS = "GET_USER_MEMBERSHIP_SUCCESS";
export const GET_USER_MEMBERSHIP_FAIL = "GET_USER_MEMBERSHIP_FAIL";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const SWITCH_LANGUAGE = "SWITCH_LANGUAGE";

export const HEALTH_B2CAPI = "HEALTHCHECK-B2C";
export const HEALTH_PROJAPI = "HEALTHCHECK-PROJ";
export const HEALTH_REGAPI = "HEALTHCHECK-REG";
