import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  activateLicenseOffline,
  getLicensesForProject,
  getLicensesForUser,
  ILicense2,
  IMyLicensesResponse,
  IOfflineLicenseInstallRequest,
  IOfflineLicenseResult,
  IOfflineLicenseUninstallRequest,
  IUserProgramsAndLicenses,
  uninstallLicenseOffline,
} from "../../../api/Account/myLicensesApi";
import { ISession, ILoggedUser } from "../../../types/Auth";
//import { ProductInfo } from "@softion/b2c-service";
import { RootState } from "../../../redux/reducers/rootReducer";
import {
  Typography,
  Container,
  Button,
  Chip,
  Grid,
  IconButton,
  Box,
  Backdrop,
  CircularProgress,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IResources, { resources } from "./.resources";
import { toast, ToastContainer } from "react-toastify";
import UserDropDown from "../../admin/_shared/UsersDropDown";
import {
  getUsersList,
  IUserV0,
  processUserFlags,
} from "../../../api/Account/usersApi";
import { useParams, useHistory } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";
import SearchIcon from "@material-ui/icons/Search";
import MyLicensesGrid from "./MyLicensesGrid";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import WarningIcon from "@material-ui/icons/Warning";
import NewLicenseDialog from "./dialogs/NewLicenseDialog";
import LicenseDialog from "./dialogs/LicenseDialog";
import LicensesArchitectureMap from "./MyLicensesArchitectureMap";
import IconLight from "../../../images/licenses/licenses.light.128x128.png";
import IconDark from "../../../images/licenses/licenses.dark.128x128.png";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { ThemeOptions } from "../../../types/Enums";
import * as urls from "../../../types/Constants";

interface IRequestData {
  programId: string;
  productId: string;
  userId: string;
  productName: string;
}

export interface IMyLicensesPageProps {
  themeName: ThemeOptions;
}

const MyLicensesPage: React.FC<IMyLicensesPageProps> = ({ themeName }) => {
  //const { productId, userId } = useParams();
  const { requestData } = useParams();
  const history = useHistory();

  const session: ISession = useSelector((state: RootState) => {
    return state.auth;
  }); //REDUX

  const loggedUser: ILoggedUser = useSelector((state: RootState) => {
    return state.userInfo;
  });

  const res: IResources = resources;
  const language: string = useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [licenses, setLicenses] = useState<ILicense2[]>([]);
  const [programAndLicenses, setProgramAndLicenses] = useState<
    IUserProgramsAndLicenses[]
  >([]);
  const [orphans, setOrphans] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [showNewLicenseDialog, setShowNewLicenseDialog] = useState<boolean>(
    false
  );
  const [dialogLicense, setDialogLicense] = useState<ILicense2>();
  const [showLicenseDialog, setShowLicenseDialog] = useState<boolean>(false);

  const [users, setUsers] = useState<IUserV0[]>([]);
  const [displayProduct, setDisplayProduct] = useState<string>();
  const [impersonatedUser, setImpersonatedUser] = useState<string>(); //value displayed for the admin's user dropbox
  const [displayUserName, setDisplayUserName] = useState<string>();
  const [currentUser, setCurrentUser] = useState<IUserV0>(); //value displayed on the New License dialog
  const [activateOnBehalfOf, setActivateOnBehalfOf] = useState<IUserV0>(); //value displayed on the New License dialog
  const [canActivate, setCanActivate] = useState<boolean>(false);
  const [pageTitle, setPageTitle] = useState<string>();

  const showError = (err: string | undefined) => {
    if (err) {
      toast.error(err, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const showMessage = (msg: string | null | undefined) => {
    if (msg) {
      toast.info(msg);
    }
  };

  const processResults = (result: IMyLicensesResponse, user?: string) => {
    const involvedUsers = result.users.map((u) => processUserFlags(u));
    if (loggedUser?.isAdmin !== true) {
      //All users are already available for admin,
      //Otherwise any involved users in the reponse are sent in the same response to avoid exposing all users in any non-admin session
      setUsers(involvedUsers);
      if (!currentUser) {
        setCurrentUser(
          involvedUsers.find((u) => u.userId == loggedUser.userId)
        );
      }
    }

    setImpersonatedUser(user);
    const userList = users && users.length > 0 ? users : involvedUsers;
    if (user && userList && userList.length > 0) {
      const userInfo = userList.find((u) => u.userId === user);
      setActivateOnBehalfOf(userInfo);
      setDisplayUserName(userInfo?.name);
    } else {
      setActivateOnBehalfOf(undefined);
      setDisplayUserName(undefined);
    }
    setProgramAndLicenses(result.userProgramsAndLicenses);
    const list: ILicense2[] = [];
    let orphanList: ILicense2[] = [];
    let userCanActivate = false;
    result.userProgramsAndLicenses.forEach((item) => {
      orphanList = orphanList.concat(
        item.orphanLicenses.map<ILicense2>((o) => ({
          ...o,
          programName: item.programName,
          productName: item.productName,
          isOrphan: true,
          isExternal: false,
        }))
      );
      item.locations.forEach((branchAndStation) => {
        branchAndStation.licenses.forEach((lic) => {
          list.push({
            ...lic,
            programName: item.programName,
            productName: item.productName,
            stationName:
              item.stationNames.length > lic.station
                ? item.stationNames[lic.station - 1]
                : "",
            isOrphan: false,
          });
        });
      });
      userCanActivate =
        userCanActivate ||
        Boolean(item.requestorScopes.find((sc) => sc === "OfflineLicense"));
    });
    setOrphans(orphanList.length > 0);
    if (loggedUser.isAdmin) {
      setLicenses(list.concat(orphanList));
      setCanActivate(true);
    } else {
      setLicenses(list);
      setCanActivate(userCanActivate);
    }
    setIsLoading(false);
  };

  const getLicensesAsync = async (user?: string) => {
    if (user || requestData) {
      setIsLoading(true);
      setDialogOpen(false);
      setShowNewLicenseDialog(false);
      setImpersonatedUser(undefined);
      setActivateOnBehalfOf(undefined);
      setDisplayUserName(undefined);
      setProgramAndLicenses([]);
      if (requestData) {
        const request: IRequestData = JSON.parse(atob(requestData));
        setDisplayProduct(request.productName);
        await getLicensesForProject(
          request.userId,
          request.productId,
          (result) => processResults(result, request.userId),
          (error) => {
            showError(error);
            setLicenses([]);
            setIsLoading(false);
          }
        );
      } else if (user) {
        setDisplayProduct(undefined);
        await getLicensesForUser(
          user,
          (result) => processResults(result, user),
          (error) => {
            showError(error);
            setLicenses([]);
            setIsLoading(false);
          }
        );
      }
    } else {
      setLicenses([]);
    }
  };

  const refreshUsersAsync = async () => {
    await getUsersList(
      (response) => {
        const orderedUsers: IUserV0[] = response.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
        //TODO: WE DO NOT WANT ALL THE USERS TO BE RETRIEVED AND DISPLAYED TO THE USER IN THE NETWORK TAB
        setUsers([...orderedUsers]);
      },
      (err) => showError(err)
    );
  };

  useEffect(() => {
    resources.setLanguage(language);
  }, []);

  const refreshAsync = async () => {
    if (loggedUser?.userId) {
      if (
        loggedUser &&
        (requestData || (!loggedUser.isAdmin && !loggedUser.isDistributor))
      ) {
        await getLicensesAsync(loggedUser.userId);
      }
    }
  };

  const getTitle = () => {
    if (loggedUser.isCustomer) return res.titleCustomer;
    if (loggedUser.isAdmin) return res.titleAdmin;
    if (loggedUser.isDistributor) return res.titleDistributor;
    if (loggedUser.isSupport) return res.titleSupport;
    return "";
  };

  useEffect(() => {
    if (loggedUser?.userId) {
      const cUser = users.find((u) => u.userId === loggedUser?.userId);
      if (cUser) {
        setCurrentUser(cUser);
      }
    }
  }, [users]);

  useEffect(() => {
    if (loggedUser?.isAdmin && (!users || users.length === 0)) {
      refreshUsersAsync();
    }
    setCurrentUser(undefined);
    if (loggedUser?.userId) {
      const cUser = users.find((u) => u.userId === loggedUser?.userId);
      setPageTitle(getTitle());
      setCurrentUser({
        userId: loggedUser?.userId,
        name: cUser?.name || session.user || loggedUser?.userId,
        flags: 72,
        isSupport: loggedUser.isSupport,
        isAdmin: loggedUser.isAdmin,
        isDistributor: loggedUser.isDistributor,
        isCustomer: loggedUser.isCustomer,
        isTestUser: false,
        isActive: true,
        isExternal: false,
      }); //cUser);
      refreshAsync();
    }
  }, [loggedUser, session]);

  const themedStyles = makeStyles((theme: Theme) =>
    createStyles({
      input: {
        marginLeft: theme.spacing(1),
        width: 320,
        flex: 1,
      },
      chip: {
        margin: "10px 0px 10px 0px",
      },
      topContainer: { paddingTop: "24px" },

      gridContainer: {
        padding: "32px",
        borderRadius: "26px",
      },

      [theme.breakpoints.down("sm")]: {
        gridContainer: {
          padding: "16px",
          borderRadius: "8px",
        },
      },
      searchButton: {
        padding: "2px",
        margin: "0px",
        width: "32px",
        height: "32px",
      },
      clearButton: {
        padding: "0px",
        margin: "0px",
      },
      breadCrums: {
        padding: "0 0 12px 0",
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
      },
      titleIcon: {
        width: "48px",
        height: "48px",
        margin: "2px 12px 2px 2px",
      },
      usersDropDown: {
        width: 280,
      },
    })
  );
  const styles = themedStyles();

  const openLicenseDetails = (license: ILicense2) => {
    //productId#
    setDialogLicense(license);
    if (license) {
      setShowLicenseDialog(true);
    }
  };

  const openNewLicenseDialog = () => {
    setShowNewLicenseDialog(true);
  };
  const closeNewLicenseDialog = (successful: boolean) => {
    setShowNewLicenseDialog(false);
    if (successful) {
      refreshAsync();
    }
  };
  const handleActivateLicense = async (
    request: IOfflineLicenseInstallRequest,
    onSuccess: (result: IOfflineLicenseResult) => void,
    onFail: () => void
  ) => {
    request = { ...request, language: language };
    await activateLicenseOffline(
      request,
      (result) => {
        showMessage(res.licenseSuccessfullyGenerated);
        onSuccess(result);
      },
      (error) => {
        showError(error);
        onFail();
      }
    );
  };
  const handleUninstallLicense = async (
    request: IOfflineLicenseUninstallRequest
  ) => {
    request = { ...request, language: language };
    await uninstallLicenseOffline(
      request,
      async () => {
        showMessage("Uninstalled successfully");
        setShowLicenseDialog(false);
        await getLicensesAsync(impersonatedUser || "");
      },
      (error) => showError(error)
    );
  };

  const isMultipleLocations = () => {
    return (
      programAndLicenses &&
      programAndLicenses.length > 0 &&
      (programAndLicenses.length > 1 ||
        programAndLicenses[0].stations > 1 ||
        programAndLicenses[0].branches.length > 0)
    );
  };

  const goToPage = (url: string | undefined) => {
    if (url) {
      history.push(url);
    }
  };

  const Navigation = () => {
    return (
      <Breadcrumbs
        className={styles.breadCrums}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link color="inherit" href="#" onClick={() => goToPage(urls.URL_HOME)}>
          Home
        </Link>
        <Link
          color="inherit"
          href="#"
          onClick={() => goToPage(urls.URL_MYPURCHASES)}
        >
          {res.orders}
        </Link>
        <Typography color="textPrimary">Licenses</Typography>
      </Breadcrumbs>
    );
  };

  return session.loggedIn ? (
    <Container className={styles.topContainer}>
      <div>
        {requestData && <Navigation />}
        <Grid container justify="space-between" alignItems="flex-end">
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <img
                src={themeName === "dark" ? IconDark : IconLight}
                className={styles.titleIcon}
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">{pageTitle}</Typography>
            </Grid>
          </Grid>
          {/* <Grid item xs={10} sm={8}>
            <Typography variant="h2" gutterBottom>
              {pageTitle}
            </Typography>
          </Grid> */}

          <Grid container direction="row" spacing={4}>
            {displayProduct && (
              <Grid item>
                <Chip
                  label={displayProduct}
                  icon={<LocalOfferIcon />}
                  color="primary"
                  className={styles.chip}
                />
              </Grid>
            )}

            {displayUserName && displayUserName != currentUser?.name && (
              <Grid item>
                <Chip
                  label={displayUserName}
                  icon={<PersonIcon />}
                  color="primary"
                  className={styles.chip}
                />
              </Grid>
            )}
            {orphans && (
              <Grid item>
                <Chip
                  label="Some licenses are not displayed"
                  icon={<WarningIcon />}
                  color="secondary"
                  className={styles.chip}
                />
              </Grid>
            )}
          </Grid>

          <Grid container>
            {loggedUser.isAdmin === true && !requestData ? (
              <Grid item sm={4} md={8} lg={12}>
                {dialogOpen ? (
                  <form
                    noValidate
                    autoComplete="off"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "95%;",
                      maxWidth: "300px",
                    }}
                  >
                    <Grid
                      xs={12}
                      item
                      container
                      direction="row"
                      alignItems="center"
                      justify="flex-start"
                    >
                      <Grid item xs={8}>
                        <UserDropDown
                          name="userId"
                          label="View As"
                          className={styles.usersDropDown}
                          users={users}
                          value={impersonatedUser}
                          enabled={true}
                          allowEmpty={true}
                          allowShowId={true}
                          onChange={(e) => setImpersonatedUser(e.target.value)}
                          touched={false}
                          error={undefined}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          type="submit"
                          className={styles.searchButton}
                          aria-label="search"
                          onClick={() => getLicensesAsync(impersonatedUser)}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setDialogOpen(true)}
                  >
                    {res.viewAs}
                  </Button>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        <Backdrop className={styles.backdrop} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <ToastContainer />

        {canActivate && (
          <Button
            variant="contained"
            color="primary"
            disabled={activateOnBehalfOf ? false : true}
            onClick={() => openNewLicenseDialog()}
          >
            Activate License
          </Button>
        )}

        {!isLoading && (
          <Box className={styles.gridContainer}>
            <MyLicensesGrid rows={licenses} onOpenDialog={openLicenseDetails} />
          </Box>
        )}
        {dialogLicense && (
          <LicenseDialog
            license={dialogLicense}
            open={showLicenseDialog}
            isAdmin={loggedUser.isAdmin}
            handleUninstall={handleUninstallLicense}
            handleCancel={() => setShowLicenseDialog(false)}
          />
        )}
        {activateOnBehalfOf && programAndLicenses && (
          <NewLicenseDialog
            open={showNewLicenseDialog}
            user={currentUser}
            onBehalfOf={activateOnBehalfOf}
            programsAndLicenses={programAndLicenses}
            handleSubmit={handleActivateLicense}
            handleClose={closeNewLicenseDialog}
            handleNotification={showMessage}
          />
        )}
        {programAndLicenses && isMultipleLocations() && (
          <Grid item container direction="column" alignItems="center">
            <Typography variant="h5" color="primary">
              {res.architectureHeader}
            </Typography>
            <LicensesArchitectureMap programAndLicenses={programAndLicenses} />
          </Grid>
        )}
      </div>
    </Container>
  ) : (
    <div>Your session has expired</div>
  );
};

export default MyLicensesPage;
