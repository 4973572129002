import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import menuButtonDark from "../../images/menu-button-dark.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faAdjust, faGlobe } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ISession, ILoggedUser } from "../../types/Auth";
import darkLogo from "../../images/softion-logo-dark.svg";
import IResources, { resources } from "./.resources";
import { LanguageOptions, ThemeOptions } from "../../types/Enums";
import Badge from "@material-ui/core/Badge";
import PersonIcon from "@material-ui/icons/Person";
import * as urls from "../../types/Constants";

export interface IHeaderProps {
  session: ISession;
  themeName: "dark" | "light" | undefined;
  onMenuClick(): void;
  onSwitchLanguage(name: LanguageOptions): void;
  onSwitchTheme(name: ThemeOptions): void;
  onLogin(): void;
  onLogout(): void;
}

const Header: React.FC<IHeaderProps> = ({
  onMenuClick,
  session,
  themeName,
  onSwitchLanguage,
  onSwitchTheme,
  onLogin,
  onLogout,
}) => {
  const loggedUser: ILoggedUser = useSelector((state: RootState) => {
    return state.userInfo;
  });

  const res: IResources = resources;
  const language: string = useSelector((state: RootState) => {
    resources.setLanguage(state.language);
    return state.language;
  });

  const themeToString = (): string => {
    if (themeName === "dark") return res.switchToLightMode;
    return res.switchToDarkMode;
  };

  const [userError, setUserError] = useState<boolean>();

  useEffect(() => {
    setUserError(false);
    if (loggedUser?.error) {
      setUserError(true);
    }
  }, [loggedUser]);

  return (
    <Navbar
      bg="dark"
      variant="dark"
      className="navbar navbar-expand-lg navbar-inverse navbar-fixed-top"
    >
      <Navbar.Brand className="nav-logo">
        <NavLink to="/" exact className="nav-link">
          <span>
            <img src={darkLogo} className="logo" alt="Softion" />
          </span>
        </NavLink>
      </Navbar.Brand>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Item className="desktop-screen">
            <Nav.Link as={NavLink} to="/services" exact className="nav-link">
              {res.services}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="desktop-screen">
            <Nav.Link as={NavLink} to="/products" exact className="nav-link">
              {res.products}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
      {session && session.loggedIn === true ? (
        <Nav className="mr-auto">
          <Nav.Item className="ml-auto desktop-screen">
            <Nav.Link
              className="nav-link"
              onClick={() =>
                onSwitchTheme(themeName === "dark" ? "light" : "dark")
              }
              href="#"
            >
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id="tooltip-theme">{themeToString()}</Tooltip>
                }
              >
                <FontAwesomeIcon icon={faAdjust} />
              </OverlayTrigger>
            </Nav.Link>
          </Nav.Item>
          <NavDropdown
            id="nav-dropdown-language"
            className="ml-auto desktop-screen"
            title={
              <div
                className="justify-content-end"
                style={{ display: "inline-block" }}
              >
                <FontAwesomeIcon icon={faGlobe} />
                <span style={{ margin: "12px" }}>{language}</span>
              </div>
            }
          >
            <NavDropdown.Item
              eventKey="languageEn"
              onSelect={() => onSwitchLanguage("en")}
            >
              English
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="languageEs"
              onSelect={() => onSwitchLanguage("es")}
            >
              Español
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            id="nav-dropdown-account"
            className="desktop-screen"
            title={
              <div
                className="justify-content-end"
                style={{ display: "inline-block", minWidth: "90px" }}
              >
                <Badge
                  color="error"
                  badgeContent="!"
                  invisible={userError === false}
                >
                  <PersonIcon />
                </Badge>

                {/* <FontAwesomeIcon icon={faUser} /> */}
                <span style={{ margin: "12px" }}>{session.user}</span>
              </div>
            }
          >
            <NavDropdown.Item
              eventKey="4.1"
              as={NavLink}
              to="/my-account"
              disabled={userError}
            >
              {res.accountDetails}
            </NavDropdown.Item>

            <NavDropdown.Item
              eventKey="4.3"
              as={NavLink}
              disabled={userError}
              to="/my-account/my-purchases"
            >
              {res.accountMyOrders}
            </NavDropdown.Item>

            <NavDropdown.Item
              eventKey="4.2"
              as={NavLink}
              disabled={userError}
              to="/my-account/my-products"
            >
              {res.accountMyProducts}
            </NavDropdown.Item>

            {(loggedUser.isCustomer === true || loggedUser.isAdmin) && (
              <NavDropdown.Item
                eventKey="4.3"
                as={NavLink}
                disabled={userError}
                to={urls.URL_MYLICENSES}
              >
                {res.accountMyLicenses}
              </NavDropdown.Item>
            )}

            {(loggedUser.isSupport === true || loggedUser.isAdmin === true) && (
              <NavDropdown.Item
                eventKey="4.4"
                as={NavLink}
                disabled={userError}
                to={urls.URL_WIKI}
              >
                Wiki
              </NavDropdown.Item>
            )}

            {loggedUser.userId && (
              <NavDropdown.Item
                eventKey="4.5"
                as={NavLink}
                disabled={userError}
                to={urls.URL_DOCUMENTS}
              >
                Documentation
              </NavDropdown.Item>
            )}

            {loggedUser.isAdmin === true && (
              <>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  eventKey="admin.projects"
                  as={NavLink}
                  to="/admin/projects"
                >
                  Projects
                </NavDropdown.Item>

                <NavDropdown.Item
                  eventKey="admin.products"
                  as={NavLink}
                  to="/admin/products"
                >
                  Products and Files
                </NavDropdown.Item>

                <NavDropdown.Item
                  eventKey="admin.users"
                  as={NavLink}
                  to="/admin/users"
                >
                  {res.users}
                </NavDropdown.Item>

                <NavDropdown.Item
                  eventKey="admin.files"
                  as={NavLink}
                  to="/admin/files"
                >
                  File Explorer
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item eventKey="4.3" as={NavLink} to="/diagnostics">
                  Diagnostics
                </NavDropdown.Item>
              </>
            )}

            <NavDropdown.Divider />
            <NavDropdown.Item
              eventKey="4.4"
              as={NavLink}
              to="/logout"
              onClick={() => onLogout()}
            >
              {res.logout}
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      ) : (
        <Nav className="mr-auto">
          <NavDropdown
            id="nav-offline-dropdown-language"
            className="ml-auto desktop-screen"
            title={
              <div
                className="justify-content-end"
                style={{ display: "inline-block" }}
              >
                <FontAwesomeIcon icon={faGlobe} />
                <span style={{ margin: "12px" }}>{language}</span>
              </div>
            }
          >
            <NavDropdown.Item
              eventKey="languageEn"
              onSelect={() => onSwitchLanguage("en")}
            >
              English
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="languageEs"
              onSelect={() => onSwitchLanguage("es")}
            >
              Español
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Item className="ml-auto desktop-screen">
            <Nav.Link className="nav-link" onClick={() => onLogin()} href="#">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-login">{res.login}</Tooltip>}
              >
                <FontAwesomeIcon icon={faUser} />
              </OverlayTrigger>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="ml-auto desktop-screen">
            <Nav.Link
              className="nav-link"
              onClick={() =>
                onSwitchTheme(themeName === "dark" ? "light" : "dark")
              }
              href="#"
            >
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-theme">{themeToString()}</Tooltip>
                }
              >
                <FontAwesomeIcon icon={faAdjust} />
              </OverlayTrigger>
            </Nav.Link>
          </Nav.Item>
        </Nav>
      )}
      <Nav id="right-buttons">
        <Form inline>
          <button type="button" className="mobile-screen sidebar-menu-button">
            <img
              alt="open"
              src={menuButtonDark}
              onClick={() => onMenuClick()}
              style={{ width: 32, height: 32, opacity: 0.8 }}
            />
          </button>
        </Form>
      </Nav>
    </Navbar>
  );
};

export default Header;
