import React, { useState } from "react";
import { IBlobWithLinks } from "../../../api/Admin/dbFilesApi";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Link,
  Typography,
  Avatar,
  IconButton,
  Badge,
} from "@material-ui/core";
import {
  DataTypeProvider,
  SortingState,
  SearchState,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  TableColumnWidthInfo,
} from "@devexpress/dx-react-grid";
import {
  Grid as DevExpressGrid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  SearchPanel,
  TableColumnResizing,
  TableColumnVisibility,
  Toolbar,
  ColumnChooser,
} from "@devexpress/dx-react-grid-material-ui";

import Moment from "react-moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import GroupIcon from "@material-ui/icons/Group";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import FileCopyIcon from "@material-ui/icons/FileCopy";

//https://devexpress.github.io/devextreme-reactive/react/grid/demos/featured/tree-data/

export interface IBlobsGridProps {
  files: IBlobWithLinks[];
  handleOpenFile: (row: IBlobWithLinks) => void;
  handleOpenProducts: (row: IBlobWithLinks) => void;
  handleOpenUsers: (row: IBlobWithLinks) => void;
  handleNotification: (msg: string | null | undefined) => void;
}

const BlobsGrid: React.FC<IBlobsGridProps> = ({
  files,
  handleOpenFile,
  handleOpenProducts,
  handleOpenUsers,
  handleNotification,
}) => {
  const [columns] = useState([
    { name: "displayName", title: "Name" },
    { name: "description", title: "Description" },
    { name: "date", title: "Date" },
    { name: "category", title: "Category" },
    { name: "version", title: "Ver." },
    { name: "language", title: "Lang." },
    { name: "platform", title: "Platform" },
    { name: "size", title: "Size" },
    { name: "isActive", title: "Active" },
    { name: "isInUse", title: "In Use" },
    { name: "usageType", title: "Used By" },
    { name: "action", title: "Action" },
  ]);

  const [columnWidths, setColumnWidths] = useState<TableColumnWidthInfo[]>([
    { columnName: "displayName", width: 460 },
    { columnName: "description", width: 150 },
    { columnName: "date", width: 150 },
    { columnName: "category", width: 150 },
    { columnName: "version", width: 150 },
    { columnName: "language", width: 150 },
    { columnName: "platform", width: 150 },
    { columnName: "size", width: 150 },
    { columnName: "isActive", width: 150 },
    { columnName: "isInUse", width: 150 },
    { columnName: "usageType", width: 150 },
    { columnName: "action", width: 200 },
  ]);

  const [defaultHiddenColumnNames] = useState([
    "description",
    "language",
    "platform",
    "size",
    "usageType",
    "isInUse",
    "isActive",
  ]);

  const shareToClipboard = (file: IBlobWithLinks) => {
    navigator.clipboard.writeText(file.url || "").then(() => {
      handleNotification("Copied to clipboard: " + file.url);
    });
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const useStyles = makeStyles((theme) => ({
    avatar: {
      width: "34px",
      height: "34px",
    },
    avatarWithCategory: {
      backgroundColor: theme.palette.primary.main,
      width: "34px",
      height: "34px",
    },
    productNameLink: {
      margin: "0 0 3px 0",
    },
    actionButton: {
      margin: "0 3px 0 3px",
    },
    badgeButton: {
      margin: "0 3px 0 6px",
    },
  }));
  const styles = useStyles();

  const [nameColumn] = useState(["displayName"]);
  const FileNameColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      <Grid item xs={2}>
        {row.category ? (
          <Avatar className={styles.avatarWithCategory}>
            {row.category.substring(0, 1)}
          </Avatar>
        ) : (
          <Avatar className={styles.avatar}>-</Avatar>
        )}
      </Grid>
      <Grid
        item
        xs={10}
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Link
          href="#"
          color="inherit"
          className={styles.productNameLink}
          onClick={() => handleOpenFile(row)}
        >
          <u> {row.displayName}</u>
        </Link>
        <Typography variant="caption">{row.description}</Typography>
      </Grid>
    </Grid>
  );

  const [actionColumn] = useState(["action"]);
  const ActionColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => {
    const file = files.find((f) => f.fileId === row.fileId);
    const productCount = file?.products.length;
    const userCount = file?.users.length;
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {file && (
          <>
            <IconButton
              disabled={row.isActive !== true}
              href={row.url}
              size="small"
              className={styles.actionButton}
              target="_blank"
            >
              <GetAppIcon />
            </IconButton>

            <Badge badgeContent={userCount} color="primary">
              <IconButton
                size="small"
                className={styles.badgeButton}
                onClick={() => handleOpenUsers(file)}
              >
                <GroupIcon />
              </IconButton>
            </Badge>

            <Badge badgeContent={productCount} color="primary">
              <IconButton
                size="small"
                className={styles.badgeButton}
                onClick={() => handleOpenProducts(file)}
              >
                <LocalOfferIcon />
              </IconButton>
            </Badge>

            <IconButton
              disabled={row.isActive !== true}
              onClick={() => shareToClipboard(row)}
              className={styles.actionButton}
              size="small"
            >
              <FileCopyIcon />
            </IconButton>
          </>
        )}
      </Grid>
    );
  };

  const [dateColumn] = useState(["date"]);
  const DateColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => <Moment format="DD/MM/YYYY">{row.date}</Moment>;

  const [sizeColumn] = useState(["size"]);
  const SizeColumnRenderer: React.ComponentType<DataTypeProvider.ValueFormatterProps> = ({
    row,
  }) => <Typography>{formatBytes(row.size)}</Typography>;

  return (
    <DevExpressGrid rows={files} columns={columns}>
      <SearchState />
      <SortingState />
      <FilteringState />
      <DataTypeProvider
        for={nameColumn}
        formatterComponent={FileNameColumnRenderer}
      />
      <DataTypeProvider
        for={dateColumn}
        formatterComponent={DateColumnRenderer}
      />
      <DataTypeProvider
        for={actionColumn}
        formatterComponent={ActionColumnRenderer}
      />
      <DataTypeProvider
        for={sizeColumn}
        formatterComponent={SizeColumnRenderer}
      />
      <IntegratedFiltering />
      <IntegratedSorting />
      <Table />
      <TableColumnResizing
        columnWidths={columnWidths}
        onColumnWidthsChange={setColumnWidths}
      />
      <TableColumnVisibility
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
      <TableHeaderRow showSortingControls />
      <Toolbar />
      <TableFilterRow />
      <SearchPanel />
      <ColumnChooser />
    </DevExpressGrid>
  );
};

export default BlobsGrid;
