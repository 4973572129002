import React from "react";

export interface IMailToLinkProps {
  emailAddress: string;
  subject?: string;
  body?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class MailToLink extends React.Component<IMailToLinkProps, any> {
  render() {
    const subject = this.props.subject ? `subject=${this.props.subject}` : ``;
    const bodyDelim = this.props.subject ? "&" : "?";
    const body = this.props.body ? `${bodyDelim}body=${this.props.body}` : ``;
    return (
      <a
        href={`mailto:${this.props.emailAddress}${subject}${body}`}
      >{`${this.props.emailAddress}`}</a>
    );
  }
}

export default MailToLink;
