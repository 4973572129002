import React, { useState, useEffect } from "react";
import { ProjectFile } from "@softion/b2c-service";
import { ProductInfo } from "../../../api/Account/myProductsApi";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import Moment from "react-moment";
import FileInformationDialog from "./FileInformationDialog";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import prettyBytes from "pretty-bytes";
import IResources from "./.resources";
import { Grid } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

export interface IMyProductsGridProps {
  projects: ProductInfo[];
  resources: IResources;
  onMessage: (message: string | null | undefined) => void;
  onGoToDetails: (file: ProjectFile) => void;
}

export interface IMyProductRowProps {
  row: ProductInfo;
  onOpenDialog: (row: ProjectFile, group: string | undefined) => void;
}

const MyProductsGrid: React.FC<IMyProductsGridProps> = ({
  projects,
  resources,
  onMessage,
  onGoToDetails,
}) => {
  const [rows, setRows] = useState<ProductInfo[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogFile, setDialogFile] = useState<ProjectFile | undefined>(
    undefined
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      table: {
        maxWidth: 1024,
      },
      nestedListItem: {
        paddingLeft: theme.spacing(4),
      },
      expandButton: {
        width: "32px",
        height: "32px",
        margin: "2px 9px 2px 0",
      },
    })
  );
  const styles = useStyles();

  const [listItemOpen, setListItemOpen] = useState<boolean[]>(
    Array(999).fill(false)
  );
  const listItemClick = (index: number, value: boolean) => {
    setListItemOpen({ [index]: value });
  };

  const useRowStyles = makeStyles({
    root: {
      "& > *": {
        borderBottom: "unset",
      },
    },
  });

  const openDialog = (file: ProjectFile) => {
    setDialogFile(file);
    setIsDialogOpen(true);
  };

  useEffect(() => {
    setRows(projects);
  }, []);

  function Row(props: IMyProductRowProps) {
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell align="left">
            <Grid container direction="row" justify="flex-start">
              <IconButton
                aria-label="expand row"
                size="small"
                className={styles.expandButton}
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
              <Typography variant="subtitle1" gutterBottom component="div">
                {props.row.groupName}
              </Typography>
            </Grid>
          </TableCell>

          <TableCell />
          <TableCell />
          <TableCell align="left">
            <Hidden smDown>
              {props.row.orderDate ? (
                <Moment format="DD/MM/YYYY">{props.row.orderDate}</Moment>
              ) : null}
            </Hidden>
          </TableCell>
          <TableCell align="left">
            <Hidden smDown>{props.row.latestVersion}</Hidden>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="documents">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>{resources.fileSize}</TableCell>
                      <TableCell>{resources.fileDate}</TableCell>
                      <TableCell>{resources.fileVersion}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.row?.files &&
                      props.row.files.map((documentRow) => (
                        <TableRow key={documentRow.url}>
                          <TableCell component="th" scope="row">
                            {documentRow.isActive || false ? (
                              <Link
                                onClick={() =>
                                  onGoToDetails(
                                    documentRow,
                                    props.row.groupName
                                  )
                                }
                                target="_blank"
                                color="inherit"
                              >
                                <u>{documentRow.displayName}</u>
                              </Link>
                            ) : (
                              <div>{documentRow.displayName}</div>
                            )}
                          </TableCell>

                          <TableCell>
                            {documentRow.size
                              ? prettyBytes(documentRow.size)
                              : null}
                          </TableCell>

                          <TableCell>
                            {documentRow.date ? (
                              <Moment format="DD/MM/YYYY">
                                {documentRow.date}
                              </Moment>
                            ) : null}
                          </TableCell>

                          <TableCell>{documentRow.version}</TableCell>
                          <TableCell>
                            <IconButton
                              size="small"
                              href={documentRow.url}
                              target="_blank"
                            >
                              <GetAppIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      groupName: PropTypes.string.isRequired,
      productId: PropTypes.string.isRequired,
      productName: PropTypes.string.isRequired,
      orderDate: PropTypes.string,
      latestVersion: PropTypes.string,
      files: PropTypes.arrayOf(
        PropTypes.shape({
          displayName: PropTypes.string.isRequired,
          size: PropTypes.number,
          location: PropTypes.string.isRequired,
          releaseNotes: PropTypes.string,
          description: PropTypes.string,
          isActive: PropTypes.bool,
        })
      ).isRequired,
    }).isRequired,
  };

  return (
    <div>
      <Hidden xsDown>
        <TableContainer>
          <Table className={styles.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">{resources.orderDate}</TableCell>
                <TableCell align="left">
                  {resources.orderLatestVersion}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((downloadRow) => (
                <Row
                  key={downloadRow.productId}
                  row={downloadRow}
                  onOpenDialog={(p) => openDialog(p)}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden smUp>
        <List component="nav" aria-labelledby="nested-list-subheader">
          {rows.map((downloadRow, index) => (
            <div key={downloadRow.productId}>
              <ListItem
                button
                onClick={() => listItemClick(index, !listItemOpen[index])}
              >
                <ListItemIcon>
                  <LocalOfferOutlinedIcon />
                </ListItemIcon>
                <ListItemText>{downloadRow.groupName}</ListItemText>
                {listItemOpen[index] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={listItemOpen[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {downloadRow?.files &&
                    downloadRow.files.map((fileRow) => (
                      <ListItem
                        button
                        key={fileRow.url}
                        className={styles.nestedListItem}
                        onClick={() => openDialog(fileRow)}
                      >
                        <ListItemText
                          primary={fileRow.displayName}
                          secondary={fileRow.description}
                        />
                        <MoreHorizIcon />
                      </ListItem>
                    ))}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </Hidden>
      <FileInformationDialog
        file={dialogFile}
        resources={resources}
        open={isDialogOpen}
        onMessage={onMessage}
        onClose={() => setIsDialogOpen(false)}
      ></FileInformationDialog>
    </div>
  );
};

export default MyProductsGrid;
