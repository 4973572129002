/* eslint-disable */
import { setupAxios, processApiRequest } from "../apiHelper";
import { string } from "yup";

//SETUP
const baseUrl = process.env.PROJECTS_API;
const baseApi = setupAxios(baseUrl!);

export interface IProductFile {
  productFileId: number;
  fileId: number;
  displayName: string;
  category?: string;
  url: string;
  productId: string;
  version?: string;
  productName?: string;
  language?: string;
  platform?: string;
  description?: string;
  releaseNotes?: string;
  date?: Date;
  size?: number;
  isActive: boolean;
}

export interface IFileUserScope {
  programId: string;
  programName: string;
  orderId: string;
  userId: string;
  userName: string;
  scopeId: string;
}

export const getProductFiles = async (
  productId: string,
  onSuccess: (result: IProductFile[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "products/" + productId + "/files";
  await processApiRequest(baseApi.get<IProductFile[]>(url), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const getUsersForFile = async (
  productId: string,
  fileId: number,
  onSuccess: (result: IFileUserScope[]) => void,
  errorMessage: (msg: string) => void
) => {
  const url = "products/" + productId + "/users/" + fileId;
  await processApiRequest(baseApi.get<IFileUserScope[]>(url), {
    onSuccess: (response) => onSuccess(response.data),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const addProductFile = async (
  request: IProductFile,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "products/" + request.productId + "/files";
  await processApiRequest(baseApi.post<IProductFile>(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const updateProductFile = async (
  request: IProductFile,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url = "products/" + request.productId + "/files";
  await processApiRequest(baseApi.put<IProductFile>(url, request), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};

export const deleteProductFile = async (
  request: IProductFile,
  onSuccess: () => void,
  errorMessage: (msg: string) => void
) => {
  const url =
    "products/" + request.productId + "/files/" + request.productFileId;
  await processApiRequest(baseApi.delete(url), {
    onSuccess: (response) => onSuccess(),
    badRequest: (msg) => errorMessage(msg),
    notFound: (msg) => errorMessage(msg),
    forbidden: (msg) =>
      errorMessage("You are not authorized to permform this action"),
  });
};
